import React, { useCallback, useMemo, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { gql } from "@apollo/client";
import styled from "styled-components";
import { space } from "styled-system";

import Box from "../components/Box";
import DataLoader from "../components/DataLoader";
import Grid from "../components/Grid";
import Page from "../components/Page";
import TestProductList from "../components/TestProductList";
import Text from "../components/Text";
import { theme } from "../core/theme";
import { ALL_TESTS_SLUG, getQuestionnaireIntroUrl } from "../core/urls";
import { TEST_PRODUCT_FAMILY_FIELDS, TEST_PRODUCT_FIELDS } from "../graphql/shop";
import { USER_TEST_RECOMMENDATION_FIELDS } from "../graphql/tpo/results/types";
import useWindowSize from "../hooks/use-window-size";
import { ReactComponent as CrossIcon } from "../images/cross.svg";
import { ReactComponent as Pencil } from "../images/pencil.svg";
import { ReactComponent as Logo } from "../images/tpo/omnos-navbar-logo-mobile.svg";
import { PanelBox, SpacedContentBox } from "./Boxes";
import { SolidButton, CircleButton, FilterButton } from "./Buttons";
import ChevronComponent from "./Chevron";
import { SolidChevronButton } from "./ChevronButton";
import { CollapseableText } from "./CollapseableText";
import GridIcon from "./GridIcon";
import { HeadingExtraLarge } from "./Headings";
import ListIcon from "./ListIcon";
import Menu from "./Menu";
import { GridTestProductCard, StackedTestProductCard } from "./TestProductCard";

const TEST_PRODUCTS_QUERY = gql`
  query TestProductsQuery($sector: String, $dimensions: [ImageDimensionsInputType]) {
    testProducts(sector: $sector) {
      ...TestProductFields
      shot1Purple(dimensions: $dimensions) {
        resizeUrl
        width
      }
    }
    testProductFamilies {
      ...TestProductFamilyFields
    }
    userTestRecommendations {
      ...UserTestRecommendationMinimalFields
    }
  }
  ${TEST_PRODUCT_FIELDS}
  ${TEST_PRODUCT_FAMILY_FIELDS}
  ${USER_TEST_RECOMMENDATION_FIELDS}
`;

function parseQueryParams(search) {
  return decodeURI(search)
    .split("&")
    .map(part => part.split("="))
    .filter(part => part.length === 2)
    .reduce(
      (filters, [key, values]) => ({
        ...filters,
        [key]: values.split(",")
      }),
      {}
    );
}

function stringifyQueryParams(params) {
  return Object.entries(params)
    .map(([key, values]) => [key, Array.isArray(values) ? values.join(",") : values])
    .filter(([key, values]) => values)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
}

const MenuItem = styled.div`
  ${space}
  background-color: ${props => (props.selected ? "#f2f2f2" : "inherit")};
  &:hover {
    background-color: #f2f2f2;
  }
  cursor: pointer;
`;

const GENDERS = ["All", "Male", "Female"];

function filterTestProducts(testProducts, { genderedFilter, sampleTypes, areasOfInterest }) {
  return testProducts
    .filter(
      testProduct =>
        !testProduct.specifiedSex ||
        genderedFilter === "All" ||
        testProduct.specifiedSex === genderedFilter
    )
    .filter(
      testProduct =>
        !sampleTypes.length ||
        testProduct.sampleTypes.some(sampleType => sampleTypes.includes(sampleType))
    )
    .filter(
      testProduct =>
        !areasOfInterest.length ||
        testProduct.areasOfInterest.some(areaOfInterest => areasOfInterest.includes(areaOfInterest))
    );
}

function SelfAssessmentCard({ stacked }) {
  const history = useHistory();

  return (
    <Box display="flex" flexDirection={stacked ? "column" : "row"} borderRadius={5}>
      <Box
        bg="white"
        borderTopLeftRadius={5}
        borderTopRightRadius={5}
        height={stacked ? "300px" : "100%"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        width={stacked ? "100%" : "40%"}
        minWidth={stacked ? "100%" : "40%"}
        minHeight={!stacked ? "300px" : undefined}
      >
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Box
            borderRadius="33%"
            borderColor="dark"
            borderStyle="solid"
            size={[60, 100]}
            borderWidth={5}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box width={[23, 36]}>
              <Pencil fill={theme.colors.dark} width="100%" />
            </Box>
          </Box>
          <Text fontSize={18} fontFamily="gilroyBold" mt={30}>
            Free self assessment
          </Text>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        backgroundColor="blue"
        p={[4, 4, 5, 6]}
        flexGrow={1}
      >
        <Text color="white" fontSize={stacked ? 18 : 28} fontFamily="gilroyBold">
          Which test is right for you?
        </Text>
        <Text color="white" fontSize={16} my={23} pb={50}>
          For a comprehensive assessment, take our FREE, in-depth questionnaire, which ranks all
          tests to reveal your ultimate match. Start exploring now and unlock a healthier you!
        </Text>
        <SolidChevronButton
          bg="green"
          mt="auto"
          mr={stacked ? null : "auto"}
          handleClick={() => history.push(getQuestionnaireIntroUrl("symptoms"))}
        >
          <Box component="span" color="inherit" display={["inline-block", "inline-block", "none"]}>
            symptom questionnaire
          </Box>
          <Box component="span" color="inherit" display={["none", "none", "inline-block"]}>
            free symptom questionnaire
          </Box>
        </SolidChevronButton>
      </Box>
    </Box>
  );
}

function ListView({
  testProducts,
  areasOfInterest,
  sampleTypes,
  genderedFilter,
  userTestRecommendations
}) {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 1024;

  return (
    <Grid mt={40}>
      <SelfAssessmentCard stacked={isMobile} />
      <TestProductList
        familySlug={ALL_TESTS_SLUG}
        testProducts={filterTestProducts(testProducts, {
          areasOfInterest,
          sampleTypes,
          genderedFilter
        })}
        matchedSymptomsMap={userTestRecommendations.reduce(
          (acc, cur, i) => ({
            ...acc,
            [cur.product.id]: { symptoms: cur.symptoms, rank: i }
          }),
          {}
        )}
        stacked={isMobile}
        TestProductCard={StackedTestProductCard}
      />
    </Grid>
  );
}

function GridView({
  testProducts,
  areasOfInterest,
  sampleTypes,
  genderedFilter,
  userTestRecommendations
}) {
  return (
    <Grid
      mt={40}
      gridTemplateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)"]}
      gap={10}
    >
      <SelfAssessmentCard stacked />
      <TestProductList
        familySlug={ALL_TESTS_SLUG}
        testProducts={filterTestProducts(testProducts, {
          areasOfInterest,
          sampleTypes,
          genderedFilter
        })}
        matchedSymptomsMap={userTestRecommendations.reduce(
          (acc, cur, i) => ({
            ...acc,
            [cur.product.id]: { symptoms: cur.symptoms, rank: i }
          }),
          {}
        )}
        stacked
        TestProductCard={GridTestProductCard}
      />
    </Grid>
  );
}

function TestProductsPageLoadedContent({ userTestRecommendations, testProducts }) {
  const location = useLocation();

  const [openAreasOfInterestMenu, setOpenAreasOfInterestMenu] = useState(false);
  const [openSampleTypesMenu, setOpenSampleTypesMenu] = useState(false);

  const areasOfInterest = useMemo(
    () => [
      ...new Set(
        testProducts
          .map(testProduct => testProduct.areasOfInterest)
          .reduce((merged, arr) => [...merged, ...arr], [])
      )
    ],
    [testProducts]
  );

  const areasOfInterestOptions = areasOfInterest.map(areaOfInterest => ({
    label: areaOfInterest,
    value: areaOfInterest
  }));

  const sampleTypes = useMemo(
    () => [
      ...new Set(
        testProducts
          .map(testProduct => testProduct.sampleTypes)
          .reduce((merged, arr) => [...merged, ...arr], [])
      )
    ],
    [testProducts]
  );

  const sampleTypeOptions = sampleTypes.map(sampleType => ({
    label: sampleType,
    value: sampleType
  }));

  const history = useHistory();

  const searchParams = location.search?.slice(1, undefined) || "";

  const filters = useMemo(() => parseQueryParams(searchParams), [searchParams]);

  const genderedFilter = GENDERS.includes(filters.gender?.[0]) ? filters.gender?.[0] : "All";

  const areasOfInterestUsed = useMemo(
    () => [
      ...new Set(
        Array.isArray(filters.areas_of_interest)
          ? [...new Set(filters.areas_of_interest)].filter(areaOfInterest =>
              areasOfInterest.includes(areaOfInterest)
            )
          : []
      )
    ],
    [filters, areasOfInterest]
  );

  const sampleTypesUsed = useMemo(
    () => [
      ...new Set(
        Array.isArray(filters.sample_types)
          ? [...new Set(filters.sample_types)].filter(sampleType =>
              sampleTypes.includes(sampleType)
            )
          : []
      )
    ],
    [filters, sampleTypes]
  );

  const [view, setView] = useState("grid");

  const removeAreaOfInterest = useCallback(
    filter => {
      let filteredAreasOfInterest = new Set(areasOfInterestUsed);
      filteredAreasOfInterest.delete(filter);
      history.push({
        pathname: location.pathname,
        search: stringifyQueryParams({
          ...filters,
          areas_of_interest: [...filteredAreasOfInterest]
        })
      });
    },
    [areasOfInterestUsed, history, location, filters]
  );

  const removeSampleType = useCallback(
    filter => {
      let filteredSampleTypes = new Set(sampleTypesUsed);
      filteredSampleTypes.delete(filter);
      history.push({
        pathname: location.pathname,
        search: stringifyQueryParams({
          ...filters,
          sample_types: [...filteredSampleTypes]
        })
      });
    },
    [sampleTypesUsed, history, location, filters]
  );

  const removeFilter = useCallback(
    filter => {
      if (areasOfInterestUsed.includes(filter)) {
        removeAreaOfInterest(filter);
      } else if (sampleTypesUsed.includes(filter)) {
        removeSampleType(filter);
      }
    },
    [removeAreaOfInterest, removeSampleType, areasOfInterestUsed, sampleTypesUsed]
  );

  return (
    <>
      <Box>
        <Box display="flex" gap={10}>
          <FilterButton
            handleClick={() => {
              history.push({
                pathname: location.pathname,
                search: stringifyQueryParams({
                  ...filters,
                  gender: "All"
                })
              });
            }}
            selected={"All" === genderedFilter}
          >
            All
          </FilterButton>
          <FilterButton
            handleClick={() => {
              history.push({
                pathname: location.pathname,
                search: stringifyQueryParams({
                  ...filters,
                  gender: "Male"
                })
              });
            }}
            selected={"Male" === genderedFilter}
          >
            Male
          </FilterButton>
          <FilterButton
            handleClick={() => {
              history.push({
                pathname: location.pathname,
                search: stringifyQueryParams({
                  ...filters,
                  gender: "Female"
                })
              });
            }}
            selected={"Female" === genderedFilter}
          >
            Female
          </FilterButton>
        </Box>
        <Box
          display="flex"
          flexDirection={["column", "row"]}
          justifyContent="space-between"
          mt={4}
          mb={0}
          gap={[20, 0]}
        >
          <Box display="flex" flexDirection={["column", "row"]} gap={10}>
            <Menu opened={openAreasOfInterestMenu} onChange={setOpenAreasOfInterestMenu}>
              <Menu.Target>
                <SolidButton
                  {...FilterButton.defaultProps}
                  color="dark"
                  bg="white"
                  borderColor="white"
                  textTransform="unset"
                  fontSize="16px"
                  letterSpacing={0}
                >
                  Areas of interest
                  <ChevronComponent
                    direction={openAreasOfInterestMenu ? "bottom" : "top"}
                    fill={theme.colors.dark}
                    style={{
                      marginLeft: 10
                    }}
                  />
                </SolidButton>
              </Menu.Target>
              <Menu.Dropdown mt={2} py={2} zIndex={2} minWidth={320}>
                {areasOfInterestOptions.map(option => (
                  <Menu.Item
                    key={option.value}
                    onClick={() => {
                      if (areasOfInterestUsed.includes(option.value)) {
                        let filteredAreasOfInterest = new Set(areasOfInterestUsed);
                        filteredAreasOfInterest.delete(option.value);
                        history.push({
                          pathname: location.pathname,
                          search: stringifyQueryParams({
                            ...filters,
                            areas_of_interest: [...filteredAreasOfInterest]
                          })
                        });
                      } else {
                        history.push({
                          pathname: location.pathname,
                          search: stringifyQueryParams({
                            ...filters,
                            areas_of_interest: [...areasOfInterestUsed, option.value]
                          })
                        });
                      }
                    }}
                    selected={areasOfInterestUsed.includes(option.value)}
                  >
                    {option.label}
                  </Menu.Item>
                ))}
                {!areasOfInterestOptions.length && (
                  <Box px={4} py={2}>
                    <Text textAlign="center">None found</Text>
                  </Box>
                )}
              </Menu.Dropdown>
            </Menu>
            <Menu opened={openSampleTypesMenu} onChange={setOpenSampleTypesMenu}>
              <Menu.Target>
                <SolidButton
                  {...FilterButton.defaultProps}
                  color="dark"
                  bg="white"
                  borderColor="white"
                  textTransform="unset"
                  fontSize="16px"
                  letterSpacing={0}
                >
                  Sample Types
                  <ChevronComponent
                    direction={openSampleTypesMenu ? "bottom" : "top"}
                    fill={theme.colors.dark}
                    style={{
                      marginLeft: 10
                    }}
                  />
                </SolidButton>
              </Menu.Target>
              <Menu.Dropdown mt={2} py={2} zIndex={2} minWidth={320}>
                {sampleTypeOptions.map(option => (
                  <Menu.Item
                    key={option.value}
                    onClick={() => {
                      if (sampleTypesUsed.includes(option.value)) {
                        let filteredSampleTypes = new Set(sampleTypesUsed);
                        filteredSampleTypes.delete(option.value);
                        history.push({
                          pathname: location.pathname,
                          search: stringifyQueryParams({
                            ...filters,
                            sample_types: [...filteredSampleTypes]
                          })
                        });
                      } else {
                        history.push({
                          pathname: location.pathname,
                          search: stringifyQueryParams({
                            ...filters,
                            sample_types: [...sampleTypesUsed, option.value]
                          })
                        });
                      }
                    }}
                  >
                    <MenuItem selected={sampleTypesUsed.includes(option.value)} px={4} py={2}>
                      {option.label}
                    </MenuItem>
                  </Menu.Item>
                ))}
                {!sampleTypeOptions.length && (
                  <Box px={4} py={2}>
                    <Text textAlign="center">None found</Text>
                  </Box>
                )}
              </Menu.Dropdown>
            </Menu>
          </Box>
          <Box display="flex" gap={10} alignItems="center" justifyContent={["flex-end", null]}>
            <CircleButton
              bg={view === "grid" ? "dark" : "white"}
              height={40}
              width={40}
              onClick={() => setView("grid")}
            >
              <GridIcon fill={view === "grid" ? "white" : "dark"} />
            </CircleButton>
            <CircleButton
              bg={view === "list" ? "dark" : "white"}
              height={40}
              width={40}
              onClick={() => setView("list")}
            >
              <ListIcon fill={view === "list" ? "white" : "dark"} />
            </CircleButton>
          </Box>
        </Box>
        {!![...areasOfInterestUsed, ...sampleTypesUsed].length && (
          <Box display="flex" flexWrap="wrap" alignItems="center" gap={[20, 10]} mt={4}>
            <Box display="flex" alignItems="center" gap={10} flexWrap="wrap">
              {[...areasOfInterestUsed, ...sampleTypesUsed].map(text => (
                <SolidButton key={text} {...FilterButton.defaultProps}>
                  <span onClick={() => removeFilter(text)}>
                    <CrossIcon
                      fill="white"
                      width="10px"
                      height="10px"
                      style={{
                        marginRight: 10
                      }}
                    />
                  </span>
                  {text}
                </SolidButton>
              ))}
            </Box>
            {(!!areasOfInterestUsed.length || !!sampleTypesUsed.length) && (
              <Text
                underline
                cursor="pointer"
                onClick={() =>
                  history.push({
                    pathname: location.pathname
                  })
                }
              >
                Clear all filters
              </Text>
            )}
          </Box>
        )}
      </Box>
      {view === "grid" && (
        <GridView
          sampleTypes={sampleTypesUsed}
          areasOfInterest={areasOfInterestUsed}
          genderedFilter={genderedFilter}
          testProducts={testProducts}
          userTestRecommendations={userTestRecommendations}
        />
      )}
      {view === "list" && (
        <ListView
          sampleTypes={sampleTypesUsed}
          areasOfInterest={areasOfInterestUsed}
          genderedFilter={genderedFilter}
          testProducts={testProducts}
          userTestRecommendations={userTestRecommendations}
        />
      )}
    </>
  );
}

export default function TestProductsPage() {
  return (
    <Page backgroundColor="haze">
      <SpacedContentBox bg="purple" maxWidth={600} pt={40} pb={60} px={20}>
        <Box display="flex" flexDirection={["column", "row"]} gap={40} alignItems="center">
          <Logo
            height={150}
            width={150}
            style={{
              minWidth: 150
            }}
          />
          <Box>
            <HeadingExtraLarge color="white" fontSize={[24, 24, 36]}>
              Omnos Shop
            </HeadingExtraLarge>
            <Text color="white" mt={40}>
              Welcome to the Omnos Shop! Discover our wide range of functional tests designed to
              guide you on your health & wellness journey.
            </Text>
          </Box>
        </Box>
      </SpacedContentBox>
      <PanelBox
        maxWidth={1588}
        topPadding
        outer={{
          px: 0,
          bg: "white"
        }}
      >
        <SpacedContentBox maxWidth={600} pb={60} px={20}>
          <HeadingExtraLarge fontSize={[24, 24, 36]} mb={40}>
            Discover the right test for you
          </HeadingExtraLarge>
          <CollapseableText>
            {`Easily navigate through our selection by using our convenient filter options. Tailor your search based on your sex, sample collection type, or specific areas of interest.

            Not sure where to start? Each individual test has its own symptom questionnaire for an extra level of reassurance. Or take our self assessment to reveal what tests from our whole range best match your symptoms. Start exploring now and unlock a healthier you!
          `}
          </CollapseableText>
        </SpacedContentBox>
      </PanelBox>
      <PanelBox
        maxWidth={1588}
        bottomPadding
        outer={{
          mx: "5.498vw",
          px: 0,
          mt: 40
        }}
      >
        <DataLoader
          query={TEST_PRODUCTS_QUERY}
          variables={{
            dimensions: [
              {
                width: 990
              },
              {
                width: 1000
              }
            ]
          }}
          render={({ testProducts, userTestRecommendations }) => (
            <TestProductsPageLoadedContent
              testProducts={testProducts}
              userTestRecommendations={userTestRecommendations}
            />
          )}
        />
      </PanelBox>
    </Page>
  );
}
