import React from "react";

import { gql } from "@apollo/client";

import { getResultUrlFromSlugs } from "../core/urls";
import RecommendationWhy from "../tpo/RecommendationWhy";
import DataLoader from "./DataLoader";

const RECOMMENDED_SUPPLEMENT_QUERY = gql`
  query RecommendedSupplements($userId: ID, $supplementSlug: ID) {
    recommendedSupplement(userId: $userId, supplementSlug: $supplementSlug) {
      slug
      id
      characteristicSet {
        id
        recommendationSet {
          id
          relatedUserResults(userId: $userId) {
            id
            result {
              id
              name
            }
          }
        }
      }
    }
  }
`;

function RecommendedSupplement({ clientId, slug }) {
  return (
    <DataLoader
      query={RECOMMENDED_SUPPLEMENT_QUERY}
      variables={{ userId: clientId, supplementSlug: slug }}
      render={({ recommendedSupplement }) => {
        const relatedUserResultsForSupplement = [];
        recommendedSupplement.characteristicSet.forEach(characteristic => {
          characteristic.recommendationSet.forEach(recommendation => {
            recommendation.relatedUserResults.forEach(relatedUserResult => {
              relatedUserResultsForSupplement.push(relatedUserResult);
            });
          });
        });
        if (relatedUserResultsForSupplement.length) {
          return (
            <RecommendationWhy
              getResultUrl={getResultUrlFromSlugs}
              backgroundColor="white"
              relatedUserResults={relatedUserResultsForSupplement}
            />
          );
        } else {
          return false;
        }
      }}
    />
  );
}

export default RecommendedSupplement;
