import { useHistory } from "react-router-dom";

import ArrowCircle from "../components/ArrowCircle";
import Box from "../components/Box";
import { PanelBox, SpacedContentBox } from "./Boxes";
import { SegmentedProgressChart, useSegmentedProgressChart } from "./Charts";
import { SolidChevronButton } from "./ChevronButton";
import Group from "./Group";
import { HeadingExtraLarge } from "./Headings";
import Section from "./Section";
import Stack from "./Stack";

export function ScoreChart({ item }) {
  const history = useHistory();
  const preparedChartProps = useSegmentedProgressChart({ ...item.chart, fill: true });

  return (
    <Box
      onClick={() => item.href && history.push(item.href)}
      cursor={item.href ? "pointer" : "not-allowed"}
    >
      <Stack gap={10}>
        <Group alignItems="center" justifyContent="space-between">
          <Box fontFamily="gilroyBold" fontSize={18} lineHeight="130%">
            {item.title}
          </Box>
          {item.showChevron && <ArrowCircle arrowColor="white" bg="black" size={40} />}
        </Group>
        <SegmentedProgressChart {...preparedChartProps} />
      </Stack>
    </Box>
  );
}

export default function Scores({ bg, header, bodyCopy, items, buttonText, navigation }) {
  return (
    <PanelBox bg={bg} medium verticalPadding>
      {navigation}
      <PanelBox small nested>
        <Section.Header>
          <HeadingExtraLarge fontSize={[24, 34, 34, 44]}>{header}</HeadingExtraLarge>
        </Section.Header>
        <Section.BodyCopy>{bodyCopy}</Section.BodyCopy>
      </PanelBox>
      <Section.Content>
        <SpacedContentBox bg="white" borderRadius={5} maxWidth={1020} py={40}>
          <PanelBox small nested>
            {items.map((item, index) => (
              <ScoreChart item={item} key={index} />
            ))}
          </PanelBox>
        </SpacedContentBox>
      </Section.Content>
      {buttonText && (
        <PanelBox small>
          <Section.Button>
            <SolidChevronButton width={["100%", "auto"]}>{buttonText}</SolidChevronButton>
          </Section.Button>
        </PanelBox>
      )}
    </PanelBox>
  );
}

Scores.defaultProps = {
  bg: "haze"
};
