import { useEffect, useState } from "react";

import Chart from "react-apexcharts";

import { buildLineGraphConfiguration, generateMarker, getIcon } from "utils/cot";

export function LineGraph({ colors, type, height, width, fullRange, points, tooltips }) {
  const [config, setConfig] = useState(false);

  useEffect(() => {
    async function prepareOptions() {
      let icon = await getIcon();
      let annotations = await Promise.all(
        points.map(async ([x, y], index) => ({
          x: new Date(x).getTime(),
          y,
          image: {
            path: await generateMarker(icon, colors[index])
          },
          marker: {
            size: 8
          }
        }))
      );

      let { series, options } = buildLineGraphConfiguration({
        fullRange,
        points,
        tooltips
      });

      options = {
        ...options,
        annotations: {
          points: annotations
        }
      };

      setConfig({
        series,
        options
      });
    }

    prepareOptions();
  }, []);

  if (!config) return null;

  return (
    <Chart
      type={type}
      height={height}
      width={width}
      options={config.options}
      series={config.series}
    />
  );
}

LineGraph.defaultProps = {
  type: "line",
  height: 350,
  width: "100%"
};
