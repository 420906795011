import gql from "graphql-tag";

import { QUESTION_FIELDS, USER_ANSWER_FIELDS } from "./types";

export const QUESTIONS_QUERY = gql`
  query QuestionsQuery($presentationSection: Int) {
    questions(presentationSection: $presentationSection) {
      ...QuestionFields
    }
  }
  ${QUESTION_FIELDS}
`;

export const PROGRESS_BAR_QUERY = gql`
  query ProgressBarQuery($userSubmissionId: ID) {
    allQuestions: questions {
      ...QuestionFields
    }
    allUserAnswers: userAnswers(userSubmissionId: $userSubmissionId) {
      ...UserAnswerFields
    }
  }
  ${QUESTION_FIELDS}
  ${USER_ANSWER_FIELDS}
`;

export const QUESTIONNAIRE_QUERY = gql`
  query QuestionsQuery($presentationSection: Int, $userSubmissionId: ID, $productSlug: String) {
    questions(presentationSection: $presentationSection, productSlug: $productSlug) {
      ...QuestionFields
    }
    userAnswers(
      presentationSection: $presentationSection
      userSubmissionId: $userSubmissionId
      productSlug: $productSlug
    ) {
      ...UserAnswerFields
    }
  }
  ${QUESTION_FIELDS}
  ${USER_ANSWER_FIELDS}
`;

export const RECOMMENDED_SUPPLEMENTS_TRUNCATED_QUERY = gql`
  query RecommendedSupplementsTruncated {
    recommendedSupplements {
      id
    }
  }
`;
