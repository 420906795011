import { Fragment, useEffect, useMemo, useState } from "react";

import { gql, useLazyQuery } from "@apollo/client";

import Box from "../components/Box";
import { SpacedPanelBox } from "../components/Boxes";
import { HeadingLarge } from "../components/Headings";
import { DownloadCrossOriginFile, InternalTextLink } from "../components/Links";
import Loading from "../components/Loading";
import Text from "../components/Text";
import {
  getClientManagementDefaultUrl,
  getPartnerFoodListUrl,
  getPartnerQuestionnaireResultsUrl
} from "../core/urls";
import { useDataLoader } from "../hooks/useDataLoader";
import { useDebounce } from "../hooks/useDebounce";
import ChevronComponent from "./Chevron";
import { SolidChevronButton } from "./ChevronButton";
import Menu, { Button } from "./Menu";
import Pagination from "./Pagination";
import Table from "./Table";
import { Search } from "./TextInput";

function SortMenu({ open, setOpen, options, value, onChange }) {
  return (
    <Menu opened={open} onChange={setOpen}>
      <Menu.Target>
        <Button bg="dark" color="white" open={open}>
          {options.find(option => option.value === value).label}
        </Button>
      </Menu.Target>
      <Menu.Dropdown mt={2} py={2} zIndex={2} minWidth={200}>
        {options.map(sortOption => (
          <Menu.Item
            onClick={() => onChange(sortOption.value)}
            selected={sortOption.value === value}
            key={sortOption.value}
          >
            {sortOption.label}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
}

const sortOptions = [
  {
    label: "Asc",
    value: "asc"
  },
  {
    label: "Desc",
    value: "desc"
  }
];

function DesktopList({
  items,
  search,
  setSearch,
  sort,
  setSort,
  sortMenuOpen,
  setSortMenuOpen,
  pageInfo,
  setPage,
  loading
}) {
  const columns = useMemo(
    () => [
      {
        id: "email",
        label: "Name / Email",
        accessor: ({ firstName, lastName, email }) => {
          return (
            <Box display="flex" flexDirection="column" gap={10}>
              {!!(firstName && lastName) && (
                <Text fontFamily="gilroyBold" fontSize={16}>{`${firstName}, ${lastName}`}</Text>
              )}
              {!!email && <Text fontSize={16}>{email}</Text>}
            </Box>
          );
        }
      },
      {
        id: "createdDateFormatted",
        accessor: "createdDateFormatted",
        label: "Date Invited"
      },
      { id: "isAffiliate", accessor: "isAffiliate", label: "Affiliate" },
      { id: "status", accessor: "status", label: "Status" },
      {
        id: "results",
        accessor: row => {
          return <ViewResults align="right" {...row} />;
        },
        label: "Results",
        align: "center",
        width: "25%"
      }
    ],
    []
  );

  return (
    <>
      <Box display="flex" justifyContent="flex-end" gap={10}>
        <Search onChange={setSearch} value={search} />
        <SortMenu
          value={sort}
          onChange={setSort}
          open={sortMenuOpen}
          setOpen={setSortMenuOpen}
          options={sortOptions}
        />
      </Box>
      <Box py={20} />
      <Table columns={columns} rows={items} loading={loading} />
      <Box py={20} />
      {pageInfo && (
        <Pagination
          variant="default"
          totalPages={pageInfo.totalPages}
          currentPage={pageInfo.currentPage}
          hasNextPage={pageInfo.hasNextPage}
          hasPreviousPage={pageInfo.hasPreviousPage}
          onChange={setPage}
        />
      )}
    </>
  );
}

const VIEW_RESULTS_QUERY = gql`
  query ViewResults($resultsAccessLinkId: String) {
    resultsAccessLink(id: $resultsAccessLinkId) {
      id
      userTests {
        testProduct {
          id
          name
        }
        dataFiles {
          id
          name
          dataFileUrl
          testDataFile {
            id
            fileType {
              id
              name
              contentType
              extension
            }
          }
        }
        generatedPdfs {
          id
          pdfFile
          userTest {
            id
          }
        }
      }
    }
  }
`;

function Granted({ align, resultsAccessLinkId, userId, userTestId }) {
  const [open, setOpen] = useState(false);

  const [fetch, { data }] = useLazyQuery(VIEW_RESULTS_QUERY, {
    variables: {
      resultsAccessLinkId
    }
  });

  useEffect(() => {
    if (open) {
      fetch();
    }
  }, [open, fetch]);

  const resultsAccessLink = data?.resultsAccessLink;

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems={align === "left" ? "flex-start" : "flex-end"}
      >
        <SolidChevronButton
          as="a"
          href={getClientManagementDefaultUrl(userId)}
          target="_blank"
          rel="noopener noreferrer"
          bg="green"
          border={0}
        >
          Results
        </SolidChevronButton>
        <Box
          display="flex"
          alignItems="center"
          py={2}
          cursor="pointer"
          onClick={() => setOpen(!open)}
        >
          <Text fontFamily="gilroyBold" uppercase fontSize={10} letterSpacing={2.8}>
            view lab reports
          </Text>
          <ChevronComponent chevronDir={open ? "top" : "bottom"} fill="dark" />
        </Box>
      </Box>
      {open && (
        <Box
          display="flex"
          flexDirection="column"
          gap={10}
          alignItems={align === "left" ? "flex-start" : "flex-end"}
        >
          <InternalTextLink
            display="block"
            href={getPartnerQuestionnaireResultsUrl(userId, "symptoms")}
            newTab
          >
            Self assessment
          </InternalTextLink>
          <InternalTextLink href={getPartnerFoodListUrl(userId)} newTab>
            Food list
          </InternalTextLink>
          {resultsAccessLink?.userTests.map(userTest => (
            <Fragment key={userTest.id}>
              {userTest.dataFiles
                .filter(userTestDataFile => userTestDataFile.dataFileUrl)
                .map(userTestDataFile => (
                  <DownloadCrossOriginFile
                    key={userTestDataFile.id}
                    fileUrl={userTestDataFile.dataFileUrl}
                    fileName={`${userTestDataFile.name}.${userTestDataFile.testDataFile.fileType.extension}`}
                    contentType={userTestDataFile.testDataFile.fileType.contentType}
                    trigger={
                      <Box
                        as="button"
                        textDecoration="underline"
                        color="anchorBlue"
                        textAlign={align}
                      >
                        {userTestDataFile.name}
                      </Box>
                    }
                  />
                ))}
              {userTest.generatedPdfs
                .filter(generatedPdf => generatedPdf.pdfFile)
                .slice(0, 1)
                .map(generatedPdf => (
                  <DownloadCrossOriginFile
                    trigger={
                      <Box
                        as="button"
                        textDecoration="underline"
                        color="anchorBlue"
                        textAlign={align}
                      >
                        Generated biomarker report
                      </Box>
                    }
                    fileUrl={generatedPdf.pdfFile}
                    fileName={`generated_biomarker_report_${generatedPdf.userTest.id}.pdf`}
                    contentType="application/pdf"
                    key={generatedPdf.id}
                  />
                ))}
            </Fragment>
          ))}
        </Box>
      )}
    </Box>
  );
}

function Forbidden({ align }) {
  return (
    <Box display="flex" justifyContent={align}>
      <SolidChevronButton bg="gray" border={0}>
        Results
      </SolidChevronButton>
    </Box>
  );
}

function ViewResults({ align, id, granted, userId, userTestId }) {
  return !!granted && !!userId && !!userTestId ? (
    <Granted
      align={align}
      userId={userId}
      userTestId={userTestId}
      resultsAccessLinkId={id.split(":")[1]}
    />
  ) : (
    <Forbidden align={align} />
  );
}

function ConnectedUserCard({ firstName, lastName, email, granted, userId, userTestId, id }) {
  return (
    <Box
      pt={2}
      pb={20}
      display="flex"
      flexDirection="column"
      gap={20}
      borderColor="haze"
      borderBottomStyle="solid"
      borderWidth={1}
    >
      <Box display="flex" flexDirection="column" gap={10}>
        {!!(firstName && lastName) && (
          <Text fontFamily="gilroyBold" fontSize={16}>{`${firstName}, ${lastName}`}</Text>
        )}
        {!!email && <Text fontSize={16}>{email}</Text>}
      </Box>
      <Box display="flex" justifyContent="flex-start">
        <ViewResults
          id={id}
          granted={granted}
          userId={userId}
          userTestId={userTestId}
          align="left"
        />
      </Box>
    </Box>
  );
}

function MobileList({ items, pageInfo, loading, onChange }) {
  return (
    <Box display="flex" flexDirection="column" gap={10}>
      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Loading />
        </Box>
      )}
      {items?.map(item => (
        <ConnectedUserCard key={item.id} {...item} />
      ))}
      {pageInfo && (
        <>
          <Box py={2} />
          <Pagination
            variant="mobile"
            totalPages={pageInfo.totalPages}
            currentPage={pageInfo.currentPage}
            hasNextPage={pageInfo.hasNextPage}
            hasPreviousPage={pageInfo.hasPreviousPage}
            onChange={onChange}
          />
        </>
      )}
    </Box>
  );
}

const CONNECTED_USERS_QUERY = gql`
  query ConnectedUsersQuery($page: Int, $orderBy: String, $search: String) {
    connectedUsersPagination(page: $page, orderBy: $orderBy, search: $search) {
      items {
        id
        firstName
        lastName
        email
        createdDateFormatted
        isAffiliate
        status
        userId
        userTestId
        granted
      }
      pageInfo {
        totalPages
        currentPage
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

function ConnectedUsers() {
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 200);
  const [sortMenuOpen, setSortMenuOpen] = useState(false);
  const [sort, setSort] = useState(sortOptions[0].value);
  const [page, setPage] = useState(1);

  const { data, loading } = useDataLoader({
    query: CONNECTED_USERS_QUERY,
    variables: {
      page,
      orderBy: sort,
      search: debouncedSearch
    }
  });

  const items = data?.connectedUsersPagination?.items;
  const pageInfo = data?.connectedUsersPagination?.pageInfo;

  return (
    <SpacedPanelBox data-component-name="Connected patients">
      <HeadingLarge pb as="h2">
        Connected users
      </HeadingLarge>
      <Box display={["none", "none", "block"]}>
        <DesktopList
          search={search}
          setSearch={setSearch}
          sort={sort}
          setSort={setSort}
          sortMenuOpen={sortMenuOpen}
          setSortMenuOpen={setSortMenuOpen}
          page={page}
          setPage={setPage}
          items={items}
          pageInfo={pageInfo}
          loading={loading}
        />
      </Box>
      <Box display={["block", "block", "none"]}>
        <MobileList items={items} pageInfo={pageInfo} loading={loading} onChange={setPage} />
      </Box>
    </SpacedPanelBox>
  );
}

export default ConnectedUsers;
