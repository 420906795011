import React, { useState } from "react";

import { useApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import gql from "graphql-tag";
import moment from "moment";

import { theme } from "../core/theme";
import { PLATFORM_PROFILE_FORM_URL } from "../core/urls";
import {
  ACCEPT_RESULTS_ACCESS_REQUEST_MUTATION,
  DELETE_RESULTS_ACCESS_REQUEST_MUTATION,
  PLATFORM_USER_PROFILE_FIELDS,
  PLATFORM_USER_RESULTS_ACCESS_REQUESTS_QUERY,
  RESULTS_ACCESS_LINK_FIELDS
} from "../graphql/accounts";
import { USER_TEST_FIELDS_TRUNCATED } from "../graphql/tpo/results/types";
import useDocTitle from "../hooks/use-doc-title";
import Modal from "../tpo/Modal";
import Box from "./Box";
import { SpacedContentBox } from "./Boxes";
import { SolidButton } from "./Buttons";
import DashboardHeader from "./DashboardHeader";
import DataLoader from "./DataLoader";
import Divider from "./Divider";
import Grid from "./Grid";
import { IntroBlockHeading } from "./Headings";
import { InternalTextLink, DownloadCrossOriginFile } from "./Links";
import Page from "./Page";
import Stack from "./Stack";
import Text from "./Text";

export const SETTINGS_PAGE_QUERY = gql`
  query SettingsPageQuery {
    userTests {
      ...UserTestFieldsTruncated
      dataFiles {
        id
        dataFileUrl
        name
        testDataFile {
          id
          fileType {
            id
            name
            contentType
            extension
          }
        }
      }
      generatedPdfs {
        id
        userTest {
          id
        }
        pdfFile
      }
    }
    platformUserProfile {
      ...PlatformUserProfileFields
    }
    platformUserResultsAccessLinks {
      ...ResultsAccessLinkFields
    }
  }
  ${USER_TEST_FIELDS_TRUNCATED}
  ${PLATFORM_USER_PROFILE_FIELDS}
  ${RESULTS_ACCESS_LINK_FIELDS}
`;

function YourDetails({ settings }) {
  return (
    <SpacedContentBox mt={[6, 6, 6, 8]} data-component-name="Your details">
      <IntroBlockHeading pb>Your details</IntroBlockHeading>
      <Stack divider={<Divider />}>
        {settings
          .filter(item => {
            return !!item.value;
          })
          .map((setting, index) => {
            return (
              <React.Fragment key={index}>
                <Text>
                  {setting.key} : {setting.value}
                </Text>
              </React.Fragment>
            );
          })}
      </Stack>
      <Text pt={40}>
        Click <InternalTextLink href={PLATFORM_PROFILE_FORM_URL}>here</InternalTextLink> to edit
        your details
      </Text>
    </SpacedContentBox>
  );
}

function LabReports({ userTests }) {
  return (
    <SpacedContentBox mt={[6, 6, 6, 8]} data-component-name="Lab reports">
      <IntroBlockHeading pb>Lab reports</IntroBlockHeading>
      {userTests.length === 0 && <p>You currently have no test data</p>}
      <Stack divider={<Divider />}>
        {userTests.map(userTest => (
          <React.Fragment key={userTest.id}>
            <Text>
              {userTest.name} ({moment(userTest.created).format("DD/MM/YYYY")})
            </Text>
            {userTest.dataFiles
              .filter(userTestDataFile => userTestDataFile.dataFileUrl)
              .map(userTestDataFile => (
                <DownloadCrossOriginFile
                  trigger={
                    <Box as="button" mt={20} textDecoration="underline" color="anchorBlue">
                      {userTestDataFile.name}
                    </Box>
                  }
                  fileUrl={userTestDataFile.dataFileUrl}
                  fileName={`${userTestDataFile.name}.${userTestDataFile.testDataFile.fileType.extension}`}
                  contentType={userTestDataFile.testDataFile.fileType.contentType}
                  key={userTestDataFile.id}
                />
              ))}
            {/* {userTest.generatedPdfs
              .filter(generatedPdf => generatedPdf.pdfFile)
              .slice(0, 1)
              .map(generatedPdf => (
                <DownloadCrossOriginFile
                  trigger={
                    <Box as="button" mt={20} textDecoration="underline" color="anchorBlue">
                      Generated biomarker report
                    </Box>
                  }
                  fileUrl={generatedPdf.pdfFile}
                  fileName={`generated_biomarker_report_${generatedPdf.userTest.id}.pdf`}
                  contentType="application/pdf"
                  key={generatedPdf.id}
                />
              ))} */}
          </React.Fragment>
        ))}
      </Stack>
    </SpacedContentBox>
  );
}

function ResultsAccessLink({ identifier, created, id, granted }) {
  const [deleting, setDeleting] = useState(false);
  const [accepting, setAccepting] = useState(false);
  const [requestDeleteId, setRequestDeleteId] = useState(null);
  const apolloClient = useApolloClient();

  function deleteResultsAccessLink(requestId) {
    if (!deleting) {
      setDeleting(true);
      apolloClient
        .mutate({
          mutation: DELETE_RESULTS_ACCESS_REQUEST_MUTATION,
          variables: {
            input: {
              requestId
            }
          },
          refetchQueries: [
            {
              query: PLATFORM_USER_RESULTS_ACCESS_REQUESTS_QUERY
            }
          ]
        })
        .then(() => {
          setDeleting(false);
        })
        .catch(error => {
          console.log("deleteResultsAccessLink Error", error);
          Sentry.captureException(error);
          setDeleting(false);
        });
    }
  }

  function acceptResultsAccessLink(requestId) {
    if (!accepting) {
      setAccepting(true);
      apolloClient
        .mutate({
          mutation: ACCEPT_RESULTS_ACCESS_REQUEST_MUTATION,
          variables: {
            input: {
              resultsAccessGranted: true,
              requestId
            }
          },
          refetchQueries: [
            {
              query: PLATFORM_USER_RESULTS_ACCESS_REQUESTS_QUERY
            }
          ]
        })
        .then(() => {
          setAccepting(false);
        })
        .catch(error => {
          console.log("acceptResultsAccessLink error", error);
          Sentry.captureException(error);
          setAccepting(false);
        });
    }
  }

  return (
    <>
      <Box display="flex" alignItems="center">
        <Text>{identifier}</Text>
      </Box>
      <Box display="flex" alignItems="center">
        <Text>{moment(created).format("DD/MM/YYYY")}</Text>
      </Box>
      {granted ? (
        <Box display="flex" alignItems="center">
          <SolidButton
            key="delete"
            bg="red"
            borderColor="red"
            paddingSize="small"
            ml={0}
            width="intital"
            handleClick={() => {
              setRequestDeleteId(id);
            }}
          >
            Remove
          </SolidButton>
        </Box>
      ) : (
        <Box display="flex" alignItems="center">
          <SolidButton
            key="accept"
            paddingSize="small"
            ml={0}
            width="intital"
            submitting={accepting}
            handleClick={() => {
              acceptResultsAccessLink(id);
            }}
          >
            Accept
          </SolidButton>
        </Box>
      )}
      <Modal
        close={() => {
          setRequestDeleteId(null);
        }}
        centered
        maxWidth={800}
        closeButton
        show={requestDeleteId !== null}
        bg="white"
      >
        <Box
          px={[20, 30, 40, 50]}
          pb={[20, 30, 40, 50]}
          margin="auto"
          bg="white"
          position="relative"
        >
          Are you sure you want to delete this results access request?
          <SolidButton
            mx="auto"
            bg="red"
            borderColor="red"
            mt={30}
            submitting={deleting}
            handleClick={() => {
              deleteResultsAccessLink(requestDeleteId);
            }}
          >
            Delete
          </SolidButton>
        </Box>
      </Modal>
    </>
  );
}

function ResultsAccessLinks({ platformUserResultsAccessLinks }) {
  return (
    <SpacedContentBox mt={[6, 6, 6, 8]} data-component-name="Results access links">
      <IntroBlockHeading pb>Results access links</IntroBlockHeading>
      <Grid gridTemplateColumns={["1fr", "1fr 1fr 150px", null, null]}>
        {platformUserResultsAccessLinks.map(
          (
            {
              id,
              created,
              granted,
              partnerUserProfile: {
                user: { identifier }
              }
            },
            index
          ) => {
            return (
              <ResultsAccessLink
                key={index}
                id={id}
                created={created}
                granted={granted}
                identifier={identifier}
              />
            );
          }
        )}
      </Grid>
    </SpacedContentBox>
  );
}

function Actions({ userEmail }) {
  return (
    <SpacedContentBox mt={[6, 6, 6, 8]} data-component-name="Actions">
      <IntroBlockHeading pb>Your data</IntroBlockHeading>
      <Text>
        For privacy compliance you can request a copy of the data we hold on you by clicking on the
        "request data" button below.
      </Text>
      <Text pt={20}>
        Please note your test results will be uploaded automatically to your dashboard and you will
        be notified when they are available. A copy of your raw data files will also appear above
        for you to download.
      </Text>
      <Box pt={40}>
        <Grid gridTemplateColumns="1fr 1fr">
          <SolidButton
            as="a"
            maxWidth={null}
            href={`mailto:support@omnos.me?subject=Request my data&body=Please can I receive a copy of the data for the account in the name of ${userEmail}`}
          >
            Request data
          </SolidButton>
          <SolidButton
            bg="red"
            borderColor="red"
            as="a"
            maxWidth={null}
            href={`mailto:support@omnos.me?subject=Delete my account&body=Please can I delete the account in the name of ${userEmail}`}
          >
            Delete account
          </SolidButton>
        </Grid>
      </Box>
    </SpacedContentBox>
  );
}

function SettingsPage() {
  useDocTitle("Settings");
  return (
    <Page header={<DashboardHeader>Settings</DashboardHeader>}>
      <DataLoader
        query={SETTINGS_PAGE_QUERY}
        render={({ userTests, platformUserProfile, platformUserResultsAccessLinks }) => {
          const settings = [
            {
              key: "First name",
              value: platformUserProfile.user.firstName
            },
            {
              key: "Last name",
              value: platformUserProfile.user.lastName
            },
            {
              key: "Email",
              value: platformUserProfile.user.email
            },
            {
              key: "Date of birth",
              value: platformUserProfile.dateOfBirth
            },
            {
              key: "Height",
              value: platformUserProfile.height
            },
            {
              key: "Weight",
              value: platformUserProfile.weight
            }
          ];

          return (
            <Box pb={theme.spacing.large} data-component-name="Settings">
              <YourDetails settings={settings} />
              <LabReports userTests={userTests} />
              {platformUserResultsAccessLinks.length > 0 && (
                <ResultsAccessLinks
                  platformUserResultsAccessLinks={platformUserResultsAccessLinks}
                />
              )}
              <Actions userEmail={platformUserProfile.user.email} />
            </Box>
          );
        }}
      />
    </Page>
  );
}

export default SettingsPage;
