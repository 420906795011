import React, { useEffect } from "react";

import { useHistory } from "react-router-dom";

import { useApolloClient } from "@apollo/client";
import { useQuery } from "@apollo/client";

import { theme } from "../core/theme";
import { getOrderUrl } from "../core/urls";
import { ORDER_QUERY } from "../graphql/shop";
import useDocTitle from "../hooks/use-doc-title";
import Box from "./Box";
import CenteredHeader from "./CenteredHeader";
import Loading from "./Loading";
import Page from "./Page";
import Text from "./Text";

export default function OrderProcessingPage({
  match: {
    params: { orderId }
  }
}) {
  useDocTitle("Order processing");
  const history = useHistory();
  const apolloClient = useApolloClient();
  const { data } = useQuery(ORDER_QUERY, {
    client: apolloClient,
    variables: { id: orderId },
    pollInterval: 1000
  });

  useEffect(() => {
    if (data !== undefined) {
      if (data.order !== undefined) {
        if (data.order.status === "Payment succeeded") {
          if (typeof window.gtag !== typeof undefined) {
            // https://developers.google.com/analytics/devguides/collection/gtagjs/ecommerce

            const testItemsData = data.order.testItems.map(item => {
              return {
                id: item.id,
                name: item.product.name,
                brand: "Google",
                category: "Tests",
                quantity: 1,
                price: item.product.price
              };
            });
            const supplementItemsData = data.order.supplementItems.map(item => {
              return {
                id: item.id,
                name: item.product.name,
                brand: "Google",
                category: "Supplements",
                quantity: 1,
                price: item.product.price
              };
            });

            const trackingData = {
              transaction_id: data.order.id,
              value: data.order.discountedTotal,
              currency: "GBP",
              shipping: data.order.postageCosts,
              items: [...testItemsData, ...supplementItemsData]
            };
            window.gtag("event", "purchase", trackingData);
          }
          history.push(getOrderUrl(orderId));
        }
      }
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <Page backgroundColor="haze" header={<CenteredHeader heading="Order"></CenteredHeader>}>
      <Box pb={theme.spacing.large}>
        <Text data-component-name="Processing message" textAlign="center">
          Payment processing, please wait
        </Text>
        <Loading />
      </Box>
    </Page>
  );
}
