import React, { useState } from "react";

import { useHistory, useLocation } from "react-router";

import { theme } from "../core/theme";
import { DASHBOARD_HOME_URL, LOGIN_URL, SIGNUP_URL } from "../core/urls";
import { ORDER_QUERY } from "../graphql/shop";
import useDocTitle from "../hooks/use-doc-title";
import { useAuthContext } from "./AuthProvider";
import Box from "./Box";
import { SolidButton } from "./Buttons";
import CenteredHeader from "./CenteredHeader";
import ChargelessCheckoutForm from "./ChargelessCheckoutForm";
import DataLoader from "./DataLoader";
import Discount from "./Discount";
import Grid from "./Grid";
import { HeadingMedium } from "./Headings";
import { ExternalTextLink } from "./Links";
import OrderSummary from "./OrderSummary";
import Page from "./Page";
import StripeCheckoutForm from "./StripeCheckoutForm";
import StripePaymentForm from "./StripePaymentForm";
import Text from "./Text";

function BaseContent({ children }) {
  return (
    <Box px="10px">
      <Box maxWidth={1000} mx="auto">
        <Box bg="white" px={20} pb={theme.spacing.large}>
          {children}
        </Box>
      </Box>
    </Box>
  );
}

function FormSection({ heading, children }) {
  return (
    <Box pt={theme.spacing.large} maxWidth={600} mx="auto">
      {heading && <HeadingMedium pb>{heading}</HeadingMedium>}
      {children}
    </Box>
  );
}

function OrderNotFound() {
  return (
    <BaseContent>
      <FormSection heading="Order not found">
        I'm sorry, we can't seem to find that order. Please contact{" "}
        <ExternalTextLink href="mailto:support@omnos.me">support@omnos.me</ExternalTextLink>
      </FormSection>
    </BaseContent>
  );
}

function EmptyCheckout() {
  return (
    <BaseContent>
      <FormSection heading="Basket summary">You have no items in your basket</FormSection>
    </BaseContent>
  );
}

function ChargelessCheckout({ order }) {
  return (
    <BaseContent>
      <FormSection heading="Basket summary">
        <OrderSummary order={order} />
      </FormSection>
      <FormSection heading="Discount">
        <Discount order={order} />
      </FormSection>
      <FormSection heading="Shipping details">
        <ChargelessCheckoutForm order={order} />
      </FormSection>
    </BaseContent>
  );
}

function CharglessCheckoutComplete({ order }) {
  return (
    <BaseContent>
      <FormSection heading="Basket summary">
        <OrderSummary
          order={order}
          status="Thank you for placing your order. Estimated delivery: 2-3 business days."
          showBookingLinks
        />
      </FormSection>
    </BaseContent>
  );
}

function StripeCheckoutComplete({ order }) {
  const history = useHistory();
  const { user } = useAuthContext();

  return (
    <BaseContent>
      <FormSection heading="Basket summary">
        <OrderSummary order={order} status="Payment successful" showBookingLinks />
      </FormSection>
      {user ? (
        <SolidButton
          mt={80}
          width="100%"
          maxWidth={300}
          mx="auto"
          handleClick={() => {
            history.push(DASHBOARD_HOME_URL);
          }}
        >
          Back to your dashboard
        </SolidButton>
      ) : (
        <FormSection heading="Next steps">
          <Text pb={[30, 40, null, null]}>
            To continue your journey with Omnos, you need to create an account. This will allow you
            to explore your test results.
          </Text>
          <SolidButton
            width="100%"
            maxWidth={300}
            mx="auto"
            handleClick={() => {
              history.push(SIGNUP_URL, {
                email: order.email,
                firstName: order.billingFirstName,
                lastName: order.billingLastName
              });
            }}
          >
            Sign up
          </SolidButton>
        </FormSection>
      )}
    </BaseContent>
  );
}

function StripePayment({ order }) {
  return (
    <>
      <BaseContent>
        <FormSection heading="Basket summary">
          <OrderSummary order={order} />
        </FormSection>
        <FormSection heading="Payment details">
          <StripePaymentForm order={order} />
        </FormSection>
      </BaseContent>
    </>
  );
}

function StripeCheckout({ order }) {
  const history = useHistory();
  const [confirmedGuestCheckout, setConfirmedGuestCheckout] = useState(false);
  const showConfirmGuestCheckout = order.user === null && !confirmedGuestCheckout;

  const location = useLocation();

  if (showConfirmGuestCheckout) {
    return (
      <>
        <BaseContent>
          <FormSection heading="Basket summary">
            <OrderSummary order={order} />
          </FormSection>
          <FormSection heading="Choose checkout type">
            <Text pb={[30, 40, null, null]}>
              We can see that you're not currently logged in. If you have an account, please sign in
              to continue your purchase, otherwise you can checkout as a guest now and create an
              account later
            </Text>
            <Grid gridTemplateColumns="1fr 1fr">
              <SolidButton
                handleClick={() => {
                  setConfirmedGuestCheckout(true);
                }}
                maxWidth={null}
                data-component-name="Guest checkout button"
              >
                Checkout as guest
              </SolidButton>
              <SolidButton
                handleClick={() => {
                  history.push(`${LOGIN_URL}?next=${location.pathname}`);
                }}
                maxWidth={null}
                data-component-name="Login button"
              >
                Login to your account
              </SolidButton>
            </Grid>
          </FormSection>
        </BaseContent>
      </>
    );
  }

  return (
    <>
      <BaseContent>
        <FormSection heading="Basket summary">
          <OrderSummary order={order} />
        </FormSection>
        <FormSection heading="Discount">
          <Discount order={order} />
        </FormSection>
        <FormSection>
          <StripeCheckoutForm order={order} />
        </FormSection>
      </BaseContent>
    </>
  );
}

function OrderPage({
  match: {
    params: { orderId }
  }
}) {
  useDocTitle(`Order ${orderId}`);
  return (
    <Page backgroundColor="haze" header={<CenteredHeader heading="Order"></CenteredHeader>}>
      <Box pb={theme.spacing.large}>
        <DataLoader
          query={ORDER_QUERY}
          variables={{
            id: orderId
          }}
          render={data => {
            const order = data.order;

            if (order === null) {
              return <OrderNotFound />;
            }
            if (order.testItems.length === 0 && order.supplementItems.length === 0) {
              return <EmptyCheckout />;
            }
            if (order.discountedTotal + order.postageCosts === 0) {
              if (order.status === "Chargeless checkout complete") {
                return <CharglessCheckoutComplete order={order} />;
              } else {
                return <ChargelessCheckout order={order} />;
              }
            } else {
              if (order.status === "Payment succeeded") {
                return <StripeCheckoutComplete order={order} />;
              } else if (order.status === "Payment initiated") {
                return <StripePayment order={order} />;
              } else {
                return <StripeCheckout order={order} />;
              }
            }
          }}
        ></DataLoader>
      </Box>
    </Page>
  );
}

export default OrderPage;
