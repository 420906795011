import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import { useApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/browser";

import { getOrderUrl } from "../core/urls";
import { groupItemsByName, priceFormat } from "../core/utils";
import { ADD_ITEM_TO_BASKET, OPEN_ORDER_QUERY, REMOVE_ITEM_FROM_BASKET } from "../graphql/shop";
import { useAppState } from "./AppStateProvider";
import Box from "./Box";
import { SolidButton } from "./Buttons";
import DataLoader from "./DataLoader";
import { HeadingExtraLarge } from "./Headings";
import Text, { SpanText } from "./Text";

export function getClinicFees(lineSummary) {
  let total = lineSummary.clinicBookings.reduce((acc, booking) => {
    return acc + (booking.location?.clinic?.fee || 0);
  }, 0);
  return total.toFixed(2);
}

export function getPrice(lineSummary) {
  let count = lineSummary.count;
  let linePrice = lineSummary.linePrice;
  let clinicFee = lineSummary?.clinicLocation?.clinic?.fee || 0;
  return ((linePrice - clinicFee) * count).toFixed(2);
}

// Pre black friday previous price calculation: 24/11/2022
// function getPreviousPriceFromOrderItem(item) {
//   let previousPrice = 0;
//   if (item.productOption) {
//     previousPrice = item.productOption.price;
//     if (item.clinicLocation?.clinic?.fee) {
//       previousPrice = previousPrice + item.clinicLocation?.clinic?.fee;
//     }
//   } else {
//     previousPrice = item.product.previousPrice || item.product.price;
//   }
//   return previousPrice;
// }

function getPreviousPriceFromOrderItem(item) {
  return item.product.previousPrice || item.product.price || 0;
}

function Basket() {
  const { basketOpen, setBasketOpen } = useAppState();
  const history = useHistory();
  const apolloClient = useApolloClient();
  const [submitting, setSubmitting] = useState(false);

  function addItemToBasket(productInfo) {
    if (!submitting) {
      setSubmitting(true);
      apolloClient
        .mutate({
          mutation: ADD_ITEM_TO_BASKET,
          variables: {
            clinicLocationId: productInfo.clinicLocationId,
            compositeId: productInfo.compositeId
          },
          refetchQueries: [
            {
              query: OPEN_ORDER_QUERY
            }
          ]
        })
        .then(() => {
          setSubmitting(false);
        })
        .catch(error => {
          console.log("Error adding item to basket", error);
          Sentry.captureException(error);
          setSubmitting(false);
        });
    }
  }

  function removeItemFromBasket(productInfo) {
    if (!submitting) {
      setSubmitting(true);
      apolloClient
        .mutate({
          mutation: REMOVE_ITEM_FROM_BASKET,
          variables: {
            clinicLocationId: productInfo.clinicLocationId,
            compositeId: productInfo.compositeId
          },
          refetchQueries: [
            {
              query: OPEN_ORDER_QUERY
            }
          ]
        })
        .then(() => {
          setSubmitting(false);
        })
        .catch(error => {
          console.log("Error removing item from basket", error);
          Sentry.captureException(error);
          setSubmitting(false);
        });
    }
  }

  return (
    <DataLoader
      query={OPEN_ORDER_QUERY}
      loading={null}
      render={({ openOrder }) => {
        let allOrderItems;
        let orderTotal;
        let postageCosts;

        if (openOrder) {
          allOrderItems = [...openOrder.testItems, ...openOrder.supplementItems];
          orderTotal = openOrder.discountedTotal;
          postageCosts = openOrder.postageCosts;
        } else {
          allOrderItems = [];
          postageCosts = 0;
          orderTotal = 0;
        }

        const orderItemsByName = groupItemsByName(allOrderItems);
        const orderItemCount = allOrderItems.length;

        const previousPrice = allOrderItems.reduce(
          (price, item) => price + getPreviousPriceFromOrderItem(item),
          0
        );

        return (
          <Box
            bg="white"
            position="fixed"
            top={0}
            left={0}
            right={0}
            bottom={0}
            opacity={basketOpen ? 1 : 0}
            zIndex={3}
            px={20}
            display="flex"
            flexDirection="column"
            style={{
              transition: "opacity 250ms",
              pointerEvents: basketOpen ? "all" : "none",
              overflow: "scroll"
            }}
            data-component-name="Basket"
          >
            <Box width="100%" maxWidth={800} mx="auto" pt={[130, 140, 150, 160]} pb={80}>
              <HeadingExtraLarge pb={20}>Your basket</HeadingExtraLarge>
              <Text pb={20}>
                {orderItemCount} item{orderItemCount !== 1 && "s"}
              </Text>
              {Object.keys(orderItemsByName).map((orderItem, index) => {
                const lineSummary = orderItemsByName[orderItem];
                let clinic = null;
                if (lineSummary.clinicBookings.length > 0) {
                  clinic = lineSummary.clinicBookings[0].location;
                }
                return (
                  <Box
                    key={index}
                    mb={Object.keys(orderItemsByName).length - 1 !== index ? 5 : null}
                  >
                    <Box display="flex" alignItems="center">
                      <Box display="flex" alignItems="center" width={0.75}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          bg="haze"
                          p="10px"
                          borderRadius="5px"
                        >
                          <Box
                            as="button"
                            size={30}
                            borderRadius="50%"
                            bg="slate"
                            color="white"
                            style={{
                              transition: "opacity 500ms"
                            }}
                            opacity={submitting}
                            disabled={submitting}
                            onClick={() => {
                              removeItemFromBasket(
                                {
                                  clinicLocationId: clinic?.id,
                                  compositeId: lineSummary.compositeId
                                },
                                false
                              );
                            }}
                          >
                            <Text fontFamily="gilroyBold" fontSize={20}>
                              -
                            </Text>
                          </Box>
                          <Text mx="10px">{lineSummary.count}</Text>
                          <Box
                            as="button"
                            size={30}
                            borderRadius="50%"
                            bg="slate"
                            color="white"
                            style={{
                              transition: "opacity 500ms"
                            }}
                            opacity={submitting ? 0.3 : 1.0}
                            disabled={submitting}
                            onClick={() => {
                              addItemToBasket({
                                clinicLocationId: clinic?.id,
                                compositeId: lineSummary.compositeId
                              });
                            }}
                          >
                            <Text fontFamily="gilroyBold" fontSize={20}>
                              +
                            </Text>
                          </Box>
                        </Box>
                        <Text fontFamily="gilroyBold" flexGrow="1" mx={2}>
                          {lineSummary.nameInBasket}
                        </Text>
                      </Box>
                      <Box width={0.25}>
                        <Text textAlign="right" fontSize={[16, 18, 20, 22]}>
                          £{getPrice(lineSummary)}
                        </Text>
                      </Box>
                    </Box>
                    {clinic && (
                      <Box my={2}>
                        <Box display="flex">
                          <Box width={0.75}>
                            <SpanText>{`${clinic.name || clinic.clinic?.name || "Clinic"}, ${
                              clinic.address
                            }`}</SpanText>
                          </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between" my={2}>
                          <Text fontFamily="gilroyBold">Clinic Fees</Text>
                          <Text>£{getClinicFees(lineSummary)}</Text>
                        </Box>
                      </Box>
                    )}
                  </Box>
                );
              })}
              <Box display="flex" alignItems="flex-end" flexDirection="column">
                <Text pt={[30, null, null, 40]}>
                  Postage : {postageCosts > 0 ? `£${postageCosts.toFixed(2)}` : "Free"}
                </Text>
                <Text mt={20} fontSize={30} fontFamily="dinBold" data-component-name="Total">
                  TOTAL :&nbsp;
                  {previousPrice > orderTotal && (
                    <span
                      style={{
                        textDecoration: "line-through",
                        textDecorationThickness: "4px",
                        textDecorationColor: "black",
                        color: "rgba(0, 0, 0, 0.3)",
                        marginRight: "10px"
                      }}
                    >
                      £{priceFormat(previousPrice)}
                    </span>
                  )}
                  £{orderTotal.toFixed(2)}
                </Text>
                <Box flexDirection="column" display="flex">
                  {orderItemCount > 0 && (
                    <SolidButton
                      mt={[30, null, null, 40]}
                      handleClick={() => {
                        setBasketOpen(false);
                        history.push(getOrderUrl(openOrder.id));
                      }}
                      data-component-name="Checkout button"
                    >
                      Checkout
                    </SolidButton>
                  )}
                  <SolidButton
                    mt={20}
                    bg="charcoal"
                    borderColor="charcoal"
                    handleClick={() => {
                      setBasketOpen(false);
                    }}
                  >
                    Continue shopping
                  </SolidButton>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      }}
    ></DataLoader>
  );
}

export default Basket;
