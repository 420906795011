import Box from "../components/Box";
import { PanelBoxV2 } from "./NewBoxes";

export default function NothingToShow({ header, jumbotron }) {
  return (
    <>
      <PanelBoxV2
        maxWidth={1538}
        outer={{
          pt: [30, 30, 60],
          pb: [50, 50, 80],
          px: 20
        }}
      >
        {header}
        {header && jumbotron && <Box py={[2, 2, 20]} />}
        <PanelBoxV2
          maxWidth={1020}
          inner={{
            bg: "white",
            pt: [30, 30, 60],
            pb: [30, 30, 60],
            px: 20
          }}
        >
          <PanelBoxV2 maxWidth={760}>{jumbotron}</PanelBoxV2>
        </PanelBoxV2>
      </PanelBoxV2>
    </>
  );
}
