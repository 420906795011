import { useHistory } from "react-router-dom";

import { DiscoverTests } from "../components/TestProductPage";
import Text from "../components/Text";
import { TESTS_URL } from "../core/urls";
import { PanelBox } from "./Boxes";
import { SolidChevronButton } from "./ChevronButton";
import Flag from "./Flag";
import { HeadingExtraLarge } from "./Headings";
import Section from "./Section";

function TestProducts({ flagNumber, products, matchedSymptomsMap, ...rest }) {
  const history = useHistory();
  return (
    <>
      <PanelBox large topPadding {...rest}>
        <PanelBox small nested>
          {flagNumber && (
            <Section.Flag>
              <Flag text={2} />
            </Section.Flag>
          )}
          <Section.Header>
            <HeadingExtraLarge fontSize={[24, 34, 34, 44]}>Omnos Tests</HeadingExtraLarge>
          </Section.Header>
          <Section.BodyCopy>
            <Text fontSize={[15, 16]}>
              Complete our free self assessment questionnaires to get some initial advice and test
              recommendations
            </Text>
          </Section.BodyCopy>
        </PanelBox>
      </PanelBox>
      <Section.Content px={20}>
        <DiscoverTests testProducts={products} matchedSymptomsMap={matchedSymptomsMap} />
      </Section.Content>
      <PanelBox large bottomPadding>
        <Section.Button>
          <SolidChevronButton
            handleClick={() => {
              return history.push({ pathname: TESTS_URL });
            }}
          >
            view all tests
          </SolidChevronButton>
        </Section.Button>
      </PanelBox>
    </>
  );
}

export default TestProducts;
