import PropTypes from "prop-types";

import Box from "./Box";

function AspectRatio({ children, value, flex }) {
  if (value === null) {
    return children;
  }
  return (
    <Box position="relative" width="100%" data-component-name="AspectRatio" flex={flex}>
      <Box position="absolute" top={0} left={0} bottom={0} right={0}>
        {children}
      </Box>
      <Box style={{ paddingBottom: `${value * 100}%` }} />
    </Box>
  );
}

AspectRatio.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number,
  flex: PropTypes.number
};

export default AspectRatio;
