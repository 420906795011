import React, { useEffect } from "react";

import { useHistory } from "react-router-dom";

import Loading from "./Loading";

function RewriteShopRoute({ destination }) {
  const history = useHistory();

  useEffect(() => {
    history.push(destination);
  }, [destination, history]);

  return <Loading />;
}

export default RewriteShopRoute;
