import React from "react";

import PropTypes from "prop-types";

import Box from "./Box";
import Checkbox from "./Checkbox";

function CheckboxGroup({ options, maxSelect, value, handleChange, error }) {
  return options.map((option, optionIndex) => {
    const checked = value !== null && value.indexOf(option.value) !== -1;
    return (
      <Box pt="5px" pb="5px" key={optionIndex} data-component-name="checkbox-group">
        <Checkbox
          label={option.name}
          value={checked}
          error={error}
          handleChange={() => {
            let newValue;
            if (checked) {
              newValue = value.filter(item => {
                return item !== option.value;
              });
            } else {
              newValue = [...value, option.value];
            }
            if (!maxSelect | (newValue.length <= maxSelect)) {
              handleChange(newValue);
            }
          }}
        />
      </Box>
    );
  });
}

CheckboxGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  value: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.bool
};

export default CheckboxGroup;
