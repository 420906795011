import { gql } from "@apollo/client";

export const USER_DATAPOINTS_QUERY = gql`
  query UserDatapointsQuery($datapointId: ID!, $cot: Boolean) {
    userDatapoints(datapointId: $datapointId, cot: $cot) {
      id
      value
      dueForRetest
      dateExpired
      chartConfiguration {
        terminology
        colours
        interpretation
        normalisedRange {
          minimum
          maximum
          range
          value
        }
        chartRange {
          range
        }
        terminology
        chartType
      }
      datapoint {
        id
        units
      }
      userTest {
        id
        created
      }
    }
  }
`;
