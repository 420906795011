import { useParams } from "react-router-dom";

import { gql } from "@apollo/client";

import Box from "../components/Box";
import DataLoader from "../components/DataLoader";
import Page from "../components/Page";
import { SymptomsAccordion } from "./Accordions";
import { PanelBox } from "./Boxes";

export function getAnswerColor(score) {
  if (score === 0) {
    return "green";
  } else if (score === 1) {
    return "orange";
  }
  return "red";
}

function QuestionnaireResultsContent({ questionnaire }) {
  return (
    <Page bg="haze">
      <PanelBox medium verticalPadding>
        {questionnaire.sections.map((section, index) => (
          <SymptomsAccordion
            key={section.section}
            expandBtnText={`Section ${section.section}`}
            my={4}
            open={index === 0 ? true : false}
          >
            {section.questions.map(question => (
              <Box display="flex" key={`${section.section}:${question.question}`} my={2}>
                <Box textAlign="left" width={0.5}>
                  {question.question}
                </Box>
                <Box
                  color={!!question.answer && getAnswerColor(question.score)}
                  textAlign="right"
                  width={0.5}
                >
                  {question.answer || "N/A"}
                </Box>
              </Box>
            ))}
          </SymptomsAccordion>
        ))}
      </PanelBox>
    </Page>
  );
}

const GET_PARTNER_QUESTIONNAIRE_RESULTS = gql`
  query GET_PARTNER_QUESTIONNAIRE_RESULTS($userId: ID!, $questionnaireId: String!) {
    questionnaireResults(userId: $userId, questionnaireId: $questionnaireId) {
      id
      sections {
        section
        questions {
          question
          answer
          score
        }
      }
    }
  }
`;

function QuestionnaireResults() {
  const { clientId, questionnaireId } = useParams();

  return (
    <DataLoader
      query={GET_PARTNER_QUESTIONNAIRE_RESULTS}
      variables={{ userId: clientId, questionnaireId }}
      render={({ questionnaireResults }) => {
        return <QuestionnaireResultsContent questionnaire={questionnaireResults} />;
      }}
    />
  );
}

export default QuestionnaireResults;
