import { get, isFunction } from "lodash";
import styled from "styled-components";

import Box from "../components/Box";
import Loading from "../components/Loading";

const TableWrapper = styled(Box)`
  table {
    border-collapse: collapse;
    width: 100%;
  }

  table thead tr th {
    text-align: left;
    font-family: Gilroy W05 Medium;
    font-size: 18px;
  }

  table tbody tr td {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  tr {
    border-bottom: 1px solid #f2f2f2;
  }
`;

export default function Table({ columns, rows, loading, display }) {
  return (
    <TableWrapper display={display}>
      <table>
        <thead>
          <tr>
            {columns.map(column => (
              <th
                key={column.id}
                style={{
                  textAlign: column.align,
                  width: column.width
                }}
              >
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows?.map(row => (
            <tr key={row.id}>
              {columns.map(col => (
                <td
                  key={col.id}
                  style={{
                    verticalAlign: "baseline"
                  }}
                >
                  {isFunction(col.accessor) ? col.accessor(row) : get(row, col.accessor)}
                </td>
              ))}
            </tr>
          ))}
          {loading && (
            <tr>
              <td colSpan={columns.length}>
                <Loading />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </TableWrapper>
  );
}
