import Box from "../components/Box";
import { PanelBoxV2 } from "./NewBoxes";

export function TextBox({ children, size }) {
  let maxWidth = 0;
  if (size === "xs") {
    maxWidth = 600;
  } else if (size === "s") {
    maxWidth = 760;
  }

  return (
    <PanelBoxV2
      maxWidth={maxWidth}
      bg="white"
      outer={{
        px: [20, 20, 0]
      }}
      inner={{
        display: "flex",
        flexDirection: "column",
        gap: [40]
      }}
    >
      {children}
    </PanelBoxV2>
  );
}

export function ChartBox({ children, size }) {
  let maxWidth = 0;
  let px = [];

  if (size === "s") {
    maxWidth = 760;
  } else if (size === "m") {
    maxWidth = 1020;
    px = [20, 20, 130];
  }

  return (
    <PanelBoxV2
      maxWidth={maxWidth}
      outer={{
        bg: "white"
      }}
      inner={{
        px
      }}
    >
      {children}
    </PanelBoxV2>
  );
}

export function ChartDetailModalLayout({ chart, text, accordion }) {
  return (
    <>
      <PanelBoxV2
        outer={{
          bg: "white"
        }}
      >
        <PanelBoxV2
          maxWidth={760}
          inner={{
            borderRadius: 5
          }}
          outer={{
            p: 20,
            pt: 0,
            pb: [50, 50, 80]
          }}
        >
          {chart}
        </PanelBoxV2>
        <PanelBoxV2
          maxWidth={600}
          bg="white"
          outer={{
            p: 50,
            pt: 0
          }}
          inner={{
            display: "flex",
            flexDirection: "column",
            gap: [10, 10, 20]
          }}
        >
          {text}
        </PanelBoxV2>
      </PanelBoxV2>
      <PanelBoxV2
        maxWidth={760}
        outer={{
          pt: [30, 30, 60],
          pb: [50, 50, 80],
          px: [20, 20, 0],
          bg: "haze"
        }}
      >
        {accordion}
      </PanelBoxV2>
    </>
  );
}

export function ChartDetailLayout({ chart, text, accordion }) {
  return (
    <>
      <PanelBoxV2
        maxWidth={1020}
        outer={{
          pt: [0, 0, 60]
        }}
        inner={{
          bg: "white",
          pt: [30, 30, 40]
        }}
      >
        <PanelBoxV2
          maxWidth={760}
          inner={{
            borderRadius: 5,
            px: [20, 20, 0]
          }}
        >
          {chart}
        </PanelBoxV2>
        <PanelBoxV2
          maxWidth={600}
          outer={{
            pt: [20, 20, 60],
            px: [50, 50, null],
            pb: [50, 50, 80]
          }}
          inner={{
            display: "flex",
            flexDirection: "column",
            gap: [10, 10, 20]
          }}
        >
          {text}
        </PanelBoxV2>
      </PanelBoxV2>
      <PanelBoxV2
        maxWidth={760}
        outer={{
          pt: [30, 30, 60],
          px: [20, 20, 0],
          pb: [50, 50, 80],
          bg: "haze"
        }}
      >
        {accordion}
      </PanelBoxV2>
    </>
  );
}
