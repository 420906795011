import { useContext, useMemo, useState } from "react";

import { useHistory, useParams, Switch, Route } from "react-router-dom";

import { gql } from "@apollo/client";
import { THINGS_TO_AVOID, THINGS_TO_DO } from "core/constants";
import { groupBy } from "lodash";

import Box from "../components/Box";
import DataLoader from "../components/DataLoader";
import Page from "../components/Page";
import {
  CLIENT_MANAGEMENT_PLATFORM_URL_BASE,
  CLIENT_MANAGEMENT_USER_SUBSECTOR,
  getClientManagementDefaultUrl,
  getClientManagementUserSubsectorUrl,
  getTPOSubsectorUrl
} from "../core/urls";
import {
  CORE_USER_THEME_WITH_SECTORS_FIELDS,
  USER_SUBSECTOR_FIELDS
} from "../graphql/tpo/common/types";
import {
  CHART_CONFIGURATION_FIELDS,
  USER_TEST_FIELDS_TRUNCATED
} from "../graphql/tpo/results/types";
import { Button } from "./Buttons";
import Center from "./Center";
import ChartModal from "./ChartModal";
import ChevronComponent from "./Chevron";
import { CollapseableText } from "./CollapseableText";
import { IncludeExpiredMarkers } from "./ExpiredMarkers";
import Group from "./Group";
import Menu, { Button as MenuButton } from "./Menu";
import Modal from "./Modal";
import Accordion, { AccordionContext } from "./NewAccordion";
import { SymptomsList } from "./NewAccordions";
import { PanelBoxV2 } from "./NewBoxes";
import NothingToShow from "./NothingToShow";
import {
  USER_SUBSECTORS_QUERY,
  PriorityRecommendations,
  RecommendationModalContent
} from "./Recommendations";
import SegmentedControl from "./SegmentedControl";
import Stack from "./Stack";
import TestStatuses from "./TestStatuses";
import {
  RelatedResultContext,
  RelatedResults,
  USER_RESULT_QUERY,
  UserResultModelContent
} from "./UserResult";
import { CardListContext } from "./UserResults";
import {
  USER_SUBSECTOR_QUERY,
  UserSubsectorBody,
  UserSubsectorMenuItem,
  useCardList,
  useUserSubsectorsMenu
} from "./UserSubsector";
import { GET_DISPLAY_GROUPS, GenesTestContent, NonGenesTestContent } from "./UserTest";
import { UserSubsectorScores } from "./UserTheme";
import { ViewerContext } from "./Viewer";

const ALL_TABS = [
  {
    label: "tests",
    slug: "tests"
  },
  {
    label: "wellness scores",
    slug: "wellness"
  },
  {
    label: "self assessment",
    slug: "self_assessment"
  },
  {
    label: "recommendations",
    slug: "recommendations"
  },
  {
    label: "food list",
    slug: "food_list"
  }
];

function Tab({ tab }) {
  const { patientId, tab: selectedTab } = useParams();
  const history = useHistory();

  return (
    <Button
      bg={tab.slug === selectedTab ? "dark" : "white"}
      color={tab.slug === selectedTab ? "white" : "dark"}
      fontSize={[14]}
      py={20}
      px={40}
      borderTopLeftRadius={5}
      borderTopRightRadius={5}
      borderRightStyle={ALL_TABS.slice(-1)?.[0]?.slug !== tab.slug ? "solid" : null}
      borderRightColor="haze"
      borderWidth={1}
      display="inline"
      ml={ALL_TABS?.[0]?.slug === tab.slug ? "auto" : null}
      mr={ALL_TABS.slice(-1)?.[0]?.slug === tab.slug ? "auto" : null}
      whiteSpace="nowrap"
      handleClick={() => {
        history.push(getClientManagementDefaultUrl(patientId, tab.slug));
      }}
      disabled={tab.disabled}
    >
      {tab.label}
    </Button>
  );
}

const PATIENT_DETAILS = gql`
  query PatientDetails($userId: ID!) {
    user(userId: $userId) {
      id
      firstName
      lastName
    }
  }
`;

function GenesTest({ userTest }) {
  const { patientId } = useParams();

  return (
    <DataLoader
      query={GET_DISPLAY_GROUPS}
      variables={{
        userId: patientId,
        userTestId: userTest.id
      }}
      render={({ userTest: { resultDisplayGroups } }) => (
        <GenesTestContent
          clientId={patientId}
          userTest={userTest}
          resultDisplayGroups={resultDisplayGroups}
        />
      )}
    />
  );
}

function Tests({ userTests }) {
  const { patientId } = useParams();
  const [selectedTest, setSelectedTest] = useState(userTests?.[0]);

  const userTest = userTests.find(userTest => userTest.id === selectedTest?.id);

  return (
    <>
      <PanelBoxV2
        maxWidth={1538}
        outer={{
          pt: [30, 30, 60],
          px: 20,
          pb: [40, 40, 80]
        }}
      >
        <Stack gap={40}>
          <PanelBoxV2 maxWidth={[464, 464, 464, 500]}>
            {userTests.length ? (
              <TestStatuses
                heading="Available tests"
                ordersInProgress={[]}
                testResults={userTests}
                handleSelect={selected => setSelectedTest(selected)}
                defaultOpen={false}
              />
            ) : (
              <TestStatuses heading="No tests" />
            )}
          </PanelBoxV2>
          {!!userTest && (
            <Center>
              <Box>
                <Box fontFamily="gilroyBold" fontSize={[24, 24, 44]} textAlign="center">
                  {userTest?.name}
                </Box>
                <Box textAlign="center">
                  <Box as="span" fontFamily="gilroyBold">
                    Sample taken:
                  </Box>
                  <Box as="span" fontFamily="gilroyMedium" fontSize={16} ml={1}>
                    {userTest?.sampleCollectedDateFormatted}
                  </Box>
                </Box>
              </Box>
            </Center>
          )}
        </Stack>
        <Box py={[15, 15, 20]} />
        {!!userTest && (
          <ViewerContext.Provider
            value={{
              userId: patientId
            }}
          >
            {userTest.isGenesTest ? (
              <GenesTest userTest={userTest} />
            ) : (
              <NonGenesTestContent
                userTest={userTest}
                showFullExplanation={false}
                clientId={patientId}
              />
            )}
          </ViewerContext.Provider>
        )}
      </PanelBoxV2>
    </>
  );
}

const PATIENT_TESTS = gql`
  query PatientTests($userId: ID!) {
    userTests(userId: $userId) {
      ...UserTestFieldsTruncated
    }
  }
  ${USER_TEST_FIELDS_TRUNCATED}
`;

function TestsTab() {
  const { patientId } = useParams();

  return (
    <DataLoader
      query={PATIENT_TESTS}
      variables={{
        userId: patientId
      }}
      render={({ userTests }) =>
        userTests.length ? (
          <Tests userTests={userTests} />
        ) : (
          <NothingToShow
            jumbotron={
              <>
                <Box as="h2" fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
                  No purchased tests
                </Box>
                <Box py={[2, 2, 20]} />
                <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
                  Your patient currently has no purchased tests. Details will be available here once
                  an order has been placed.
                </Box>
              </>
            }
          />
        )
      }
    />
  );
}

const USER_THEME_QUERY = gql`
  query UserThemeQuery($themeSlug: String!, $userId: ID!) {
    userTheme(themeSlug: $themeSlug, userId: $userId) {
      ...CoreUserThemeWithSectorsFields
    }
    userSubsectors(themeSlug: $themeSlug, userId: $userId) {
      ...UserSubsectorFields
      userResultCount
      chartConfiguration {
        colours
        normalisedRange {
          value
        }
      }
    }
  }
  ${CORE_USER_THEME_WITH_SECTORS_FIELDS}
  ${USER_SUBSECTOR_FIELDS}
`;

function UserSubsectorMenu({ userSubsector: pageUserSubsector }) {
  const { userSubsectors, open, setOpen } = useUserSubsectorsMenu({
    themeSlug: "health"
  });
  const history = useHistory();
  const viewerCtx = useContext(ViewerContext);

  return (
    <Menu opened={open} onChange={setOpen}>
      <Menu.Target>
        <MenuButton
          opened={open}
          lineHeight="24px"
          paddingY="16px"
          paddingX="40px"
          maxWidth="100%"
          bg="dark"
          color="white"
        >
          <span
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              maxWidth: "100%"
            }}
          >
            {pageUserSubsector.name}
          </span>
        </MenuButton>
      </Menu.Target>
      {!!userSubsectors.length && (
        <Menu.Dropdown
          mt={2}
          py={2}
          zIndex={2}
          minWidth={310}
          maxWidth={310}
          height={500}
          maxHeight={500}
          overflowY="auto"
          bg="dark"
        >
          {userSubsectors.map(userSubsector => (
            <Menu.Item
              onClick={() =>
                history.push(
                  viewerCtx?.userId
                    ? getClientManagementUserSubsectorUrl(viewerCtx.userId, userSubsector.slug)
                    : getTPOSubsectorUrl("health", userSubsector.slug)
                )
              }
              key={userSubsector.id}
              selected={userSubsector.id === pageUserSubsector.id}
              py={16}
              selectedColor="gray"
              // TODO - confirm this gray color per design
            >
              <UserSubsectorMenuItem userSubsector={userSubsector} color="white" />
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      )}
    </Menu>
  );
}

function UserSubsector() {
  const { subsectorSlug } = useParams();
  const viewerContext = useContext(ViewerContext);

  return (
    <DataLoader
      query={USER_SUBSECTOR_QUERY}
      variables={{ themeSlug: "health", subsectorSlug, userId: viewerContext?.userId }}
      render={({ userSubsector }) => (
        <UserSubsectorBody
          aboveMainChart={
            <>
              <Center>
                <UserSubsectorMenu userSubsector={userSubsector} />
              </Center>
              <Box py={[2, 2, 20]} />
            </>
          }
          userSubsector={userSubsector}
        />
      )}
    />
  );
}

function WellnessScoresTab() {
  const viewerContext = useContext(ViewerContext);

  return (
    <Switch>
      <Route path={CLIENT_MANAGEMENT_PLATFORM_URL_BASE} exact>
        <DataLoader
          query={USER_THEME_QUERY}
          variables={{
            userId: viewerContext?.userId,
            themeSlug: "health"
          }}
          render={({ userTheme, userSubsectors }) =>
            userSubsectors.length ? (
              <>
                <PanelBoxV2
                  maxWidth={760}
                  outer={{
                    pt: [40, 40, 60],
                    px: 20,
                    pb: [50, 50, 80],
                    bg: "white"
                  }}
                >
                  <PanelBoxV2
                    maxWidth={760}
                    outer={{
                      px: 20
                    }}
                  >
                    <Box as="h2" fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
                      Patient Health Scores
                    </Box>
                    <Box py={[2, 2, 20]} />
                    <CollapseableText fontSize={[14, 14, 16]}>
                      {`In this sector, we are looking at how various structures of the body, such as,
                      cells, tissues, organs, and systems are interlinked.

                      A healthy body is maintained by good nutrition, optimal detoxification, regular exercise, balanced hormones, avoiding harmful habits, making informed and responsible decisions about health, and seeking medical assistance when necessary.`}
                    </CollapseableText>
                  </PanelBoxV2>
                </PanelBoxV2>
                <UserSubsectorScores userTheme={userTheme} userSubsectors={userSubsectors} />
              </>
            ) : (
              <NothingToShow
                jumbotron={
                  <>
                    <Box as="h2" fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
                      No Results available
                    </Box>
                    <Box py={[2, 2, 20]} />
                    <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
                      Your patients wellness results will become available once they have taken a
                      functional test or completed a symptom questionnaire.
                    </Box>
                  </>
                }
              />
            )
          }
        />
      </Route>
      <Route path={CLIENT_MANAGEMENT_USER_SUBSECTOR} component={UserSubsector} />
    </Switch>
  );
}

const GET_PARTNER_QUESTIONNAIRE_RESULTS = gql`
  query GET_PARTNER_QUESTIONNAIRE_RESULTS($userId: ID!) {
    userSubmission(userId: $userId) {
      id
      report {
        hasAnswers
        sections {
          presentationSection
          userQuestions {
            id
            question {
              id
              text
            }
            answerScore
            answerText
          }
        }
      }
    }
  }
`;

function SelfAssessment({ sections }) {
  return (
    <>
      <PanelBoxV2
        maxWidth={1020}
        outer={{
          pt: [30, 30, 60],
          pb: [50, 50, 80],
          px: 20
        }}
      >
        <PanelBoxV2
          maxWidth={760}
          outer={{
            px: 20
          }}
        >
          <Box as="h2" fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
            Self assessment
          </Box>
          <Box py={[2, 2, 20]} />
          <CollapseableText fontSize={[14, 14, 16]}>
            Find below all responses to the symptoms questionnaire which form part of the wellness
            score. Please encourage your patient for a regular update every 3-6 months to keep it up
            to date.
          </CollapseableText>
        </PanelBoxV2>
        <Box py={[2, 2, 20]} />
        <Accordion
          variant="separated"
          chevronVariant="circle"
          multiple
          gap={[10, 10, 20]}
          size={20}
        >
          {sections?.map(section => (
            <Accordion.Item value={section.presentationSection} key={section.presentationSection}>
              <Accordion.Control px={[15, 15, 20]} py={[2, 2, 15]}>
                <Box
                  fontFamily="gilroyBold"
                  fontSize={[16, 16, 18]}
                  lineHeight="130%"
                >{`Section ${section.presentationSection}`}</Box>
              </Accordion.Control>
              <Accordion.Panel px={[20, 20, 40]}>
                <SymptomsList questions={section.userQuestions} />
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      </PanelBoxV2>
    </>
  );
}

function SelfAssessmentTab() {
  const viewerContext = useContext(ViewerContext);

  return (
    <DataLoader
      query={GET_PARTNER_QUESTIONNAIRE_RESULTS}
      variables={{
        userId: viewerContext.userId
      }}
      render={({ userSubmission }) =>
        userSubmission?.report?.hasAnswers ? (
          <SelfAssessment sections={userSubmission.report.sections} />
        ) : (
          <NothingToShow
            header={
              <PanelBoxV2
                maxWidth={760}
                outer={{
                  px: 20
                }}
              >
                <Box as="h2" fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
                  Self assessment
                </Box>
                <Box py={[2, 2, 20]} />
                <CollapseableText fontSize={[14, 14, 16]}>
                  The following answers from the symptom questionnaire are used in th wellness
                  scoring system. Encourage your patient to update this questionnaire every 3-6
                  months.
                </CollapseableText>
              </PanelBoxV2>
            }
            jumbotron={
              <>
                <Box as="h2" fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
                  No responses available
                </Box>
                <Box py={[2, 2, 20]} />
                <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
                  Looks like your patient is yet to complete their symptom questionnaire.
                </Box>
              </>
            }
          />
        )
      }
    />
  );
}

function RecommendationsTab() {
  const viewerContext = useContext(ViewerContext);

  const { viewCard, closeCard, cardOpen } = useCardList();

  const cardListApi = useMemo(
    () => ({
      viewCard,
      closeCard
    }),
    [viewCard, closeCard]
  );

  return (
    <DataLoader
      query={USER_SUBSECTORS_QUERY}
      variables={{
        dimensions: [
          {
            width: 990
          },
          {
            width: 1000
          }
        ],
        excludeEmptyRecommendations: true,
        includeThingsToDoCount: true,
        includeThingsToAvoidCount: true,
        includeThingsToTakeCount: true,
        includePeopleToSeeCount: true,
        userId: viewerContext?.userId,
        suppressed: true
      }}
      render={({ userSubsectors, userTestRecommendations }) =>
        userSubsectors?.length ? (
          <>
            <CardListContext.Provider value={cardListApi}>
              <PriorityRecommendations
                header="Patient Prioritised Recommendation List"
                userSubsectors={userSubsectors}
                userTestRecommendations={userTestRecommendations}
                bodyCopy=""
              />
            </CardListContext.Provider>
            <Modal
              centered
              bg={cardOpen?.bg}
              close={closeCard}
              show={!!cardOpen}
              maxWidth={1020}
              closeButton
              headerProps={{
                pr: 20,
                pt: 20,
                pb: [40, 40, 20]
              }}
            >
              {cardOpen?.item?.id && (
                <RecommendationModalContent recommendationId={cardOpen.item.id} />
              )}
            </Modal>
          </>
        ) : (
          <NothingToShow
            header={
              <PanelBoxV2
                maxWidth={760}
                outer={{
                  px: 20
                }}
              >
                <Box as="h2" fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
                  {PriorityRecommendations.defaultProps.header}
                </Box>
              </PanelBoxV2>
            }
            jumbotron={
              <>
                <Box as="h2" fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
                  No recommendations available
                </Box>
                <Box py={[2, 2, 20]} />
                <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
                  Looks like your patient is yet to complete their symptom questionnaire.
                </Box>
              </>
            }
          />
        )
      }
    />
  );
}

export const EXAMPLE_FOODS_QUERY = gql`
  query ExampleFoodsQuery($userId: ID) {
    foodsToDo: examples(userId: $userId, verb: "Increase", category: "Food") {
      id
      slug
      name
      parentCategory {
        id
        name
      }
    }
    foodsToAvoid: examples(userId: $userId, verb: "Reduce", category: "Food") {
      id
      slug
      name
      parentCategory {
        id
        name
      }
    }
  }
`;

export const EXAMPLE_QUERY = gql`
  query Example($exampleId: ID, $userId: ID, $verbGroup: VerbGroups) {
    example(exampleId: $exampleId) {
      id
      name
      relatedUserResults(userId: $userId, verbGroup: $verbGroup) {
        id
        chartConfiguration {
          ...ChartConfigurationFields
        }
        result {
          id
          name
        }
        resultState {
          id
          name
        }
      }
    }
  }
  ${CHART_CONFIGURATION_FIELDS}
`;

function Foods({ foodsToDo, foodsToAvoid }) {
  const viewerContext = useContext(ViewerContext);
  const [list, setList] = useState("increase");
  const foodsToDoNoConflict = foodsToDo.filter(food => !foodsToAvoid.find(f => f.id === food.id));
  const { viewCard, cardOpen, setCardOpen } = useCardList();

  const increase = groupBy(foodsToDoNoConflict, "parentCategory.name");
  const avoid = groupBy(foodsToAvoid, "parentCategory.name");

  const items = list === "increase" ? Object.entries(increase) : Object.entries(avoid);

  const verb_group_map = {
    increase: THINGS_TO_DO,
    avoid: THINGS_TO_AVOID
  };

  return (
    <PanelBoxV2
      maxWidth={1538}
      outer={{
        pt: [30, 30, 60],
        pb: [50, 50, 80],
        bg: "haze"
      }}
    >
      <Stack gap={40}>
        <Box display="flex" justifyContent={["center"]}>
          <SegmentedControl
            data={[
              {
                label: "Increase",
                value: "increase"
              },
              {
                label: "Avoid",
                value: "avoid"
              }
            ]}
            onChange={setList}
            value={list}
            height={55}
          />
        </Box>
        {items.map(([groupName, foods]) => (
          <Stack key={groupName} gap={20}>
            <Box fontFamily="gilroyBold" fontSize={28}>
              {groupName}
            </Box>
            <RelatedResultContext.Provider
              value={{
                openModal: result => {
                  viewCard({
                    type: "results",
                    item: result
                  });
                }
              }}
            >
              <Accordion
                variant="separated"
                chevronVariant="circle"
                multiple
                size={20}
                gap={[10, 10, 20]}
              >
                {foods.map(food => (
                  <Accordion.Item value={food.id}>
                    <Accordion.Control
                      px={[15, 15, 20]}
                      py={[2, 2, 15]}
                      chevronIcon={
                        <Group alignItems="center" gap={10}>
                          <Box
                            fontFamily="gilroyBold"
                            fontSize={10}
                            textTransform="uppercase"
                            letterSpacing={2.8}
                          >
                            Why?
                          </Box>
                          <AccordionContext.Consumer>
                            {({ openState }) => (
                              <ChevronComponent
                                fill="dark"
                                height={10}
                                width={10}
                                direction={openState.has(food.id) ? "bottom" : "right"}
                                style={{
                                  margin: 0
                                }}
                              />
                            )}
                          </AccordionContext.Consumer>
                        </Group>
                      }
                    >
                      <Box fontFamily="gilroyBold" fontSize={18} lineHeight="130%">
                        {food.name}
                      </Box>
                    </Accordion.Control>
                    <Accordion.Panel px={[20, 20, 40]} pb={[2, 2, 20]}>
                      <DataLoader
                        query={EXAMPLE_QUERY}
                        variables={{
                          exampleId: food.id,
                          userId: viewerContext?.userId,
                          verbGroup: verb_group_map[list].replace(/\s/g, "_")
                        }}
                        render={({ example }) => (
                          <RelatedResults results={example.relatedUserResults} />
                        )}
                      ></DataLoader>
                    </Accordion.Panel>
                  </Accordion.Item>
                ))}
              </Accordion>
            </RelatedResultContext.Provider>
          </Stack>
        ))}
      </Stack>
      <ChartModal close={() => setCardOpen(undefined)} show={!!cardOpen?.type}>
        {viewCard ? (
          <ViewerContext.Consumer>
            {({ userId }) => (
              <DataLoader
                query={USER_RESULT_QUERY}
                variables={{
                  userId,
                  userResultId: cardOpen.item.id
                }}
                render={({ userResult, relatedGenes }) => (
                  <UserResultModelContent userResult={userResult} relatedGenes={relatedGenes} />
                )}
              />
            )}
          </ViewerContext.Consumer>
        ) : null}
      </ChartModal>
    </PanelBoxV2>
  );
}

function FoodList() {
  const viewerContext = useContext(ViewerContext);

  return (
    <DataLoader
      query={EXAMPLE_FOODS_QUERY}
      variables={{
        userId: viewerContext?.userId
      }}
      render={({ foodsToDo, foodsToAvoid }) =>
        foodsToDo.length || foodsToAvoid.length ? (
          <>
            <PanelBoxV2
              maxWidth={760}
              outer={{
                pt: [40, 40, 60],
                px: 20,
                pb: [50, 50, 80]
              }}
            >
              <PanelBoxV2
                maxWidth={760}
                outer={{
                  px: 20
                }}
              >
                <Box as="h2" fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
                  Food list
                </Box>
                <Box py={[2, 2, 20]} />
                <CollapseableText fontSize={[14, 14, 16]}>
                  {`We’ve analysed your self assessment and test results to create a personalised food list. Explore the items within each food group to reveal your recommendations.`}
                </CollapseableText>
              </PanelBoxV2>
              <Foods foodsToDo={foodsToDo} foodsToAvoid={foodsToAvoid} />
            </PanelBoxV2>
          </>
        ) : (
          <NothingToShow
            header={
              <PanelBoxV2
                maxWidth={760}
                outer={{
                  px: 20
                }}
              >
                <Box as="h2" fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
                  Food list
                </Box>
                <Box py={[2, 2, 20]} />
                <CollapseableText fontSize={[14, 14, 16]}>
                  We’ve analysed your self assessment and test results to create a personalised food
                  list. Explore the items within each food group to reveal your recommendations.
                </CollapseableText>
              </PanelBoxV2>
            }
            jumbotron={
              <>
                <Box as="h2" fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
                  No foods available
                </Box>
                <Box py={[2, 2, 20]} />
                <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
                  Looks like your patient is yet to complete their symptom questionnaire.
                </Box>
              </>
            }
          />
        )
      }
    />
  );
}

export default function ClientManagementPlatform() {
  const { patientId, tab } = useParams();

  return (
    <DataLoader
      query={PATIENT_DETAILS}
      variables={{
        userId: patientId
      }}
      render={({ user }) => {
        return (
          <ViewerContext.Provider
            value={{
              userId: patientId
            }}
          >
            <Page bg="haze" includeFooter>
              <Box bg="partners">
                {/* <IncludeExpiredMarkers /> */}
                <PanelBoxV2
                  maxWidth={1020}
                  outer={{
                    px: 20
                  }}
                >
                  <Box py={[20, 20, 30]} />
                  <Box
                    color="dark"
                    fontSize={[24, 34, 34, 44]}
                    lineHeight="115%"
                    fontFamily="gilroyBold"
                  >
                    {`${user.firstName} ${user.lastName}`}
                  </Box>
                  <Box py={[2, 2, 15]} />
                </PanelBoxV2>
              </Box>
              <PanelBoxV2
                outer={{
                  bg: "partners",
                  pl: 20,
                  borderWidth: 1,
                  borderBottomStyle: "solid",
                  borderColor: "haze"
                }}
                inner={{
                  display: "flex",
                  overflowX: "auto"
                }}
              >
                {ALL_TABS.map(tab => (
                  <Tab tab={tab} key={tab.label} />
                ))}
              </PanelBoxV2>
              {tab === "tests" && <TestsTab />}
              {tab === "wellness" && <WellnessScoresTab />}
              {tab === "self_assessment" && <SelfAssessmentTab />}
              {tab === "recommendations" && <RecommendationsTab />}
              {tab === "food_list" && <FoodList />}
            </Page>
          </ViewerContext.Provider>
        );
      }}
    />
  );
}
