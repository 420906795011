import React from "react";

import { motion } from "framer-motion";

const DURATION = 0.5;
const EXIT_DURATION = 0.2;

function FadeIn(props) {
  return (
    <motion.div
      className={props.className}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: {
          duration: DURATION
        }
      }}
      exit={{ opacity: 0, transition: { duration: EXIT_DURATION } }}
      style={props.style}
    >
      {props.children}
    </motion.div>
  );
}

export default FadeIn;
