import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { gql, useMutation } from "@apollo/client";
import { capitalize } from "lodash";
import styled from "styled-components";

import Box from "../components/Box";
import DataLoader from "../components/DataLoader";
import Form, { FormContext } from "../components/Form";
import { DownloadCrossOriginFile } from "../components/Links";
import Loading from "../components/Loading";
import Page from "../components/Page";
import RadioGroup from "../components/RadioGroup";
import Text from "../components/Text";
import { theme } from "../core/theme";
import useWindowSize from "../hooks/use-window-size";
import { ReactComponent as CrossImage } from "../images/cross.svg";
import { ReactComponent as FileImage } from "../images/file.svg";
import { ReactComponent as TickImage } from "../images/tick.svg";
import { ReactComponent as TriangleImage } from "../images/triangle.svg";
import { SpacedContentBox } from "./Boxes";
import { FilterButton, SolidButton } from "./Buttons";
import ChevronComponent from "./Chevron";
import { ChevronButton } from "./ChevronButton";
import { CollapseableText } from "./CollapseableText";
import { HeadingExtraLarge, HeadingLarge, HeadingMedium } from "./Headings";
import Jumbotron from "./Jumbotron";
import Menu from "./Menu";
import Modal from "./Modal";
import { MonthYearInputField } from "./MonthYearInput";

const FileIconWrapper = styled.div`
  position: relative;
  .triangle {
    position: absolute;
    top: 1%;
    left: 0%;
  }
`;

function FileIcon() {
  return (
    <FileIconWrapper>
      <TriangleImage className="triangle" />
      <FileImage />
    </FileIconWrapper>
  );
}

const UploadTestContext = createContext();

const GET_TEST_DATA_FILES = gql`
  query TestDataFiles {
    testDataFiles {
      id
      name
      testingService
      sampleType
      sampleFile
      fileType {
        id
        extension
        contentType
      }
      registrationQuestions(considerGender: true) {
        id
        question
        answers {
          id
          answer
        }
      }
    }
    testingServices
    sampleTypes
  }
`;

function SampleTypeButton({ children, selected, ...defaultProps }) {
  const props = selected
    ? { backgroundColor: "dark", borderColor: "dark", color: "white" }
    : { color: "dark", bg: "transparent" };

  let padding;
  if (defaultProps.size === "lg") {
    padding = "15px 20px";
  } else if (defaultProps.size === "md") {
    padding = "10px 20px";
  }

  return (
    <SolidButton {...defaultProps} {...props} padding={padding}>
      {children}
    </SolidButton>
  );
}

SampleTypeButton.defaultProps = {
  fontSize: 12,
  lineHeight: "12px",
  borderTopLeftRadius: 40,
  borderTopRightRadius: 40,
  borderBottomLeftRadius: 40,
  borderBottomRightRadius: 40,
  whiteSpace: "nowrap",
  size: "lg"
};

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0 10px;
  width: 100%;

  thead th {
    font-family: Gilroy W05 Bold;
    text-align: left;
  }

  th,
  td {
    padding: 20px 35px;
  }

  tbody tr {
    background-color: white;
  }
`;

function SampleTable({ rows, tableProps }) {
  const { openModal } = useContext(UploadTestContext);

  return (
    <Table {...tableProps}>
      <thead>
        <tr>
          <th>Labratory</th>
          <th>Sample Type</th>
          <th>Test Name</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {rows.map(row => (
          <tr key={row.id}>
            <td>{row.testingService}</td>
            <td>
              <SampleTypeButton selected size="md">
                {row.sampleType}
              </SampleTypeButton>
            </td>
            <td>{row.name}</td>
            <td>
              <Box display="flex">
                {row.meta.sampleFile ? (
                  <DownloadCrossOriginFile
                    fileUrl={row.meta.sampleFile}
                    fileName={`${row.name}.${row.meta.fileType.extension}`}
                    contentType={row.meta.fileType.contentType}
                    containerProps={{
                      display: "contents"
                    }}
                    trigger={
                      <SolidButton
                        {...SampleTypeButton.defaultProps}
                        borderRadius={5}
                        padding="15px 30px"
                        bg="dark"
                        borderColor="dark"
                        color="white"
                        mr={2}
                      >
                        sample file
                      </SolidButton>
                    }
                  />
                ) : (
                  <SolidButton
                    {...SampleTypeButton.defaultProps}
                    borderRadius={5}
                    padding="15px 30px"
                    bg="dark"
                    borderColor="dark"
                    color="white"
                    mr={2}
                  >
                    sample file
                  </SolidButton>
                )}
                <ChevronButton
                  bg="green"
                  borderColor="green"
                  padding="15px 30px"
                  whiteSpace="nowrap"
                  handleClick={() => openModal(row)}
                >
                  upload file
                </ChevronButton>
              </Box>
            </td>
          </tr>
        ))}
        {!rows.length && (
          <tr>
            <td colSpan={4}>No matches available</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

const FileInputWrapper = styled.div`
  position: relative;

  input[type="file"] {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const GENERATE_PRESIGNED_URL_MUTATION = gql`
  mutation GeneratePresignedUrlMutation($input: GeneratePresignedUrlMutationInput!) {
    generatePresignedUrlMutation(input: $input) {
      fields
      url
      errors {
        field
        messages
      }
    }
  }
`;

function uploadFileToS3(url, fields, file) {
  const formData = new FormData();
  Object.entries(fields).forEach(([key, value]) => formData.set(key, value));
  formData.set("file", file, fields.key);
  const request = new Request(url, {
    method: "POST",
    body: formData
  });
  return fetch(request);
}

function FileInputButton({ handleChange }) {
  const [generatePresignedPostUrl] = useMutation(GENERATE_PRESIGNED_URL_MUTATION);
  const { showFileUploadModal, setCallingApi } = useContext(UploadTestContext);
  const ref = useRef();

  const [presignedUrl, setPresignedUrl] = useState();
  const [file, setFile] = useState();
  const [status, setStatus] = useState(0);
  // 0 is initial state i.e. not yet clicked file button to upload file
  // 1 is uploading to s3
  // 2 is upload successful
  // 3 upload failed

  const uploadFile = useCallback(
    e => {
      setCallingApi(true);
      generatePresignedPostUrl({
        variables: {
          input: {
            testDataFile: showFileUploadModal.meta.id
          }
        }
      })
        .then(res => res.data)
        .then(data => {
          if (data.generatePresignedUrlMutation.url && data.generatePresignedUrlMutation.fields) {
            setPresignedUrl({
              fields: data.generatePresignedUrlMutation.fields,
              url: data.generatePresignedUrlMutation.url
            });
          } else {
            setPresignedUrl();
          }
          setCallingApi(false);
        })
        .catch(error => {
          setPresignedUrl();
          setCallingApi(false);
          setStatus(3);
          console.log(
            `Error encountered generating a presigned url for user test upload, ${error}`
          );
        });
    },
    [generatePresignedPostUrl, setPresignedUrl, showFileUploadModal, setCallingApi, setStatus]
  );

  const handleChangeRef = useRef();
  handleChangeRef.current = handleChange;

  useEffect(() => {
    if (file && presignedUrl) {
      setCallingApi("uploading file");
      setStatus(1);
      uploadFileToS3(presignedUrl.url, presignedUrl.fields, file)
        .then(response => {
          if (response.status === 204 && handleChangeRef.current) {
            handleChangeRef.current(presignedUrl.fields.key);
          }
          setCallingApi(false);
          setStatus(2);
        })
        .catch(error => {
          setCallingApi(false);
          setStatus(3);
          console.log("Error encountered uploading file to s3", error);
        });
    }
  }, [file, presignedUrl, setCallingApi, setStatus]);

  if (status === 0) {
    return (
      <FileInputWrapper>
        <input
          ref={ref}
          onClick={uploadFile}
          type="file"
          onChange={() => {
            setFile(ref.current.files[0]);
          }}
        />
        <ChevronButton bg="dark" borderColor="dark" padding="15px 30px" whiteSpace="nowrap">
          upload file
        </ChevronButton>
      </FileInputWrapper>
    );
  }

  if (status === 1) {
    return (
      <>
        <Box>
          <FileIcon />
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Loading size={25} minHeight={50} />
          <Text ml={30}>Upload in progress</Text>
        </Box>
      </>
    );
  }

  if (status === 2) {
    return (
      <Box>
        <Box display="flex" justifyContent="center">
          <FileIcon />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" mt={20}>
          <TickImage fill={theme.colors.green} height={24} />
          <Text ml={2}>File upload complete</Text>
        </Box>
      </Box>
    );
  }

  if (status === 3) {
    return (
      <Box display="flex" alignItems="center" gap={10}>
        <CrossImage fill={theme.colors.genetics} height={20} width={20} />
        <Text>Upload error - please close and retry</Text>
      </Box>
    );
  }
}

function SummaryAndFileUpload({ handleChange }) {
  const { showFileUploadModal } = useContext(UploadTestContext);
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box>
        <Text fontFamily="gilroyMedium" fontSize={16} mt={70} mb={4} textAlign="center">
          Lab provider: {showFileUploadModal.testingService}
        </Text>
        <Text fontFamily="gilroyMedium" fontSize={16} mb={6} textAlign="center">
          Sample type: {showFileUploadModal.sampleType.toLowerCase()}
        </Text>
        <Box></Box>
      </Box>
      <FileInputButton handleChange={handleChange} />
    </Box>
  );
}

const CREATE_USER_TEST_MUTATION = gql`
  mutation CreateUserTestMutation($input: UploadUserTestMutationInput!) {
    uploadUserTestMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

const formFields = [
  {
    fields: [
      {
        name: "dateTaken",
        initialValue: "",
        widget: {
          component: MonthYearInputField,
          props: {
            label: (
              <Text fontFamily="gilroyBold" fontSize={18} mb={4}>
                Enter the date the test was taken
              </Text>
            )
          }
        }
      },
      {
        name: "filename",
        initialValue: "",
        widget: {
          component: SummaryAndFileUpload,
          props: {}
        }
      }
    ]
  }
];

function Questions({ questions, handleChange: propHandleChange, value }) {
  const handleChange = useCallback(
    (question, answerId) => {
      const parsed = value === "" ? {} : JSON.parse(value);
      parsed[question.id] = answerId;
      propHandleChange(JSON.stringify(parsed));
    },
    [value, propHandleChange]
  );

  function getValueForQuestion(question) {
    const parsed = value === "" ? {} : JSON.parse(value);
    return parsed[question.id] || "";
  }

  return questions.map(question => (
    <RadioGroup
      key={question.id}
      value={getValueForQuestion(question)}
      handleChange={answerId => handleChange(question, answerId)}
      label={question.question}
      options={question.answers.map(answer => ({ name: answer.answer, value: answer.id }))}
      labelProps={{ my: 20 }}
    />
  ));
}

function FileUploadForm({ setSuccessfullyUploaded }) {
  const { closeModal, showFileUploadModal, callingApi } = useContext(UploadTestContext);
  return (
    <Form
      handleSubmitted={() => setSuccessfullyUploaded(true)}
      mutation={CREATE_USER_TEST_MUTATION}
      extraInput={{
        testDataFile: showFileUploadModal.meta.id
      }}
      formWrapperProps={{
        maxWidth: null,
        mx: null
      }}
      data={[
        ...formFields,
        {
          fields: [
            {
              name: "questionsAndAnswers",
              initialValue: "",
              widget: {
                component: Questions,
                props: {
                  questions: showFileUploadModal.questions
                }
              }
            }
          ]
        }
      ]}
      buttons={
        <FormContext.Consumer>
          {({ submitting, submit }) => (
            <>
              <Box
                display="flex"
                mt={40}
                flexDirection={["column", "row"]}
                visibility={callingApi === "uploading file" ? "hidden" : "visible"}
              >
                <ChevronButton
                  bg="red"
                  borderColor="red"
                  mr={4}
                  my={[4, 0]}
                  handleClick={closeModal}
                >
                  Cancel
                </ChevronButton>
                <ChevronButton
                  bg="green"
                  borderColor="green"
                  handleClick={() => {
                    if (!submitting) {
                      submit();
                    }
                  }}
                >
                  Process
                </ChevronButton>
              </Box>
            </>
          )}
        </FormContext.Consumer>
      }
    />
  );
}

function FileUploadModal() {
  const { closeModal, showFileUploadModal } = useContext(UploadTestContext);
  const [successfullyUploaded, setSuccessfullyUploaded] = useState();
  const windowSize = useWindowSize();

  return (
    <Modal
      bg="white"
      close={() => {
        closeModal();
        setSuccessfullyUploaded(false);
      }}
      show={!!showFileUploadModal?.id && windowSize.width >= 1024}
      centered
      closeButton
      maxWidth={800}
      my={20}
    >
      {showFileUploadModal?.id ? (
        <SpacedContentBox maxWidth={646} p={4} pt={6} pb={80}>
          <HeadingMedium mb={40} textTransform="uppercase">
            {capitalize(showFileUploadModal.name)}
          </HeadingMedium>
          {successfullyUploaded ? (
            <>
              <Text lineHeight={1.5} mb={2}>
                Your file has been queued to be processed.
              </Text>
              <Text lineHeight={1.5}>We will email you as soon as it is available to view.</Text>
              <ChevronButton
                handleClick={() => {
                  closeModal();
                  setSuccessfullyUploaded(false);
                }}
                mt={40}
              >
                Add another file
              </ChevronButton>
            </>
          ) : (
            // every time the modal is closed this should unmount because showFileUploadModal becomes undefined
            // so next time the modal opens it remounts and so there is no risk of values getting mixed up
            // relating to different uploads
            <FileUploadForm setSuccessfullyUploaded={setSuccessfullyUploaded} />
          )}
        </SpacedContentBox>
      ) : null}
    </Modal>
  );
}

const DEFAULT_SAMPLE_TYPE = "ALL SAMPLE TYPES";

const TestingServiceItem = styled.li`
  cursor: pointer;
  padding: 20px;
  background-color: ${props => (props.selected ? "#f2f2f2" : null)};

  &:hover {
    background-color: #f2f2f2;
  }
`;

function UploadTest({ testDataFiles, testingServices, sampleTypes }) {
  const [showFileUploadModal, setShowFileUploadModal] = useState({});
  const [selectedSampleType, setSelectedSampleType] = useState(DEFAULT_SAMPLE_TYPE);
  const [testingServiceFilter, setTestingServiceFilter] = useState();

  const [callingApi, setCallingApi] = useState(false);

  const closeModal = useCallback(() => {
    if (!callingApi) {
      // if the user closes the form modal
      // and api calls still have't resolved
      // react will attempt to update state for components
      // now unmounted
      setShowFileUploadModal({});
    }
  }, [callingApi, setShowFileUploadModal]);

  const openModal = useCallback(
    obj => {
      setShowFileUploadModal(obj);
    },
    [setShowFileUploadModal]
  );

  const windowSize = useWindowSize();

  const uploadTestContextApi = useMemo(
    () => ({
      closeModal,
      openModal,
      showFileUploadModal,
      setCallingApi,
      callingApi
    }),
    [closeModal, openModal, showFileUploadModal, setCallingApi, callingApi]
  );

  const [showDropdownMenu, setShowDropdownMenu] = useState(false);

  const allRows = testDataFiles.reduce(
    (acc, cur) => [
      ...acc,
      ...cur.testingService
        .filter(testingService => testingService !== "Omnos" && testingService !== "Regenerus")
        .map(testingService => ({
          id: `${cur.id}:${testingService}`,
          name: cur.name,
          testingService,
          sampleType: cur.sampleType,
          questions: cur.registrationQuestions,
          meta: {
            ...cur
          }
        }))
    ],
    []
  );

  const allSampleTypes = [...new Set(allRows.reduce((acc, curr) => [...acc, curr.sampleType], []))];

  let rows = [...allRows];
  if (selectedSampleType !== DEFAULT_SAMPLE_TYPE) {
    rows = allRows.filter(row => row.sampleType === selectedSampleType);
  }
  if (testingServiceFilter) {
    rows = rows.filter(row => row.meta.testingService.includes(testingServiceFilter));
  }
  return (
    <UploadTestContext.Provider value={uploadTestContextApi}>
      <Page height="100vh">
        <Box display="flex" flexDirection="column" height="100%">
          <Jumbotron>
            <HeadingExtraLarge color="white" fontSize={[24, 34, 34, 44]} mb={30}>
              Test upload data (beta)
            </HeadingExtraLarge>
          </Jumbotron>
          <SpacedContentBox bg="white" maxWidth={760} px={4}>
            <HeadingLarge mt={60} mb={30}>
              Upload your previous test results
            </HeadingLarge>
            <CollapseableText containerProps={{ mb: 40 }}>
              If you have a test result from one of our supported labs you can now upload it into
              the platform and have it considered with your other Omnos tests.
            </CollapseableText>
          </SpacedContentBox>
          <SpacedContentBox bg="haze" flexGrow={1} maxWidth={1278} px={4} overflow="hidden">
            <HeadingMedium fontFamily="gilroyBold" mb={30} mt={60}>
              Find a matching test
            </HeadingMedium>
            <Text lineHeight={"24px"} fontSize={16}>
              Use the sample type or testing service below to find the test type that matches your
              sample.
            </Text>
            <Box display="flex" gap="8px" mt={6} mb={6}>
              {[
                DEFAULT_SAMPLE_TYPE,
                ...sampleTypes.filter(sampleType => allSampleTypes.includes(sampleType))
              ].map(sampleType => (
                <SampleTypeButton
                  key={sampleType}
                  handleClick={() => setSelectedSampleType(sampleType)}
                  selected={selectedSampleType === sampleType}
                >
                  {sampleType}
                </SampleTypeButton>
              ))}
            </Box>
            <Box display="flex" alignItems="center">
              <Menu
                opened={showDropdownMenu}
                onChange={internalShowValue => setShowDropdownMenu(internalShowValue)}
                trigger="hover"
              >
                <Menu.Target>
                  <SolidButton
                    {...FilterButton.defaultProps}
                    fontSize={16}
                    padding="21.5px 30px"
                    textTransform="uppercase"
                    color="dark"
                    bg="white"
                    borderColor="white"
                    letterSpacing={0}
                  >
                    Testing Services
                    <ChevronComponent
                      direction={showDropdownMenu ? "bottom" : "top"}
                      fill={theme.colors.dark}
                      style={{
                        marginLeft: 10
                      }}
                    />
                  </SolidButton>
                </Menu.Target>
                <Menu.Dropdown mt={2} py={2} zIndex={2} minWidth={320}>
                  {testingServices.map(lab => (
                    <Menu.Item
                      key={lab}
                      selected={lab === testingServiceFilter}
                      onClick={() => setTestingServiceFilter(lab)}
                      px={4}
                      py={4}
                    >
                      <Text fontFamily="gilroyBold">{lab}</Text>
                    </Menu.Item>
                  ))}
                </Menu.Dropdown>
              </Menu>
              <Text
                cursor="pointer"
                onClick={() => {
                  setTestingServiceFilter();
                  setSelectedSampleType(DEFAULT_SAMPLE_TYPE);
                }}
                underline
                ml={40}
              >
                Clear all filters
              </Text>
            </Box>
            <Box mt={50} mb={80}>
              <SampleTable rows={rows} />
              <FileUploadModal />
            </Box>
          </SpacedContentBox>
        </Box>
        <Modal
          centered
          bg="white"
          containerProps={{ px: 20 }}
          maxWidth={500}
          show={windowSize.width <= 1024}
        >
          <Box mx="auto" py={100}>
            <Text mb={2} fontFamily="gilroyBold" textAlign="center">
              We need a little more room
            </Text>
            <Text fontFamily="gilroyBold" fontSize={12} textAlign="center">
              Please increase the width of your browser to proceed
            </Text>
          </Box>
        </Modal>
      </Page>
    </UploadTestContext.Provider>
  );
}

function UploadTestDataLoader() {
  return (
    <DataLoader
      query={GET_TEST_DATA_FILES}
      render={({ testDataFiles, testingServices, sampleTypes }) => (
        <UploadTest
          testDataFiles={testDataFiles}
          testingServices={testingServices}
          sampleTypes={sampleTypes}
        />
      )}
    />
  );
}

export default UploadTestDataLoader;
