import Checkbox from "../components/Checkbox";

const partnerUpdatesConsentField = ({ label }) => ({
  initialValue: true,
  name: "partnerUpdatesConsent",
  boxProps: {
    pt: 40
  },
  widget: {
    component: Checkbox,
    props: {
      label,
      containsLink: true,
      labelProps: {
        alignItems: "unset"
      }
    }
  }
});

export default partnerUpdatesConsentField;
