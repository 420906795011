import { Elements } from "@stripe/react-stripe-js";
import Helmet from "react-helmet";
import { Redirect, Route } from "react-router-dom";
import { Switch } from "react-router-dom";

import { loadStripe } from "@stripe/stripe-js";
import { ThemeProvider } from "styled-components";

import { AcceptPartnerInvite } from "../components/Acceptances";
import { AcceptPartnerLinkInvite } from "../components/Acceptances";
import { AcceptResultsAccessLink } from "../components/Acceptances";
import ApolloManager from "../components/ApolloManager";
import AppStateProvider from "../components/AppStateProvider";
import AuthProvider from "../components/AuthProvider";
import AuthRoute from "../components/AuthRoute";
import Basket from "../components/Basket";
import ChangePassword from "../components/ChangePassword";
import ChangePasswordSuccess from "../components/ChangePasswordSuccess";
import CookiePage from "../components/CookiePage";
import DietPreferencesPage from "../components/DietPreferencesPage";
import DiscountCodeRedirectPage from "../components/DiscountCodeRedirectPage";
import Error404Page from "../components/Error404Page";
import Error500Page from "../components/Error500Page";
import FoodsPage from "../components/FoodsPage";
import GoalsPage from "../components/GoalsPage";
import HomePage from "../components/HomePage";
import LoginPage from "../components/LoginPage";
import OrderHistoryPage from "../components/OrderHistoryPage";
import OrderPage from "../components/OrderPage";
import OrderProcessingPage from "../components/OrderProcessingPage";
import PartnerShareLinkRedirectPage from "../components/PartnerShareLinkRedirectPage";
import PartnerTermsAndConditionsPage from "../components/PartnerTermsAndConditionsPage";
import PartnersMarketingRedirect from "../components/PartnersMarketingRedirect";
import PartnersPage from "../components/PartnersPage";
import PlatformProfileWelcomePage from "../components/PlatformProfileWelcomePage";
import PlatformUserProfileCompletePage from "../components/PlatformUserProfileCompletePage";
import PlatformUserProfileFormPage from "../components/PlatformUserProfileFormPage";
import PrivacyPage from "../components/PrivacyPage";
import RegisterKitCompletePage from "../components/RegisterKitCompletePage";
import RegisterKitPage from "../components/RegisterKitPage";
import RegisterKitQuestionsPage from "../components/RegisterKitQuestionsPage";
import RegisterKitSignupPage from "../components/RegisterKitSignupPage";
import RequestPasswordResetDonePage from "../components/RequestPasswordResetDonePage";
import RequestPasswordResetPage from "../components/RequestPasswordResetPage";
import ResetPasswordCompletePage from "../components/ResetPasswordCompletePage";
import ResetPasswordPage from "../components/ResetPasswordPage";
import RewriteShopRoute from "../components/RewriteShopRoute";
import SettingsPage from "../components/SettingsPage";
import ShippingAndReturnsPolicyPage from "../components/ShippingAndReturnsPolicy";
import SignupCompletePage from "../components/SignupCompletePage";
import SignupConfirmPage from "../components/SignupConfirmPage";
import SignupDonePage from "../components/SignupDonePage";
import SignupPage from "../components/SignupPage";
import SupplementPage from "../components/SupplementPage";
import SupplementsPage from "../components/SupplementsPage";
import TermsOfServicePage from "../components/TermsOfService";
import TermsOfUsePage from "../components/TermsOfUse";
import TestProductPage from "../components/TestProductPage";
import { getStripePublishableKey } from "../core/config";
import { AFFILIATE, PRACTITIONER } from "../core/constants";
import { theme } from "../core/theme";
import {
  ACCEPT_INVITE_SUCCESS_URL,
  ACCEPT_PARTNER_INVITE_PATTERN_URL,
  ACCEPT_PARTNER_LINK_INVITE_PATTERN_URL,
  ACCEPT_RESULTS_ACCESS_REQUEST_PATTERN_URL,
  CATCH_SHOP_URL,
  CHANGE_PASSWORD_COMPLETE_URL,
  CHANGE_PASSWORD_URL,
  COOKIE_URL,
  DASHBOARD_HOME_URL,
  DISCOUNT_CODE_REDIRECT_URL,
  ERROR_404_URL,
  ERROR_500_URL,
  HOME_URL,
  LOGIN_URL,
  OLD_TEST_PATTERN_URL,
  OLD_TESTS_URL,
  ORDER_PATTERN_URL,
  ORDER_PROCESSING_PATTERN_URL,
  ORDERS_URL,
  PARTNER_SHARE_LINK_URL,
  PARTNER_TERMS_URL,
  PARTNERS_AFFILIATE_URL,
  PARTNERS_PRACTITIONER_URL,
  PARTNERS_URL,
  PLATFORM_PROFILE_COMPLETE_URL,
  PLATFORM_PROFILE_DIET_PREFERENCES_URL,
  PLATFORM_PROFILE_FORM_URL,
  PLATFORM_PROFILE_GOALS_URL,
  PLATFORM_PROFILE_WELCOME_URL,
  PRIVACY_URL,
  QUESTIONNAIRES_URL_BASE,
  REGISTER_KIT_COMPLETE_URL,
  REGISTER_KIT_QUESTIONS_URL,
  REGISTER_KIT_SIGNUP_URL,
  REGISTER_KIT_URL,
  REQUEST_PASSWORD_RESET_DONE,
  REQUEST_PASSWORD_RESET_URL,
  RESET_PASSWORD_COMPLETE_URL,
  RESET_PASSWORD_CONFIRM_PATTERN_URL,
  SETTINGS_URL,
  SIGNUP_COMPLETE_URL,
  SIGNUP_CONFIRM_PATTERN_URL,
  SIGNUP_DONE_URL,
  SIGNUP_URL,
  SHIPPING_AND_RETURNS_URL,
  SUPPLEMENT_PATTERN_URL,
  SUPPLEMENTS_URL,
  TERMS_OF_SERVICE_URL,
  TERMS_OF_USE_URL,
  TEST_FAMILY_PATTERN_URL,
  TEST_PATTERN_URL,
  TESTS_URL,
  THEME_URL,
  RECOMMENDATIONS_URL,
  RECOMMENDATION_URL,
  FOODS_URL,
  TEST_RESULT_URL,
  TEST_RESULTS_URL,
  PARTNER_TEST_RESULTS_URL,
  PARTNER_TEST_RESULT_URL,
  PARTNER_FOOD_LIST_URL,
  PARTNER_QUESTIONNAIRE_RESULTS_URL,
  PARTNER_RECOMMENDATION_URL,
  PARTNER_SUPPLEMENT_URL,
  UPLOAD_TEST,
  TEST_DATAPOINT_URL,
  PARTNER_TEST_DATAPOINT_URL,
  CLIENT_MANAGEMENT_PLATFORM_URL_BASE,
  PARTNER_THEME_SUBSECTOR_URL,
  PARTNER_RECOMMENDATIONS_URL,
  THEME_SUBSECTOR_URL
} from "../core/urls";
import ClientManagementPlatform from "./ClientManagementPlatform";
import Dashboard from "./Dashboard";
import { ExpiredMarkersProvider } from "./ExpiredMarkers";
import ExternalKitRegistration from "./ExternalKitRegistration";
import ExternalKitRegistrationComplete from "./ExternalKitRegistrationComplete";
import MainMenu from "./MainMenu";
import Nav from "./Nav";
import QuestionnaireResults from "./QuestionnaireResults";
import QuestionnaireRoutes from "./QuestionnaireRoutes";
import RecommendationPage from "./RecommendationPage";
import RecommendationsPage from "./Recommendations";
import SentryErrorTest from "./SentryErrorTest";
import TestProductsPage from "./TestProductsPage";
import UploadTest from "./UploadTest";
import UserDatapoint from "./UserDatapoint";
import UserResultPage from "./UserResult";
import UserSubsectorPage from "./UserSubsector";
import UserTest from "./UserTest";
import ThemePage from "./UserTheme";
import "./styles.css";

const stripePromise = loadStripe(getStripePublishableKey());

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <ApolloManager>
          <Elements stripe={stripePromise}>
            <AppStateProvider>
              <ExpiredMarkersProvider>
                <Helmet>
                  <meta property="og:title" content="Omnos" />
                  <meta
                    property="og:description"
                    content="The Omnos programme has been developed to identify how small adjustments to your lifestyle, nutrition and fitness can have incredible results on your overall wellbeing."
                  />
                  <meta property="og:image" content="/og_1200x630.jpg" />
                  <meta property="og:url" content="https://omnos.me" />
                  <meta name="twitter:card" content="summary_large_image" />
                </Helmet>
                <Nav />
                <Basket />
                <MainMenu />
                <Switch>
                  {/* Accounts */}
                  <Route path={HOME_URL} component={HomePage} exact />
                  <Route path={SIGNUP_URL} component={SignupPage} exact />
                  <Route path={SIGNUP_DONE_URL} component={SignupDonePage} exact />
                  <Route path={SIGNUP_CONFIRM_PATTERN_URL} component={SignupConfirmPage} exact />
                  <Route path={SIGNUP_COMPLETE_URL} component={SignupCompletePage} exact />
                  <Route path={LOGIN_URL} component={LoginPage} exact />
                  <Route
                    path={REQUEST_PASSWORD_RESET_URL}
                    component={RequestPasswordResetPage}
                    exact
                  />
                  <Route
                    path={REQUEST_PASSWORD_RESET_DONE}
                    component={RequestPasswordResetDonePage}
                    exact
                  />
                  <Route
                    path={RESET_PASSWORD_CONFIRM_PATTERN_URL}
                    component={ResetPasswordPage}
                    exact
                  />
                  <Route
                    path={RESET_PASSWORD_COMPLETE_URL}
                    component={ResetPasswordCompletePage}
                    exact
                  />
                  <AuthRoute path={CHANGE_PASSWORD_URL} component={ChangePassword} exact />
                  <AuthRoute
                    path={CHANGE_PASSWORD_COMPLETE_URL}
                    component={ChangePasswordSuccess}
                    exact
                  />
                  <AuthRoute path={SETTINGS_URL} component={SettingsPage} exact />
                  {/* Invites */}
                  <Route
                    path={ACCEPT_PARTNER_INVITE_PATTERN_URL}
                    component={AcceptPartnerInvite}
                    exact
                  />
                  <Route
                    path={ACCEPT_PARTNER_LINK_INVITE_PATTERN_URL}
                    component={AcceptPartnerLinkInvite}
                    exact
                  />
                  <AuthRoute
                    path={ACCEPT_RESULTS_ACCESS_REQUEST_PATTERN_URL}
                    component={AcceptResultsAccessLink}
                    exact
                  />
                  <Route path={ACCEPT_INVITE_SUCCESS_URL} component={SignupCompletePage} exact />
                  {/* Onboarding */}
                  <AuthRoute
                    path={PLATFORM_PROFILE_WELCOME_URL}
                    component={PlatformProfileWelcomePage}
                    exact
                  />
                  <AuthRoute
                    path={PLATFORM_PROFILE_FORM_URL}
                    component={PlatformUserProfileFormPage}
                    exact
                  />
                  <AuthRoute path={PLATFORM_PROFILE_GOALS_URL} component={GoalsPage} exact />
                  <AuthRoute
                    path={PLATFORM_PROFILE_DIET_PREFERENCES_URL}
                    component={DietPreferencesPage}
                    exact
                  />
                  <AuthRoute
                    path={PLATFORM_PROFILE_COMPLETE_URL}
                    component={PlatformUserProfileCompletePage}
                    exact
                  />
                  {/* Shop */}
                  <Route path={SUPPLEMENTS_URL} component={SupplementsPage} exact />
                  <Route path={SUPPLEMENT_PATTERN_URL} component={SupplementPage} exact />
                  <Redirect from={TEST_FAMILY_PATTERN_URL} to="/tests" exact />
                  <Route path={TESTS_URL} component={TestProductsPage} exact />
                  <Route path={TEST_PATTERN_URL} component={TestProductPage} exact />
                  <Route path={ORDER_PATTERN_URL} component={OrderPage} exact />
                  <Route
                    path={ORDER_PROCESSING_PATTERN_URL}
                    component={OrderProcessingPage}
                    exact
                  />
                  <Route path={ORDERS_URL} component={OrderHistoryPage} exact />
                  <RewriteShopRoute path={CATCH_SHOP_URL} destination={TESTS_URL} exact />
                  <RewriteShopRoute path={OLD_TESTS_URL} destination={TESTS_URL} exact />
                  <RewriteShopRoute path={OLD_TEST_PATTERN_URL} destination={TESTS_URL} exact />
                  {/* Register Kit */}
                  <Route
                    path="/external-kit-registration"
                    component={ExternalKitRegistration}
                    exact
                  />
                  <Route
                    path="/external-kit-registration/complete"
                    component={ExternalKitRegistrationComplete}
                    exact
                  />
                  <Route path={REGISTER_KIT_URL} component={RegisterKitPage} exact />
                  <Route path={REGISTER_KIT_SIGNUP_URL} component={RegisterKitSignupPage} exact />
                  <Route
                    path={REGISTER_KIT_QUESTIONS_URL}
                    component={RegisterKitQuestionsPage}
                    exact
                  />
                  <Route
                    path={REGISTER_KIT_COMPLETE_URL}
                    component={RegisterKitCompletePage}
                    exact
                  />
                  {/* Partners */}
                  <Route
                    path={DISCOUNT_CODE_REDIRECT_URL}
                    component={DiscountCodeRedirectPage}
                    exact
                  />
                  <Route
                    path={PARTNER_SHARE_LINK_URL}
                    component={PartnerShareLinkRedirectPage}
                    exact
                  />
                  <Route
                    path={PARTNERS_URL}
                    render={() => (
                      <PartnersMarketingRedirect>
                        <PartnersPage />
                      </PartnersMarketingRedirect>
                    )}
                    exact
                  />
                  <Route
                    path={PARTNERS_AFFILIATE_URL}
                    render={() => <PartnersPage tier={AFFILIATE} />}
                    exact
                  />
                  <Route
                    path={PARTNERS_PRACTITIONER_URL}
                    render={() => <PartnersPage tier={PRACTITIONER} />}
                    exact
                  />
                  <AuthRoute
                    path={PARTNER_THEME_SUBSECTOR_URL}
                    component={UserSubsectorPage}
                    exact
                  />
                  <AuthRoute path={PARTNER_TEST_RESULTS_URL} component={UserTest} exact />
                  <AuthRoute path={PARTNER_TEST_RESULT_URL} component={UserResultPage} exact />
                  <AuthRoute path={PARTNER_TEST_DATAPOINT_URL} component={UserDatapoint} exact />
                  <AuthRoute path={PARTNER_FOOD_LIST_URL} component={FoodsPage} exact />
                  <AuthRoute
                    path={PARTNER_RECOMMENDATIONS_URL}
                    component={RecommendationsPage}
                    exact
                  />
                  <AuthRoute
                    path={PARTNER_RECOMMENDATION_URL}
                    component={RecommendationPage}
                    exact
                  />
                  <AuthRoute path={PARTNER_SUPPLEMENT_URL} component={SupplementPage} exact />
                  <AuthRoute
                    path={CLIENT_MANAGEMENT_PLATFORM_URL_BASE}
                    component={ClientManagementPlatform}
                  />
                  <AuthRoute
                    path={PARTNER_QUESTIONNAIRE_RESULTS_URL}
                    component={QuestionnaireResults}
                    exact
                  />
                  {/* <AuthRoute path={PARTNER_TESTS_PATTERN_URL} component={PartnerTestsPage} exact />
                <AuthRoute path={PARTNER_TEST_PATTERN_URL} component={PartnerTestPage} exact />
                <AuthRoute path={PARTNER_RESULT_PATTERN_URL} component={PartnerResultPage} exact />
                <AuthRoute
                  path={PARTNER_RECOMMENDATION_PATTERN_URL}
                  component={PartnerRecommendationPages}
                  exact
                /> */}
                  {/* Legal */}
                  <Route path={TERMS_OF_SERVICE_URL} exact component={TermsOfServicePage} />
                  <Route path={TERMS_OF_USE_URL} exact component={TermsOfUsePage} />
                  <Route
                    path={SHIPPING_AND_RETURNS_URL}
                    exact
                    component={ShippingAndReturnsPolicyPage}
                  />
                  <Route path={PARTNER_TERMS_URL} exact component={PartnerTermsAndConditionsPage} />
                  <Route path={PRIVACY_URL} exact component={PrivacyPage} />
                  <Route path={COOKIE_URL} exact component={CookiePage} />
                  {/* Errors */}
                  <Route path={ERROR_500_URL} exact component={Error500Page} />
                  <Route path={ERROR_404_URL} exact component={Error404Page} />
                  {/* TPO */}
                  <AuthRoute path={DASHBOARD_HOME_URL} component={Dashboard} exact />
                  <AuthRoute path={THEME_URL} component={ThemePage} exact />
                  <AuthRoute path={THEME_SUBSECTOR_URL} component={UserSubsectorPage} exact />
                  <Route path={QUESTIONNAIRES_URL_BASE} component={QuestionnaireRoutes} />
                  <AuthRoute path={RECOMMENDATIONS_URL} component={RecommendationsPage} exact />
                  <AuthRoute path={RECOMMENDATION_URL} component={RecommendationPage} exact />
                  <AuthRoute path={TEST_RESULTS_URL} component={UserTest} exact />
                  <AuthRoute path={TEST_DATAPOINT_URL} component={UserDatapoint} exact />
                  <AuthRoute path={TEST_RESULT_URL} component={UserResultPage} exact />
                  <AuthRoute path={FOODS_URL} component={FoodsPage} exact />
                  <AuthRoute path={UPLOAD_TEST} component={UploadTest} exact />
                  {/* Not found */}
                  <Route path="/sentry-error-test" component={SentryErrorTest} exact />
                  <Error404Page />
                </Switch>
              </ExpiredMarkersProvider>
            </AppStateProvider>
          </Elements>
        </ApolloManager>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
