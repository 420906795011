import { useHistory } from "react-router-dom";

import Box from "../components/Box";
import { SpacedContentBox } from "../components/Boxes";
import Checkbox from "../components/Checkbox";
import DateInput from "../components/DateInput";
import Form from "../components/Form";
import Input from "../components/Input";
import { IntroBlockContent } from "../components/IntroBlock";
import Page from "../components/Page";
import RadioGroup from "../components/RadioGroup";
import Text from "../components/Text";
import { ACTIVATE_EXTERNAL_KIT_MUTATION } from "../graphql/providers";
import useDocTitle from "../hooks/use-doc-title";
import { ReactComponent as Logo } from "../images/logo.svg";
import { ReactComponent as RegenerusLogo } from "../images/regenerus-logo.svg";
import { HeadingExtraSmall } from "./Headings";
import Jumbotron from "./Jumbotron";

const formFields = [
  {
    header: <HeadingExtraSmall>Test Kit Barcode</HeadingExtraSmall>,
    fields: [
      {
        name: "testItemId",
        initialValue: "",
        widget: {
          component: Input,
          props: {
            label: "Barcode number"
          }
        },
        boxProps: {
          pt: "20px",
          pb: "30px"
        }
      }
    ]
  },
  {
    header: <HeadingExtraSmall>User Details</HeadingExtraSmall>,
    fields: [
      {
        name: "userFirstName",
        initialValue: "",
        widget: {
          component: Input,
          props: {
            autoComplete: "given-name",
            label: "First name"
          }
        },
        boxProps: {
          pt: "20px",
          pb: "15px"
        }
      },
      {
        name: "userLastName",
        initialValue: "",
        widget: {
          component: Input,
          props: {
            autoComplete: "family-name",
            label: "Last name"
          }
        },
        boxProps: {
          pb: "15px"
        }
      },
      {
        name: "userEmail",
        initialValue: "",
        widget: {
          component: Input,
          props: {
            autoComplete: "email",
            label: "Email address"
          }
        },
        boxProps: {
          pb: "30px"
        }
      }
    ]
  },
  {
    header: <HeadingExtraSmall>Date of birth</HeadingExtraSmall>,
    fields: [
      {
        name: "userDateOfBirth",
        initialValue: "",
        widget: {
          component: DateInput,
          props: {}
        },
        boxProps: {
          pt: "15px",
          pb: "30px"
        }
      }
    ]
  },
  {
    header: <HeadingExtraSmall>Gender</HeadingExtraSmall>,
    fields: [
      {
        name: "userGender",
        initialValue: "",
        widget: {
          component: RadioGroup,
          props: {
            options: [
              { name: "Male", value: "Male" },
              { name: "Female", value: "Female" }
            ]
          }
        },
        boxProps: {
          pt: "20px"
        }
      }
    ]
  },
  {
    header: "",
    fields: [
      {
        name: "testConsent",
        initialValue: false,
        widget: {
          component: Checkbox,
          props: {
            label: (
              <span>
                By registering this test you are confirming that you are the person providing the
                sample. The results for this test will be linked to these details.
              </span>
            )
          }
        },
        boxProps: {
          pt: "40px",
          mb: "-20px"
        }
      }
    ]
  }
];

function ExternalKitRegistration() {
  useDocTitle("Register test kit");
  const history = useHistory();

  const extraInput = {};

  const handleSubmitted = () => history.push("/external-kit-registration/complete");

  return (
    <Page
      addNavBarHeight={false}
      header={
        <Jumbotron bg="blue" flexDirection="row" justifyContent="unset" height="auto">
          <Box
            height="100%"
            display="flex"
            flexDirection={["column", "row"]}
            alignItems="center"
            py={50}
            gap={[20, null]}
          >
            <Box mr={[null, "auto"]} width={[170, 180, 190, 200]}>
              <Logo />
            </Box>
            <Box ml={[null, "auto"]}>
              <Text color="white" fontFamily="gilroyRegular" fontSize={18} mb={20}>
                Supplied by
              </Text>
              <RegenerusLogo />
            </Box>
          </Box>
        </Jumbotron>
      }
    >
      <SpacedContentBox maxWidth="500px" mt={[6, 6, 6, 8]} data-component-name="Register your kit">
        <IntroBlockContent heading="Register your kit" />
        <Box maxWidth={500} mx="auto">
          <Form
            data={formFields}
            mutation={ACTIVATE_EXTERNAL_KIT_MUTATION}
            extraInput={extraInput}
            handleSubmitted={handleSubmitted}
            formWrapperProps={{
              mb: 30
            }}
          />
        </Box>
      </SpacedContentBox>
    </Page>
  );
}

export default ExternalKitRegistration;
