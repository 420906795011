import Box from "../components/Box";
import { PanelBoxV2 } from "./NewBoxes";

function JumbotronV2({ title, partner, top }) {
  return (
    <Box bg="purple">
      {top}
      <PanelBoxV2
        maxWidth={1020}
        outer={{
          px: 20
        }}
      >
        <Box py={partner ? [40, 40, 60] : [25, 25, 40]} />
        <Box
          color="white"
          fontSize={[24, 34, 34, 44]}
          lineHeight={["130%", "115%", "115%"]}
          fontFamily="gilroyBold"
        >
          {title}
        </Box>
        {!partner && <Box py={[15, 15, 20]} />}
      </PanelBoxV2>
    </Box>
  );
}

JumbotronV2.defaultProps = {
  partner: false
};

export default JumbotronV2;
