import Box from "components/Box";

export function GraphTemplate({ yAxisWidth, yAxis, chart }) {
  return (
    <Box bg="white" overflowX="hidden" paddingY={4} position="relative">
      {yAxis}
      <Box pl={yAxisWidth}>{chart}</Box>
    </Box>
  );
}
