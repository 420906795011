import Box from "../components/Box";

function Stack({ children, ...props }) {
  return <Box {...props}>{children}</Box>;
}

Stack.defaultProps = {
  display: "flex",
  flexDirection: "column",
  gap: 10
};

export default Stack;
