import React from "react";

import { ALL, RECOMMENDED } from "../core/constants";
import { RECOMMENDED_SUPPLEMENTS_TRUNCATED_QUERY } from "../graphql/tpo/content/queries";
import useDocTitle from "../hooks/use-doc-title";
import { ReactComponent as SupplementsIcon } from "../images/supplements.svg";
import { useAuthContext } from "./AuthProvider";
import Box from "./Box";
import DataLoader from "./DataLoader";
import ImageAndIconHeader from "./ImageAndIconHeader";
import IntroBlock from "./IntroBlock";
import Page from "./Page";
import SupplementsList from "./SupplementsList";

const introContent = {
  heading: "Supplements are important...",
  text:
    "We can get a huge amount of benefit from small changes to our diets. Alongside our nutritional advice we offer more specific, targeted support, in the form of carefully selected supplements. These supplements have been designed to be specific rather than general."
};

function SupplementsPage() {
  useDocTitle("Supplements");

  const { user } = useAuthContext();

  return (
    <Page backgroundColor="haze">
      <Box maxWidth={1200} mx="auto">
        <ImageAndIconHeader
          backgroundColor="white"
          icon={<SupplementsIcon />}
          heading="Supplements"
        />
        <IntroBlock
          heading={introContent.heading}
          text={introContent.text}
          backgroundColor="supplements"
        />
      </Box>
      {!!user ? (
        <DataLoader
          query={RECOMMENDED_SUPPLEMENTS_TRUNCATED_QUERY}
          render={({ recommendedSupplements }) => {
            return (
              <SupplementsList
                recommendedSupplements={recommendedSupplements}
                defaultFilter={recommendedSupplements.length > 0 ? RECOMMENDED : ALL}
              />
            );
          }}
        ></DataLoader>
      ) : (
        <SupplementsList defaultFilter={ALL} />
      )}
    </Page>
  );
}

export default SupplementsPage;
