import React, { useEffect, useState } from "react";

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useHistory } from "react-router-dom";

import { useApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import PropTypes from "prop-types";

import { theme } from "../core/theme";
import { getOrderProcessingUrl } from "../core/urls";
import { PLATFORM_USER_PROFILE_QUERY } from "../graphql/accounts";
import { GET_PAYMENT_INTENT } from "../graphql/shop";
import { useAuthContext } from "./AuthProvider";
import Box from "./Box";
import { SolidButton } from "./Buttons";
import Checkbox from "./Checkbox";
import DataLoader from "./DataLoader";
import Errors from "./Errors";
import { SpanText } from "./Text";

const cardStyle = {
  base: {
    color: "#32325d",
    fontFamily: "Arial, sans-serif",
    fontSmoothing: "antialiased",
    fontSize: "16px",
    "::placeholder": {
      color: theme.colors.slate
    }
  },
  invalid: {
    color: theme.colors.red,
    iconColor: theme.colors.red
  }
};

function StripePaymentFormContent({ order, platformUserProfile }) {
  const [succeeded, setSucceeded] = useState(false);
  const [paymentErrors, setPaymentErrors] = useState("");
  const [paymentIntentErrors, setPaymentIntentErrors] = useState("");
  const [cardErrors, setCardErrors] = useState("");
  const [processing, setProcessing] = useState("");
  const [empty, setEmpty] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const apolloClient = useApolloClient();
  const [saveCard, setSaveCard] = useState(false);
  const [useSavedCard, setUseSavedCard] = useState(false);

  useEffect(() => {
    if (order.discountedTotal !== 0) {
      apolloClient
        .mutate({
          mutation: GET_PAYMENT_INTENT,
          variables: {
            orderId: order.id
          }
        })
        .then(result => {
          if (result.data.getPaymentIntent.status === "Success") {
            setPaymentIntentErrors("");
            setClientSecret(result.data.getPaymentIntent.clientSecret);
          } else {
            setPaymentIntentErrors(result.data.getPaymentIntent.status);
          }
        })
        .catch(error => {
          console.log("Get payment intent error");
          console.log(error);
          Sentry.captureException(error);
          setPaymentIntentErrors(error.message);
        });
    }

    // eslint-disable-next-line
  }, [order.discountedTotal]);

  const handleChange = async event => {
    setEmpty(event.empty);
    setCardErrors(event.error ? event.error.message : "");
  };

  const handleSubmit = async () => {
    setProcessing(true);

    let variables;

    if (useSavedCard) {
      variables = {
        payment_method: platformUserProfile.stripeCardPaymentMethodId
      };
    } else {
      variables = {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: `${order.billingFirstName} ${order.billingLastName}`,
            address: {
              line1: order.billingAddress,
              line2: order.billingTownCity,
              postal_code: order.billingPostalCode
            }
          }
        },
        shipping: {
          name: `${order.shippingFirstName} ${order.shippingLastName}`,
          address: {
            line1: order.shippingAddress,
            line2: order.shippingTownCity,
            postal_code: order.shippingPostalCode,
            country: order.shippingCountry
          },
          phone: order.shippingPhone
        }
      };
      if (saveCard) {
        variables.setup_future_usage = "on_session";
      }
    }
    try {
      const payload = await stripe.confirmCardPayment(clientSecret, variables);
      if (payload.error) {
        setPaymentErrors(`Payment failed ${payload.error.message}`);
        setProcessing(false);
      } else {
        setPaymentErrors("");
        setProcessing(false);
        setSucceeded(true);
      }
    } catch (err) {
      setPaymentErrors("Sorry an error has occured");
    }
  };

  useEffect(() => {
    if (succeeded) {
      history.push(getOrderProcessingUrl(order.id));
    }
    // eslint-disable-next-line
  }, [succeeded]);

  return (
    <form>
      {platformUserProfile?.stripeCardPaymentMethodId && (
        <Box mb={20}>
          <SpanText
            as="button"
            underline
            color="purple"
            onClick={e => {
              e.preventDefault();
              setUseSavedCard(!useSavedCard);
            }}
          >
            {useSavedCard
              ? "Use new card"
              : `Use saved ${platformUserProfile.stripeCardBrand} card ending in ${platformUserProfile.stripeCardLast4}`}
          </SpanText>
        </Box>
      )}
      {!useSavedCard && (
        <>
          <Box borderColor="haze" borderWidth="1px" borderStyle="solid" borderRadius="5px" p="10px">
            <CardElement
              id="card-element"
              options={{ style: cardStyle, hidePostalCode: true }}
              onChange={handleChange}
            />
          </Box>
          {platformUserProfile && (
            <Box pt={20}>
              <Checkbox
                value={saveCard}
                label="Save card"
                handleChange={() => {
                  setSaveCard(!saveCard);
                }}
                fieldName="saveCard"
              />
            </Box>
          )}
        </>
      )}
      <SolidButton
        width="100%"
        maxWidth={300}
        mx="auto"
        mt={60}
        disabled={
          (empty && !useSavedCard) || cardErrors || paymentIntentErrors || processing || succeeded
        }
        submitting={processing}
        handleClick={() => {
          handleSubmit();
        }}
        data-component-name="Pay now button"
      >
        Pay now
      </SolidButton>
      <Errors>{cardErrors || paymentIntentErrors || paymentErrors}</Errors>
    </form>
  );
}

function StripePaymentForm({ order }) {
  const { user } = useAuthContext();

  if (user) {
    return (
      <DataLoader
        query={PLATFORM_USER_PROFILE_QUERY}
        render={({ platformUserProfile }) => {
          return (
            <StripePaymentFormContent order={order} platformUserProfile={platformUserProfile} />
          );
        }}
      />
    );
  } else {
    return <StripePaymentFormContent order={order} />;
  }
}

StripePaymentForm.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    discountedTotal: PropTypes.number.isRequired
  })
};

export default StripePaymentForm;
