import React, { useState } from "react";

import { useApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/browser";

import { OPEN_ORDER_QUERY, UPDATE_DISCOUNT_MUTATION } from "../graphql/shop";
import Box from "./Box";
import { Spinner } from "./Buttons";
import Input from "./Input";
import Text from "./Text";

function Discount({ order }) {
  const apolloClient = useApolloClient();

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");

  const [discountCode, setDiscountCode] = useState(order.discountCode);

  function updateDiscount(discountCode) {
    if (!submitting) {
      setError("");
      setSubmitting(true);
      apolloClient
        .mutate({
          mutation: UPDATE_DISCOUNT_MUTATION,
          variables: {
            input: {
              id: order.id,
              discountCode: discountCode.trim()
            }
          },
          refetchQueries: [
            {
              query: OPEN_ORDER_QUERY
            }
          ],
          awaitRefetchQueries: true
        })
        .then(result => {
          if (result.data.updateDiscountMutation.errors.length) {
            if (result.data.updateDiscountMutation.errors[0].messages) {
              setError(result.data.updateDiscountMutation.errors[0].messages[0]);
            }
          }
          setSubmitting(false);
        })
        .catch(error => {
          console.log("Error updating discount", error);
          Sentry.captureException(error);
          setSubmitting(false);
        });
    }
  }

  const orderDiscountCodeSet = order.discountCode !== "";

  let inputValue = discountCode;
  if (orderDiscountCodeSet) {
    inputValue = `${order.discountCode} applied`;
  }

  return (
    <Box position="relative">
      <Input
        type="text"
        fieldName="discountCode"
        value={inputValue}
        label="Discount code"
        disabled={orderDiscountCodeSet}
        showPencil={false}
        handleChange={value => {
          setDiscountCode(value);
        }}
      >
        {orderDiscountCodeSet ? (
          <Box
            as="button"
            color="red"
            onClick={e => {
              e.preventDefault();
              setDiscountCode("");
              updateDiscount("");
            }}
          >
            {submitting && <Spinner left color="grey" />}Remove
          </Box>
        ) : (
          <Box
            disabled={discountCode === ""}
            as="button"
            color="green"
            onClick={e => {
              e.preventDefault();
              updateDiscount(discountCode);
            }}
            data-component-name="Apply discount button"
          >
            {submitting && <Spinner left color="grey" />}Apply
          </Box>
        )}
      </Input>
      <Text color="red" pt={20}>
        {order.discountMeetsRequirements ? error : "Basket does not meet discount requirements."}
      </Text>
    </Box>
  );
}

export default Discount;
