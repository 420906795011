import { useContext, useMemo, useState } from "react";

import { Redirect, useParams } from "react-router-dom";

import { gql } from "@apollo/client";
import { CHART_CONFIGURATION_FIELDS, RESULT_TYPE_FIELDS } from "graphql/tpo/results/types";
import { useDataLoader } from "hooks/useDataLoader";

import Box from "../components/Box";
import DataLoader from "../components/DataLoader";
import Page from "../components/Page";
import {
  ERROR_404_URL,
  getClientManagementUserSubsectorUrl,
  getTPOSubsectorUrl
} from "../core/urls";
import {
  CORE_USER_THEME_WITH_SECTORS_FIELDS,
  USER_SUBSECTOR_FIELDS
} from "../graphql/tpo/common/types";
import useDocTitle from "../hooks/use-doc-title";
import ChartModal from "./ChartModal";
import { CollapseableText } from "./CollapseableText";
import Menu, { Button } from "./Menu";
import { PanelBoxV2 } from "./NewBoxes";
import NothingToShow from "./NothingToShow";
import ProgressBarCircle from "./ProgressBarCircle";
import SegmentedControl from "./SegmentedControl";
import Spacer from "./Spacer";
import { ScoreChart } from "./ThemeScores";
import UserQuestionnaireSubmissionSummaries from "./UserQuestionnaireSubmissionSummaries";
import { CardListContext } from "./UserResults";
import {
  ListTemplate,
  ModelContent,
  ResultItemCard,
  useCardList,
  useListControls
} from "./UserSubsector";
import { ViewerContext } from "./Viewer";

function WellnessSummary({ score }) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      py={[30, 30, 40]}
      px={20}
      bg="purple"
    >
      <Box width={270}>
        <ProgressBarCircle percentValue={score}>
          <Box as="h2" color="white" fontFamily="gilroyBold" fontSize={28} lineHeight="130%">
            Health
          </Box>
        </ProgressBarCircle>
      </Box>
    </Box>
  );
}

const USER_RESULT_CONNECTIONS_QUERY = gql`
  query UserSubsectorResultsQuery(
    $subsectorRelatedOnly: Boolean
    $first: Int!
    $after: String
    $orderBy: String
    $search: String
    $userId: ID
  ) {
    userResultConnections(
      subsectorRelatedOnly: $subsectorRelatedOnly
      userId: $userId
      after: $after
      first: $first
      orderBy: $orderBy
      search: $search
    ) {
      edges {
        cursor
        node {
          id
          result {
            ...ResultTypeFields
          }
          availableDatapointRatio
          chartConfiguration {
            ...ChartConfigurationFields
          }
          resultState {
            id
            name
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${RESULT_TYPE_FIELDS}
  ${CHART_CONFIGURATION_FIELDS}
`;

const PAGE_SIZE = 10;

function AllResults() {
  const viewerContext = useContext(ViewerContext);

  const controls = useListControls({
    sortOptions: [
      {
        label: "Results: A - Z",
        value: "result__name"
      },
      {
        label: "Results: Z - A",
        value: "-result__name"
      },
      {
        label: "Results: Low to High",
        value: "-normalised_order"
      },
      {
        label: "Results: High to Low",
        value: "normalised_order"
      }
    ]
  });

  const { data, loading, fetchMore } = useDataLoader({
    query: USER_RESULT_CONNECTIONS_QUERY,
    variables: {
      subsectorRelatedOnly: true,
      userId: viewerContext?.userId,
      first: PAGE_SIZE,
      after: "",
      orderBy: controls.sort,
      search: controls.debouncedSearch
    }
  });

  const userResults = data?.userResultConnections?.edges?.map(edge => edge.node) || [];
  const hasMore = data?.userResultConnections?.pageInfo?.hasNextPage;
  const endCursor = data?.userResultConnections?.pageInfo?.endCursor;

  return (
    <ListTemplate
      items={userResults}
      loading={loading}
      fetchMore={fetchMore}
      hasMore={hasMore}
      endCursor={endCursor}
      ItemCard={ResultItemCard}
      controls={controls}
    />
  );
}

const listControls = [
  {
    label: "Subsectors",
    value: "subsectors"
  },
  {
    label: "All Results",
    value: "allResults"
  }
];

export function UserSubsectorScores({ userTheme, userSubsectors }) {
  const viewerContext = useContext(ViewerContext);
  const clientId = viewerContext?.userId;

  const items = userSubsectors?.map(subsector => {
    const category = {
      title: `${subsector.name}`,
      chart: {
        colours: subsector.chartConfiguration.colours,
        value: subsector.chartConfiguration.normalisedRange.value
      }
    };
    if (subsector.userResultCount > 0) {
      category.href = clientId
        ? getClientManagementUserSubsectorUrl(clientId, subsector.slug)
        : getTPOSubsectorUrl(userTheme.slug, subsector.slug);
      category.showChevron = true;
    } else {
      category.showChevron = false;
    }
    return category;
  });

  const [list, setList] = useState("subsectors");
  const { viewCard, closeCard, cardOpen, setCardOpen } = useCardList();
  const [listMenuOpen, setListMenuOpen] = useState(false);

  const cardListApi = useMemo(
    () => ({
      viewCard,
      closeCard
    }),
    [viewCard, closeCard]
  );

  return (
    <PanelBoxV2
      maxWidth={1538}
      outer={{
        pt: [30, 30, 60],
        pb: [50, 50, 80],
        bg: "haze",
        px: [20, 20, 0]
      }}
    >
      <Box display="flex" flexDirection={["row"]} justifyContent={["center"]}>
        <Box display={["none", "block"]}>
          <SegmentedControl data={listControls} onChange={setList} value={list} height={55} />
        </Box>
      </Box>
      <Box display={["block", "none"]}>
        <Menu opened={listMenuOpen} onChange={setListMenuOpen}>
          <Menu.Target>
            <Button open={listMenuOpen} fullWidth lineHeight="24px" paddingY="16px" paddingX="40px">
              {listControls.find(item => item.value === list).label}
            </Button>
          </Menu.Target>
          <Menu.Dropdown mt={2} py={2} zIndex={2} minWidth={200}>
            {listControls.map(item => (
              <Menu.Item
                key={item.value}
                onClick={() => setList(item.value)}
                selected={item.value === list}
                fontSize={14}
                lineHeight={1.7}
                py={16}
              >
                {item.label}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      </Box>
      {list === "subsectors" && (
        <>
          <Spacer py={[2, 20, 20]} />
          <PanelBoxV2
            maxWidth={1020}
            inner={{
              pt: [40],
              pb: [40],
              px: [20],
              bg: "white",
              borderRadius: 5
            }}
          >
            <PanelBoxV2
              maxWidth={760}
              inner={{
                display: "flex",
                flexDirection: "column",
                gap: 20
              }}
            >
              {items.map((item, index) => (
                <ScoreChart item={item} key={index} />
              ))}
            </PanelBoxV2>
          </PanelBoxV2>
        </>
      )}
      <CardListContext.Provider value={cardListApi}>
        {list === "allResults" && <AllResults />}
      </CardListContext.Provider>
      <ChartModal bg="white" close={() => setCardOpen(undefined)} show={!!cardOpen?.type}>
        <ModelContent type={cardOpen?.type} item={cardOpen?.item} />
      </ChartModal>
    </PanelBoxV2>
  );
}

export function Wellness({ userTheme, userSubsectors }) {
  return (
    <>
      <WellnessSummary score={Math.round(userTheme.invertedScore * 100)} />
      <PanelBoxV2
        maxWidth={1538}
        outer={{
          pt: [30, 30, 60],
          pb: [50, 50, 80],
          bg: "white",
          px: [20, 20, 0]
        }}
        inner={{
          px: [20, 20, 0]
        }}
      >
        <PanelBoxV2 maxWidth={760}>
          <Box fontFamily="gilroyBold" fontSize={[24, 24, 36]} lineHeight="130%">
            Your health scores
          </Box>
          <Spacer py={[2, 2, 20]} />
          <CollapseableText fontSize={[14, 14, 16]}>
            {`In this sector, we are looking at how various structures of the body, such as, cells, tissues, organs, and systems are interlinked.

          A healthy body is maintained by good nutrition, optimal detoxification, regular exercise, balanced hormones, avoiding harmful habits, making informed and responsible decisions about health, and seeking medical assistance when necessary
          `}
          </CollapseableText>
        </PanelBoxV2>
      </PanelBoxV2>
      <UserSubsectorScores userTheme={userTheme} userSubsectors={userSubsectors} />
    </>
  );
}

function ThemePageContent({
  userTheme,
  userSubsectors,
  snapshotStatus,
  snapshotQueued,
  userSubmissions
}) {
  useDocTitle(userTheme?.name);

  if (!userTheme) {
    return <Redirect to={ERROR_404_URL} />;
  }

  return (
    <Page includeFooter bg="haze">
      {userSubsectors.length ? (
        <>
          <Wellness userTheme={userTheme} userSubsectors={userSubsectors} />
          <UserQuestionnaireSubmissionSummaries
            bg="white"
            snapshotStatus={snapshotStatus}
            snapshotQueued={snapshotQueued}
            userSubmissions={userSubmissions}
          />
        </>
      ) : (
        <NothingToShow
          jumbotron={
            <>
              <Box as="h2" fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
                No Results available
              </Box>
              <Box py={[2, 2, 20]} />
              <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
                Your wellness results will become available once they have taken a functional test
                or completed a symptom questionnaire.
              </Box>
            </>
          }
        />
      )}
    </Page>
  );
}

const USER_THEME_QUERY = gql`
  query UserThemeQuery($themeSlug: String!) {
    userTheme(themeSlug: $themeSlug) {
      ...CoreUserThemeWithSectorsFields
    }
    userSubsectors(themeSlug: $themeSlug) {
      ...UserSubsectorFields
      userResultCount
      chartConfiguration {
        colours
        normalisedRange {
          value
        }
      }
    }
    user {
      id
      snapshotStatus
      snapshotQueued
    }
    userSubmissions {
      id
      complete
      status
      hasExpiredAnswers
    }
  }
  ${CORE_USER_THEME_WITH_SECTORS_FIELDS}
  ${USER_SUBSECTOR_FIELDS}
`;

export default function ThemePage() {
  const { themeSlug } = useParams();

  return (
    <DataLoader
      query={USER_THEME_QUERY}
      variables={{
        themeSlug
      }}
      render={({ userTheme, userSubsectors, user, userSubmissions }) => (
        <ThemePageContent
          userTheme={userTheme}
          userSubsectors={userSubsectors}
          user={user}
          userSubmissions={userSubmissions}
        />
      )}
    />
  );
}
