import { useState } from "react";

import propTypes from "@styled-system/prop-types";
import PropTypes from "prop-types";

import Box from "../components/Box";
import ChevronComponent from "./Chevron";

export default function Accordion({
  bg,
  borderRadius,
  bottom,
  buttonProps,
  children,
  collapseBtnText,
  expandBtnText,
  fontSize,
  fullWidth,
  loading,
  my,
  onClick,
  open,
  px,
  py,
  pt,
  pb,
  side
}) {
  const [show, setShow] = useState(open);

  const btnText = show ? collapseBtnText || expandBtnText : expandBtnText;

  let boxWrapprProps = {};
  if (!fullWidth) {
    boxWrapprProps["display"] = "flex";
    boxWrapprProps["justifyContent"] = side === "right" ? "flex-end" : "flex-start";
  } else {
    boxWrapprProps["justifyContent"] = "space-between";
  }

  return (
    <Box bg={bg} borderRadius={borderRadius} dataComponentName="Accordion" my={my}>
      {!bottom && show && !loading && children}
      <Box px={px} py={py} pt={pt} pb={pb}>
        <Box
          cursor="pointer"
          fontSize={fontSize}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onClick && onClick();
            setShow(!show);
          }}
        >
          <Box
            as="h4"
            textTransform="capitalize"
            display="flex"
            alignItems="center"
            {...boxWrapprProps}
            {...buttonProps}
          >
            {btnText}
            <ChevronComponent fill="black" direction={show ? "bottom" : "top"} />
          </Box>
        </Box>
      </Box>
      {bottom && show && !loading && children}
    </Box>
  );
}

Accordion.defaultProps = {
  bg: "white",
  bottom: true,
  fontSize: 10,
  fullWidth: false,
  loading: false,
  px: 4,
  py: 3,
  pt: null,
  pb: null,
  open: false,
  side: "right"
};

Accordion.propTypes = {
  bg: propTypes.color.bg,
  borderRadius: propTypes.border.borderRadius,
  bottom: PropTypes.bool,
  buttonProps: PropTypes.shape({
    fontFamily: propTypes.typography.fontFamily,
    letterSpacing: PropTypes.string,
    textTransform: PropTypes.string
  }),
  children: PropTypes.node,
  collapseBtnText: PropTypes.string,
  expandBtnText: PropTypes.string,
  fontSize: propTypes.typography.fontSize,
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool,
  my: propTypes.space.my,
  onClick: PropTypes.func,
  open: PropTypes.bool,
  px: propTypes.space.px,
  py: propTypes.space.py,
  side: PropTypes.string
};
