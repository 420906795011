import { useHistory, useLocation } from "react-router-dom";

import Box from "../components/Box";
import { InternalTextLink } from "../components/Links";
import { DASHBOARD_HOME_URL } from "../core/urls";
import useDocTitle from "../hooks/use-doc-title";
import { PanelBox } from "./Boxes";
import { LoadingResults, Results, useOutro } from "./QuestionnaireOutro";
import Page from "./QuestionnairePages";
import { usedQuestionnaireNavigation, usedAppBackButton } from "./QuestionnaireUtilities";
import TestProductResponsiveImage, { TestProductImage } from "./TestProductResponsiveImage";
import { BestMatchedTest } from "./TestRecommendations";

function CompleteQuestionnaireResults({ userTestRecommendation }) {
  useDocTitle("Questionnaire Results");
  return (
    <Page
      header="Results"
      Main={
        <PanelBox medium>
          <PanelBox bg="white" nested small verticalPadding outer={{ mb: 20 }}>
            <BestMatchedTest.Header.Secondary />
            <BestMatchedTest.BodyCopy />
            <Box display="flex" flexDirection={["column", "column", "row"]}>
              <Box
                display="flex"
                position="relative"
                width={["100%", "100%", "50%"]}
                justifyContent="flex-end"
                pr={[0, 0, 20]}
              >
                <TestProductImage minHeight="389px" height="100%" width="100%">
                  <TestProductResponsiveImage
                    images={userTestRecommendation.product.shot1Grey.map(image => ({
                      width: image.width,
                      image: image.resizeUrl
                    }))}
                    pixelDensity={2}
                  />
                </TestProductImage>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                pl={[0, 0, 20]}
                pt={[40, 40, 0]}
                width={["100%", "100%", "50%"]}
              >
                <BestMatchedTest.Symptoms userTestRecommendation={userTestRecommendation}>
                  <InternalTextLink color="green" href={DASHBOARD_HOME_URL} mt={[20, 20, 30]}>
                    Continue to dashboard
                  </InternalTextLink>
                </BestMatchedTest.Symptoms>
              </Box>
            </Box>
          </PanelBox>
        </PanelBox>
      }
    />
  );
}

function CompleteQuestionnaireOutro() {
  const history = useHistory();
  const location = useLocation();
  const { bestMatchedTest } = useOutro();
  if (!usedQuestionnaireNavigation(location) || usedAppBackButton(history)) {
    return null;
  }
  if (!bestMatchedTest?.show) {
    return <LoadingResults />;
  }
  return (
    <Results
      userTestRecommendation={bestMatchedTest.test}
      Component={CompleteQuestionnaireResults}
    />
  );
}

export default CompleteQuestionnaireOutro;
