import React, { useState } from "react";

import { useApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import PropTypes from "prop-types";

import {
  CHECKOUT_MUTATION,
  OPEN_ORDER_QUERY,
  ORDER_QUERY,
  UPDATE_SHIPPING_COUNTRY_MUTATION
} from "../graphql/shop";
import { useAuthContext } from "./AuthProvider";
import Form from "./Form";
import { HeadingExtraSmall } from "./Headings";
import {
  ageConfirmation,
  shippingFields,
  shippingFieldsWithEmail,
  whereDidYouHearAboutUs,
  whereDidYouHearAboutUsFurther
} from "./StripeCheckoutForm";

function ChargelessCheckoutForm({ order }) {
  const { user } = useAuthContext();

  const apolloClient = useApolloClient();
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState("");

  async function updateShippingCountry(shippingCountry) {
    if (!updating) {
      setError("");
      setUpdating(true);

      try {
        const {
          data: { updateShippingCountryMutation }
        } = await apolloClient.mutate({
          mutation: UPDATE_SHIPPING_COUNTRY_MUTATION,
          variables: {
            input: {
              id: order.id,
              shippingCountry
            }
          },
          refetchQueries: [{ query: OPEN_ORDER_QUERY }],
          awaitRefetchQueries: true
        });

        if (updateShippingCountryMutation.errors.length > 0) {
          if (updateShippingCountryMutation.errors[0].messages) {
            setError(updateShippingCountryMutation.errors[0].messages[0]);
          }
        }
      } catch (error) {
        console.error("Error updating shipping country", error);
        Sentry.captureException(error);
      }

      setUpdating(false);
    }
  }
  const userShippingFields = user ? shippingFields : shippingFieldsWithEmail;

  if (order.shippingCountry) {
    let shippingCountryField = userShippingFields.filter(
      field => field.name === "shippingCountry"
    )[0];
    if (shippingCountryField) {
      shippingCountryField.initialValue = order.shippingCountry;
    }
  }

  const [whereDidYouHearAboutUsValue, setWhereDidYouHearAboutUsValue] = useState();

  const formData = [
    { fields: [...userShippingFields, ageConfirmation] },
    {
      fields: [
        whereDidYouHearAboutUs,
        ...(whereDidYouHearAboutUsValue === "Practitioner" ||
        whereDidYouHearAboutUsValue === "Influencer"
          ? [whereDidYouHearAboutUsFurther]
          : [])
      ],
      header: (
        <HeadingExtraSmall pb pt>
          Where did you hear about us?
        </HeadingExtraSmall>
      )
    }
  ];

  return (
    <Form
      maxWidth={600}
      data={formData}
      handleChange={values => {
        if (values.shippingCountry !== order.shippingCountry) {
          updateShippingCountry(values.shippingCountry);
        }
        setWhereDidYouHearAboutUsValue(values.whereDidYouHearAboutUs);
      }}
      mutation={CHECKOUT_MUTATION}
      extraInput={{ id: order.id }}
      stickySubmitting={true}
      refetchQueries={[
        { query: OPEN_ORDER_QUERY },
        {
          query: ORDER_QUERY,
          variables: {
            id: order.id
          }
        }
      ]}
      awaitRefetchQueries={true}
      handleSubmitted={() => {
        window.scrollTo(0, 0);
      }}
      extraErrors={error}
      formWrapperProps={{
        mb: 30
      }}
    />
  );
}

ChargelessCheckoutForm.propTypes = {
  order: PropTypes.shape({ id: PropTypes.string.isRequired })
};

export default ChargelessCheckoutForm;
