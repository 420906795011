import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { isNumber } from "lodash";

import Box from "../components/Box";
import Loading from "../components/Loading";
import { PanelBox } from "./Boxes";
import {
  AnswersContext,
  DownloadQuestionsContext,
  SubmissionContext
} from "./QuestionnaireContexts";
import Question from "./QuestionnaireQuestion";

function Questions({ QuestionMetaComponent, navigation, onSelectMutation }) {
  const { answered, setAnswered, showErrors, setShowErrors } = useContext(AnswersContext);
  const { loading, questions } = useContext(DownloadQuestionsContext);
  const submission = useContext(SubmissionContext);
  const questionRefs = useRef([]);

  if (questions && !questionRefs.current.length) {
    questions.forEach(() => {
      questionRefs.current.push({
        current: undefined
      });
    });
  }

  const [firstUnansweredQuestion, setFirstUnansweredQuestion] = useState();

  useEffect(() => {
    if (questionRefs.current.length && showErrors) {
      const index = questions.map(question => question.id).findIndex(id => !(id in answered));
      setFirstUnansweredQuestion(index);
    }
  }, [answered, questions, showErrors, setFirstUnansweredQuestion]);

  useEffect(() => {
    if (isNumber(firstUnansweredQuestion)) {
      if (questionRefs.current.length) {
        questionRefs.current?.[firstUnansweredQuestion]?.current?.scrollIntoView();
      }
    }
  }, [firstUnansweredQuestion]);

  const onSelect = useCallback(
    (questionId, answer) => {
      if (answered?.[questionId] !== answer) {
        if (submission?.id) {
          setAnswered({
            ...answered,
            [questionId]: answer
          });
          setShowErrors(false);
          setFirstUnansweredQuestion();
          if (onSelectMutation) {
            // assume it's a promise
            onSelectMutation({
              submission,
              questionId,
              answer,
              answered,
              setAnswered
            }).catch(error => {
              console.log("Problem submitting answer", error);
            });
          }
        }
      }
    },
    [answered, setAnswered, submission, setShowErrors, setFirstUnansweredQuestion, onSelectMutation]
  );

  // TODO -
  // we need a way of knowing if we are still waiting for questions
  // or none were returned

  if (!questions?.length || loading) {
    return <Loading color="white" />;
  }

  return (
    <PanelBox medium>
      {questions.map((question, index) => (
        <Box key={question.id}>
          {QuestionMetaComponent && <QuestionMetaComponent questionIndex={index} />}
          <Question
            onSelect={onSelect}
            question={question}
            index={index}
            answer={answered[question.id]}
            ref={questionRefs.current[index]}
            showError={showErrors && firstUnansweredQuestion === index}
          />
        </Box>
      ))}
      {navigation}
    </PanelBox>
  );
}

export default Questions;
