import { useHistory } from "react-router-dom";

import PropTypes from "prop-types";

import ArrowCircle from "../components/ArrowCircle";
import Box from "../components/Box";
import { HeadingExtraSmall } from "./Headings";

function ChevronLinkCard({ alignment, children, Header, heading, href, onClick, ...rest }) {
  const history = useHistory();

  return (
    <Box
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();

        if (onClick) {
          onClick(e);
        } else if (href) {
          return history.push({
            pathname: href
          });
        }
      }}
      {...rest}
    >
      <Box display="flex" justifyContent="space-between" alignItems={alignment}>
        {Header ? Header : <HeadingExtraSmall fontSize={[16]}>{heading}</HeadingExtraSmall>}
        {(onClick || href) && <ArrowCircle />}
      </Box>
      {children}
    </Box>
  );
}

ChevronLinkCard.propTypes = {
  alignment: PropTypes.string,
  children: PropTypes.node.isRequired,
  Header: PropTypes.node,
  heading: PropTypes.string,
  href: PropTypes.string
};

ChevronLinkCard.defaultProps = {
  alignment: "center"
};

export default ChevronLinkCard;
