import { useAuthContext } from "./AuthProvider";

function PartnersMarketingRedirect({ children }) {
  const { user } = useAuthContext();

  if (user) {
    return children;
  } else {
    window.location.href = "https://www.omnos.me/partners";
    return null;
  }
}

export default PartnersMarketingRedirect;
