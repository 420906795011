import {
  cloneElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import styled from "styled-components";
import { space } from "styled-system";

import Box from "../components/Box";
import { theme } from "../core/theme";
import { FilterButton, SolidButton } from "./Buttons";
import ChevronComponent from "./Chevron";

export const MenuContext = createContext();

function MenuTarget({ children }) {
  const { toggle, trigger, onChange } = useContext(MenuContext);

  const props = {};
  if (trigger === "click") {
    props["onClick"] = e => {
      e.menuTargetClick = true;
      toggle();
    };
  } else if (trigger === "hover") {
    props["onMouseOver"] = () => onChange(true);
  }

  return cloneElement(children, props);
}

const MenuItemContainer = styled(Box)`
  ${space}
  background-color: ${props => (props.selected ? props.selectedColor : "inherit")};
  &:hover {
    background-color: ${props => props.selectedColor};
  }
  cursor: pointer;
`;

MenuItemContainer.defaultProps = {
  selectedColor: "#f2f2f2"
};

function MenuItem({ children, onClick, ...props }) {
  const { toggle } = useContext(MenuContext);

  return (
    <MenuItemContainer
      {...props}
      onClick={e => {
        toggle();
        e.menuItemClick = true;
        onClick(e);
      }}
    >
      {children}
    </MenuItemContainer>
  );
}

MenuItem.defaultProps = {
  px: 4,
  py: 2
};

function MenuDropdown({ children, ...props }) {
  const api = useContext(MenuContext);
  return (
    api.opened && (
      <Box
        as="ul"
        position={!api.pushDown ? "absolute" : "static"}
        top={!api.pushDown ? "100%" : "auto"}
        left={!api.pushDown ? 0 : "auto"}
        width={!api.pushDown ? "100%" : "auto"}
        zIndex={1}
        backgroundColor="white"
        borderRadius="4px"
        boxShadow="2px 2px 4px rgba(26, 26, 26, 0.2)"
        onMouseOver={() => api.setMouseLeft(0)}
        {...props}
      >
        {children}
      </Box>
    )
  );
}

export default function Menu({ children, opened, onChange, pushDown = false, trigger = "click" }) {
  const onChangeRef = useRef();
  onChangeRef.current = onChange;

  const toggle = useCallback(() => {
    if (onChangeRef.current) {
      onChangeRef.current(!opened);
    }
  }, [opened]);

  const close = useCallback(() => {
    if (onChangeRef.current) {
      onChangeRef.current(false);
    }
  }, []);

  const menuRef = useRef();

  useEffect(() => {
    const func = e => {
      if (menuRef.current) {
        if (e.target.contains(menuRef.current) || !menuRef.current.contains(e.target)) {
          close();
        }
      }
    };

    if (trigger === "click") {
      document.addEventListener("click", func);

      return () => document.removeEventListener("click", func);
    }
  }, [close, trigger]);

  // Problem with supporting menu opening on mouse over and mouse leave is typically
  // we'll have a gap between the dropdown and the trigger element.  So when the user
  // moves the mouse over the gap the recently opened dropdown will close.
  // Solution is below: wait 200ms before closing.  This gives the user time to move the mouse.
  // If they land on the dropdown container within that time reset the mouseLeft to 0
  // so the pending close is aborted.  Otherwise it will close at the 500ms mark.

  const [mouseLeft, setMouseLeft] = useState(0);

  useEffect(() => {
    if (mouseLeft) {
      const id = setTimeout(() => {
        onChangeRef.current(false);
      }, 200);

      return () => {
        clearTimeout(id);
      };
    }
  }, [mouseLeft]);

  const wrapperProps = {};

  const onMouseLeave = useCallback(() => {
    setMouseLeft(mouseLeft + 1);
  }, [mouseLeft, setMouseLeft]);

  if (trigger === "hover") {
    wrapperProps["onMouseLeave"] = onMouseLeave;
  }

  const api = useMemo(
    () => ({
      toggle,
      opened,
      pushDown,
      trigger,
      onChange: onChangeRef.current,
      setMouseLeft
    }),
    [pushDown, toggle, opened, trigger]
  );

  return (
    <MenuContext.Provider value={api}>
      <div ref={menuRef} {...wrapperProps} style={{ position: !pushDown ? "relative" : "static" }}>
        {children}
      </div>
    </MenuContext.Provider>
  );
}

Menu.Item = MenuItem;
Menu.Dropdown = MenuDropdown;
Menu.Target = MenuTarget;

export function Button({ children, open, fullWidth, bg, color, ...rest }) {
  return (
    <SolidButton
      {...FilterButton.defaultProps}
      borderColor="white"
      textTransform="unset"
      fontSize="16px"
      letterSpacing={0}
      width={fullWidth ? "100%" : null}
      justifyContent="space-between"
      bg={bg}
      color={color}
      {...rest}
    >
      {children}
      <ChevronComponent
        direction={open ? "bottom" : "top"}
        fill={color || theme.colors.dark}
        style={{
          marginLeft: 10
        }}
      />
    </SolidButton>
  );
}

Button.defaultProps = {
  bg: "white",
  color: "dark"
};
