import { useHistory } from "react-router-dom";

import { REGISTER_KIT_URL } from "../core/urls";
import useDocTitle from "../hooks/use-doc-title";
import { SpacedContentBox } from "./Boxes";
import { SolidButton } from "./Buttons";
import DashboardHeader from "./DashboardHeader";
import { IntroBlockContent } from "./IntroBlock";
import Page from "./Page";

function RegisterKitCompletePage() {
  useDocTitle("Register test kit");
  const history = useHistory();

  return (
    <Page header={<DashboardHeader>Kit registration</DashboardHeader>}>
      <SpacedContentBox maxWidth="500px" mt={[6, 6, 6, 8]} data-component-name="Register your kit">
        <IntroBlockContent
          heading="Complete"
          text="Congratulations! Your test is now registered and will be linked to your account. Please follow the instructions on how to take the test, post and return to us. You will be notified via email when your results are ready to be viewed."
        />
        <SolidButton
          mt={40}
          maxWidth={300}
          width="100%"
          mx="auto"
          handleClick={() => {
            history.push(REGISTER_KIT_URL);
          }}
          data-component-name="Register another button"
        >
          Register another test
        </SolidButton>
      </SpacedContentBox>
    </Page>
  );
}

export default RegisterKitCompletePage;
