import React from "react";

import { useMutation } from "@apollo/client";
import { PropTypes } from "prop-types";

import { groupItemsByName } from "../core/utils";
import { CANCEL_BOOKING_MUTATION, RESCHEDULE_BOOKING_MUTATION } from "../graphql/shop";
import { getClinicFees, getPrice } from "./Basket";
import Box from "./Box";
import { SolidButton } from "./Buttons";
import Divider from "./Divider";
import Stack from "./Stack";
import Text from "./Text";

function formatBookingDatetime(datetime) {
  if (!datetime) {
    return "No booking details";
  }
  // Accept datetime in format "2023-06-09T09:15:00+0000"
  // and return in format "DD/MM/YYYY at HH:MM"
  const date = datetime.split("T")[0];
  const time = datetime.split("T")[1].split(":").slice(0, 2).join(":");
  return `${date.split("-").reverse().join("/")} at ${time}`;
}

function OrderSummary({
  order: {
    id: orderId,
    discountedTotal,
    postageCosts,
    supplementItems,
    testItems,
    shippingCountry
  },
  status,
  showBookingLinks = false
}) {
  const allItems = [...supplementItems, ...testItems];
  const allItemsByName = groupItemsByName(allItems);
  const items = Object.keys(allItemsByName);

  const [rescheduleBooking, { loading: rescheduleLoading, error: rescheduleError }] = useMutation(
    RESCHEDULE_BOOKING_MUTATION
  );
  const [cancelBooking, { loading: cancelLoading, error: cancelError }] = useMutation(
    CANCEL_BOOKING_MUTATION
  );

  return (
    <>
      {status && <Text pb={40}>{status}</Text>}
      <Stack divider={<Divider />}>
        {items.map((item, index) => {
          const lineSummary = allItemsByName[item];
          const clinicBookings = lineSummary.clinicBookings;
          return (
            <React.Fragment key={index}>
              <Box display="flex" justifyContent="space-between">
                <Box width={0.75}>
                  <Text fontFamily="gilroyBold">
                    {lineSummary.nameInBasket} x {lineSummary.count}
                  </Text>
                </Box>
                <Box width={0.25}>
                  <Text ml="10px" textAlign="right">
                    £{getPrice(lineSummary)}
                  </Text>
                </Box>
              </Box>
              {clinicBookings.length > 0 && (
                <Box my={2}>
                  <Box my={1} width={0.75}>
                    <Text>{`${clinicBookings[0].location.clinic.name}, ${clinicBookings[0].location.address}`}</Text>
                    {clinicBookings.map(
                      (
                        { id: bookingId, orderTestItemId, status, url, details, location },
                        index
                      ) => (
                        <>
                          {showBookingLinks && status === "incomplete" && (
                            <SolidButton
                              my={4}
                              handleClick={() => {
                                window.open(url || location.bookingLink, "_blank");
                              }}
                              maxWidth={null}
                              data-component-name="Clinic booking button"
                            >
                              Book your appointment &gt;
                            </SolidButton>
                          )}
                          {showBookingLinks && status === "pending" && (
                            <>
                              <Text>
                                Booking confirmed: {formatBookingDatetime(details?.datetime)}
                              </Text>
                              <Box width={1} display="flex" flexDirection="row" my={4}>
                                <SolidButton
                                  handleClick={() => {
                                    window.open(
                                      `mailto:support@omnos.me?subject=Appointment%20reschedule%20request%20-%20${orderId} (${orderTestItemId})`,
                                      "_blank"
                                    );

                                    // rescheduleBooking({
                                    //   variables: {
                                    //     orderTestItemId
                                    //   }
                                    // });
                                  }}
                                  maxWidth={null}
                                  data-component-name="Reschedule booking button"
                                >
                                  Reschedule &gt;
                                </SolidButton>
                                <SolidButton
                                  ml={2}
                                  bg="red"
                                  borderColor="red"
                                  handleClick={() => {
                                    window.open(
                                      `mailto:support@omnos.me?subject=Appointment%20cancel%20request%20-%20${orderId}  (${orderTestItemId})`,
                                      "_blank"
                                    );
                                    // cancelBooking({
                                    //   variables: {
                                    //     orderTestItemId
                                    //   }
                                    // });
                                  }}
                                  maxWidth={null}
                                  data-component-name="Cancel booking button"
                                >
                                  Cancel &gt;
                                </SolidButton>
                              </Box>
                            </>
                          )}
                          {showBookingLinks && status === "cancelled" && (
                            <>
                              <Text>
                                Booking cancelled: {formatBookingDatetime(details?.datetime)}
                              </Text>
                            </>
                          )}
                          {showBookingLinks && status === "complete" && (
                            <>
                              <Text>
                                Booking complete: {formatBookingDatetime(details?.datetime)}
                              </Text>
                            </>
                          )}
                        </>
                      )
                    )}
                  </Box>
                  <Box display="flex" mb={4}>
                    <Box width={0.75}>
                      <Text fontFamily="gilroyBold">Clinic Fees</Text>
                    </Box>
                    <Box width={0.25}>
                      <Text textAlign="right">£{getClinicFees(lineSummary)}</Text>
                    </Box>
                  </Box>
                </Box>
              )}
            </React.Fragment>
          );
        })}
      </Stack>
      <Text pt={20} textAlign="right">
        Postage :{" "}
        {postageCosts > 0 ? `£${postageCosts.toFixed(2)}` : shippingCountry ? "Free" : "TBC"}
      </Text>
      <Text
        fontFamily="dinBold"
        fontSize={[26, 28, 30, 32]}
        pt={20}
        textAlign="right"
        data-component-name="Total"
      >
        TOTAL : £{(discountedTotal + postageCosts).toFixed(2)}
      </Text>
    </>
  );
}

OrderSummary.propTypes = {
  status: PropTypes.string,
  order: PropTypes.shape({
    postageCosts: PropTypes.number.isRequired,
    appliedDiscount: PropTypes.shape({
      supplementPercentage: PropTypes.number,
      testPercentage: PropTypes.number,
      code: PropTypes.string.isRequired
    }),
    testItems: PropTypes.array.isRequired,
    supplementItems: PropTypes.array.isRequired
  })
};

export default OrderSummary;
