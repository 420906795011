import React from "react";

import { useParams } from "react-router-dom";

import useDocTitle from "../hooks/use-doc-title";
import foodImage from "../images/food-header-image.jpg";
import { ReactComponent as KnifeAndFork } from "../images/knife-and-fork.svg";
import { CollapseableText } from "../tpo/CollapseableText";
import Box from "./Box";
import FoodList from "./FoodList";
import ImageAndIconHeader from "./ImageAndIconHeader";
import IntroBlock from "./IntroBlock";
import Page from "./Page";

function FoodsPage() {
  useDocTitle("Food list");
  const { clientId } = useParams();
  const isPartnerView = !!clientId;

  return (
    <Page backgroundColor="haze">
      <Box maxWidth={1200} mx="auto">
        {!isPartnerView && (
          <>
            <ImageAndIconHeader
              color="white"
              backgroundImage={foodImage}
              icon={<KnifeAndFork fill="white" />}
              heading="Food list"
            />
            <IntroBlock
              heading="Summary"
              backgroundColor="partners"
              text={
                <CollapseableText>
                  {`Explore the following recommended foods to support your wellness journey.

We have created a personalised food list by combining and analysing results from your self assessment as well as tests you have taken.

The list contains foods to include, reduce, avoid, and eliminate. Explore the items within each food group to reveal your recommendations.`}
                </CollapseableText>
              }
            />
          </>
        )}
      </Box>
      <FoodList />
    </Page>
  );
}

export default FoodsPage;
