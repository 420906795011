import { pairwise } from "core/utils";

export function useSegmentedGraph({ chartConfiguration, userDatapoints }) {
  const { normalisedRange, terminology, colours } = chartConfiguration;

  const fullRange = [
    {
      value: normalisedRange.minimum
    },
    ...normalisedRange.range.map(value => ({ value })),
    {
      value: normalisedRange.maximum
    }
  ];

  const segments = pairwise(fullRange).map((points, index) => index);

  function getInterpretationColor(userDatapoint) {
    const index = terminology.findIndex(
      terminology => terminology === userDatapoint.chartConfiguration.interpretation
    );
    return colours?.[index] || "#818181";
  }

  return {
    fullRange,
    points: userDatapoints.map(userDatapoint => {
      const { interpretation } = userDatapoint.chartConfiguration;
      const index = segments.findIndex((segment, i) => terminology[i] === interpretation);
      return [userDatapoint.userTest.created, index * 1 + 0.5];
    }),
    userDatapoint: userDatapoints.slice(-1)[0],
    tooltips: userDatapoints.map((userDatapoint, index) => {
      const created = new Date(userDatapoint.userTest.created).toLocaleDateString(undefined, {
        day: "numeric",
        month: "long",
        year: "numeric"
      });

      const stack = [
        `<div class='interpretation' style='color: ${getInterpretationColor(userDatapoint)};'>${
          userDatapoint.chartConfiguration.interpretation
        }</div>`,
        `<div class='date'>${created}</div>`
      ];

      if (
        userDatapoints.length - 1 === index &&
        userDatapoint.dateExpired &&
        new Date(userDatapoint.dateExpired) <= new Date()
      ) {
        stack.push("<div class='retest'>(Due for retest)</div>");
      }

      return stack;
    }),
    colors: userDatapoints.map(userDatapoint => {
      return new Date(userDatapoint.dateExpired) <= new Date()
        ? "#818181"
        : getInterpretationColor(userDatapoint);
    })
  };
}
