import React, { useCallback, useContext, useEffect, useRef, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { gql, useMutation } from "@apollo/client";

import { PLATFORM_USER_PROFILE_FIELDS } from "../graphql/accounts";
import { STORE_INCOMING_DISCOUNT_CODE } from "../graphql/shop";
import { useDataLoader } from "../hooks/useDataLoader";
import { useAuthContext } from "./AuthProvider";

export const AppStateContext = React.createContext();
export const useAppState = () => useContext(AppStateContext);

function useWatchDiscountCode() {
  const params = new URLSearchParams(window.location.search);
  const discountCode = params.get("discountcode");
  const [storeIncomingDiscountCode] = useMutation(STORE_INCOMING_DISCOUNT_CODE);

  useEffect(() => {
    if (discountCode) {
      storeIncomingDiscountCode({ variables: { discountCode } });
    }
  }, [discountCode, storeIncomingDiscountCode]);
}

function usePathnameMonitor() {
  // Watches for changes in the pathname, trigger GA calls and resets the scroll unless the state says not to
  const { pathname, state } = useLocation();
  const prevPathnameRef = useRef(window.location.pathname);

  useEffect(() => {
    if (pathname !== prevPathnameRef.current) {
      if (state?.resetScroll !== false) {
        window.scrollTo(0, 0);
      }

      if (prevPathnameRef.current !== null) {
        if (typeof window.gtag !== typeof undefined) {
          window.gtag("config", "UA-156447102-1", { page_path: pathname });
        }
      }
      prevPathnameRef.current = pathname;
    }
  }, [pathname, state]);
}

export function isOnBoardingFlow(path) {
  if (path === "/profile/welcome") {
    return true;
  } else if (path === "/profile") {
    return true;
  } else if (path === "/profile/goals") {
    return true;
  } else if (path === "/profile/diet-preferences") {
    return true;
  }
}

const PLATFORM_USER_PROFILE = gql`
  query PlatformUserProfileQuery {
    platformUserProfile {
      ...PlatformUserProfileFields
    }
  }
  ${PLATFORM_USER_PROFILE_FIELDS}
`;

function AppStateProvider({ children }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [basketOpen, setBasketOpen] = useState(false);
  const [questionnaireProcessing, setQuestionnaireProcessing] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { user } = useAuthContext();
  const { data, refetch } = useDataLoader({
    query: PLATFORM_USER_PROFILE,
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache"
  });

  usePathnameMonitor();
  useWatchDiscountCode();

  const refetchPlatformAndRedirect = useCallback(
    callback => {
      refetch().then(res => callback(res, history));
    },
    [refetch, history]
  );

  useEffect(() => {
    if (menuOpen || basketOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    if (menuOpen && basketOpen) {
      setBasketOpen(false);
    }
    // eslint-disable-next-line
  }, [menuOpen, basketOpen]);

  useEffect(() => {
    if (
      user &&
      data?.platformUserProfile &&
      !data.platformUserProfile.isComplete &&
      !isOnBoardingFlow(location.pathname)
    ) {
      return history.push("/profile/welcome");
    }
  }, [data, location, user, history]);

  return (
    <AppStateContext.Provider
      value={{
        menuOpen,
        setMenuOpen,
        basketOpen,
        setBasketOpen,
        questionnaireProcessing,
        setQuestionnaireProcessing,
        refetchPlatformAndRedirect
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
}

export default AppStateProvider;
