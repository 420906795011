import gql from "graphql-tag";

import { TEST_INPUT_FIELDS } from "./tests";
import { USER_TEST_FIELDS_TRUNCATED } from "./tpo/results/types";

// Queries

export const CLINIC_LOCATION_FIELDS = gql`
  fragment ClinicLocationFields on ClinicLocationType {
    id
    clinic {
      id
      name
    }
    address
    postCode
    bookingLink
    phoneNumber
    coords {
      lat
      lng
    }
  }
`;

export const TEST_PRODUCT_OPTION_FAMILY_FIELDS = gql`
  fragment TestProductOptionFamilyFields on TestProductOptionFamilyType {
    id
    name
    description
  }
`;

export const TEST_PRODUCT_OPTION_TRUNCATED_FIELDS = gql`
  fragment TestProductOptionTruncatedFields on TestProductOptionType {
    id
    compositeId
    price
    default
  }
`;

export const TEST_PRODUCT_OPTION_FIELDS = gql`
  fragment TestProductOptionFields on TestProductOptionType {
    ...TestProductOptionTruncatedFields
    testProductOptionFamily {
      ...TestProductOptionFamilyFields
    }
    totalPrice
    productFamily {
      id
      name
      slug
    }
    productCode
    status
    shortDescription
    metaDescription
    metaTitle
    testType
    numOfBiomarkersTested
    processingTime
    whyYouShouldTakeThisTest
    whatYouGetWithTest
    shippingAndProcessing
    needHelp
    testDetails
    relatedSymptoms
    biomarkersTested
    howItWorks
    promoImage
    promoImageAltText
    smallPromoImage
    smallPromoImageAltText
    imageOne
    imageOneAltText
    imageTwo
    imageTwoAltText
    imageThree
    imageThreeAltText
    imageFour
    imageFourAltText
    imageFive
    imageFiveAltText
    collectingYourSampleImage
    collectingYourSampleVideo
    reportPdf
    clinicsInfo {
      fromPrice
      clinics {
        location {
          ...ClinicLocationFields
        }
        fee
        price
        distance
      }
    }
  }
  ${CLINIC_LOCATION_FIELDS}
  ${TEST_PRODUCT_OPTION_TRUNCATED_FIELDS}
  ${TEST_PRODUCT_OPTION_FAMILY_FIELDS}
`;

export const TEST_PRODUCT_FIELDS = gql`
  fragment TestProductFields on TestProductType {
    id
    compositeId
    name
    slug
    letters
    subletters
    sector
    productFamily {
      id
      name
      slug
    }
    specifiedSex
    sampleTypes
    areasOfInterest
    description
    overview
    productCode
    price
    previousPrice
    status
    bundlePromoImage
    bundlePromoImageAltText
    promoImage
    promoImageAltText
    smallPromoImage
    smallPromoImageAltText
    imageOne
    imageOneAltText
    imageTwo
    imageTwoAltText
    imageThree
    imageThreeAltText
    imageFour
    imageFourAltText
    imageFive
    imageFiveAltText
    reportPdf
    collectingYourSampleImage
    collectingYourSampleVideo
    shortDescription
    testType
    numOfBiomarkersTested
    processingTime
    productOptionsExplanation
    whyYouShouldTakeThisTest
    whatYouGetWithTest
    shippingAndProcessing
    needHelp
    testDetails
    relatedSymptoms
    biomarkersTested
    metaDescription
    metaTitle
    howItWorks
  }
`;

export const TEST_PRODUCT_SHORT_FIELDS = gql`
  fragment TestProductShortFields on TestProductType {
    id
    name
    slug
    productFamily {
      id
      name
      slug
    }
    description
    shortDescription
    overview
    productCode
    price
    smallPromoImage
    smallPromoImageAltText
  }
`;

export const TEST_PRODUCT_FAMILY_FIELDS = gql`
  fragment TestProductFamilyFields on TestProductFamilyType {
    id
    name
    slug
    metaTitle
    metaDescription
    description
  }
`;

export const SHIPPING_COUNTRIES_QUERY = gql`
  query ShippingCountriesQuery {
    shippingCountries {
      id
      name
      postageCost
    }
  }
`;

export const ORDER_TEST_ITEM_FIELDS = gql`
  fragment OrderTestItemFields on OrderTestItemType {
    id
    testSentToUser
    sampleReceivedByProvider
    sampleReceivedByLab
    resultsReceivedByProvider
    resultsProcessed
    defunct
    price
    nameInBasket
    groupBy
    userTestSet {
      ...UserTestFieldsTruncated
    }
    product {
      id
      compositeId
      name
      slug
      productType
      previousPrice
      price
      sector
    }
    productOption {
      ...TestProductOptionTruncatedFields
      productType
    }
    clinicLocation {
      id
      bookingLink
      clinic {
        id
        name
        fee
        bookingLink
      }
      address
    }
    clinicBooking {
      id
      url
      status
      details
    }
    registeredUser {
      email
      firstName
      lastName
    }
  }
  ${TEST_PRODUCT_OPTION_TRUNCATED_FIELDS}
  ${USER_TEST_FIELDS_TRUNCATED}
`;

export const ORDER_FIELDS = gql`
  fragment OrderFields on OrderType {
    id
    updated
    shippingFirstName
    shippingLastName
    shippingAddress
    shippingPostalCode
    shippingCountry
    shippingPhone
    billingFirstName
    billingLastName
    billingAddress
    billingPostalCode
    billingCountry
    postageCosts
    discountCode
    postageCosts
    fullTotal
    discountedTotal
    discountCode
    discountMeetsRequirements
    status
    email
    user {
      id
    }
    supplementItems {
      id
      nameInBasket
      groupBy
      price
      product {
        id
        compositeId
        name
        productType
        price
        slug
      }
    }
    testItems {
      ...OrderTestItemFields
    }
    refundSet {
      id
      updated
      amount
    }
  }
  ${ORDER_TEST_ITEM_FIELDS}
`;

export const ORDER_QUERY = gql`
  query GetOrder($id: String!) {
    order(id: $id) {
      ...OrderFields
    }
  }
  ${ORDER_FIELDS}
`;

export const OPEN_ORDER_QUERY = gql`
  query GetOpenOrder {
    openOrder {
      ...OrderFields
    }
  }
  ${ORDER_FIELDS}
`;

export const ORDERS_QUERY = gql`
  query OrderQuery($userId: ID) {
    orders(userId: $userId) {
      ...OrderFields
    }
  }
  ${ORDER_FIELDS}
`;

export const ORDER_TEST_ITEMS_QUERY = gql`
  query GetOrderTestItems($userId: ID) {
    orderTestItems(userId: $userId) {
      ...OrderTestItemFields
    }
  }
  ${ORDER_TEST_ITEM_FIELDS}
`;

export const ORDERS_AND_RESULTS_QUERY = gql`
  query OrdersAndResultsQuery($userId: ID) {
    testResults(userId: $userId) {
      ...TestInputFields
    }
    ordersInProgress(userId: $userId) {
      ...OrderTestItemFields
    }
  }
  ${ORDER_TEST_ITEM_FIELDS}
  ${TEST_INPUT_FIELDS}
`;

// Mutations

export const UPDATE_OPEN_ORDER = gql`
  mutation UpdateOpenOrder($testItemIds: [ID]!, $supplementItemIds: [ID]!, $discountCode: String!) {
    updateOpenOrder(
      testItemIds: $testItemIds
      supplementItemIds: $supplementItemIds
      discountCode: $discountCode
    ) {
      status
    }
  }
`;

export const ADD_ITEM_TO_BASKET = gql`
  mutation AddItemToBasket($compositeId: ID!, $clinicLocationId: ID) {
    addItemToBasket(clinicLocationId: $clinicLocationId, compositeId: $compositeId) {
      status
    }
  }
`;

export const REMOVE_ITEM_FROM_BASKET = gql`
  mutation RemoveItemFromBasket($compositeId: ID!, $clinicLocationId: ID) {
    removeItemFromBasket(clinicLocationId: $clinicLocationId, compositeId: $compositeId) {
      status
    }
  }
`;

export const STORE_INCOMING_DISCOUNT_CODE = gql`
  mutation StoreIncomingDiscountCode($discountCode: String!) {
    storeIncomingDiscountCode(discountCode: $discountCode) {
      status
    }
  }
`;

export const GET_PAYMENT_INTENT = gql`
  mutation GetPaymentIntent($orderId: ID!) {
    getPaymentIntent(orderId: $orderId) {
      clientSecret
      status
    }
  }
`;

export const REMOVE_PAYMENT_METHOD = gql`
  mutation RemovePaymentMethod {
    removePaymentMethod {
      status
    }
  }
`;

export const CHECKOUT_MUTATION = gql`
  mutation CheckoutMutation($input: CheckoutMutationInput!) {
    checkoutMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const UPDATE_DISCOUNT_MUTATION = gql`
  mutation UpdateDiscountMutation($input: UpdateDiscountMutationInput!) {
    updateDiscountMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const UPDATE_SHIPPING_COUNTRY_MUTATION = gql`
  mutation UpdateShippingCountryMutation($input: UpdateShippingCountryMutationInput!) {
    updateShippingCountryMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const SUPPLEMENT_QUERY = gql`
  query SupplementQuery($slug: String!) {
    supplement(slug: $slug) {
      id
      compositeId
      name
      productCode
      price
      description
      directions
      allergens
      slug
      nutritionalInfoImage
      productImage
      status
      supplementCategories {
        id
        name
      }
    }
  }
`;

export const SUPPLEMENTS_QUERY = gql`
  query SupplementsQuery {
    supplements {
      id
      name
      slug
      compositeId
    }
  }
`;

export const RESCHEDULE_BOOKING_MUTATION = gql`
  mutation RescheduleBookingMutation($orderTestItemId: ID!) {
    rescheduleBookingMutation(orderTestItemId: $orderTestItemId) {
      status
      errors {
        field
        messages
      }
    }
  }
`;

export const CANCEL_BOOKING_MUTATION = gql`
  mutation CancelBookingMutation($orderTestItemId: ID!) {
    cancelBookingMutation(orderTestItemId: $orderTestItemId) {
      status
      errors {
        field
        messages
      }
    }
  }
`;
