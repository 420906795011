import React, { useEffect } from "react";

import { useHistory, useLocation } from "react-router-dom";
import { Route } from "react-router-dom";

import { LOGIN_URL } from "../core/urls";
import { useAuthContext } from "./AuthProvider";
import Loading from "./Loading";

function AuthRoute(props) {
  const history = useHistory();
  const location = useLocation();
  const { user } = useAuthContext();

  // Redirect to login page if the user is loaded but is in fact null
  useEffect(() => {
    if (!user) {
      history.push(`${LOGIN_URL}?next=${location.pathname}`);
    }
    // eslint-disable-next-line
  }, [user]);

  // If we are waiting for the redirect to kick in, render a loading
  // component instead of the actual route
  if (!user) {
    return <Loading />;
  }

  return <Route {...props} />;
}

export default AuthRoute;
