import React, { useRef } from "react";

import styled from "styled-components";

import { theme } from "../core/theme";
import { ReactComponent as Chevron } from "../images/chevron-down.svg";
import Box from "./Box";

const BaseSelect = styled.select`
  -webkit-appearance: menulist-button;
  height: 40px;
  opacity: 0;
  width: 100%;
`;

function Select({ error, options, handleChange, fieldName, placeholder, value }) {
  const selectElRef = useRef();

  let fg = value ? theme.colors.dark : theme.colors.charcoal;
  let border = theme.colors.carbon;

  if (error) {
    fg = theme.colors.red;
    border = theme.colors.red;
  }

  return (
    <Box position="relative">
      <BaseSelect
        id={fieldName}
        ref={selectElRef}
        onChange={e => {
          handleChange(selectElRef.current.value);
        }}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </BaseSelect>
      <Box
        as="label"
        htmlFor={fieldName}
        position="absolute"
        top={0}
        left={0}
        bottom={0}
        right={0}
        display="flex"
        alignItems="center"
        px="10px"
        borderRadius="5px"
        borderStyle="solid"
        borderWidth="1px"
        borderColor={border}
        color={fg}
        style={{
          pointerEvents: "none"
        }}
        justifyContent="space-between"
      >
        {value || placeholder}
        <Chevron fill={fg} width="10px" />
      </Box>
    </Box>
  );
}

Select.defaultProps = {
  options: []
};

export default Select;
