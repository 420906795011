import styled from "styled-components";

export const TestProductImage = styled.div`
  position: relative;
  overflow: hidden;
  background: ${props => props.background || "transparent"};
  height: ${props => props.height || "auto"};
  min-height: ${props => props.minHeight || "unset"};
  min-width: ${props => props.minWidth || "unset"};
  width: ${props => props.width || "auto"};

  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

function getSources(images) {
  const imagesAsc = images.sort((a, b) => +a.width - +b.width); // asc width order
  return imagesAsc.map(img => `${img.image} ${img.width}w`).join(",");
}

function getSizes(images, breakpoints, pixelDensity = 1) {
  const imagesAsc = images.sort((a, b) => +a.width - +b.width); // asc width order
  return imagesAsc
    .map((img, index) =>
      index !== imagesAsc.length - 1
        ? `(max-width: ${breakpoints?.[img.width] || img.width}px) ${img.width / pixelDensity}px`
        : `${img.width / pixelDensity}px`
    )
    .join(",");
}

export default function TestProductResponsiveImage({ alt, breakpoints, images, pixelDensity }) {
  if (!images.length) return null;
  if (images.length === 1) return <img src={images[0].image} alt={alt} />;

  return (
    <img
      srcSet={getSources(images)}
      sizes={getSizes(images, breakpoints, pixelDensity)}
      alt={alt}
    />
  );
}
