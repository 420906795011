import { getQuestionnaireStepUrl } from "../core/urls";
import Intro, { useIntro } from "./QuestionnaireIntro";
import { LoadingPage } from "./QuestionnairePages";

function CompleteQuestionnaireIntro() {
  const { handleClick, submission } = useIntro(getQuestionnaireStepUrl("symptoms", 1));

  if (!submission) {
    return <LoadingPage />;
  }

  return (
    <Intro
      h2="self-assessment"
      h3="Symptoms"
      bodyCopy={`The following series of questions will help us give you a wellness score and
      identify areas where you might benefit from support.`}
      buttonText={submission.complete ? "Edit" : "Get Started"}
      handleClick={handleClick}
    />
  );
}

export default CompleteQuestionnaireIntro;
