import React from "react";

import { useHistory } from "react-router-dom";

import gql from "graphql-tag";

import { PLATFORM_PROFILE_DIET_PREFERENCES_URL } from "../core/urls";
import { GOALS_MUTATION } from "../graphql/accounts";
import useDocTitle from "../hooks/use-doc-title";
import { useAuthContext } from "./AuthProvider";
import BasicPage from "./BasicPage";
import DataLoader from "./DataLoader";
import Form from "./Form";
import RadioGroup from "./RadioGroup";

export const GOALS_PAGE_QUERY = gql`
  query GoalsPageQuery {
    goals {
      id
      text
    }
    platformUserProfile {
      id
      goals {
        id
      }
    }
  }
`;

function GoalsPage() {
  useDocTitle("Your goals");
  const history = useHistory();
  const { user } = useAuthContext();

  return (
    <BasicPage heading="Your goals">
      <DataLoader
        query={GOALS_PAGE_QUERY}
        render={({ platformUserProfile, goals }) => {
          const formData = [
            {
              fields: [
                {
                  name: "goals",
                  initialValue: platformUserProfile.goals.map(goal => {
                    return goal.id;
                  }),
                  widget: {
                    props: {
                      multiSelect: true,
                      options: goals.map(item => {
                        return {
                          name: item.text,
                          value: item.id
                        };
                      })
                    },
                    component: RadioGroup
                  }
                }
              ]
            }
          ];
          return (
            <Form
              mutation={GOALS_MUTATION}
              handleSubmitted={() => {
                history.push(PLATFORM_PROFILE_DIET_PREFERENCES_URL);
              }}
              extraInput={{ id: user.id }}
              data={formData}
              refetchQueries={[
                {
                  query: GOALS_PAGE_QUERY
                }
              ]}
              formWrapperProps={{
                mb: 30
              }}
            />
          );
        }}
      ></DataLoader>
    </BasicPage>
  );
}

export default GoalsPage;
