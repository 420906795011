import React, { useState } from "react";

import { ReactComponent as Chevron } from "../images/chevron-down.svg";
import Box from "./Box";
import Rotation from "./Rotation";
import Stack from "./Stack";
import { SpanText } from "./Text";

// TODO, add animation eg. https://codesandbox.io/s/animate-height-framer-motion-yn59l?file=/src/AnimateHeight.js

export function FullWidthTop({ children, open, setOpen, color, ...props }) {
  return (
    <Box
      as="button"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      onClick={e => {
        e.preventDefault();
        setOpen(!open);
      }}
      {...props}
    >
      {children}
      <Rotation deg={open ? 180 : 0}>
        <Chevron fill={color} width="10px" />
      </Rotation>
    </Box>
  );
}

export function StackedBottom({ items, callback, setOpen, color, ...props }) {
  return (
    <Box {...props}>
      <Stack itemBoxProps={{ pt: "10px", pb: "10px" }}>
        {items.map(({ value, content }, index) => {
          if (typeof content === "string") {
            content = (
              <SpanText display="block" lineHeight={1} textAlign="left">
                {content}
              </SpanText>
            );
          }
          return (
            <Box
              color={color}
              display="block"
              key={index}
              as="button"
              width="100%"
              onClick={() => {
                setOpen(false);
                callback(value);
              }}
            >
              {content}
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
}

export function Expand({ renderTop, renderBottom, defaultOpen, bg, push, roundCorners }) {
  const [open, setOpen] = useState(defaultOpen);

  const roundAmt = roundCorners ? 5 : 0;
  return (
    <Box position="relative" data-component-name="Expand">
      <Box
        width="100%"
        borderTopRightRadius={roundAmt}
        borderTopLeftRadius={roundAmt}
        borderBottomLeftRadius={open ? 0 : roundAmt}
        borderBottomRightRadius={open ? 0 : roundAmt}
        bg={bg}
      >
        {renderTop(open, setOpen)}
      </Box>
      {open && (
        <Box
          borderBottomLeftRadius={roundAmt}
          borderBottomRightRadius={roundAmt}
          bg={bg}
          position={!push && "absolute"}
          width="100%"
          zIndex={1}
        >
          {renderBottom(setOpen)}
        </Box>
      )}
    </Box>
  );
}

Expand.defaultProps = {
  roundCorners: true,
  defaultOpen: false,
  width: "100%"
};

export function SelectExpand({ top, items, callback, color, bg, defaultOpen, push, roundCorners }) {
  return (
    <Expand
      roundCorners={roundCorners}
      push={push}
      bg={bg}
      color={color}
      defaultOpen={defaultOpen && items.length > 0}
      renderTop={(open, setOpen) => {
        return (
          <FullWidthTop
            open={open}
            setOpen={value => {
              setOpen(value && items.length > 0);
            }}
            p={20}
            color={color}
          >
            {top}
          </FullWidthTop>
        );
      }}
      renderBottom={setOpen => {
        return (
          <StackedBottom
            px={20}
            pb={20}
            color={color}
            setOpen={setOpen}
            items={items}
            callback={value => {
              callback(value);
            }}
          />
        );
      }}
    />
  );
}

SelectExpand.defaultProps = {
  color: "white",
  bg: "dark",
  push: false
};

export default Expand;
