import PropTypes from "prop-types";

import { useAppState } from "../components/AppStateProvider";
import Box from "../components/Box";
import { ReactComponent as BasketSVG } from "../images/tpo/basket.svg";

export default function BasketButton({ hidden }) {
  const { basketOpen, setBasketOpen } = useAppState();

  return (
    <Box
      as="button"
      onClick={() => {
        setBasketOpen(!basketOpen);
      }}
      width={50}
      height={50}
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{ transition: "opacity 250ms" }}
      opacity={hidden ? 0 : 1}
    >
      <BasketSVG display="block" />
    </Box>
  );
}

BasketButton.propTypes = {
  hidden: PropTypes.bool
};
