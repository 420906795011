import React, { useState } from "react";

import { useApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import PropTypes from "prop-types";

import {
  CHECKOUT_MUTATION,
  OPEN_ORDER_QUERY,
  ORDER_QUERY,
  UPDATE_SHIPPING_COUNTRY_MUTATION
} from "../graphql/shop";
import { useAuthContext } from "./AuthProvider";
import CheckBox from "./Checkbox";
import CountrySelect from "./CountrySelect";
import Form from "./Form";
import { HeadingExtraSmall, HeadingMedium } from "./Headings";
import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Text from "./Text";

// ----------------------------------------------------------------- Shipping

const shippingFirstName = {
  initialValue: "",
  name: "shippingFirstName",
  widget: {
    component: Input,
    props: {
      type: "text",
      label: "First name",
      autoComplete: "given-name"
    }
  }
};

const shippingLastName = {
  initialValue: "",
  name: "shippingLastName",
  boxProps: {
    pt: "15px"
  },
  widget: {
    component: Input,
    props: {
      type: "text",
      label: "Last name",
      autoComplete: "family-name"
    }
  }
};

const email = {
  initialValue: "",
  name: "email",
  boxProps: {
    pt: "15px"
  },
  widget: {
    component: Input,
    props: {
      type: "email",
      label: "Email address",
      autoComplete: "email"
    }
  }
};

const shippingAddress1 = {
  initialValue: "",
  name: "shippingAddress1",
  boxProps: {
    pt: "15px"
  },
  widget: {
    component: Input,
    props: {
      type: "text",
      label: "Address Line 1",
      autoComplete: "street-address"
    }
  }
};

const shippingAddress2 = {
  initialValue: "",
  name: "shippingAddress2",
  boxProps: {
    pt: "15px"
  },
  widget: {
    component: Input,
    props: {
      type: "text",
      label: "Address Line 2",
      autoComplete: "street-address"
    }
  }
};

const shippingTownCity = {
  initialValue: "",
  name: "shippingTownCity",
  boxProps: {
    pt: "15px"
  },
  widget: {
    component: Input,
    props: {
      type: "text",
      label: "Town / City",
      autoComplete: "address-level1"
    }
  }
};

const shippingPostalCode = {
  initialValue: "",
  name: "shippingPostalCode",
  boxProps: {
    pt: "15px"
  },
  widget: {
    component: Input,
    props: {
      type: "text",
      label: "Postal code",
      autoComplete: "postal-code"
    }
  }
};

const shippingCountry = {
  initialValue: "",
  name: "shippingCountry",
  boxProps: {
    pt: "30px"
  },
  widget: {
    component: CountrySelect,
    props: {
      type: "text",
      label: "Country",
      showCost: true
    }
  }
};

const shippingPhone = {
  initialValue: "",
  name: "shippingPhone",
  boxProps: {
    pt: "15px"
  },
  widget: {
    component: Input,
    props: {
      type: "text",
      label: "Phone number",
      autoComplete: "tel"
    }
  }
};

// ----------------------------------------------------------------- Checkbox

const useShippingForBilling = {
  initialValue: true,
  name: "useShippingForBilling",
  excludeFromSubmission: true,
  boxProps: { pt: 30 },
  widget: {
    component: CheckBox,
    props: {
      label: "Use shipping details for billing"
    }
  }
};

const internationalShippingConsent = [
  {
    widget: {
      component: Text,
      props: {
        fontSize: "0.8em",
        mt: "24px",
        children:
          "All our products are shipped from the UK and will be sent “DAP – Delivery at place”. We will arrange for our courier to deliver to your specified address, but please be aware that you as the buyer will be responsible for any cost related to local custom clearance regulations."
      }
    }
  },
  {
    initialValue: false,
    name: "internationalShippingConsent",
    boxProps: { pt: 30 },
    widget: {
      component: CheckBox,
      props: {
        label:
          "I understand that I am responsible for any cost related to local custom clearance regulations."
      }
    }
  }
];
// ----------------------------------------------------------------- Billing

const billingFirstName = {
  initialValue: "",
  name: "billingFirstName",
  serializer: (value, values) => {
    return values.useShippingForBilling ? values.shippingFirstName : value;
  },
  widget: {
    component: Input,
    props: {
      type: "text",
      label: "First name",
      autoComplete: "given-name"
    }
  }
};

const billingLastName = {
  initialValue: "",
  name: "billingLastName",
  serializer: (value, values) => {
    return values.useShippingForBilling ? values.shippingLastName : value;
  },
  boxProps: {
    pt: "15px"
  },
  widget: {
    component: Input,
    props: {
      type: "text",
      label: "Last name",
      autoComplete: "family-name"
    }
  }
};

const billingAddress = {
  initialValue: "",
  name: "billingAddress",
  serializer: (value, values) => {
    return values.useShippingForBilling
      ? [values.shippingAddress1, values.shippingAddress2].filter(str => str).join(", ")
      : value;
  },
  boxProps: {
    pt: "15px"
  },
  widget: {
    component: Input,
    props: {
      type: "text",
      label: "Address",
      autoComplete: "street-address"
    }
  }
};

const billingTownCity = {
  initialValue: "",
  name: "billingTownCity",
  serializer: (value, values) => {
    return values.useShippingForBilling ? values.shippingTownCity : value;
  },
  boxProps: {
    pt: "15px"
  },
  widget: {
    component: Input,
    props: {
      type: "text",
      label: "Town / City",
      autoComplete: "address-level1"
    }
  }
};

const billingPostalCode = {
  initialValue: "",
  name: "billingPostalCode",
  serializer: (value, values) => {
    return values.useShippingForBilling ? values.shippingPostalCode : value;
  },
  boxProps: {
    pt: "15px"
  },
  widget: {
    component: Input,
    props: {
      type: "text",
      label: "Postal code",
      autoComplete: "postal-code"
    }
  }
};

const billingCountry = {
  initialValue: "",
  name: "billingCountry",
  serializer: (value, values) => {
    return values.useShippingForBilling ? values.shippingCountry : value;
  },
  boxProps: {
    pt: "30px"
  },
  widget: {
    component: CountrySelect,
    props: {
      type: "text",
      label: "Country",
      showCost: false
    }
  }
};

export const ageConfirmation = {
  initialValue: false,
  name: "ageConfirmation",
  boxProps: { pt: 30 },
  widget: {
    component: CheckBox,
    props: {
      label: "I confirm that the recipient of this test is over 18 years old"
    }
  }
};

export const whereDidYouHearAboutUs = {
  initialValue: "",
  name: "whereDidYouHearAboutUs",
  widget: {
    component: RadioGroup,
    props: {
      options: [
        {
          name: "Social Media",
          value: "Social Media"
        },
        {
          name: "Campaign",
          value: "Campaign"
        },
        {
          name: "Search",
          value: "Search"
        },
        {
          name: "Word Of Mouth",
          value: "Word Of Mouth"
        },
        {
          name: "Practitioner",
          value: "Practitioner"
        },
        {
          name: "Influencer",
          value: "Influencer"
        }
      ]
    }
  }
};

export const whereDidYouHearAboutUsFurther = {
  initialValue: "",
  name: "whereDidYouHearAboutUsFurther",
  boxProps: {
    pt: "15px"
  },
  widget: {
    component: Input,
    props: {
      placeholder: "Please provide additional details"
    }
  }
};

export const shippingFields = [
  shippingFirstName,
  shippingLastName,
  shippingAddress1,
  shippingAddress2,
  shippingTownCity,
  shippingPostalCode,
  shippingPhone,
  shippingCountry
];

export const shippingFieldsWithEmail = [
  shippingFirstName,
  shippingLastName,
  email,
  shippingAddress1,
  shippingAddress2,
  shippingTownCity,
  shippingPostalCode,
  shippingPhone,
  shippingCountry
];

const billingFields = [
  billingFirstName,
  billingLastName,
  billingAddress,
  billingTownCity,
  billingPostalCode,
  billingCountry
];

function StripeCheckoutForm({ order }) {
  const [hideBilling, setHideBilling] = useState(true);
  const { user } = useAuthContext();

  const apolloClient = useApolloClient();
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState("");
  const [whereDidYouHearAboutUsValue, setWhereDidYouHearAboutUsValue] = useState();

  async function updateShippingCountry(shippingCountry) {
    if (!updating) {
      setError("");
      setUpdating(true);

      try {
        const {
          data: { updateShippingCountryMutation }
        } = await apolloClient.mutate({
          mutation: UPDATE_SHIPPING_COUNTRY_MUTATION,
          variables: {
            input: {
              id: order.id,
              shippingCountry
            }
          },
          refetchQueries: [{ query: OPEN_ORDER_QUERY }],
          awaitRefetchQueries: true
        });

        if (updateShippingCountryMutation.errors.length > 0) {
          if (updateShippingCountryMutation.errors[0].messages) {
            setError(updateShippingCountryMutation.errors[0].messages[0]);
          }
        }
      } catch (error) {
        console.error("Error updating shipping country", error);
        Sentry.captureException(error);
      }

      setUpdating(false);
    }
  }
  let userShippingFields = [...(user ? shippingFields : shippingFieldsWithEmail)];

  if (order.shippingCountry) {
    let shippingCountryField = userShippingFields.filter(
      field => field.name === "shippingCountry"
    )[0];
    if (shippingCountryField) {
      shippingCountryField.initialValue = order.shippingCountry;
    }

    if (order.shippingCountry !== "United Kingdom") {
      userShippingFields = userShippingFields.concat(internationalShippingConsent);
    }
  }

  const formData = [
    {
      header: <HeadingMedium pb>Shipping details</HeadingMedium>,
      name: "shipping",
      fields: [...userShippingFields, useShippingForBilling]
    },
    {
      header: (
        <HeadingMedium pt={40} pb>
          Billing details
        </HeadingMedium>
      ),
      name: "billing",
      fields: billingFields
    },
    {
      name: "confirmation",
      fields: [ageConfirmation]
    },
    {
      fields: [
        whereDidYouHearAboutUs,
        ...(whereDidYouHearAboutUsValue === "Practitioner" ||
        whereDidYouHearAboutUsValue === "Influencer"
          ? [whereDidYouHearAboutUsFurther]
          : [])
      ],
      header: (
        <HeadingExtraSmall pb pt>
          Where did you hear about us?
        </HeadingExtraSmall>
      )
    }
  ];

  const extraInput = {
    id: order.id
  };

  if (user) {
    extraInput.email = "";
  }

  return (
    <Form
      buttonText="Continue to payment"
      maxWidth={600}
      data={formData}
      handleChange={values => {
        if (values.shippingCountry !== order.shippingCountry) {
          updateShippingCountry(values.shippingCountry);
        }
        setHideBilling(values.useShippingForBilling);
        setWhereDidYouHearAboutUsValue(values.whereDidYouHearAboutUs);
      }}
      mutation={CHECKOUT_MUTATION}
      extraInput={extraInput}
      refetchQueries={[
        { query: OPEN_ORDER_QUERY },
        {
          query: ORDER_QUERY,
          variables: {
            id: order.id
          }
        }
      ]}
      hiddenFieldSets={hideBilling ? ["billing"] : []}
      awaitRefetchQueries={true}
      handleSubmitted={() => {
        window.scrollTo(0, 0);
      }}
      extraErrors={error}
      formWrapperProps={{
        mb: 30
      }}
    />
  );
}

StripeCheckoutForm.propTypes = {
  order: PropTypes.shape({ id: PropTypes.string.isRequired })
};

export default StripeCheckoutForm;
