import { useEffect, useMemo, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { gql } from "@apollo/client";

import { REGISTER_KIT_QUESTIONS_URL, REGISTER_KIT_SIGNUP_URL } from "../core/urls";
import { VALIDATE_KIT_MUTATION } from "../graphql/providers";
import useDocTitle from "../hooks/use-doc-title";
import Box from "./Box";
import { SpacedContentBox } from "./Boxes";
import DashboardHeader from "./DashboardHeader";
import DataLoader from "./DataLoader";
import { SelectExpand } from "./Expand";
import Form from "./Form";
import Input from "./Input";
import { IntroBlockContent } from "./IntroBlock";
import Page from "./Page";
import Text from "./Text";

function RegisterKitPageContent({ products, user }) {
  const [selectedTest, setSelectedTest] = useState();
  const [formValues, setFormValues] = useState({});
  useDocTitle("Register test kit");
  const history = useHistory();
  const location = useLocation();

  const TEST_OPTIONS = useMemo(() => {
    return products.map(registrableProduct => ({
      value: registrableProduct.testProduct.id,
      content: registrableProduct.testProduct.truncatedName,
      testOption: registrableProduct
    }));
  }, [products]);

  useEffect(() => {
    if (!user && !location?.state?.createdUserEmail) {
      history.push(REGISTER_KIT_SIGNUP_URL);
    }
  }, [user, location, history]);

  return (
    <Page header={<DashboardHeader>Kit registration</DashboardHeader>}>
      <SpacedContentBox maxWidth="500px" mt={[6, 6, 6, 8]} data-component-name="Register your kit">
        <IntroBlockContent
          heading="Register your kit"
          text="Before you return your sample we need you to link your kit with your account. Please fill in your details below to continue."
        />
        <Box pt={40} maxWidth="500px" margin="auto">
          <SelectExpand
            top={
              <Text color="white" fontFamily="gilroyBold">
                {selectedTest?.content || "Select your test"}
              </Text>
            }
            callback={value => setSelectedTest(TEST_OPTIONS.filter(opt => opt.value === value)[0])}
            items={TEST_OPTIONS}
            push={true}
          />
        </Box>
      </SpacedContentBox>
      <SpacedContentBox maxWidth="500px" mt={[6, 6, 6, 8]} data-component-name="Register your kit">
        {selectedTest && selectedTest.value && (
          <>
            <IntroBlockContent
              heading={selectedTest.content}
              text={selectedTest.testOption.instructions}
            />
            <Form
              mutation={VALIDATE_KIT_MUTATION}
              buttonText="Next"
              handleChange={values => setFormValues(values)}
              handleSubmitted={result => {
                history.push(REGISTER_KIT_QUESTIONS_URL, {
                  selectedTest,
                  ...formValues,
                  ...location.state,
                  user
                });
              }}
              extraInput={{
                testProductId: selectedTest.value
              }}
              data={[
                {
                  fields: [
                    {
                      name: "testItemId",
                      initialValue: "",
                      widget: {
                        component: Input,
                        props: {
                          label: selectedTest.testOption.label
                        }
                      },
                      boxProps: {
                        pt: "40px"
                      }
                    },
                    {
                      name: "confirmTestItemId",
                      initialValue: "",
                      widget: {
                        component: Input,
                        props: {
                          label: `Confirm ${selectedTest.testOption.label}`
                        }
                      },
                      boxProps: {
                        pt: "20px"
                      }
                    }
                  ]
                }
              ]}
              formWrapperProps={{
                mb: 30
              }}
            />
          </>
        )}
        {selectedTest && !selectedTest.value && (
          <Text>Currently only our Genes or Bloods tests can be registered automatically.</Text>
        )}
      </SpacedContentBox>
    </Page>
  );
}

const GET_REGISTRABLE_PRODUCTS = gql`
  query GetRegistrableProducts {
    user {
      id
      platformUserProfile {
        id
        dateOfBirth
        gender
      }
    }
    registrableProducts {
      id
      testProduct {
        id
        truncatedName
      }
      instructions
      finalInstructions
      label
      consent
      questionsHeader
      questions {
        id
        question
        gender
        answers {
          id
          answer
          order
        }
      }
    }
  }
`;

function RegisterKitPage() {
  return (
    <DataLoader
      query={GET_REGISTRABLE_PRODUCTS}
      fetchPolicy="no-cache"
      render={({ registrableProducts, user }) => (
        <RegisterKitPageContent products={registrableProducts} user={user} />
      )}
    />
  );
}

export default RegisterKitPage;
