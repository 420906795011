import { useHistory, useLocation } from "react-router-dom";

import { isOnBoardingFlow, useAppState } from "../components/AppStateProvider";
import { useAuthContext } from "../components/AuthProvider";
import Box from "../components/Box";
import { ExternalTextLink } from "../components/Links";
import { NAV_Z_INDEX } from "../core/constants";
import { APP_URL, MARKETING_URL } from "../core/urls";
import { ReactComponent as OmnosNavbarLogoDesktop } from "../images/tpo/omnos-navbar-logo-desktop.svg";
import { ReactComponent as OmnosNavbarLogoMobile } from "../images/tpo/omnos-navbar-logo-mobile.svg";
import BackButton from "./BackButton";
import BasketButton from "./BasketButton";
import BurgerButton from "./BurgerButton";
import Group from "./Group";
import { PanelBoxV2 } from "./NewBoxes";
import ReleaseButton from "./ReleaseButton";

export const NAV_BAR_HEIGHT = 50;

export default function Nav() {
  const { menuOpen } = useAppState();
  const { user } = useAuthContext();
  const history = useHistory();
  const location = useLocation();

  const isClientManagementPlatformRelated = location.pathname.includes("/patients");

  const isAnExternalRegistrationKitPage =
    location.pathname === "/external-kit-registration" ||
    location.pathname === "/external-kit-registration/complete";

  if (isAnExternalRegistrationKitPage) {
    return null;
  }

  return (
    <Box backgroundColor="blue" position="fixed" top={0} left={0} width="100%" zIndex={NAV_Z_INDEX}>
      <PanelBoxV2
        maxWidth={1538}
        outer={{
          px: 20
        }}
      >
        <Group alignItems="center" justifyContent="space-between" height={NAV_BAR_HEIGHT}>
          <Group alignItems="center">
            <BackButton
              hidden={menuOpen || history.length <= 1}
              handleClick={() => {
                history.goBack();
              }}
            />
            <ExternalTextLink href={user ? APP_URL : MARKETING_URL}>
              <Box display={["flex", "none"]}>
                <OmnosNavbarLogoMobile style={{ height: 30, marginLeft: 20 }} />
              </Box>
              <Box display={["none", "flex"]}>
                <OmnosNavbarLogoDesktop style={{ height: 30, marginLeft: 20 }} />
              </Box>
            </ExternalTextLink>
            {user?.impersonated && <ReleaseButton ml="10px" user={user} />}
          </Group>
          <Box
            display={
              isClientManagementPlatformRelated || isOnBoardingFlow(location.pathname)
                ? "none"
                : "flex"
            }
            alignItems="center"
            position="relative"
            left={12.5}
          >
            <BasketButton hidden={menuOpen} />
            <BurgerButton />
          </Box>
        </Group>
      </PanelBoxV2>
    </Box>
  );
}
