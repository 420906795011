import React, { useState } from "react";

import { AnimatePresence } from "framer-motion";
import PropTypes from "prop-types";

import { ALL, RECOMMENDED } from "../core/constants";
import { theme } from "../core/theme";
import { getSupplementUrl } from "../core/urls";
import { SUPPLEMENTS_QUERY } from "../graphql/shop";
import ArrowCircle from "./ArrowCircle";
import Box, { ToBox } from "./Box";
import DataLoader from "./DataLoader";
import FadeIn from "./FadeIn";
import Grid from "./Grid";
import Switcher from "./Switcher";

function SupplementItem({ supplement }) {
  return (
    <ToBox
      href={getSupplementUrl(supplement.slug)}
      hoverRollover
      bg="white"
      p={20}
      borderRadius="5px"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box mr={20}>{supplement.name}</Box>
        <Box position="relative" flexShrink="0">
          <ArrowCircle />
        </Box>
      </Box>
    </ToBox>
  );
}

function SupplementsList({ defaultFilter, recommendedSupplements }) {
  const [currentFilter, setCurrentFilter] = useState(defaultFilter);

  const hasRecommendations = recommendedSupplements?.length > 0;

  return (
    <>
      {hasRecommendations && (
        <Box pt={theme.spacing.large} px={20}>
          <Switcher
            option1="All"
            option2="Recommended"
            current={2}
            handleOption1={() => {
              setCurrentFilter(ALL);
            }}
            handleOption2={() => {
              setCurrentFilter(RECOMMENDED);
            }}
          />
        </Box>
      )}
      <Box py={theme.spacing.large} px={20} data-component-name="Supplements list">
        <Box maxWidth={1200} mx="auto">
          <DataLoader
            query={SUPPLEMENTS_QUERY}
            render={({ supplements }) => {
              let items = supplements;
              if (currentFilter === RECOMMENDED) {
                if (hasRecommendations) {
                  items = supplements.filter(supplement => {
                    const matchedRecommendation = recommendedSupplements.find(
                      recommendedSupplement => {
                        return supplement.id === recommendedSupplement.id;
                      }
                    );
                    if (matchedRecommendation) {
                      return true;
                    } else {
                      return false;
                    }
                  });
                }
              }
              return (
                <AnimatePresence initial={false} exitBeforeEnter>
                  <FadeIn key={currentFilter}>
                    <Grid gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr", "1fr 1fr 1fr"]}>
                      {items.map((item, index) => {
                        return (
                          <SupplementItem key={`${index}-${currentFilter}`} supplement={item} />
                        );
                      })}
                    </Grid>
                  </FadeIn>
                </AnimatePresence>
              );
            }}
          />
        </Box>
      </Box>
    </>
  );
}

SupplementsList.propTypes = {
  recommended: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired })),
  defaultFilter: PropTypes.oneOf([ALL, RECOMMENDED])
};

export default SupplementsList;
