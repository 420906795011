import { createContext, isValidElement } from "react";

import { isNull } from "lodash";
import cloneDeep from "lodash.clonedeep";
import PropTypes from "prop-types";

import Box from "../components/Box";
import { SpanText } from "../components/Text";
import { mergeDefaultProps } from "../core/utils";
import {
  DiscreteChart,
  GenesAllelesRowChart,
  GradientChart,
  SegmentedChart,
  SegmentedProgressChart,
  UnknownChart,
  useDiscreteChart,
  useGradientChart,
  useSegmentedChart,
  useSegmentedProgressChart
} from "./Charts";
import ChevronLinkCard from "./ChevronLinkCard";
import { HeadingExtraExtraSmall, HeadingMedium } from "./Headings";

// TODO - can we reduce these components into one?

export const ChartConfigurationContext = createContext();
export const ResultPresentationContext = createContext();
export const DEFAULT_CHART_CARD_PROPS = {
  ChevronLinkCardProps: {
    cursor: "pointer"
  },
  chartProps: {
    Component: UnknownChart
  },
  interpretations: {}
};

export function ChartCardBody(props) {
  const mergedProps = mergeDefaultProps(ChartCardBody.defaultProps, props);

  const { headingProps, chartProps, relatedBiomarkers, relatedResults, ...rest } = mergedProps;

  return (
    <>
      <Box {...rest}>
        {headingProps && (
          <Box>
            {isValidElement(headingProps.secondary?.heading)
              ? headingProps.secondary.heading
              : headingProps.secondary?.heading && (
                  <HeadingExtraExtraSmall
                    as="h3"
                    textTransform="uppercase"
                    textAlign="center"
                    {...headingProps.secondary?.props}
                  >
                    {headingProps.secondary?.heading}
                  </HeadingExtraExtraSmall>
                )}
            {headingProps.primary?.heading && (
              <HeadingMedium
                as="h3"
                textTransform="uppercase"
                textAlign="center"
                {...headingProps.primary?.props}
              >
                {headingProps.primary?.heading}
              </HeadingMedium>
            )}
          </Box>
        )}
      </Box>
      {chartProps?.Component && <chartProps.Component {...chartProps.props} />}
    </>
  );
}

ChartCardBody.defaultProps = {
  headingProps: {
    primary: {
      props: {
        mt: 1,
        mb: 2,
        fontSize: 16
      }
    },
    secondary: {
      props: {
        color: "dark",
        fontSize: 10
      }
    }
  }
};

export function ChartCard(props) {
  const {
    ChevronLinkCardProps,
    headingProps,
    chartProps,
    relatedBiomarkers,
    relatedResults
  } = mergeDefaultProps(ChartCard.defaultProps, props);

  return (
    <ChevronLinkCard {...ChevronLinkCardProps}>
      <ChartCardBody
        headingProps={headingProps}
        chartProps={chartProps}
        relatedBiomarkers={relatedBiomarkers}
        relatedResults={relatedResults}
      />
    </ChevronLinkCard>
  );
}

ChartCard.propTypes = {
  ChevronLinkCardProps: PropTypes.object,
  headingProps: PropTypes.object,
  chartProps: PropTypes.object,
  relatedBiomarkers: PropTypes.object,
  relatedResults: PropTypes.array
};

ChartCard.defaultProps = {
  ChevronLinkCardProps: {
    px: [4, 8],
    py: 2
  }
};

export function GradientChartCard({
  children,
  chart,
  title,
  interpretation,
  href,
  onClick,
  value,
  units
}) {
  const props = cloneDeep(DEFAULT_CHART_CARD_PROPS);
  props.chartProps.Component = GradientChart;
  const preparedChartProps = useGradientChart(chart);
  if (isNull(preparedChartProps)) {
    props.chartProps.Component = UnknownChart;
  }
  props.chartProps.props = preparedChartProps;
  const color = "black";
  props.ChevronLinkCardProps.heading = title;
  props.ChevronLinkCardProps.href = href;
  props.ChevronLinkCardProps.onClick = onClick;
  props.headingProps = {
    primary: {
      heading: isNull(preparedChartProps) ? "" : interpretation,
      props: {
        fontSize: 16,
        mt: 1,
        mb: 2,
        color
      }
    },
    secondary: {
      heading: isNull(preparedChartProps) ? (
        ""
      ) : (
        <Box fontSize={15} textAlign="center">
          <Box as="span" fontFamily="gilroyBold" color="dark" key="value">
            {value}
          </Box>
          {units && (
            <Box as="span" fontFamily="gilroyBold" color="midGrey" key="units">
              {units}
            </Box>
          )}
        </Box>
      )
    }
  };

  let valueColor = "dark"; // helps to spot problems in the FE if we use a different color
  if (interpretation?.toLowerCase() === "optimal") {
    valueColor = "#00BF86";
  } else if (
    interpretation?.toLowerCase() === "below optimal" ||
    interpretation?.toLowerCase() === "above optimal"
  ) {
    valueColor = "#FCA557";
  } else if (interpretation?.toLowerCase() === "low" || interpretation?.toLowerCase() === "high") {
    valueColor = "#FF5D6D";
  }

  props.interpretations = {
    color: valueColor,
    valueColor,
    interpretation,
    value
  };

  return (
    <ResultPresentationContext.Provider value={props}>
      {children}
    </ResultPresentationContext.Provider>
  );
}

export function DiscreteChartCard({ children, chart, title, interpretation, href, onClick }) {
  const props = cloneDeep(DEFAULT_CHART_CARD_PROPS);
  props.chartProps.Component = DiscreteChart;
  const preparedChartProps = useDiscreteChart(chart);
  props.chartProps.props = preparedChartProps;
  const color = "black";
  props.ChevronLinkCardProps.heading = title;
  props.ChevronLinkCardProps.href = href;
  props.ChevronLinkCardProps.onClick = onClick;
  props.headingProps = {
    primary: {
      heading: interpretation,
      props: {
        fontSize: 16,
        mt: 1,
        mb: 2,
        color
      }
    }
  };
  props.interpretations = {
    interpretation,
    color
  };
  return (
    <ResultPresentationContext.Provider value={props}>
      {children}
    </ResultPresentationContext.Provider>
  );
}

export function SegmentedChartCard({
  children,
  chart,
  title,
  interpretation,
  secondaryHeading,
  href,
  onClick
}) {
  const props = cloneDeep(DEFAULT_CHART_CARD_PROPS);
  props.chartProps.Component = SegmentedChart;
  const preparedChartProps = useSegmentedChart(chart);
  props.chartProps.props = preparedChartProps;
  const color = "black";
  props.ChevronLinkCardProps.heading = title;
  props.ChevronLinkCardProps.href = href;
  props.ChevronLinkCardProps.onClick = onClick;
  props.headingProps = {
    primary: {
      heading: interpretation,
      props: {
        fontSize: 16,
        mt: 1,
        mb: 2,
        color
      }
    },
    secondary: {
      heading: secondaryHeading
    }
  };

  return (
    <ResultPresentationContext.Provider value={props}>
      {children}
    </ResultPresentationContext.Provider>
  );
}

export function SegmentedProgressChartCard({
  children,
  chart,
  title,
  interpretation,
  secondaryHeading,
  href,
  onClick
}) {
  const props = cloneDeep(DEFAULT_CHART_CARD_PROPS);
  props.chartProps.Component = SegmentedProgressChart;
  const preparedChartProps = useSegmentedProgressChart(chart);
  props.chartProps.props = preparedChartProps;
  const color = "black";
  props.ChevronLinkCardProps.heading = title;
  props.ChevronLinkCardProps.href = href;
  props.ChevronLinkCardProps.onClick = onClick;
  props.headingProps = {
    primary: {
      heading: interpretation,
      props: {
        fontSize: 16,
        mt: 1,
        mb: 2,
        color
      }
    },
    secondary: {
      heading: secondaryHeading
    }
  };
  return (
    <ResultPresentationContext.Provider value={props}>
      {children}
    </ResultPresentationContext.Provider>
  );
}

function GenesAllelesText({ color, fontFamily, fontSize, marginCorrection, text }) {
  const geneText = text?.split("|")?.[1]?.trim();

  return (
    <Box>
      <SpanText
        fontFamily={fontFamily}
        fontSize={fontSize}
        marginCorrection={marginCorrection}
        mr={1}
      >
        ALLELES
      </SpanText>
      |
      <SpanText
        color={color}
        fontFamily={fontFamily}
        fontSize={fontSize}
        marginCorrection={marginCorrection}
        ml={1}
      >
        {geneText}
      </SpanText>
    </Box>
  );
}

export function GenesAllelesChartCard({
  children,
  dataComponentName,
  title,
  value: stringifiedArrayValue,
  href,
  onClick,
  riskAllele
}) {
  const arrayValue = JSON.parse(stringifiedArrayValue);

  const value = `ALLELES | ${arrayValue.join("")}`;
  let color = "dark";
  let risk;
  if (riskAllele?.value === 1.0) {
    color = "red";
    risk = "High";
  } else if (riskAllele?.value === 0.5) {
    color = "orange";
    risk = "Moderate";
  } else if (riskAllele?.value === 0) {
    color = "green";
    risk = "Optimal";
  }

  let splitTitle = title.split("-");
  let snp = splitTitle?.[1] || "";

  const baseConfig = {
    biomarkerModal: {
      color: "dark"
    },
    ChevronLinkCardProps: {
      cursor: "pointer",
      href,
      onClick,
      heading: title
    },
    chartProps: {
      Component: GenesAllelesRowChart,
      props: {
        color,
        dataComponentName,
        risk,
        snp,
        type: value
      }
    },
    headingProps: {
      primary: {
        heading: value
      }
    },
    interpretations: {
      valueColor: color
    },
    relatedBiomarkers: {
      RightSideTextComponent: GenesAllelesText
    }
  };

  return (
    <ResultPresentationContext.Provider value={baseConfig}>
      {children}
    </ResultPresentationContext.Provider>
  );
}

GenesAllelesChartCard.defaultProps = {
  dataComponentName: "GenesAllelesChartCard"
};

export function UnknownChartCard({ children, title, href }) {
  const props = cloneDeep(DEFAULT_CHART_CARD_PROPS);
  // props.chartProps.Component = SegmentedProgressChart;
  // props.chartProps.props = chart;
  // const color = "black";
  props.ChevronLinkCardProps.heading = title;
  props.ChevronLinkCardProps.href = href;
  // props.ChevronLinkCardProps.onClick = onClick;
  props.headingProps = {};
  return (
    <ResultPresentationContext.Provider value={props}>
      {children}
    </ResultPresentationContext.Provider>
  );
}
