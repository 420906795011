import Box from "components/Box";
import { DiscreteChart, SegmentedChart, useDiscreteChart, useSegmentedChart } from "tpo/Charts";

export function GradientAxis({ background, labels }) {
  return (
    <Box display="flex" position="relative" height="100%">
      <Box position="relative" height="100%" minWidth={10} width={20}>
        {labels.map((label, index) =>
          label !== undefined ? (
            <Box
              key={index}
              position="absolute"
              transform="translateY(100%)"
              bottom={`${(index / (labels.length - 1)) * 100}%`}
              right={0}
              borderTop="1px solid #1a1a1a"
              fontSize={"8px"}
              fontFamily="gilroyBold"
              width={"100%"}
              textAlign="left"
            >
              {label}
            </Box>
          ) : null
        )}
      </Box>
      <Box
        minWidth={5}
        width={5}
        height="100%"
        background={background}
        borderRadius={100}
        transform="rotate(180deg)"
      />
    </Box>
  );
}

export function DiscreteAxis({ chartConfiguration: { colours, terminology, normalisedRange } }) {
  const { terms, values } = useDiscreteChart({
    colours,
    terminology,
    normalisedRange
  });
  return (
    <DiscreteChart
      terms={terms}
      values={values}
      wordProps={{
        transform: "scale(1, -1)"
      }}
    />
  );
}

export function SegmentedAxis({
  chartConfiguration: { colours, normalisedRange, terminology, interpretation }
}) {
  const { terms, discreteValues, value } = useSegmentedChart({
    colours,
    normalisedRange,
    terminology,
    interpretation
  });
  return (
    <SegmentedChart
      terms={terms}
      discreteValues={discreteValues}
      value={value}
      showValueIcon={false}
    />
  );
}
