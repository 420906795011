import { createContext, useContext, useEffect, useMemo } from "react";

import { createPortal } from "react-dom";

import Box from "../components/Box";
import CloseButton from "../components/CloseButton";
import { MODAL_OVERLAY_Z_INDEX, MODAL_Z_INDEX } from "../core/constants";
import { theme } from "../core/theme";

const body = document.getElementsByTagName("body")[0];

const ModalContext = createContext();

function Backdrop() {
  return (
    <Box
      position="fixed"
      top={0}
      right={0}
      bottom={0}
      left={0}
      height="100vh"
      opacity={0.5}
      zIndex={MODAL_OVERLAY_Z_INDEX}
      bg="blue"
    />
  );
}

function Container({ children, px }) {
  return (
    <Box
      position="fixed"
      top={0}
      right={0}
      bottom={0}
      left={0}
      minHeight={"100vh"}
      zIndex={MODAL_Z_INDEX}
      px={px}
    >
      {children}
    </Box>
  );
}

function Header() {
  const { closeButton, close, title, headerProps } = useContext(ModalContext);

  return (
    <Box display="flex" position="sticky" top={0} left={0} width="100%" {...headerProps}>
      {title}
      {closeButton && (
        <Box ml="auto">
          <CloseButton
            height={[40, 40, 55]}
            width={[40, 40, 55]}
            color={theme.colors.dark}
            onClick={close}
            bg="white"
          />
        </Box>
      )}
    </Box>
  );
}

function ModalContent({ children }) {
  const { bg, centered, closeButton, maxWidth, title } = useContext(ModalContext);

  const centeredProps = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: centered ? "center" : "unset",
    minHeight: "100%",
    paddingTop: 20,
    paddingBottom: 20,
    maxHeight: "100%"
  };

  return (
    <Box zIndex={MODAL_Z_INDEX} padding={20} {...centeredProps}>
      <Box bg={bg} maxWidth={maxWidth} width="100%" height="100%" maxHeight="100%" overflowY="auto">
        {(title || closeButton) && <Header />}
        {children}
      </Box>
    </Box>
  );
}

function Modal({
  bg,
  children,
  show,
  close,
  centered,
  maxWidth,
  closeButton,
  my,
  containerProps,
  headerProps
}) {
  useEffect(() => {
    if (show) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "unset";
    }
    return () => {
      body.style.overflow = "unset";
    };
  }, [show]);

  const modalApi = useMemo(
    () => ({
      bg,
      centered,
      closeButton,
      close,
      maxWidth,
      show,
      my,
      headerProps
    }),
    [bg, closeButton, maxWidth, show, centered, my, close, headerProps]
  );

  return (
    <ModalContext.Provider value={modalApi}>
      {show &&
        createPortal(
          <>
            <Backdrop />
            <Container {...containerProps}>
              <ModalContent>{children}</ModalContent>
            </Container>
          </>,
          body
        )}
    </ModalContext.Provider>
  );
}

Modal.defaultProps = {
  headerProps: {
    pr: 20,
    py: 20
  }
};

export default Modal;
