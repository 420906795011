import { useCallback, useEffect, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { REGISTER_KIT_COMPLETE_URL, REGISTER_KIT_SIGNUP_URL, REGISTER_KIT_URL } from "../core/urls";
import { ACTIVATE_KIT_MUTATION } from "../graphql/providers";
import useDocTitle from "../hooks/use-doc-title";
import Box from "./Box";
import { SpacedContentBox } from "./Boxes";
import Checkbox from "./Checkbox";
import DashboardHeader from "./DashboardHeader";
import DateInput from "./DateInput";
import Form from "./Form";
import { IntroBlockContent } from "./IntroBlock";
import Page from "./Page";
import RadioGroup from "./RadioGroup";

const genderField = {
  name: "userGender",
  initialValue: "",
  widget: {
    component: RadioGroup,
    props: {
      options: [
        { name: "Male", value: "Male" },
        { name: "Female", value: "Female" }
      ]
    }
  },
  boxProps: {
    pt: "40px"
  }
};

const dateOfBirthField = {
  name: "userDateOfBirth",
  widget: {
    component: DateInput,
    props: {
      label: "Date of bith"
    }
  },
  boxProps: {
    pt: "40px"
  }
};

function getConsentField(message) {
  return {
    name: "testConsent",
    initialValue: false,
    widget: {
      props: {
        label: <span>{message}</span>
      },
      component: Checkbox
    },
    boxProps: {
      pt: "40px"
    }
  };
}

function Question({ question, value, handleChange: propHandleChange }) {
  const handleChange = useCallback(
    answerId => {
      const oldValue = value.filter(
        questionAndAnswer => questionAndAnswer.question !== question.id
      );
      const newValue = [
        ...oldValue,
        {
          question: question.id,
          answer: answerId
        }
      ];
      propHandleChange(newValue);
    },
    [value, question, propHandleChange]
  );

  const valueSpecificToQuestion = value.find(
    questionAndAnswer => questionAndAnswer.question === question.id
  );
  const answerValue = valueSpecificToQuestion ? valueSpecificToQuestion.answer : "";
  const possibleAnswers = question.answers.map(answer => ({
    name: answer.answer,
    value: answer.id
  }));

  return (
    <RadioGroup
      value={answerValue}
      handleChange={handleChange}
      label={question.question}
      options={possibleAnswers}
      labelProps={{ my: 20 }}
    />
  );
}

function Questions({ questions, genderSelected, value, handleChange }) {
  const genderRelevantQuestions = questions.filter(question => {
    if (question.gender) {
      return question.gender === genderSelected;
    }
    return question;
  });

  return genderRelevantQuestions.map(question => (
    <Question key={question.id} question={question} value={value} handleChange={handleChange} />
  ));
}

function questionsField(questionsHeader, questions, genderSelected) {
  return {
    header: <Box mt={40}>{questionsHeader}</Box>,
    fields: [
      {
        name: "questionsAndAnswers",
        initialValue: [],
        widget: {
          component: Questions,
          props: {
            questions,
            genderSelected
          }
        },
        boxProps: {
          mt: 40
        }
      }
    ]
  };
}

function RegisterKitQuestionsPage() {
  useDocTitle("Register test kit");
  const history = useHistory();
  const location = useLocation();

  const { selectedTest, createdUserEmail, testItemId, user } = location.state || {};

  useEffect(() => {
    if (!user && !location?.state?.createdUserEmail) {
      history.push(REGISTER_KIT_SIGNUP_URL);
    }
    if (!location?.state?.selectedTest) {
      history.push(REGISTER_KIT_URL);
    }
  }, [user, location, history]);

  const questions = selectedTest?.testOption?.questions;

  const extraInput = {
    userId: user?.id || "",
    userEmail: user?.email || createdUserEmail,
    testProductId: selectedTest?.value,
    testItemId: testItemId,
    confirmTestItemId: testItemId
  };

  const handleSubmitted = () => history.push(REGISTER_KIT_COMPLETE_URL);

  const [genderSelected, setGenderSelected] = useState();

  if (!selectedTest) {
    return null;
  }

  const formData = [];

  if (!user?.platformUserProfile?.gender) {
    formData.push({
      header: <Box>Please let us know your gender.</Box>,
      fields: [genderField]
    });
  }

  if (!createdUserEmail && !user?.platformUserProfile?.dateOfBirth) {
    // If the user signed up via the reg kit sign up flow form then we will have a date of birth
    // it's just not on the user object since the user object is for the auth'd user and this flow
    // doesn't log the user in
    formData.push({
      header: <Box mt={40}>Please let us know your date of birth</Box>,
      fields: [dateOfBirthField]
    });
  }

  if (questions?.length) {
    formData.push(
      questionsField(
        selectedTest?.testOption?.questionsHeader,
        questions,
        user?.platformUserProfile?.gender || genderSelected
      )
    );
  }

  formData.push({
    header: <Box mt={40}>Finally please check the consent box to confirm your registration</Box>,
    fields: [getConsentField(selectedTest?.testOption?.consent)]
  });

  return (
    <Page header={<DashboardHeader>Kit registration</DashboardHeader>}>
      <SpacedContentBox maxWidth="500px" mt={[6, 6, 6, 8]} data-component-name="Register your kit">
        <IntroBlockContent heading="Register your kit" />
        {selectedTest && selectedTest.value && (
          <>
            <Box maxWidth={500} mx="auto">
              <Form
                data={formData}
                mutation={ACTIVATE_KIT_MUTATION}
                extraInput={extraInput}
                handleSubmitted={handleSubmitted}
                handleChange={values => {
                  values.userGender !== genderSelected && setGenderSelected(values.userGender);
                }}
                formWrapperProps={{
                  mb: 30
                }}
              />
            </Box>
          </>
        )}
      </SpacedContentBox>
    </Page>
  );
}

export default RegisterKitQuestionsPage;
