import React, { useContext } from "react";

import gql from "graphql-tag";

import { DASHBOARD_HOME_URL } from "../core/urls";
import { DIET_PREFERENCES_MUTATION } from "../graphql/accounts";
import useDocTitle from "../hooks/use-doc-title";
import { AppStateContext } from "./AppStateProvider";
import { useAuthContext } from "./AuthProvider";
import BasicPage from "./BasicPage";
import DataLoader from "./DataLoader";
import Form from "./Form";
import RadioGroup from "./RadioGroup";

export const DIET_PREFERENCES_PAGE_QUERY = gql`
  query DietPreferencesPageQuery {
    dietPreferences {
      id
      name
    }
    platformUserProfile {
      id
      dietPreferences {
        id
      }
    }
  }
`;

function DietPreferencesPage() {
  useDocTitle("Your diet preferences");
  const { user } = useAuthContext();
  const { refetchPlatformAndRedirect } = useContext(AppStateContext);

  return (
    <BasicPage heading="Diet preferences">
      <DataLoader
        query={DIET_PREFERENCES_PAGE_QUERY}
        render={({ platformUserProfile, dietPreferences }) => {
          const formData = [
            {
              fields: [
                {
                  name: "dietPreferences",
                  initialValue: platformUserProfile.dietPreferences.map(dietPreferences => {
                    return dietPreferences.id;
                  }),
                  widget: {
                    props: {
                      multiSelect: true,
                      options: dietPreferences.map(item => {
                        return {
                          name: item.name,
                          value: item.id
                        };
                      })
                    },
                    component: RadioGroup
                  }
                }
              ]
            }
          ];
          return (
            <Form
              mutation={DIET_PREFERENCES_MUTATION}
              handleSubmitted={() => {
                refetchPlatformAndRedirect((res, history) => {
                  if (res?.data?.platformUserProfile?.isComplete) {
                    history.push(DASHBOARD_HOME_URL);
                  }
                });
              }}
              extraInput={{ id: user.id }}
              data={formData}
              refetchQueries={[
                {
                  query: DIET_PREFERENCES_PAGE_QUERY
                }
              ]}
              formWrapperProps={{
                mb: 30
              }}
            />
          );
        }}
      />
    </BasicPage>
  );
}

export default DietPreferencesPage;
