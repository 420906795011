import Box from "../components/Box";
import { ExternalTextLink } from "../components/Links";
import Text from "../components/Text";
import ChevronComponent from "./Chevron";
import Accordion, { AccordionContext } from "./NewAccordion";
import { getAnswerColor } from "./QuestionnaireResults";

export function Research({ research }) {
  return (
    <Accordion.Item value="relatedResearch">
      <Accordion.Control px={[15, 15, 20]} py={[2, 2, 15]}>
        <Text marginCorrection={false} fontFamily="gilroyBold" fontSize={[16, 16, 18]}>
          Related Research
        </Text>
      </Accordion.Control>
      <Accordion.Panel px={[20, 20, 40]} pb={[2, 2, 20]}>
        {research?.map(research => (
          <ExternalTextLink
            display="block"
            key={research.id}
            fontSize={16}
            href={research.url}
            py={[2, 2, 20]}
          >
            {research.name}
          </ExternalTextLink>
        ))}
      </Accordion.Panel>
    </Accordion.Item>
  );
}

export function SymptomsList({ questions }) {
  return questions.map(question => (
    <Box
      display="flex"
      flexDirection={["column", "column", "row"]}
      gap={[10, 10, 0]}
      key={`${question.id}`}
      py={[2, 2, 20]}
    >
      <Box textAlign="left" width={[null, null, 0.5]} fontSize={[14, 14, 16]}>
        {question.question.text}
      </Box>
      <Box textAlign={["left", "left", "right"]} width={[null, null, 0.5]}>
        <Text
          color={!!question.answerText && getAnswerColor(question.answerScore)}
          fontFamily="gilroyBold"
          textAlign={["left", "left", "right"]}
          fontSize={[14, 14, 16]}
        >
          {question.answerText || "N/A"}
        </Text>
      </Box>
    </Box>
  ));
}

export function Symptoms({ experienced, notExperienced }) {
  return (
    <Accordion.Item value="symptoms">
      <Accordion.Control px={[15, 15, 20]} py={[2, 2, 15]}>
        <Text marginCorrection={false} fontFamily="gilroyBold" fontSize={[16, 16, 18]}>
          Related Symptoms
        </Text>
      </Accordion.Control>
      <Accordion.Panel p={0}>
        <Accordion variant="default" chevronVariant="default" multiple size={15}>
          {!!experienced?.length && (
            <Accordion.Item value="experienced">
              <Accordion.Control
                p={15}
                pr={[30, 30, 35]}
                pl={[15, 15, 30]}
                chevronIcon={
                  <AccordionContext.Consumer>
                    {({ openState }) => (
                      <ChevronComponent
                        fill="dark"
                        height={10}
                        width={10}
                        direction={openState.has("experienced") ? "bottom" : "top"}
                        style={{
                          margin: 0
                        }}
                      />
                    )}
                  </AccordionContext.Consumer>
                }
              >
                <Text marginCorrection={false} fontFamily="gilroyBold" fontSize={15}>
                  Experienced
                </Text>
              </Accordion.Control>
              <Accordion.Panel p={0} pb={[2, 2, 20]} px={[20, 20, 40]}>
                <SymptomsList questions={experienced} />
              </Accordion.Panel>
            </Accordion.Item>
          )}
          {!!notExperienced?.length && (
            <Accordion.Item value="notExperienced">
              <Accordion.Control
                p={15}
                pr={[30, 30, 35]}
                pl={[15, 15, 30]}
                chevronIcon={
                  <AccordionContext.Consumer>
                    {({ openState }) => (
                      <ChevronComponent
                        fill="dark"
                        height={10}
                        width={10}
                        direction={openState.has("notExperienced") ? "bottom" : "top"}
                        style={{
                          margin: 0
                        }}
                      />
                    )}
                  </AccordionContext.Consumer>
                }
              >
                <Text marginCorrection={false} fontFamily="gilroyBold" fontSize={15}>
                  Not experienced
                </Text>
              </Accordion.Control>
              <Accordion.Panel p={0} pb={[2, 2, 20]} px={[20, 20, 40]}>
                <SymptomsList questions={notExperienced} />
              </Accordion.Panel>
            </Accordion.Item>
          )}
        </Accordion>
      </Accordion.Panel>
    </Accordion.Item>
  );
}
