import React, { useState } from "react";

import { useApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import PropTypes from "prop-types";

import { AVAILABLE } from "../core/constants";
import { ADD_ITEM_TO_BASKET, OPEN_ORDER_QUERY } from "../graphql/shop";
import { useAppState } from "./AppStateProvider";
import Box from "./Box";
import { SolidButton } from "./Buttons";
import RichText from "./RichText";
import Text from "./Text";

function SupplementProductDetails({
  description,
  directions,
  price,
  previousPrice,
  status,
  productType,
  id,
  compositeId
}) {
  const [submitting, setSubmitting] = useState(false);
  const { setBasketOpen } = useAppState();
  const apolloClient = useApolloClient();

  function addItemToBasket(compositeId) {
    if (!submitting) {
      setSubmitting(true);
      apolloClient
        .mutate({
          mutation: ADD_ITEM_TO_BASKET,
          variables: {
            compositeId
          },
          refetchQueries: [
            {
              query: OPEN_ORDER_QUERY
            }
          ],
          awaitRefetchQueries: true
        })
        .then(result => {
          setBasketOpen(true);
          setSubmitting(false);
        })
        .catch(error => {
          console.log("Error adding item to basket", error);
          Sentry.captureException(error);
          setSubmitting(false);
        });
    }
  }

  return (
    <Box pt={80} px={20} data-component-name="Supplement Product details">
      <Box maxWidth={800} mx="auto">
        <RichText>{description}</RichText>
        {directions && (
          <>
            <Text fontFamily="gilroyBold" py={20}>
              Directions
            </Text>
            <RichText>{directions}</RichText>
            <Text pt={20}>
              Please be advised there will be a £3 postage fee on supplement only orders.
            </Text>
          </>
        )}
        <Box
          pt={[50, 70]}
          pb={[60, 80]}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text fontFamily="dinBold" fontSize={32} mr={20}>
            {previousPrice && (
              <span
                style={{
                  textDecoration: "line-through",
                  textDecorationThickness: "4px",
                  textDecorationColor: "black",
                  color: "rgba(0, 0, 0, 0.3)",
                  marginRight: "10px"
                }}
              >
                £{previousPrice.toFixed(2)}
              </span>
            )}
            £{price.toFixed(2)}
          </Text>
          {status === AVAILABLE ? (
            <SolidButton
              mr={0}
              bg="dark"
              borderColor="dark"
              submitting={submitting}
              handleClick={() => {
                addItemToBasket(compositeId);
              }}
              data-component-name="Add to basket"
            >
              Add to basket
            </SolidButton>
          ) : (
            <SolidButton width="initial" mr={0} bg="dark" borderColor="dark" disabled={true}>
              Coming soon
            </SolidButton>
          )}
        </Box>
      </Box>
    </Box>
  );
}

SupplementProductDetails.propTypes = {
  description: PropTypes.string.isRequired,
  directions: PropTypes.string,
  price: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  productType: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};

export default SupplementProductDetails;
