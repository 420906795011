import Box from "../components/Box";

const UNCOLOURED = "#17224D";

function ProgressBarCircle({ children, percentValue, width }) {
  return (
    <Box position="relative" width={width}>
      <svg
        width="100%"
        height="auto"
        viewBox="0 0 270 270"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M163.877 3.09691C160.042 2.26121 156.257 4.6919 155.421 8.52601C154.585 12.3601 157.016 16.1457 160.85 16.9814C168.702 18.6928 176.268 21.168 183.467 24.3256C187.061 25.9017 191.252 24.2662 192.828 20.6725C194.404 17.0788 192.769 12.8878 189.175 11.3117C181.123 7.78005 172.659 5.01118 163.877 3.09691Z"
          fill="#FF5D6D"
        />
        <path
          d="M203.226 18.4849C199.841 16.499 195.488 17.6329 193.502 21.0174C191.516 24.4019 192.65 28.7555 196.034 30.7414C202.91 34.7757 209.353 39.4717 215.274 44.7424C218.206 47.3514 222.697 47.0901 225.306 44.1589C227.915 41.2277 227.654 36.7365 224.722 34.1275C218.109 28.2409 210.911 22.9941 203.226 18.4849Z"
          fill={percentValue > 5 ? "#FF5D6D" : UNCOLOURED}
          fillOpacity={percentValue > 5 ? 1 : 0.3}
        />
        <path
          d="M235.872 45.2776C233.263 42.3464 228.772 42.0851 225.841 44.6941C222.91 47.3031 222.649 51.7943 225.258 54.7255C230.528 60.6472 235.224 67.0899 239.259 73.9655C241.245 77.35 245.598 78.4839 248.983 76.498C252.367 74.5121 253.501 70.1585 251.515 66.774C247.006 59.089 241.759 51.8913 235.872 45.2776Z"
          fill={percentValue > 10 ? "#FF705D" : UNCOLOURED}
          fillOpacity={percentValue > 10 ? 1 : 0.3}
        />
        <path
          d="M258.688 80.8251C257.112 77.2315 252.921 75.5959 249.328 77.1721C245.734 78.7482 244.098 82.9392 245.674 86.5329C248.832 93.7323 251.307 101.298 253.019 109.15C253.854 112.984 257.64 115.415 261.474 114.579C265.308 113.743 267.739 109.958 266.903 106.123C264.989 97.341 262.22 88.8775 258.688 80.8251Z"
          fill={percentValue > 15 ? "#FF705D" : UNCOLOURED}
          fillOpacity={percentValue > 15 ? 1 : 0.3}
        />
        <path
          d="M269.354 121.71C268.972 117.804 265.496 114.948 261.591 115.33C257.685 115.712 254.829 119.187 255.211 123.093C255.593 127.008 255.789 130.98 255.789 135C255.789 139.02 255.593 142.992 255.211 146.907C254.829 150.813 257.685 154.288 261.591 154.67C265.496 155.052 268.972 152.196 269.354 148.29C269.781 143.916 270 139.482 270 135C270 130.518 269.781 126.084 269.354 121.71Z"
          fill={percentValue > 20 ? "#FC9257" : UNCOLOURED}
          fillOpacity={percentValue > 20 ? 1 : 0.3}
        />
        <path
          d="M266.903 163.877C267.739 160.042 265.308 156.257 261.474 155.421C257.64 154.585 253.854 157.016 253.019 160.85C251.307 168.702 248.832 176.268 245.674 183.467C244.098 187.061 245.734 191.252 249.328 192.828C252.921 194.404 257.112 192.769 258.688 189.175C262.22 181.123 264.989 172.659 266.903 163.877Z"
          fill={percentValue > 25 ? "#FC9257" : UNCOLOURED}
          fillOpacity={percentValue > 25 ? 1 : 0.3}
        />
        <path
          d="M251.515 203.226C253.501 199.841 252.367 195.488 248.983 193.502C245.598 191.516 241.245 192.65 239.259 196.034C235.224 202.91 230.528 209.353 225.258 215.274C222.649 218.206 222.91 222.697 225.841 225.306C228.772 227.915 233.264 227.654 235.872 224.722C241.759 218.109 247.006 210.911 251.515 203.226Z"
          fill={percentValue > 30 ? "#FCA557" : UNCOLOURED}
          fillOpacity={percentValue > 30 ? 1 : 0.3}
        />
        <path
          d="M224.722 235.872C227.654 233.263 227.915 228.772 225.306 225.841C222.697 222.91 218.206 222.649 215.274 225.258C209.353 230.528 202.91 235.224 196.034 239.259C192.65 241.245 191.516 245.598 193.502 248.983C195.488 252.367 199.841 253.501 203.226 251.515C210.911 247.006 218.109 241.759 224.722 235.872Z"
          fill={percentValue > 35 ? "#FCA557" : UNCOLOURED}
          fillOpacity={percentValue > 35 ? 1 : 0.3}
        />
        <path
          d="M189.175 258.688C192.769 257.112 194.404 252.921 192.828 249.328C191.252 245.734 187.061 244.098 183.467 245.674C176.268 248.832 168.702 251.307 160.85 253.019C157.016 253.854 154.585 257.64 155.421 261.474C156.257 265.308 160.042 267.739 163.877 266.903C172.659 264.989 181.123 262.22 189.175 258.688Z"
          fill={percentValue > 40 ? "#FCBA57" : UNCOLOURED}
          fillOpacity={percentValue > 40 ? 1 : 0.3}
        />
        <path
          d="M123.093 255.211C119.187 254.829 115.712 257.685 115.33 261.591C114.948 265.496 117.804 268.972 121.71 269.354C126.084 269.781 130.518 270 135 270C139.482 270 143.916 269.781 148.29 269.354C152.196 268.972 155.052 265.496 154.67 261.591C154.288 257.685 150.813 254.829 146.907 255.211C142.992 255.593 139.02 255.789 135 255.789C130.98 255.789 127.008 255.593 123.093 255.211Z"
          fill={percentValue > 45 ? "#FCBA57" : UNCOLOURED}
          fillOpacity={percentValue > 45 ? 1 : 0.3}
        />
        <path
          d="M86.5329 245.674C82.9392 244.098 78.7482 245.734 77.1721 249.328C75.5959 252.921 77.2315 257.112 80.8251 258.688C88.8775 262.22 97.341 264.989 106.123 266.903C109.958 267.739 113.743 265.308 114.579 261.474C115.415 257.64 112.984 253.854 109.15 253.019C101.298 251.307 93.7323 248.832 86.5329 245.674Z"
          fill={percentValue > 50 ? "#FCCE57" : UNCOLOURED}
          fillOpacity={percentValue > 50 ? 1 : 0.3}
        />
        <path
          d="M54.7256 225.258C51.7943 222.649 47.3031 222.91 44.6942 225.841C42.0852 228.772 42.3464 233.264 45.2776 235.872C51.8913 241.759 59.0891 247.006 66.774 251.515C70.1585 253.501 74.5121 252.367 76.498 248.983C78.4839 245.598 77.3501 241.245 73.9655 239.259C67.0899 235.224 60.6473 230.528 54.7256 225.258Z"
          fill={percentValue > 55 ? "#FCCE57" : UNCOLOURED}
          fillOpacity={percentValue > 55 ? 1 : 0.3}
        />
        <path
          d="M30.7414 196.034C28.7555 192.65 24.4019 191.516 21.0174 193.502C17.6329 195.488 16.499 199.841 18.4849 203.226C22.9941 210.911 28.2409 218.109 34.1276 224.722C36.7365 227.654 41.2277 227.915 44.159 225.306C47.0902 222.697 47.3514 218.206 44.7424 215.274C39.4717 209.353 34.7757 202.91 30.7414 196.034Z"
          fill={percentValue > 60 ? "#FCE157" : UNCOLOURED}
          fillOpacity={percentValue > 60 ? 1 : 0.3}
        />
        <path
          d="M16.9814 160.85C16.1457 157.016 12.3601 154.585 8.52601 155.421C4.6919 156.257 2.26121 160.042 3.09691 163.877C5.01118 172.659 7.78005 181.123 11.3117 189.175C12.8878 192.769 17.0788 194.404 20.6725 192.828C24.2662 191.252 25.9017 187.061 24.3256 183.467C21.168 176.268 18.6928 168.702 16.9814 160.85Z"
          fill={percentValue > 65 ? "#FCE157" : UNCOLOURED}
          fillOpacity={percentValue > 65 ? 1 : 0.3}
        />
        <path
          d="M14.7895 123.093C15.1714 119.187 12.3149 115.712 8.40938 115.33C4.50388 114.948 1.02827 117.804 0.646402 121.71C0.218705 126.084 0 130.518 0 135C0 139.482 0.218705 143.916 0.646401 148.29C1.02827 152.196 4.50388 155.052 8.40938 154.67C12.3149 154.288 15.1714 150.813 14.7895 146.907C14.4067 142.992 14.2105 139.02 14.2105 135C14.2105 130.98 14.4067 127.008 14.7895 123.093Z"
          fill={percentValue > 70 ? "#D3F060" : UNCOLOURED}
          fillOpacity={percentValue > 70 ? 1 : 0.3}
        />
        <path
          d="M24.3256 86.5329C25.9017 82.9392 24.2662 78.7482 20.6725 77.1721C17.0788 75.5959 12.8878 77.2315 11.3117 80.8251C7.78005 88.8775 5.01118 97.341 3.09691 106.123C2.26121 109.958 4.6919 113.743 8.52601 114.579C12.3601 115.415 16.1457 112.984 16.9814 109.15C18.6928 101.298 21.168 93.7323 24.3256 86.5329Z"
          fill={percentValue > 75 ? "#D3F060" : UNCOLOURED}
          fillOpacity={percentValue > 75 ? 1 : 0.3}
        />
        <path
          d="M44.7424 54.7256C47.3514 51.7943 47.0901 47.3031 44.1589 44.6942C41.2277 42.0852 36.7365 42.3464 34.1275 45.2776C28.2409 51.8913 22.9941 59.0891 18.4849 66.774C16.499 70.1585 17.6329 74.5121 21.0174 76.498C24.4019 78.4839 28.7555 77.3501 30.7414 73.9655C34.7757 67.0899 39.4717 60.6473 44.7424 54.7256Z"
          fill={percentValue > 80 ? "#36D275" : UNCOLOURED}
          fillOpacity={percentValue > 80 ? 1 : 0.3}
        />
        <path
          d="M73.9655 30.7414C77.35 28.7555 78.4839 24.4019 76.498 21.0174C74.5121 17.6329 70.1585 16.499 66.774 18.4849C59.089 22.9941 51.8913 28.2409 45.2776 34.1276C42.3464 36.7365 42.0851 41.2277 44.6941 44.159C47.3031 47.0902 51.7943 47.3514 54.7255 44.7424C60.6472 39.4717 67.0899 34.7757 73.9655 30.7414Z"
          fill={percentValue > 85 ? "#36D275" : UNCOLOURED}
          fillOpacity={percentValue > 85 ? 1 : 0.3}
        />
        <path
          d="M109.15 16.9814C112.984 16.1457 115.415 12.3601 114.579 8.52601C113.743 4.6919 109.958 2.26121 106.123 3.09691C97.341 5.01118 88.8775 7.78005 80.8251 11.3117C77.2315 12.8878 75.5959 17.0788 77.1721 20.6725C78.7482 24.2662 82.9392 25.9017 86.5329 24.3256C93.7323 21.168 101.298 18.6928 109.15 16.9814Z"
          fill={percentValue > 90 ? "#00BF86" : UNCOLOURED}
          fillOpacity={percentValue > 90 ? 1 : 0.3}
        />
        <path
          d="M135 0C130.518 0 126.084 0.218705 121.71 0.646401C117.804 1.02827 114.948 4.50388 115.33 8.40938C115.712 12.3149 119.187 15.1714 123.093 14.7895C127.008 14.4067 130.98 14.2105 135 14.2105C139.02 14.2105 142.992 14.4067 146.907 14.7895C150.813 15.1714 154.288 12.3149 154.67 8.40938C155.052 4.50388 152.196 1.02827 148.29 0.646402C143.916 0.218705 139.482 0 135 0Z"
          fill={percentValue > 95 ? "#00BF86" : UNCOLOURED}
          fillOpacity={percentValue > 95 ? 1 : 0.3}
        />
      </svg>
      <Box
        position="absolute"
        top={0}
        left={0}
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%"
      >
        {children}
      </Box>
    </Box>
  );
}

export default ProgressBarCircle;
