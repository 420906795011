import React, { useState } from "react";

import PropTypes from "prop-types";

import { AFFILIATE, EXPERT, PRACTITIONER } from "../core/constants";
import { CREATE_PARTNER_INVITATION_MUTATION, PARTNER_LINKS_QUERY } from "../graphql/accounts";
import Box from "./Box";
import { SpacedPanelBox } from "./Boxes";
import { SolidButton } from "./Buttons";
import Form from "./Form";
import { HeadingLarge } from "./Headings";
import Input from "./Input";
import Text, { SpanText } from "./Text";

function CreatePartnerInvitation({ tier, userHashId }) {
  const [showAddAnother, setShowAddAnother] = useState(false);
  const [formKey, setFormKey] = useState(0);
  const [createdAffiliateLink, setCreatedAffiliateLink] = useState(false);
  const partnerLinkURL = `${window.location.origin}/accounts/partner/link/${userHashId}`;
  let heading;
  let content;

  if (tier === AFFILIATE) {
    heading = "Invite new users";
    content =
      "Invite and sign up new users to the platform and claim 20% of all full-priced orders placed. Fill in the users email address below and we will send them an invitation to join Omnos on your behalf.";
  } else {
    heading = "Invite your clients";
    content =
      "You can claim 20% of all full-priced orders placed for new platforms users. Fill in their email address below and we will send an invitation to join Omnos and allow you access to their results.";
  }

  return (
    <SpacedPanelBox>
      <HeadingLarge pb as="h2">
        {heading}
      </HeadingLarge>
      <Text pb={30}>{content}</Text>
      <Box
        position="relative"
        style={{
          overflowWrap: "anywhere"
        }}
      >
        <Form
          key={formKey}
          mutation={CREATE_PARTNER_INVITATION_MUTATION}
          refetchQueries={[{ query: PARTNER_LINKS_QUERY }]}
          handleSubmitted={({ createdAffiliateLink }) => {
            if (createdAffiliateLink) {
              setCreatedAffiliateLink(true);
            } else {
              setCreatedAffiliateLink(false);
            }
            setShowAddAnother(true);
          }}
          data={[
            {
              fields: [
                {
                  initialValue: "",
                  name: "platformUserEmail",
                  widget: {
                    component: Input,
                    props: {
                      type: "email",
                      label: "Email"
                    }
                  }
                }
              ]
            }
          ]}
          formWrapperProps={{
            mb: 30
          }}
        />
        {showAddAnother && (
          <Box
            bg="white"
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            top={0}
            bottom={0}
            left={0}
            right={0}
          >
            <Text fontFamily="gilroyBold" textAlign="center">
              {createdAffiliateLink ? "Invitation sent" : "Results access request sent"}
            </Text>
            <SolidButton
              mt={20}
              handleClick={() => {
                // Reset the form by bumping the key
                setFormKey(formKey + 1);
                setShowAddAnother(false);
              }}
            >
              Add another
            </SolidButton>
            {!createdAffiliateLink && (
              <Text pt={30} maxWidth={470} textAlign="center">
                The invited user was already a platform user. If you also wish to set up an
                affiliation, please email support@omnos.me
              </Text>
            )}
          </Box>
        )}
        <Text pb={30}>
          Alternatively you can send this unique link:{" "}
          <SpanText fontFamily="gilroyBold">{partnerLinkURL}</SpanText>
        </Text>
      </Box>
    </SpacedPanelBox>
  );
}

CreatePartnerInvitation.propTypes = {
  tier: PropTypes.oneOf([AFFILIATE, PRACTITIONER, EXPERT])
};

export default CreatePartnerInvitation;
