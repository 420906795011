import { createContext, useCallback, useContext, useMemo, useState } from "react";

import Box from "../components/Box";
import Group from "./Group";
import { PanelBoxV2 } from "./NewBoxes";
import Switch from "./Switch";

export const ExpiredMarkersContext = createContext();

export function ExpiredMarkersProvider({ children }) {
  const [showExpiredMarkers, setShowExpiredMarkers] = useState(false);

  const toggle = useCallback(() => {
    setShowExpiredMarkers(!showExpiredMarkers);
  }, [showExpiredMarkers, setShowExpiredMarkers]);

  const hasExpired = useCallback(dateStr => {
    if (!dateStr) return false;
    return new Date(dateStr) <= new Date();
  }, []);

  const api = useMemo(
    () => ({
      showExpiredMarkers,
      toggle,
      hasExpired
    }),
    [showExpiredMarkers, toggle, hasExpired]
  );
  return <ExpiredMarkersContext.Provider value={api}>{children}</ExpiredMarkersContext.Provider>;
}

export function IncludeExpiredMarkers() {
  const ctx = useContext(ExpiredMarkersContext);

  if (ctx) {
    return (
      <PanelBoxV2
        maxWidth={1538}
        outer={{
          pt: 2,
          px: 20
        }}
      >
        <Group alignItems="center" gap={10} justifyContent="flex-end">
          <Box color="white" fontFamily="gilroyBold" fontSize={15} lineHeight="24px">
            Include expired markers
          </Box>
          <Switch
            data={[
              {
                label: "No",
                value: true,
                bg: "dark",
                color: "white"
              },
              {
                label: "Yes",
                value: false,
                bg: "white",
                color: "dark"
              }
            ]}
            onChange={ctx.toggle}
            value={ctx.showExpiredMarkers}
            height={40}
          />
        </Group>
      </PanelBoxV2>
    );
  }
  return null;
}
