import React from "react";

import gql from "graphql-tag";
import moment from "moment";
import PropTypes from "prop-types";

import { AFFILIATE } from "../core/constants";
import { PAYOUT_FIELDS, USER_PURCHASE_COMMISSION_FIELDS } from "../graphql/accounts";
import { SpacedPanelBox } from "./Boxes";
import DataLoader from "./DataLoader";
import Divider from "./Divider";
import { HeadingLarge, HeadingMedium } from "./Headings";
import Table from "./Table";
import Text, { SpanText } from "./Text";

export const PARTNER_INVITATIONS_AND_PAYOUTS_QUERY = gql`
  query AffiliateLinksAndPayoutsQuery {
    payouts {
      ...PayoutFields
    }
    discountCodeAndLinkCommissions {
      ...UserPurchaseCommissionFields
    }
  }
  ${PAYOUT_FIELDS}
  ${USER_PURCHASE_COMMISSION_FIELDS}
`;

export default function AffiliateIncome({ userHashId, tier }) {
  return (
    <DataLoader
      query={PARTNER_INVITATIONS_AND_PAYOUTS_QUERY}
      render={({ discountCodeAndLinkCommissions, payouts }) => {
        let incomeTotal = 0;
        const affilateIncomeRows = discountCodeAndLinkCommissions.map(order => {
          incomeTotal += order.commission;
          return [
            <Text>{order.orderId}</Text>,
            <Text>{moment(order.orderDate).format("DD/MM/YYYY")}</Text>,
            <Text>{order.commission.toFixed(2)}</Text>
          ];
        });
        const prectitionerIncomeRows = discountCodeAndLinkCommissions.map(order => {
          return [
            <Text>{order.orderDescription}</Text>,
            <Text>{moment(order.orderDate).format("DD/MM/YYYY")}</Text>,
            <Text>{order.discount}</Text>,
            <Text>{order.commission.toFixed(2)}</Text>
          ];
        });

        let paymentTotal = 0;

        const payoutRows = payouts.map(payout => {
          paymentTotal += payout.amount || 0;
          return [
            <Text>{payout.id}</Text>,
            <Text>{moment(payout.created).format("DD/MM/YYYY")}</Text>,
            <Text>{payout.status}</Text>,
            <Text>{payout.amount}</Text>
          ];
        });

        const balance = incomeTotal - paymentTotal;

        return (
          <SpacedPanelBox backgroundColor="partners" data-component-name="Affiliated income">
            <HeadingLarge pb as="h2">
              Affiliated income
            </HeadingLarge>
            <HeadingMedium pb as="h3">
              Commission
            </HeadingMedium>
            {tier === AFFILIATE ? (
              <Table
                tableHeadings={["Order ID", "Order date", "Commision"]}
                tableRows={affilateIncomeRows}
              />
            ) : (
              <Table
                tableHeadings={["Order ID", "Order date", "Discount", "Commission"]}
                tableRows={prectitionerIncomeRows}
              />
            )}
            <HeadingMedium pt pb as="h3">
              Payouts
            </HeadingMedium>
            <Table
              tableHeadings={["Reference", "Date", "Status", "Amount"]}
              tableRows={payoutRows}
            />
            <HeadingMedium pt as="h3" data-component-name="Commission Balance">
              Balance : £{balance.toFixed(2)}
            </HeadingMedium>
            <Divider mt={30} mb={30} />
            <Text>
              To claim your commission fees please send an invoice to finance@omnos.me including
              your name, payment details and your Omnos partner number{" "}
              <SpanText fontFamily="gilroyBold" uppercase>
                {userHashId}
              </SpanText>
            </Text>
          </SpacedPanelBox>
        );
      }}
    />
  );
}

AffiliateIncome.propTypes = {
  userHashId: PropTypes.string.isRequired
};
