import { CSSTransition } from "react-transition-group";

import Box from "components/Box";

const DEFAULT_PROPS = {
  timeout: 300,
  mountOnEnter: true,
  unmountOnExit: true
};

export function FadeTransition({ children, ...props }) {
  props = { ...DEFAULT_PROPS, ...props };

  let baseStyle = {
    transition: `all ${props.timeout}ms ease-in-out`,
    opacity: 0
  };

  let transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 }
  };

  return (
    <CSSTransition {...props}>
      {state => <div style={{ ...baseStyle, ...transitionStyles[state] }}>{children}</div>}
    </CSSTransition>
  );
}

export function FadeGrowTransition({ children, heights, ...props }) {
  props = { ...DEFAULT_PROPS, ...props };

  if (heights.length !== 2) {
    throw new Error("FadeGrowTransition requires exactly two heights");
  }

  let baseStyle = {
    transition: `all ${props.timeout}ms ease-in-out`,
    opacity: 0,
    height: heights[0]
  };

  let transitionStyles = {
    entering: { opacity: 1, height: `${heights[1]}px` },
    entered: { opacity: 1, height: `${heights[1]}px` },
    exiting: { opacity: 0, height: `${heights[0]}px` },
    exited: { opacity: 0, height: `${heights[0]}px` }
  };

  return (
    <CSSTransition {...props}>
      {state => <Box style={{ ...baseStyle, ...transitionStyles[state] }}>{children}</Box>}
    </CSSTransition>
  );
}
