import React, { useEffect } from "react";

import { useHistory } from "react-router-dom";

import {
  DASHBOARD_HOME_URL,
  SIGNUP_COMPLETE_URL,
  TERMS_OF_SERVICE_URL,
  TERMS_OF_USE_URL
} from "../core/urls";
import { ACTIVATE_ACCOUNT_MUTATION } from "../graphql/accounts";
import useDocTitle from "../hooks/use-doc-title";
import AccountPage from "./AccountPage";
import { useAuthContext } from "./AuthProvider";
import Box from "./Box";
import Checkbox from "./Checkbox";
import Form from "./Form";
import { ExternalTextLink } from "./Links";
import Text from "./Text";

function SignupConfirmPage({ match }) {
  useDocTitle("Signup confirmation");
  const { user } = useAuthContext();
  const history = useHistory();

  useEffect(() => {
    // If the user had already authenticated, skip this page and head to the dashboard
    if (user !== null) {
      history.push(DASHBOARD_HOME_URL);
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <AccountPage heading="Welcome to Omnos">
      <Text pt={40}>
        We take your privacy and security very seriously so please have a read and agree to our
        privacy and terms of service below before validating your account.
      </Text>
      <Box pt={40}>
        <Form
          mutation={ACTIVATE_ACCOUNT_MUTATION}
          handleSubmitted={() => {
            history.push(SIGNUP_COMPLETE_URL);
          }}
          extraInput={{
            userId: match.params.userId,
            token: match.params.token
          }}
          data={[
            {
              fields: [
                {
                  name: "acceptTerms",
                  initialValue: false,
                  widget: {
                    props: {
                      label: <span>I agree to the Omnos terms and conditions</span>
                    },
                    component: Checkbox
                  }
                }
              ]
            }
          ]}
          formWrapperProps={{
            mb: 30
          }}
        />
        <Text textAlign="center">
          I agree to the Omnos{" "}
          <ExternalTextLink href={TERMS_OF_USE_URL}>Terms of use</ExternalTextLink> and{" "}
          <ExternalTextLink href={TERMS_OF_SERVICE_URL}>Terms of service</ExternalTextLink>
        </Text>
      </Box>
    </AccountPage>
  );
}

export default SignupConfirmPage;
