import React from "react";

import { ReactComponent as ContainsCaffeine } from "../images/caffeine.svg";
import { ReactComponent as DairyFree } from "../images/dairy-free.svg";
import { ReactComponent as FiveHTP } from "../images/five-htp.svg";
import { ReactComponent as GlutenFree } from "../images/gluten-free.svg";
import { ReactComponent as GMP } from "../images/gmp.svg";
import { ReactComponent as ContainsLiquorice } from "../images/liquorice.svg";
import { ReactComponent as NonGMO } from "../images/non-gmo.svg";
import { ReactComponent as SteviaFree } from "../images/stevia-free.svg";
import { ReactComponent as Vegan } from "../images/vegan.svg";
import { ReactComponent as Vegetarian } from "../images/vegetarian.svg";
import Box from "./Box";
import Text from "./Text";

function getIcon(categoryName) {
  let icon;
  switch (categoryName) {
    case "Dairy Free":
      icon = DairyFree;
      break;
    case "Gluten Free":
      icon = GlutenFree;
      break;
    case "GMP":
      icon = GMP;
      break;
    case "Non GMO":
      icon = NonGMO;
      break;
    case "Stevia Free":
      icon = SteviaFree;
      break;
    case "Vegan":
      icon = Vegan;
      break;
    case "Contains Caffeine":
      icon = ContainsCaffeine;
      break;
    case "Vegetarian":
      icon = Vegetarian;
      break;
    case "Contains 5-HTP":
      icon = FiveHTP;
      break;
    case "Contains Liquorice":
      icon = ContainsLiquorice;
      break;
    default:
      return;
  }
  return icon;
}

function SupplmentCategories({ categories }) {
  const gap = [20, 30, 40, 50];
  return (
    <Box display="flex" flexWrap="wrap" p={gap} maxWidth={1200} mx="auto" justifyContent="center">
      {categories.map((category, index) => {
        const Icon = getIcon(category.name);
        return (
          <Box key={index} p={gap} display="flex" flexDirection="column" alignItems="center">
            <Box
              size={100}
              bg="dark"
              borderRadius="33%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Icon width={40} height={40} fill="white" />
            </Box>
            <Text
              width={100}
              fontFamily="gilroyBold"
              uppercase
              textAlign="center"
              fontSize={14}
              letterSpacing="0.1em"
              mt={20}
            >
              {category.name}
            </Text>
          </Box>
        );
      })}
    </Box>
  );
}

export default SupplmentCategories;
