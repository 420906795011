import PropTypes from "prop-types";

import Box from "../components/Box";
import Accordion from "./Accordion";
import { PanelBox } from "./Boxes";

export function ChartAccordion({ children, expandBtnText, onClick, loading }) {
  return (
    <Accordion
      buttonAs="div"
      buttonProps={{
        fontFamily: "gilroyBold",
        letterSpacing: "2.8px",
        textTransform: "uppercase"
      }}
      expandBtnText={expandBtnText}
      py={4}
      onClick={onClick}
      loading={loading}
    >
      <Box px={2} mt={4} pb={4}>
        {children}
      </Box>
    </Accordion>
  );
}

ChartAccordion.propTypes = {
  children: PropTypes.node,
  expandBtnText: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool
};

ChartAccordion.defaultProps = {
  expandBtnText: "related biomarkers"
};

export function CommonAccordion({ children, expandBtnText, ...rest }) {
  return (
    <Accordion
      bg="white"
      buttonProps={{
        fontFamily: "gilroyBold"
      }}
      expandBtnText={expandBtnText}
      fullWidth={true}
      borderRadius={5}
      fontSize={15}
      px={20}
      {...rest}
    >
      <Box as="ul" listStyle="disc" ml={4} pb={15}>
        {children}
      </Box>
    </Accordion>
  );
}

export function SymptomsAccordion({ children, expandBtnText, ...rest }) {
  return (
    <Box bg="white" borderRadius={5}>
      <PanelBox nested small>
        <CommonAccordion expandBtnText={expandBtnText} {...rest}>
          {children}
        </CommonAccordion>
      </PanelBox>
    </Box>
  );
}
