import React, { useState } from "react";

import { Helmet } from "react-helmet";
import { Redirect, useHistory } from "react-router-dom";

import { gql, useApolloClient, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import chunks from "array.chunk";

import { theme } from "../core/theme";
import {
  getTestUrl,
  getTestFamilyUrl,
  TESTS_URL,
  ERROR_404_URL,
  getTestProductQuestionnaireIntroUrl
} from "../core/urls";
import { priceFormat } from "../core/utils";
import {
  ADD_ITEM_TO_BASKET,
  CLINIC_LOCATION_FIELDS,
  OPEN_ORDER_QUERY,
  TEST_PRODUCT_FIELDS,
  TEST_PRODUCT_OPTION_FIELDS,
  TEST_PRODUCT_SHORT_FIELDS
} from "../graphql/shop";
import { USER_TEST_RECOMMENDATION_FIELDS } from "../graphql/tpo/results/types";
import useDocTitle from "../hooks/use-doc-title";
import useWindowSize from "../hooks/use-window-size";
import { ReactComponent as Lock } from "../images/lock.svg";
import { ReactComponent as Logo } from "../images/logo.svg";
import { ReactComponent as TestIcon } from "../images/test-order.svg";
import { PanelBox } from "../tpo/Boxes";
import { HeadingExtraSmall, HeadingMediumLarge } from "../tpo/Headings";
import Modal from "../tpo/Modal";
import Section from "../tpo/Section";
import TestProductResponsiveImage, { TestProductImage } from "../tpo/TestProductResponsiveImage";
import { BestMatchedTest } from "../tpo/TestRecommendations";
import { useAppState } from "./AppStateProvider";
import AspectRatio from "./AspectRatio";
import BackgroundImage from "./BackgroundImage";
import Box from "./Box";
import { SolidButton } from "./Buttons";
import DataLoader from "./DataLoader";
import { SelectExpand } from "./Expand";
import Grid from "./Grid";
import { HeadingExtraExtraSmall, HeadingExtraLarge, HeadingMedium } from "./Headings";
import Image from "./Image";
import { InternalTextLink } from "./Links";
import Loading from "./Loading";
import GoogleMap from "./Map";
import Page from "./Page";
import RichText from "./RichText";
import Slider from "./Slider";
import { UserRecommendedTestBadge } from "./TestProductList";
import Text, { SpanText } from "./Text";

function BestMatched({ userTestRecommendation, position }) {
  return (
    <PanelBox smallPadding verticalPadding bg="blue" outer={{ borderRadius: "5px" }}>
      <Section.Flag display="flex" justifyContent="center">
        <BestMatchedTest.Icon position={position} />
      </Section.Flag>
      <BestMatchedTest.Header.Primary color="white" small />
      <BestMatchedTest.BodyCopy color="white" mb={0} />
      <Box display="flex" flexDirection={["column"]}>
        <BestMatchedTest.Symptoms
          color="white"
          userTestRecommendation={userTestRecommendation}
          usedWithinProductContext
        />
      </Box>
    </PanelBox>
  );
}

function TestHeader({ children, color, maxWidth, backgroundColor }) {
  return (
    <Box
      px={20}
      pt={[100, 110, 120, null]}
      pb={[30, null, 40, null]}
      bg={backgroundColor}
      color={color}
    >
      <Box maxWidth={maxWidth} mx="auto">
        {children}
      </Box>
    </Box>
  );
}

function TestOverview({
  images,
  name,
  productFamily,
  testProducts,
  testType,
  numOfBiomarkersTested,
  processingTime,
  description,
  productOptionsExplanation,
  whyYouShouldTakeThisTest,
  whatYouGetWithTest,
  shippingAndProcessing,
  needHelp,
  directions,
  price,
  previousPrice,
  id,
  compositeId,
  options,
  slug,
  singleUserTestRecommendation,
  bundlePromoImage,
  bundlePromoImageAltText
}) {
  return (
    <Box px={20}>
      <Grid
        gridTemplateColumns={[null, null, "3fr 2fr"]}
        maxWidth={1500}
        mx="auto"
        pt={theme.spacing.extraLarge}
        gridColumnGap={50}
        mb={theme.spacing.extraLarge}
      >
        <Box>
          <Box position="sticky" top="0px">
            <Carousel images={images} />
          </Box>
        </Box>
        <Box>
          <BreadCrumb name={name} productFamily={productFamily} />
          <TestSelector testProducts={testProducts} />
          <ProductDetails
            name={name}
            testType={testType}
            numOfBiomarkersTested={numOfBiomarkersTested}
            processingTime={processingTime}
            description={description}
            productOptionsExplanation={productOptionsExplanation}
            whyYouShouldTakeThisTest={whyYouShouldTakeThisTest}
            whatYouGetWithTest={whatYouGetWithTest}
            shippingAndProcessing={shippingAndProcessing}
            needHelp={needHelp}
            directions={directions}
            price={price}
            previousPrice={previousPrice}
            id={id}
            options={options}
            compositeId={compositeId}
            slug={slug}
            singleUserTestRecommendation={singleUserTestRecommendation}
            bundlePromoImage={bundlePromoImage}
            bundlePromoImageAltText={bundlePromoImageAltText}
          />
        </Box>
      </Grid>
    </Box>
  );
}

function Carousel({ images }) {
  const [slideIndex, setSlideIndex] = useState(0);
  const windowSize = useWindowSize();

  let testProductImageHeight = 300;
  if (windowSize.width > 600) {
    testProductImageHeight = 600;
  }
  return (
    <>
      <Slider currentIndex={slideIndex} width="100%" inset="0px">
        {images.map((image, index) => {
          return (
            <TestProductImage
              key={index}
              background="white"
              height={testProductImageHeight + "px"}
              width="100%"
            >
              <TestProductResponsiveImage
                images={image}
                pixelDensity={2}
                breakpoints={{
                  [360 * 2]: 600
                }}
              />
            </TestProductImage>
          );
        })}
      </Slider>
      <SliderPips images={images} slideIndex={slideIndex} setSlideIndex={setSlideIndex} />
    </>
  );
}

function SliderPips({ images, slideIndex, setSlideIndex }) {
  return (
    <Box display="flex" flexDirection="row" justifyContent="end" pt={theme.spacing.small}>
      {images.map((_, index) => {
        return (
          <Box
            width={20}
            height={20}
            borderRadius="50%"
            border="solid 1px"
            backgroundColor={slideIndex === index ? "black" : "white"}
            key={index}
            ml={20}
            onClick={() => {
              setSlideIndex(index);
            }}
          />
        );
      })}
    </Box>
  );
}

function BreadCrumb({ productFamily, name }) {
  const styles = {
    underline: false,
    fontFamily: "gilroyBold",
    color: "dark",
    letterSpacing: "2.8px",
    fontSize: "10px",
    uppercase: true
  };
  return (
    <Box mb={theme.spacing.medium}>
      <InternalTextLink {...styles} textTransform="uppercase" href={TESTS_URL}>
        All Tests
      </InternalTextLink>
      {" / "}
      <InternalTextLink
        {...styles}
        textTransform="uppercase"
        href={getTestFamilyUrl(productFamily.slug)}
      >
        {productFamily.slug}
      </InternalTextLink>
      {" / "}
      <Text marginCorrection={false} as="span" {...styles}>
        {name}
      </Text>
    </Box>
  );
}

function TestSelector({ testProducts }) {
  const history = useHistory();
  return (
    <SelectExpand
      position="absolute"
      push
      defaultOpen={false}
      top={
        <Box display="flex" alignItems="center">
          <TestIcon fill="white" width={20} />
          <Text color="white" fontFamily="gilroyBold" ml="10px">
            Omnos tests
          </Text>
        </Box>
      }
      callback={test => {
        history.push(getTestUrl(test.slug, test.productFamily.slug));
      }}
      items={testProducts.map((test, index) => {
        return {
          value: test,
          content: (
            <Box display="flex" justifyContent="space-between" key={index}>
              <SpanText lineHeight={2} color="white">
                {test.name}
              </SpanText>
              <SpanText lineHeight={1} color="white" underline={true}>
                {test.status}
              </SpanText>
            </Box>
          )
        };
      })}
    />
  );
}

const GET_CLINICS = gql`
  query GetClinics($compositeId: ID!, $postCode: String) {
    clinicLocationsWithPrice(compositeId: $compositeId, postCode: $postCode) {
      distance
      fee
      location {
        ...ClinicLocationFields
      }
      price
    }
  }
  ${CLINIC_LOCATION_FIELDS}
`;

function ClinicLocationModalContent({
  title,
  postCode,
  onChange,
  closeModal,
  addItemToBasket,
  clinics,
  option
}) {
  let places = [];
  let lat = 53.383331;
  let lng = -1.466667;

  if (clinics?.length) {
    lat = clinics[0].location?.coords?.lat;
    lng = clinics[0].location?.coords?.lng;
    places = clinics
      .map(clinic => ({
        id: clinic.location.id,
        pos: {
          lat: clinic.location.coords?.lat,
          lng: clinic.location.coords?.lng
        }
      }))
      .filter(place => place.pos.lat !== undefined && place.pos.lng !== undefined);
  }

  return (
    <Box px={[2, 4]} py={4}>
      <HeadingExtraLarge as="h2" pb>
        {title}
      </HeadingExtraLarge>
      <Text fontSize={16}>
        Search below to find and select where you’d like to have you blood taken. You will recieve a
        special venous draw kit in the post that you should take along to your selected store or
        clinic. You will be required to book an appointment with the location prior to your visit
      </Text>
      <Box my={40}>
        <Box
          as="input"
          borderColor="black"
          borderStyle="solid"
          borderWidth={1}
          onChange={onChange}
          placeholder="Enter post code"
          p={2}
          value={postCode}
          name="postCode"
        />
      </Box>
      {lat && lng && (
        <Box mt={40}>
          <GoogleMap places={places} lat={lat} lng={lng} zoom={6} />
        </Box>
      )}
      {!clinics && <Loading />}
      {clinics?.map(clinic => (
        <Box
          borderStyle="solid"
          borderColor="black"
          borderWidth={1}
          p={4}
          key={clinic.location.id}
          my={15}
        >
          <Box display="flex" justifyContent="space-between">
            <Box>
              <HeadingExtraSmall as="h4" fontSize={18} mb={15}>
                {clinic.location.clinic.name}
              </HeadingExtraSmall>
              <Text fontFamily="gilroyMedium" color="midGrey">
                {clinic.location.address}
              </Text>
              {!!clinic.location.phoneNumber && (
                <Text fontFamily="gilroyMedium" color="midGrey" mt={1}>
                  {clinic.location.phoneNumber}
                </Text>
              )}
              <Text fontFamily="gilroyMedium" color="midGrey" mt={1}>
                {`${priceFormat(+clinic.distance)}km`}
              </Text>
            </Box>
            <Box>
              <Text fontFamily="gilroyBold" fontSize={44} textAlign="right">
                £{parseFloat(clinic.price)}
              </Text>
              <Text color="dark" fontFamily="gilroyMedium" fontSize={15} mt={2} textAlign="right">
                Includes £{parseFloat(clinic.fee)} clinic fee
              </Text>
              <SolidButton
                mt={4}
                paddingSize="mediumNarrow"
                bg="green"
                borderColor="green"
                submitting={false}
                handleClick={() => {
                  addItemToBasket({
                    clinicLocationId: clinic.location.id,
                    compositeId: option.compositeId
                  });
                  closeModal();
                }}
                data-component-name="Add to basket"
              >
                ADD TO BASKET &gt;
              </SolidButton>
            </Box>
          </Box>
        </Box>
      ))}
      {!clinics?.length && (
        <Box p={2} my={15}>
          No stores found
        </Box>
      )}
    </Box>
  );
}

function ClinicLocationsModal({
  addItemToBasket,
  closeModal,
  maxWidth,
  my,
  option,
  show,
  postCode,
  onChangePostCode
}) {
  const { data } = useQuery(GET_CLINICS, {
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
    variables: {
      compositeId: option.compositeId,
      postCode
    }
  });

  return (
    <Modal
      close={closeModal}
      show={show}
      maxWidth={maxWidth}
      my={my}
      centered
      closeButton
      bg="white"
    >
      <ClinicLocationModalContent
        title="Venous draw stores and clinics"
        postCode={postCode}
        onChange={onChangePostCode}
        closeModal={closeModal}
        addItemToBasket={addItemToBasket}
        clinics={data?.clinicLocationsWithPrice}
        option={option}
      />
    </Modal>
  );
}

ClinicLocationsModal.defaultProps = {
  maxWidth: [null, 624, 940, 1020],
  mx: [20, "auto"],
  my: 20
};

function getProductOptionTitle(option) {
  return option.testProductOptionFamily.name;
}

function getProductOptionDescription(option) {
  return option.testProductOptionFamily.description;
}

function ProductOptions({ addItemToBasket, options, previousPrice, submitting }) {
  const [clinicsModal, setClinicsModal] = useState({
    show: false,
    option: undefined
  });
  const [postCode, setPostCode] = useState("");
  const onChangePostCode = e => {
    const value = e.target.value;
    setPostCode(value);
  };

  const showClinicsModal = option => {
    setClinicsModal({
      show: true,
      option
    });
  };

  const hideClinicsModal = () => setClinicsModal({ show: false, option: false });

  /**
   * previousPrice prop added for black friday sale
   * It is the previousPrice from the base product
   */

  return (
    <>
      <Box>
        <HeadingMediumLarge as="h3" fontSize={28} my={40}>
          Purchase options
        </HeadingMediumLarge>
        <Box bg="white" borderRadius={"5px"} py={40} px={25}>
          {options.map((option, index) => (
            <React.Fragment key={option.id}>
              <Box key={option.id}>
                <HeadingExtraSmall as="h4" fontSize={18} mb={15}>
                  {getProductOptionTitle(option)}
                </HeadingExtraSmall>
                <RichText my={15}>{getProductOptionDescription(option)}</RichText>
                <Box
                  display="flex"
                  alignItems={["", "center"]}
                  justifyContent={["", "space-between"]}
                  flexDirection={["column", "row"]}
                  mt={15}
                >
                  {option.clinicsInfo.clinics.length ? (
                    <>
                      <Box>
                        <Text fontFamily="dinBold" fontSize={16}>
                          FROM
                        </Text>
                        <Box display="flex" alignItems="center" my={2}>
                          <Text fontFamily="dinBold" fontSize={44}>
                            £{parseFloat(option.clinicsInfo.fromPrice)}
                          </Text>
                        </Box>
                        <Text fontFamily="dinBold" fontSize={15} mt={1}>
                          Includes clinic fee
                        </Text>
                      </Box>
                      <SolidButton
                        paddingSize="mediumNarrow"
                        mr={0}
                        ml={[0, theme.spacing.medium]}
                        my={[15, 0]}
                        bg="green"
                        borderColor="green"
                        submitting={submitting}
                        handleClick={() => {
                          showClinicsModal(option);
                        }}
                        data-component-name="Find nearest clinic"
                      >
                        FIND NEAREST CLINIC &gt;
                      </SolidButton>
                    </>
                  ) : (
                    <>
                      <Box display="flex" alignItems="center">
                        {previousPrice && (
                          <Text fontFamily="dinBold" fontSize={44} mr={2}>
                            <span
                              style={{
                                textDecoration: "line-through",
                                textDecorationThickness: "4px",
                                textDecorationColor: "#e44c4b",
                                color: "#e44c4b"
                              }}
                            >
                              £{priceFormat(previousPrice)}
                            </span>
                          </Text>
                        )}
                        <Text fontFamily="dinBold" fontSize={44}>
                          £{parseFloat(option.totalPrice)}
                        </Text>
                      </Box>
                      <SolidButton
                        paddingSize="mediumNarrow"
                        mr={0}
                        ml={[0, theme.spacing.medium]}
                        my={[15, 0]}
                        bg="green"
                        borderColor="green"
                        submitting={submitting}
                        handleClick={() => {
                          addItemToBasket({
                            compositeId: option.compositeId
                          });
                        }}
                        data-component-name="Add to basket"
                      >
                        ADD TO BASKET &gt;
                      </SolidButton>
                    </>
                  )}
                </Box>
              </Box>
              {options.length - 1 !== index && (
                <Box
                  borderTop={1}
                  borderBottom={0}
                  borderLeft={0}
                  borderRight={0}
                  borderColor="#E5E5E5"
                  borderStyle="solid"
                  my={25}
                ></Box>
              )}
            </React.Fragment>
          ))}
        </Box>
      </Box>
      {clinicsModal.option && (
        <ClinicLocationsModal
          addItemToBasket={addItemToBasket}
          option={clinicsModal.option}
          show={clinicsModal.show}
          closeModal={hideClinicsModal}
          postCode={postCode}
          onChangePostCode={onChangePostCode}
        />
      )}
    </>
  );
}

function TestRecommendationPanel({ userTestRecommendation }) {
  if (!userTestRecommendation) return null;
  if (userTestRecommendation.rank < 6) {
    return (
      <BestMatched
        userTestRecommendation={userTestRecommendation}
        position={userTestRecommendation.rank}
      />
    );
  }
  if (userTestRecommendation.rank) {
    return (
      <InfoPanel backgroundColor="blue">
        <Box display="flex" flexDirection="column" justifyContent="space-between">
          <BestMatchedTest.TestMatchDegree
            color="white"
            userTestRecommendation={userTestRecommendation}
          />
          <BestMatchedTest.SymptomList
            color="white"
            userTestRecommendation={userTestRecommendation}
          />
        </Box>
      </InfoPanel>
    );
  }
  return null;
}

function ProductDetails({
  name,
  testType,
  numOfBiomarkersTested,
  processingTime,
  description,
  productOptionsExplanation,
  whyYouShouldTakeThisTest,
  whatYouGetWithTest,
  shippingAndProcessing,
  needHelp,
  directions,
  price,
  id,
  compositeId,
  options,
  previousPrice,
  slug,
  singleUserTestRecommendation,
  bundlePromoImage,
  bundlePromoImageAltText
}) {
  const [submitting, setSubmitting] = useState(false);
  const { setBasketOpen } = useAppState();
  const apolloClient = useApolloClient();

  function addItemToBasket(productInfo) {
    if (!submitting) {
      setSubmitting(true);
      apolloClient
        .mutate({
          mutation: ADD_ITEM_TO_BASKET,
          variables: {
            clinicLocationId: productInfo.clinicLocationId,
            compositeId: productInfo.compositeId
          },
          refetchQueries: [
            {
              query: OPEN_ORDER_QUERY
            }
          ],
          awaitRefetchQueries: true
        })
        .then(result => {
          setBasketOpen(true);
          setSubmitting(false);
        })
        .catch(error => {
          console.log("Error adding item to basket", error);
          Sentry.captureException(error);
          setSubmitting(false);
        });
    }
  }

  return (
    <>
      <HeadingExtraLarge as="h1" pt pb>
        {name}
      </HeadingExtraLarge>
      <RichText>{description}</RichText>
      <Text mt={theme.spacing.medium}>
        <SpanText fontWeight="bold">Test type</SpanText> - {testType}
      </Text>
      <Text mt="10px">
        <SpanText fontWeight="bold">Biomarkers tested</SpanText> - {numOfBiomarkersTested}
      </Text>
      <Text mt="10px" mb={singleUserTestRecommendation ? 40 : 0}>
        <SpanText fontWeight="bold">Processing time</SpanText> - {processingTime}
      </Text>
      {bundlePromoImage && (
        <Box mt={40}>
          <Image
            src={bundlePromoImage}
            alt={bundlePromoImageAltText}
            style={{
              width: "100%"
            }}
          />
        </Box>
      )}
      <TestRecommendationPanel userTestRecommendation={singleUserTestRecommendation} />
      {!!options?.length && (
        <ProductOptions
          addItemToBasket={addItemToBasket}
          options={options}
          previousPrice={previousPrice}
        />
      )}
      {!options?.length && (
        <Box display="flex" alignItems="center" pt={40}>
          <Box display="flex" alignItems="center">
            {previousPrice && (
              <Text fontFamily="gilroyBold" fontSize={44} mr={4}>
                <span
                  style={{
                    textDecoration: "line-through",
                    textDecorationThickness: "4px",
                    textDecorationColor: "#e44c4b",
                    color: "#e44c4b"
                  }}
                >
                  £{priceFormat(previousPrice)}
                </span>
              </Text>
            )}
            <Text fontFamily="gilroyBold" fontSize={44}>
              £{price}
            </Text>
          </Box>
          <SolidButton
            paddingSize="mediumNarrow"
            mr={0}
            ml={theme.spacing.medium}
            bg="green"
            borderColor="green"
            submitting={submitting}
            handleClick={() => {
              addItemToBasket({
                compositeId
              });
            }}
            data-component-name="Add to basket"
          >
            ADD TO BASKET &gt;
          </SolidButton>
        </Box>
      )}
      {productOptionsExplanation && (
        <InfoPanel>
          <RichText>{productOptionsExplanation}</RichText>
        </InfoPanel>
      )}
      {whyYouShouldTakeThisTest && (
        <InfoPanel>
          <RichText>{whyYouShouldTakeThisTest}</RichText>
        </InfoPanel>
      )}
      {
        <InfoPanel backgroundColor="#5220DD">
          <Section.Header>
            <HeadingMedium color="white" fontSize={[18, 28]}>
              Is this test right for you?
            </HeadingMedium>
          </Section.Header>
          <Section.BodyCopy color="white" mb={[40]}>
            Take our free 5 minute self assesment and discover if this test is a good match for your
            current symptoms
          </Section.BodyCopy>
          <Section.Button>
            <SolidButton
              as="a"
              bg="green"
              href={getTestProductQuestionnaireIntroUrl(slug, "symptoms")}
              borderColor="green"
              paddingSize="small"
              mr="auto"
              fontSize={12}
            >
              take free assessment now
            </SolidButton>
          </Section.Button>
        </InfoPanel>
      }
      {whatYouGetWithTest && (
        <InfoPanel>
          <RichText>{whatYouGetWithTest}</RichText>
        </InfoPanel>
      )}
      {shippingAndProcessing && (
        <InfoPanel>
          <RichText>{shippingAndProcessing}</RichText>
        </InfoPanel>
      )}
      {needHelp && (
        <InfoPanel>
          <RichText>{needHelp}</RichText>
        </InfoPanel>
      )}
      {directions && (
        <InfoPanel>
          <Text fontFamily="gilroyBold" py={20}>
            Directions
          </Text>
          <RichText>{directions}</RichText>
          <Text pt={20}>
            Please be advised there will be a £3 postage fee on supplement only orders.
          </Text>
        </InfoPanel>
      )}
    </>
  );
}

function InfoPanel({ children, backgroundColor = "white" }) {
  return (
    <Box borderRadius={5} backgroundColor={backgroundColor} mt={40} px={25} py={30}>
      {children}
    </Box>
  );
}

function HeadingAndContent({ heading, children }) {
  return (
    <Box maxWidth={600} mx="auto" mb={40}>
      <HeadingExtraLarge pb as="h2">
        {heading}
      </HeadingExtraLarge>
      <Text>{children}</Text>
    </Box>
  );
}

function List({ items }) {
  return (
    <Grid as="ul" style={{ listStyle: "disc", gridAutoRows: "min-content" }} gridRowGap="5px">
      {items.map((item, index) => {
        return (
          <Text
            as="li"
            marginCorrection={false}
            style={{ listStylePosition: "inside" }}
            key={index}
          >
            {item}
          </Text>
        );
      })}
    </Grid>
  );
}

const MAX_BIOMARKERS = 10;

function SymptomsAndMarkers({ relatedSymptoms, biomarkersTested }) {
  const hasRelatedSymptoms = !!relatedSymptoms.length;

  const chunkSize = Math.ceil(biomarkersTested.length / 3);
  const chunkedMarkers = chunks(biomarkersTested, chunkSize);

  let [biomarkers1, biomarkers2, biomarkers3] = chunkedMarkers.map(chunk => {
    if (chunk?.length > 0) {
      return chunk;
    } else {
      return [];
    }
  });

  // Pad out the theshold a bit, to avoid a situation where you click
  // "show more" and it shows you one extra line
  const isCollapsable = biomarkers1.length > MAX_BIOMARKERS + 5;
  const [collapsed, setCollapsed] = useState(true);

  if (isCollapsable && collapsed) {
    biomarkers1.splice(MAX_BIOMARKERS);
    biomarkers2.splice(MAX_BIOMARKERS);
    biomarkers3.splice(MAX_BIOMARKERS);
  }

  return (
    <Box py={theme.spacing.extraLarge} backgroundColor="partners" px={20}>
      <HeadingAndContent heading="Test details">
        Each of our tests have been carefully chosen to give you insight into specific areas of your
        health and wellness.
      </HeadingAndContent>
      <Grid
        maxWidth={1200}
        mx="auto"
        gridTemplateColumns={[null, null, hasRelatedSymptoms ? "1fr 3fr" : null]}
      >
        {hasRelatedSymptoms && (
          <Box>
            <HeadingMedium mb={20}>Related Symptoms</HeadingMedium>
            <Grid as="ul" style={{ listStyle: "disc" }} gridRowGap="5px">
              <List items={relatedSymptoms} />
            </Grid>
          </Box>
        )}
        <Box>
          <HeadingMedium mb={20}>Biomarkers tested</HeadingMedium>
          <Grid as="ul" gridTemplateColumns={[null, null, "1fr 1fr 1fr"]} gridRowGap="5px">
            {!!biomarkers1.length && <List items={biomarkers1} />}
            {!!biomarkers2.length && <List items={biomarkers2} />}
            {!!biomarkers3.length && <List items={biomarkers3} />}
          </Grid>
          {isCollapsable && (
            <Text
              mt={20}
              underline
              as="button"
              onClick={() => {
                setCollapsed(!collapsed);
              }}
            >
              {collapsed ? "Show More" : "Show Less"}
            </Text>
          )}
        </Box>
      </Grid>
    </Box>
  );
}

function VideoOrImage({ imageUrl, videoUrl }) {
  if (videoUrl) {
    return (
      <AspectRatio value={9 / 16} flex={1}>
        <iframe
          style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
          src={`https://www.youtube.com/embed/${videoUrl}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </AspectRatio>
    );
  }
  if (imageUrl) {
    return (
      <AspectRatio value={9 / 16} flex={1}>
        <BackgroundImage backgroundImage={imageUrl} width="100%" height="100%" />
      </AspectRatio>
    );
  }

  return null;
}

function HowTo({ collectingYourSampleImage, collectingYourSampleVideo, howItWorks }) {
  return (
    <Box px={20} py={theme.spacing.extraLarge} bg="white">
      <Grid
        gridTemplateColumns={[null, null, "1fr 1fr"]}
        maxWidth={1200}
        mx="auto"
        gridColumnGap={50}
      >
        <Box>
          <HeadingExtraLarge mb={30}>How it works</HeadingExtraLarge>
          <RichText>{howItWorks}</RichText>
        </Box>
        <Box display="flex" flexDirection="column">
          <HeadingExtraLarge as="h3" mb={30} mt={[20, 30, 0]}>
            Collecting your sample
          </HeadingExtraLarge>
          <VideoOrImage imageUrl={collectingYourSampleImage} videoUrl={collectingYourSampleVideo} />
        </Box>
      </Grid>
    </Box>
  );
}

function YourResultsCard({ image, heading, children }) {
  return (
    <Box
      data-component-name="Your Results Card"
      borderRadius="5px"
      overflow="hidden"
      display="flex"
      flexDirection="column"
    >
      <AspectRatio value={9 / 16} flex={0}>
        <BackgroundImage backgroundImage={image} width="100%" height="100%" />
      </AspectRatio>
      <Box bg="white" p={30} flex={1}>
        <HeadingMedium mb={20}>{heading}</HeadingMedium>
        {children}
      </Box>
    </Box>
  );
}

function SecuritySection({ left, right }) {
  return (
    <Grid gridTemplateColumns={[null, null, "1fr 1fr"]} gridColumnGap={0} gridRowGap={0}>
      {left}
      {right}
    </Grid>
  );
}

function Security() {
  const vSpacing = [40, 60, 80, 100];
  return (
    <Box px={20} bg="white" py={theme.spacing.extraLarge}>
      <Box maxWidth={1280} mx="auto">
        <SecuritySection
          left={
            <Box py={vSpacing} px={20} bg="haze">
              <Box maxWidth={400} mx="auto">
                <HeadingExtraLarge>Data security</HeadingExtraLarge>
                <Text mt={theme.spacing.medium}>
                  We take data security very seriously and we make sure you have complete control
                  over your data.
                </Text>
                <Text mt={theme.spacing.medium}>
                  You can access the raw results, request all of your data or delete your account at
                  any time. We don’t sell or share your data to third parties.
                </Text>
              </Box>
            </Box>
          }
          right={
            <Box
              bg="supplements"
              display="flex"
              alignItems="center"
              justifyContent="center"
              minHeight={300}
            >
              <Lock width="15%" />
            </Box>
          }
        />
        <Box height={30} />
        <SecuritySection
          left={
            <Box minHeight={300}>
              <BackgroundImage
                backgroundImage="/test-images/test-page/labs-image.jpg"
                alt="Testing laboratory"
                backgroundColor="haze"
                width="100%"
                height="100%"
              />
            </Box>
          }
          right={
            <Box py={vSpacing} px={20} bg="haze">
              <Box maxWidth={400} mx="auto">
                <HeadingExtraLarge>Labs you can trust</HeadingExtraLarge>
                <Text mt={theme.spacing.medium}>
                  We carefully choose our lab partners based on the highest standards of
                  accreditation and accuracy.
                </Text>
                <Text mt={theme.spacing.medium}>
                  Our team of scientists and practitioners have worked alongside our data experts to
                  ensure the recommendations and results we provide you are accurate and build a
                  complete understanding of your current health and wellbeing.
                </Text>
              </Box>
            </Box>
          }
        />
      </Box>
    </Box>
  );
}

function DiscoverCard({
  name,
  shortDescription,
  price,
  first,
  last,
  slug,
  productFamily,
  dataComponentName,
  shot1Grey,
  badge
}) {
  const history = useHistory();
  let padding = "0 10px 0 10px";
  if (first) padding = "0 10px 0 0";
  if (last) padding = "0 0 0 10px";

  const url = getTestUrl(slug, productFamily.slug);
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 645;

  return (
    <Box p={padding} display="flex" flexDirection="column" dataComponentName={dataComponentName}>
      <Box bg="haze" display="flex" flexDirection="column" flex={1} position="relative">
        {!!badge && (
          <Box position="absolute" zIndex={1}>
            {badge}
          </Box>
        )}
        <TestProductImage
          height={isMobile ? "226px" : "306px"}
          width={isMobile ? "310px" : "420px"}
        >
          <TestProductResponsiveImage
            breakpoints={{
              990: 645
            }}
            images={
              shot1Grey?.map(image => ({
                width: image.width,
                image: image.resizeUrl
              })) || []
            }
            pixelDensity={2}
            alt="product_card_image"
          />
        </TestProductImage>
        <Box flex={1} display="flex" flexDirection="column" p={20} borderTop="1px solid #fff">
          <HeadingExtraExtraSmall pb={20} as="h3">
            {name}
          </HeadingExtraExtraSmall>
          <Text>{shortDescription}</Text>
          <Box mt="auto">
            <Box display="flex" alignItems="end" justifyContent="space-between" mt={20}>
              <Text fontFamily="gilroyBold" fontSize={20} marginCorrection={false}>
                £{price}
              </Text>
              <SolidButton
                as="a"
                bg="dark"
                href={url}
                borderColor="dark"
                paddingSize="small"
                onClick={() => {
                  history.push(url);
                }}
              >
                Learn More
              </SolidButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export function DiscoverTests({ testProducts, matchedSymptomsMap = {} }) {
  return (
    <Box display="flex" justifyContent="center" width="100%" mb={theme.spacing.medium}>
      <Box display="flex" overflowX="auto" width="100%">
        {[...testProducts]
          .sort(
            (testProduct1, testProduct2) =>
              (matchedSymptomsMap[testProduct1.id]?.rank < 5
                ? matchedSymptomsMap[testProduct1.id]?.rank
                : 1000) -
              (matchedSymptomsMap[testProduct2.id]?.rank < 5
                ? matchedSymptomsMap[testProduct2.id]?.rank
                : 1000)
          )
          .map((testProduct, index) => {
            const first = index === 0;
            const last = index === testProducts.length - 1;
            return (
              <DiscoverCard
                dataComponentName={"TestProduct"}
                key={index}
                {...testProduct}
                first={first}
                last={last}
                badge={
                  matchedSymptomsMap[testProduct.id]?.rank < 5 ? (
                    <UserRecommendedTestBadge
                      bg="brand"
                      color="white"
                      rank={matchedSymptomsMap[testProduct.id]?.rank}
                    />
                  ) : null
                }
              />
            );
          })}
      </Box>
    </Box>
  );
}

function Discover({ testProducts, matchedSymptomsMap }) {
  const history = useHistory();

  return (
    <>
      <Box
        px={20}
        pb={theme.spacing.extraLarge}
        backgroundColor="white"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <HeadingAndContent heading="Discover our other tests">
          Our series of specialised tests are designed to cover the key areas of health and
          wellbeing.
        </HeadingAndContent>
        <Box display="flex" justifyContent="center" width="100%" mb={theme.spacing.medium}>
          <Box display="flex" overflowX="auto" width="100%">
            {[...testProducts]
              .sort(
                (testProduct1, testProduct2) =>
                  (matchedSymptomsMap[testProduct1.id]?.rank < 5
                    ? matchedSymptomsMap[testProduct1.id]?.rank
                    : 1000) -
                  (matchedSymptomsMap[testProduct2.id]?.rank < 5
                    ? matchedSymptomsMap[testProduct2.id]?.rank
                    : 1000)
              )
              .map((testProduct, index) => {
                const first = index === 0;
                const last = index === testProducts.length - 1;
                return (
                  <DiscoverCard
                    key={index}
                    {...testProduct}
                    first={first}
                    last={last}
                    badge={
                      matchedSymptomsMap[testProduct.id]?.rank < 5 ? (
                        <UserRecommendedTestBadge
                          bg="brand"
                          color="white"
                          rank={matchedSymptomsMap[testProduct.id]?.rank}
                        />
                      ) : null
                    }
                  />
                );
              })}
          </Box>
        </Box>
        <SolidButton
          as="a"
          bg="dark"
          borderColor="dark"
          paddingSize="medium"
          href={TESTS_URL}
          onClick={() => {
            history.push(TESTS_URL);
          }}
        >
          See All Omnos Tests &gt;
        </SolidButton>
      </Box>
    </>
  );
}

function YourResults() {
  return (
    <Box py={theme.spacing.large} px={20}>
      <HeadingAndContent heading="Your results">
        Once you've taken your test they will be interpreted and made available on your dashboard
      </HeadingAndContent>
      <Box maxWidth={1280} mx="auto">
        <Grid gridTemplateColumns={[null, null, "1fr 1fr 1fr"]}>
          <YourResultsCard heading="Results" image="/test-images/test-page/results.jpg">
            Discover and learn how each tested biomarker is influencing your body and your overall
            wellness.
          </YourResultsCard>
          <YourResultsCard
            heading="Recommendations"
            image="/test-images/test-page/recommendations.jpg"
          >
            Get personalised and actionable recommendations to help you optimise your health.
          </YourResultsCard>
          <YourResultsCard heading="Support" image="/test-images/test-page/support.jpg">
            Our team of experts are here should you have any questions about the test, the results
            or seek professional support. Please email support@omnos.me
          </YourResultsCard>
        </Grid>
      </Box>
    </Box>
  );
}

function TestProductPageContent({
  testProduct,
  testProducts,
  singleUserTestRecommendation,
  userTestRecommendations
}) {
  const name = testProduct?.name;
  useDocTitle(name);

  if (!testProduct) {
    return <Redirect to={ERROR_404_URL} />;
  }

  const {
    biomarkersTested,
    collectingYourSampleImage,
    collectingYourSampleVideo,
    directions,
    howItWorks,
    imageOne,
    metaDescription,
    metaTitle,
    needHelp,
    numOfBiomarkersTested,
    processingTime,
    productFamily,
    relatedSymptoms,
    shippingAndProcessing,
    description,
    testType,
    whatYouGetWithTest,
    productOptionsExplanation,
    whyYouShouldTakeThisTest,
    price,
    previousPrice,
    id,
    compositeId,
    slug,
    options,
    shot1White,
    shot2White,
    shot3White,
    bundlePromoImage,
    bundlePromoImageAltText
  } = testProduct;

  const images = [
    shot1White?.map(image => ({ image: image.resizeUrl, width: image.width })) || [],
    shot2White?.map(image => ({ image: image.resizeUrl, width: image.width })) || [],
    shot3White?.map(image => ({ image: image.resizeUrl, width: image.width })) || []
  ];

  return (
    <>
      <Helmet>
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription?.replace(/(<([^>]+)>)/gi, "")} />
        <meta property="og:image" content={imageOne} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <TestOverview
        images={images}
        name={name}
        productFamily={productFamily}
        testProducts={testProducts}
        testType={testType}
        numOfBiomarkersTested={numOfBiomarkersTested}
        processingTime={processingTime}
        description={description}
        productOptionsExplanation={productOptionsExplanation}
        whyYouShouldTakeThisTest={whyYouShouldTakeThisTest}
        whatYouGetWithTest={whatYouGetWithTest}
        shippingAndProcessing={shippingAndProcessing}
        needHelp={needHelp}
        directions={directions}
        price={price}
        previousPrice={previousPrice}
        id={id}
        compositeId={compositeId}
        slug={slug}
        options={options}
        singleUserTestRecommendation={singleUserTestRecommendation}
        bundlePromoImage={bundlePromoImage}
        bundlePromoImageAltText={bundlePromoImageAltText}
      />
      <SymptomsAndMarkers relatedSymptoms={relatedSymptoms} biomarkersTested={biomarkersTested} />
      <HowTo
        collectingYourSampleImage={collectingYourSampleImage}
        collectingYourSampleVideo={collectingYourSampleVideo}
        howItWorks={howItWorks}
      />
      <YourResults />
      <Security />
      <Discover
        testProducts={testProducts}
        matchedSymptomsMap={userTestRecommendations.reduce(
          (acc, cur, i) => ({
            ...acc,
            [cur.product.id]: { symptoms: cur.symptoms, rank: i }
          }),
          {}
        )}
      />
    </>
  );
}

const TEST_PRODUCT_QUERY = gql`
  query TestProduct(
    $slug: String!
    $sector: String
    $shot1WhiteDimensions: [ImageDimensionsInputType]
    $shot1GreyDimensions: [ImageDimensionsInputType]
  ) {
    testProduct(slug: $slug) {
      ...TestProductFields
      options {
        ...TestProductOptionFields
      }
      shot1White(dimensions: $shot1WhiteDimensions) {
        resizeUrl
        width
      }
      shot2White(dimensions: $shot1WhiteDimensions) {
        resizeUrl
        width
      }
      shot3White(dimensions: $shot1WhiteDimensions) {
        resizeUrl
        width
      }
    }
    testProducts(sector: $sector) {
      ...TestProductShortFields
      shot1Grey(dimensions: $shot1GreyDimensions) {
        resizeUrl
        width
      }
    }
    singleUserTestRecommendation: userTestRecommendations(productSlug: $slug) {
      ...UserTestRecommendationMinimalFields
    }
    userTestRecommendations {
      ...UserTestRecommendationMinimalFields
    }
  }
  ${TEST_PRODUCT_FIELDS}
  ${TEST_PRODUCT_SHORT_FIELDS}
  ${TEST_PRODUCT_OPTION_FIELDS}
  ${USER_TEST_RECOMMENDATION_FIELDS}
`;

function TestProductPage({
  match: {
    params: { slug }
  }
}) {
  return (
    <Page
      backgroundColor="haze"
      header={
        <TestHeader backgroundColor="purple" maxWidth={1200}>
          <Box width={[170, 180, 190, 200]} as="a" href="https://www.omnos.me" display="block">
            <Logo style={{ display: "block" }} />
          </Box>
        </TestHeader>
      }
    >
      <DataLoader
        query={TEST_PRODUCT_QUERY}
        // slug is made lower case as at some point there were some caps slugs in the DB, so links out there in the wild
        variables={{
          slug: slug.toLowerCase(),
          shot1WhiteDimensions: [
            {
              width: 360 * 2
            },
            {
              width: 950 * 2
            }
          ],
          shot1GreyDimensions: [
            {
              width: 495 * 2
            },
            {
              width: 645 * 2
            }
          ]
        }}
        render={({
          testProduct,
          testProducts,
          userTestRecommendations,
          singleUserTestRecommendation
        }) => {
          return (
            <TestProductPageContent
              testProduct={testProduct}
              testProducts={testProducts}
              singleUserTestRecommendation={singleUserTestRecommendation?.[0]}
              userTestRecommendations={userTestRecommendations}
            />
          );
        }}
      />
    </Page>
  );
}

export default TestProductPage;
