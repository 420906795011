import { useHistory } from "react-router-dom";

import { LOGIN_URL, REGISTER_KIT_URL } from "../core/urls";
import { KIT_SIGNUP_MUTATION } from "../graphql/accounts";
import useDocTitle from "../hooks/use-doc-title";
import marketingConsentField from "../tpo/MarketingConsentField";
import Box from "./Box";
import { SpacedContentBox } from "./Boxes";
import Checkbox from "./Checkbox";
import DashboardHeader from "./DashboardHeader";
import DateInput from "./DateInput";
import Form from "./Form";
import { IntroBlockHeading } from "./Headings";
import Input from "./Input";
import { InternalTextLink } from "./Links";
import Page from "./Page";
import Text from "./Text";

function RegisterKitSignupPage() {
  useDocTitle("Register test kit");
  const history = useHistory();

  return (
    <Page header={<DashboardHeader>Kit registration</DashboardHeader>}>
      <SpacedContentBox maxWidth="500px" mt={[6, 6, 6, 8]} data-component-name="Register your kit">
        <IntroBlockHeading>Register your kit</IntroBlockHeading>
        <Text pt={40}>
          Create an account to register your kit. If you already have an account{" "}
          <InternalTextLink href={`${LOGIN_URL}?next=${REGISTER_KIT_URL}`}>
            sign in.
          </InternalTextLink>
        </Text>
        <Box pt={40}>
          <Form
            mutation={KIT_SIGNUP_MUTATION}
            handleSubmitted={({ email }) => {
              history.push(REGISTER_KIT_URL, { createdUserEmail: email });
            }}
            data={[
              {
                fields: [
                  {
                    name: "firstName",
                    initialValue: "",
                    widget: {
                      component: Input,
                      props: {
                        autoComplete: "given-name",
                        label: "First name"
                      }
                    }
                  },
                  {
                    name: "lastName",
                    initialValue: "",
                    widget: {
                      component: Input,
                      props: {
                        autoComplete: "family-name",
                        label: "Last name"
                      }
                    },
                    boxProps: {
                      pt: "15px"
                    }
                  },
                  {
                    name: "dateOfBirth",
                    initialValue: "",
                    widget: {
                      component: DateInput,
                      props: {
                        label: "Date of bith"
                      }
                    },
                    boxProps: {
                      pt: "15px"
                    }
                  },
                  {
                    name: "email",
                    initialValue: "",
                    widget: {
                      component: Input,
                      props: {
                        autoComplete: "email",
                        label: "Email address"
                      }
                    },
                    boxProps: {
                      pt: "15px"
                    }
                  },
                  {
                    name: "password",
                    initialValue: "",
                    widget: {
                      component: Input,
                      props: {
                        type: "password",
                        label: "Password",
                        showEye: true
                      }
                    },
                    boxProps: {
                      pt: "15px"
                    }
                  },
                  marketingConsentField,
                  {
                    name: "confirmation",
                    initialValue: false,
                    widget: {
                      component: Checkbox,
                      props: {
                        label: (
                          <span>
                            By creating this account you are confirming that you are the person
                            providing the sample. The results for this test will be linked to this
                            account.
                          </span>
                        )
                      }
                    },
                    boxProps: {
                      pt: "25px",
                      mb: "-20px"
                    }
                  }
                ]
              }
            ]}
            formWrapperProps={{
              mb: 30
            }}
          />
        </Box>
      </SpacedContentBox>
    </Page>
  );
}

export default RegisterKitSignupPage;
