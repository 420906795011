import React from "react";

import PropTypes from "prop-types";

import useWindowSize from "../hooks/use-window-size";
import Grid from "./Grid";
import Text from "./Text";

export function repeatRule(rule, count) {
  return new Array(count).fill(rule).join(" ");
}

export function basicResizeRule(count) {
  return repeatRule("1fr", count);
}

function Table({ tableHeadings, tableRows }) {
  const windowSize = useWindowSize();

  const desktopColumns = repeatRule("auto", tableHeadings.length);

  const isMobile = windowSize.width < 960;

  if (tableRows.length === 0) {
    return <p>No data available</p>;
  }

  return (
    <Grid
      justifyContent="space-between"
      gridTemplateColumns={["1fr", "1fr", desktopColumns, desktopColumns]}
      style={{
        overflowWrap: "anywhere"
      }}
    >
      {!isMobile &&
        tableHeadings.map((tableHeading, tableHeadingIndex) => {
          return (
            <Text fontFamily="gilroyBold" key={tableHeadingIndex}>
              {tableHeading}
            </Text>
          );
        })}
      {tableRows.map((tableRow, rowIndex) => {
        return (
          <React.Fragment key={rowIndex}>
            {tableRow.map((tableCell, tableCellIndex) => {
              const mod = tableCellIndex % tableHeadings.length;
              return (
                <div key={tableCellIndex} data-component-name="Table cell">
                  {isMobile && (
                    <span
                      style={{
                        paddingTop: mod === 0 && rowIndex !== 0 ? "20px" : null,
                        display: "inline-block",
                        fontWeight: "bold",
                        marginRight: 5
                      }}
                    >
                      {tableHeadings[tableCellIndex]} {tableHeadings[tableCellIndex] ? ":" : ""}
                    </span>
                  )}
                  {tableCell}
                </div>
              );
            })}
          </React.Fragment>
        );
      })}
    </Grid>
  );
}

Table.propTypes = {
  tableHeadings: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableRows: PropTypes.arrayOf(PropTypes.array).isRequired
};

export default Table;
