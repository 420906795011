import React from "react";

import { SHIPPING_COUNTRIES_QUERY } from "../graphql/shop";
import DataLoader from "./DataLoader";
import Select from "./Select";

export default function CountrySelect({ handleChange, error, value, fieldName, showCost = false }) {
  return (
    <DataLoader
      query={SHIPPING_COUNTRIES_QUERY}
      loading={<Select placeholder="Loading" />}
      render={data => {
        const options = [...data.shippingCountries]
          .sort((a, b) => {
            let nameA = a.name.toUpperCase();
            let nameB = b.name.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          })
          .map(item => {
            return {
              value: item.name,
              label:
                item.postageCost > 0 && showCost
                  ? `${item.name} (+ £${item.postageCost.toFixed(2)})`
                  : item.name
            };
          });
        return (
          <Select
            options={options}
            handleChange={handleChange}
            error={error}
            placeholder="Country"
            value={value}
            fieldName={fieldName}
          />
        );
      }}
    ></DataLoader>
  );
}
