import PropTypes from "prop-types";

import Box from "../components/Box";
import { ReactComponent as BackButtonSVG } from "../images/tpo/arrow-back.svg";

export default function BackButton({ handleClick, hidden }) {
  return (
    <Box
      as="button"
      onClick={handleClick}
      cursor="pointer"
      opacity={hidden ? 0 : 1}
      style={{ transition: "opacity 250ms" }}
    >
      <BackButtonSVG fill="white" width={24} display="block" />
    </Box>
  );
}

BackButton.propTypes = {
  handleClick: PropTypes.func,
  hidden: PropTypes.bool
};
