import Checkbox from "../components/Checkbox";

const marketingConsentField = {
  initialValue: true,
  name: "marketingConsent",
  boxProps: {
    pt: 40
  },
  widget: {
    component: Checkbox,
    props: {
      label: (
        <span>
          By submitting this form, you indicate your consent to receiving our latest health news,
          exclusive promotions and product updates. Uncheck this box if you would not like to
          receive this information
        </span>
      ),
      containsLink: true,
      labelProps: {
        alignItems: "unset"
      }
    }
  }
};

export default marketingConsentField;
