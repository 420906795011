import React, { useState } from "react";

import { useApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import gql from "graphql-tag";
import moment from "moment";

import { theme } from "../core/theme";
import {
  RESULTS_SLUG,
  getOrderUrl,
  getResultUrlFromTest,
  getSectorUrl,
  getSupplementUrl
} from "../core/urls";
import { RESULTS_AVAILABLE, flexRow, statusFromOrderTestItem } from "../core/utils";
import { PLATFORM_USER_PROFILE_FIELDS, PLATFORM_USER_PROFILE_QUERY } from "../graphql/accounts";
import { ORDER_FIELDS, REMOVE_PAYMENT_METHOD } from "../graphql/shop";
import useDocTitle from "../hooks/use-doc-title";
import { useAuthContext } from "./AuthProvider";
import Box from "./Box";
import { SpacedContentBox } from "./Boxes";
import { SolidButton } from "./Buttons";
import DashboardHeader from "./DashboardHeader";
import DataLoader from "./DataLoader";
import Divider from "./Divider";
import { IntroBlockHeading } from "./Headings";
import { InternalTextLink } from "./Links";
import Page from "./Page";
import Stack from "./Stack";
import Text from "./Text";

export const ORDER_HISTORY_PAGE_QUERY = gql`
  query OrderHistoryPageQuery {
    orders {
      ...OrderFields
    }
    platformUserProfile {
      ...PlatformUserProfileFields
    }
  }
  ${ORDER_FIELDS}
  ${PLATFORM_USER_PROFILE_FIELDS}
`;

function TestItemStatus({ testItem }) {
  const sector = testItem.product.sector?.toLowerCase();
  const status = statusFromOrderTestItem(testItem);
  const { user } = useAuthContext();

  let registeredByOther = testItem.registeredUser && testItem.registeredUser.email !== user.email;

  if (status === RESULTS_AVAILABLE && !registeredByOther) {
    return (
      <React.Fragment>
        <InternalTextLink
          mr={20}
          href={
            sector ? getSectorUrl(sector, RESULTS_SLUG) : getResultUrlFromTest(testItem.testInput)
          }
          display="block"
        >
          <Text>{testItem.product.name}</Text>
        </InternalTextLink>
        <Text>£{testItem.product.price.toFixed(2)}</Text>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Text>
          {testItem.product.name} -{" "}
          {testItem.defunct
            ? "N/A"
            : registeredByOther
            ? `Registered by ${testItem.registeredUser.firstName} ${testItem.registeredUser.lastName}`
            : status}
        </Text>

        <Text>£{testItem.product.price.toFixed(2)}</Text>
      </React.Fragment>
    );
  }
}

function OrderHistoryPage() {
  useDocTitle("Order history");
  const apolloClient = useApolloClient();
  const [submitting, setSubmitting] = useState(false);

  function removePaymentMethod() {
    if (!submitting) {
      setSubmitting(true);
      apolloClient
        .mutate({
          mutation: REMOVE_PAYMENT_METHOD,
          // Payment method details are stored on the platform user profile
          refetchQueries: [
            {
              query: PLATFORM_USER_PROFILE_QUERY
            }
          ]
        })
        .then(result => {
          setSubmitting(false);
        })
        .catch(error => {
          console.log("Checkout mutation error");
          console.log(error);
          Sentry.captureException(error);
          setSubmitting(false);
        });
    }
  }

  return (
    <Page header={<DashboardHeader>Orders</DashboardHeader>}>
      <DataLoader
        query={ORDER_HISTORY_PAGE_QUERY}
        // Don't rely on the cache as the Stripe webhook is likely to mutate orders post checkout, rendering cached order statuses stale
        fetchPolicy="network-only"
        render={({ platformUserProfile, orders }) => {
          return (
            <Box pb={theme.spacing.large}>
              <SpacedContentBox mt={[6, 6, 6, 8]}>
                <IntroBlockHeading pb>Saved Payment card</IntroBlockHeading>
                <Box>
                  {platformUserProfile.stripeCardPaymentMethodId ? (
                    <Box {...flexRow}>
                      <Text mr={20}>
                        {platformUserProfile.stripeCardBrand.toUpperCase()} ending in{" "}
                        {platformUserProfile.stripeCardLast4}, expirey{" "}
                        {platformUserProfile.stripeCardExpMonth}/
                        {platformUserProfile.stripeCardExpYear}
                      </Text>
                      <SolidButton
                        submitting={submitting}
                        bg="red"
                        borderColor="red"
                        handleClick={() => {
                          removePaymentMethod();
                        }}
                        paddingSize="small"
                      >
                        Delete card
                      </SolidButton>
                    </Box>
                  ) : (
                    <Text>No payment card saved.</Text>
                  )}
                </Box>
              </SpacedContentBox>
              <SpacedContentBox mt={[6, 6, 6, 8]} data-component-name="Order history">
                <IntroBlockHeading pb>Order history</IntroBlockHeading>
                {orders.length > 0 ? (
                  <Stack divider={<Divider />}>
                    {orders.map((order, orderIndex) => {
                      const orderDate = moment(order.updated).format("DD/MM/YYYY");
                      return (
                        <React.Fragment key={orderIndex}>
                          <Box {...flexRow}>
                            <InternalTextLink
                              href={getOrderUrl(order.id)}
                              fontFamily="gilroyBold"
                              mr={20}
                            >
                              Order number: {order.id}
                            </InternalTextLink>
                            <Text>{orderDate}</Text>
                          </Box>
                          {order.testItems.length > 0 && (
                            <Box pt={20}>
                              <Stack
                                itemBoxProps={{
                                  ...flexRow,
                                  pt: "10px",
                                  pb: "10px"
                                }}
                              >
                                {order.testItems.map((testItem, testItemIndex) => (
                                  <TestItemStatus key={testItemIndex} testItem={testItem} />
                                ))}
                              </Stack>
                            </Box>
                          )}
                          {order.supplementItems.length > 0 && (
                            <Box pt={20}>
                              <Stack
                                itemBoxProps={{
                                  ...flexRow,
                                  pt: "10px",
                                  pb: "10px"
                                }}
                              >
                                {order.supplementItems.map(
                                  (supplementItem, supplementItemIndex) => {
                                    return (
                                      <React.Fragment key={supplementItemIndex}>
                                        <InternalTextLink
                                          mr={20}
                                          href={getSupplementUrl(supplementItem.product.slug)}
                                          display="block"
                                        >
                                          <Text>{supplementItem.product.name}</Text>
                                        </InternalTextLink>
                                        <Text>£{supplementItem.product.price.toFixed(2)}</Text>
                                      </React.Fragment>
                                    );
                                  }
                                )}
                              </Stack>
                            </Box>
                          )}
                          {order.refundSet.length > 0 && (
                            <Box pt={20}>
                              <Stack
                                itemBoxProps={{
                                  ...flexRow,
                                  pt: "10px",
                                  pb: "10px"
                                }}
                              >
                                {order.refundSet.map((refund, refundIndex) => {
                                  return (
                                    <React.Fragment key={refundIndex}>
                                      <Text mr={20}>Refund</Text>
                                      <Text>- £{refund.amount.toFixed(2)}</Text>
                                    </React.Fragment>
                                  );
                                })}
                              </Stack>
                            </Box>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </Stack>
                ) : (
                  <p>No previous purchases</p>
                )}
              </SpacedContentBox>
            </Box>
          );
        }}
      />
    </Page>
  );
}

export default OrderHistoryPage;
