import {
  PRIVACY_URL,
  SHIPPING_AND_RETURNS_URL,
  TERMS_OF_SERVICE_URL,
  TERMS_OF_USE_URL,
  SUPPLEMENTS_URL,
  ORDERS_URL,
  REGISTER_KIT_URL,
  DASHBOARD_HOME_URL,
  PARTNERS_URL,
  SETTINGS_URL,
  LOGGED_OUT_URL,
  SIGNUP_URL,
  RECOMMENDATIONS_URL,
  getThemeUrl,
  getQuestionnaireIntroUrl,
  getTestResultsUrl,
  LOGIN_URL,
  FOODS_URL,
  TESTS_URL
} from "../core/urls";

function getShopItem() {
  return {
    name: "Shop",
    children: [
      {
        name: "Omnos Tests",
        url: TESTS_URL
      },
      {
        name: "Supplements",
        url: SUPPLEMENTS_URL
      },
      {
        name: "My Orders",
        url: ORDERS_URL
      }
    ].filter(item => {
      return !!item;
    })
  };
}

export function getLoggedInMenuItems(
  showNoDataDashboardView,
  hasRecommendations,
  userTests,
  hasExamples
) {
  return [
    {
      name: "Dashboard",
      url: DASHBOARD_HOME_URL
    },
    {
      name: "Self Assessment",
      url: getQuestionnaireIntroUrl("symptoms")
    },
    !!userTests?.length
      ? {
          name: "Test Results",
          url: getTestResultsUrl(userTests[0].id)
        }
      : null,
    !showNoDataDashboardView
      ? {
          name: "Wellness Scores",
          url: getThemeUrl("health")
        }
      : null,
    hasRecommendations
      ? {
          name: "Recommendations",
          url: RECOMMENDATIONS_URL
        }
      : null,
    hasExamples
      ? {
          name: "Food List",
          url: FOODS_URL
        }
      : null,
    {
      name: "Register Kit",
      url: REGISTER_KIT_URL
    },
    getShopItem(),
    {
      name: "Omnos Partners",
      url: PARTNERS_URL
    }
  ].filter(item => {
    return !!item;
  });
}

export function getLoggedOutMenuItems() {
  return [
    getShopItem(),
    {
      name: "Sign Up",
      url: SIGNUP_URL
    }
  ];
}

export function getSecondaryItems(user) {
  return [
    {
      name: "Settings",
      url: SETTINGS_URL
    },
    {
      name: "My Orders",
      url: ORDERS_URL
    },
    {
      name: "Privacy policy",
      url: PRIVACY_URL
    },
    {
      name: "Terms of service",
      url: TERMS_OF_SERVICE_URL
    },
    {
      name: "Terms of use",
      url: TERMS_OF_USE_URL
    },
    {
      name: "Shipping and returns",
      url: SHIPPING_AND_RETURNS_URL
    },
    {
      name: "Help centre",
      url: "https://faq.omnos.me/knowledge"
    },
    !!user
      ? {
          name: "Log out",
          url: LOGGED_OUT_URL
        }
      : {
          name: "Log in",
          url: LOGIN_URL
        }
  ];
}
