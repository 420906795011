import React from "react";

import {
  PRIVACY_URL,
  SHIPPING_AND_RETURNS_URL,
  TERMS_OF_SERVICE_URL,
  TERMS_OF_USE_URL
} from "../core/urls";
import { ReactComponent as Logo } from "../images/logo.svg";
import Box from "./Box";
import { InternalTextLink } from "./Links";
import Text from "./Text";

function Footer() {
  const year = new Date().getFullYear();
  const directions = ["column", "column", "row", null];
  const aligns = ["flex-start", "flex-start", "center", null];
  const px = "15px";
  const py = "15px";
  return (
    <Box as="footer" backgroundColor="purple">
      <Box px={px} py={py} display="flex" alignItems={aligns} flexDirection={directions}>
        <Box flexShrink={0} px={px} py={py}>
          <Logo style={{ display: "block" }} width={150} />
        </Box>
        <InternalTextLink
          fontSize={14}
          px={px}
          py={py}
          color="white"
          href={TERMS_OF_SERVICE_URL}
          display="block"
        >
          <Text>Terms Of Service</Text>
        </InternalTextLink>
        <InternalTextLink
          fontSize={14}
          px={px}
          py={py}
          color="white"
          href={TERMS_OF_USE_URL}
          display="block"
        >
          <Text>Terms Of Use</Text>
        </InternalTextLink>
        <InternalTextLink
          fontSize={14}
          px={px}
          py={py}
          color="white"
          href={SHIPPING_AND_RETURNS_URL}
          display="block"
        >
          <Text>Shipping Policy</Text>
        </InternalTextLink>
        <InternalTextLink
          fontSize={14}
          px={px}
          py={py}
          color="white"
          href={PRIVACY_URL}
          display="block"
        >
          <Text>Privacy Policy</Text>
        </InternalTextLink>
        <Text fontSize={14} px={px} py={py} color="white">
          ©{year}
        </Text>
      </Box>
    </Box>
  );
}

export default Footer;
