import styled from "styled-components";
import { grid, layout, space } from "styled-system";

const Grid = styled.div`
  ${grid}
  ${layout}
  ${space}
  ${props => {
    return `align-items: ${props.alignItems}`;
  }}
`;

Grid.defaultProps = {
  display: "grid",
  gridRowGap: 20,
  gridColumnGap: 20
};

export default Grid;
