import { createContext, useCallback, useContext, useMemo, useState } from "react";

import classNames from "classnames";

import ArrowCircle from "../components/ArrowCircle";
import Box from "../components/Box";
import ChevronComponent from "./Chevron";
import "./accordionStyles.css";

export const AccordionContext = createContext();
export const AccordionItemContext = createContext();

export default function Accordion({
  children,
  variant,
  chevronVariant,
  multiple,
  size,
  nested,
  ...rest
}) {
  const [openState, setOpenState] = useState(new Set([]));

  const open = useCallback(
    id => {
      if (!multiple) {
        setOpenState(new Set([id]));
      } else {
        setOpenState(new Set([...openState, id]));
      }
    },
    [openState, setOpenState, multiple]
  );

  const close = useCallback(
    id => {
      setOpenState(new Set([...openState].filter(_id => _id !== id)));
    },
    [openState, setOpenState]
  );

  const api = useMemo(
    () => ({
      chevronVariant,
      variant,
      openState,
      open,
      close,
      size,
      nested
    }),
    [chevronVariant, variant, open, close, openState, size, nested]
  );

  let props = {
    display: "flex",
    flexDirection: "column"
  };
  props = {
    ...props,
    ...rest
  };

  return (
    <AccordionContext.Provider value={api}>
      <Box className={classNames("accordion", `${variant}Variant`)} {...props}>
        {children}
      </Box>
    </AccordionContext.Provider>
  );
}

function Item({ children, value, ...rest }) {
  const props = { ...rest };

  const api = useMemo(
    () => ({
      value
    }),
    [value]
  );

  return (
    <AccordionItemContext.Provider value={api}>
      <Box className="accordionItem" {...props}>
        {children}
      </Box>
    </AccordionItemContext.Provider>
  );
}

function Control({ children, chevronIcon: chevronIconOverride, ...props }) {
  const { chevronVariant, close, open, openState, size } = useContext(AccordionContext);
  const { value: id } = useContext(AccordionItemContext);

  const chevronDir = openState.has(id) ? "bottom" : "top";

  let chevronIcon = <ChevronComponent direction={chevronDir} width={6} fill="dark" />;
  if (chevronVariant === "circle") {
    chevronIcon = <ArrowCircle direction={chevronDir} />;
  }

  if (openState.has(id)) {
    props.borderBottomLeftRadius = 0;
    props.borderBottomRightRadius = 0;
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      onClick={() => (openState.has(id) ? close(id) : open(id))}
      cursor="pointer"
      className={classNames("accordionControl", "noSelect", openState.has(id) ? "open" : "closed")}
      padding={size}
      {...props}
    >
      <Box>{children}</Box>
      {chevronIconOverride || chevronIcon}
    </Box>
  );
}

Control.defaultProps = {
  borderRadius: 5,
  bg: "white"
};

function Panel({ children, ...rest }) {
  const { openState, size } = useContext(AccordionContext);
  const { value: id } = useContext(AccordionItemContext);

  if (!openState.has(id)) return null;

  let props = {
    padding: size,
    paddingTop: 0
  };

  props = {
    ...props,
    ...rest
  };

  return <Box {...props}>{children}</Box>;
}

Panel.defaultProps = {
  bg: "white",
  borderRadius: 5,
  borderTopRightRadius: 0,
  borderTopLeftRadius: 0
};

Accordion.Item = Item;
Accordion.Control = Control;
Accordion.Panel = Panel;

// export function Example() {
//   return (
//     <Box bg="haze" mt={200} padding={200}>
//       <Accordion variant="separated" chevronVariant="circle" multiple size={20}>
//         <Accordion.Item bg="white" value="relatedResults">
//           <Accordion.Control>
//             <Text marginCorrection={false} fontFamily="gilroyBold" fontSize={18}>
//               Related Results
//             </Text>
//           </Accordion.Control>
//           <Accordion.Panel>Related Results Panel</Accordion.Panel>
//         </Accordion.Item>
//         <Accordion.Item bg="white" value="relatedBiomarkers">
//           <Accordion.Control>
//             <Text marginCorrection={false} fontFamily="gilroyBold" fontSize={18}>
//               Related Biomarkers
//             </Text>
//           </Accordion.Control>
//           <Accordion.Panel padding={0}>
//             <Accordion nested variant="default" chevronVariant="default" multiple size={15}>
//               <Accordion.Item value="tested">
//                 <Accordion.Control>
//                   <Text marginCorrection={false} fontFamily="gilroyBold" fontSize={15}>
//                     Tested
//                   </Text>
//                 </Accordion.Control>
//                 <Accordion.Panel>tested stuff here</Accordion.Panel>
//               </Accordion.Item>
//               <Accordion.Item value="notTested">
//                 <Accordion.Control>
//                   <Text marginCorrection={false} fontFamily="gilroyBold" fontSize={15}>
//                     Not Tested
//                   </Text>
//                 </Accordion.Control>
//                 <Accordion.Panel>not tested stuff here</Accordion.Panel>
//               </Accordion.Item>
//             </Accordion>
//           </Accordion.Panel>
//         </Accordion.Item>
//         <Accordion.Item bg="white" value="relatedGenes">
//           <Accordion.Control>
//             <Text marginCorrection={false} fontFamily="gilroyBold" fontSize={18}>
//               Related Genes
//             </Text>
//           </Accordion.Control>
//           <Accordion.Panel>Panel</Accordion.Panel>
//         </Accordion.Item>
//         <Accordion.Item bg="white" value="relatedSymptoms">
//           <Accordion.Control>
//             <Text marginCorrection={false} fontFamily="gilroyBold" fontSize={18}>
//               Related Symptoms
//             </Text>
//           </Accordion.Control>
//           <Accordion.Panel>Panel</Accordion.Panel>
//         </Accordion.Item>
//         <Accordion.Item bg="white" value="relatedResearch">
//           <Accordion.Control>
//             <Text marginCorrection={false} fontFamily="gilroyBold" fontSize={18}>
//               Related Research
//             </Text>
//           </Accordion.Control>
//           <Accordion.Panel>Panel</Accordion.Panel>
//         </Accordion.Item>
//       </Accordion>
//     </Box>
//   );
// }
