import { useQuery } from "@apollo/client";
import Box from "components/Box";
import { DiscreteAxis, GradientAxis, SegmentedAxis } from "components/cot/AxisCharts";
import { GraphTemplate } from "components/cot/GraphTemplate";
import { LineGraph } from "components/cot/LineGraph";
import { USER_DATAPOINTS_QUERY } from "graphql/cot";
import { useDiscreteGraph } from "hooks/cot/useDiscreteGraph";
import { useGradientGraph } from "hooks/cot/useGradientGraph";
import { useSegmentedGraph } from "hooks/cot/useSegmentedGraph";
import "styles/cot/tooltip.css";

export function GradientGraph({ chartConfiguration, userDatapoints }) {
  const config = useGradientGraph({
    chartConfiguration,
    userDatapoints
  });

  if (!config) return null;

  return (
    <GraphTemplate
      chart={
        <LineGraph
          fullRange={config.fullRange}
          points={config.points}
          tooltips={config.tooltips}
          colors={config.colors}
        />
      }
      yAxisWidth={30}
      yAxis={
        <Box position="absolute" height={290} top={50}>
          <GradientAxis
            background={config.background}
            labels={config.fullRange.map(point => point.label)}
          />
        </Box>
      }
    />
  );
}
export function DiscreteGraph({ chartConfiguration, userDatapoints }) {
  const config = useDiscreteGraph({
    chartConfiguration,
    userDatapoints
  });

  if (!config) return null;

  return (
    <GraphTemplate
      chart={
        <LineGraph
          fullRange={config.fullRange}
          points={config.points}
          tooltips={config.tooltips}
          colors={config.colors}
        />
      }
      yAxisWidth={35}
      yAxis={
        <Box
          position="absolute"
          width={290}
          transform="scale(1, -1) rotate(-270deg)"
          top={180}
          left={-120}
        >
          <DiscreteAxis chartConfiguration={chartConfiguration} />
        </Box>
      }
    />
  );
}
export function SegmentedGraph({ chartConfiguration, userDatapoints }) {
  const config = useSegmentedGraph({
    chartConfiguration,
    userDatapoints
  });

  if (!config) return null;

  return (
    <GraphTemplate
      chart={
        <LineGraph
          fullRange={config.fullRange}
          points={config.points}
          tooltips={config.tooltips}
          colors={config.colors}
        />
      }
      yAxisWidth={35}
      yAxis={
        <Box position="absolute" width={290} transform="rotate(-90deg)" top={180} left={-120}>
          <SegmentedAxis chartConfiguration={chartConfiguration} />
        </Box>
      }
    />
  );
}

export default function DatapointGraph({
  userDatapoint: {
    datapoint: { id: datapointId },
    chartConfiguration
  }
}) {
  const { data } = useQuery(USER_DATAPOINTS_QUERY, {
    variables: {
      datapointId,
      cot: true
    },
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache"
    // if you involve the cache you'll trigger
    // refetches for the list query
  });

  if (!data) return null;

  const userDatapoints = data?.userDatapoints;

  if (chartConfiguration.chartType === "GRADIENT_CHART") {
    return (
      <GradientGraph chartConfiguration={chartConfiguration} userDatapoints={userDatapoints} />
    );
  } else if (chartConfiguration.chartType === "DISCRETE_CHART") {
    return (
      <DiscreteGraph chartConfiguration={chartConfiguration} userDatapoints={userDatapoints} />
    );
  } else if (chartConfiguration.chartType === "SEGMENTED_CHART") {
    return (
      <SegmentedGraph chartConfiguration={chartConfiguration} userDatapoints={userDatapoints} />
    );
  }

  return null;
}
