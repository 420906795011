import { ToBox } from "../components/Box";

const NAV_BUTTON_SIZES = [55, 60, 65, 70];

export default function NavButton(props) {
  return (
    <ToBox
      borderRadius="50%"
      as="button"
      size={NAV_BUTTON_SIZES}
      onClick={props.handleClick}
      borderWidth="2px"
      borderColor={props.borderColor}
      borderStyle="solid"
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      {props.children}
    </ToBox>
  );
}

NavButton.defaultProps = {
  bg: "black",
  borderColor: "black"
};
