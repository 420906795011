import { useHistory } from "react-router-dom";

import Box from "../components/Box";
import { SpacedContentBox } from "../components/Boxes";
import { SolidButton } from "../components/Buttons";
import { IntroBlockContent } from "../components/IntroBlock";
import Page from "../components/Page";
import Text from "../components/Text";
import useDocTitle from "../hooks/use-doc-title";
import { ReactComponent as Logo } from "../images/logo.svg";
import { ReactComponent as RegenerusLogo } from "../images/regenerus-logo.svg";
import Jumbotron from "./Jumbotron";

function ExternalKitRegistrationComplete() {
  useDocTitle("Register test kit");
  const history = useHistory();

  return (
    <Page
      addNavBarHeight={false}
      header={
        <Jumbotron bg="blue" flexDirection="row" justifyContent="unset" height="auto">
          <Box
            height="100%"
            display="flex"
            flexDirection={["column", "row"]}
            alignItems="center"
            py={50}
            gap={[20, null]}
          >
            <Box mr={[null, "auto"]} width={[170, 180, 190, 200]}>
              <Logo />
            </Box>
            <Box ml={[null, "auto"]}>
              <Text color="white" fontFamily="gilroyRegular" fontSize={18} mb={20}>
                Supplied by
              </Text>
              <RegenerusLogo />
            </Box>
          </Box>
        </Jumbotron>
      }
    >
      <SpacedContentBox maxWidth="500px" mt={[6, 6, 6, 8]} data-component-name="Register your kit">
        <IntroBlockContent
          heading="Complete"
          text="Congratulations! Your test is now registered and will be linked to your account. Please follow the instructions on how to take the test, post and return to us. You will be notified via email when your results are ready to be viewed."
        />
        <SolidButton
          mt={40}
          maxWidth={300}
          width="100%"
          mx="auto"
          handleClick={() => {
            history.push("/external-kit-registration");
          }}
          data-component-name="Register another button"
        >
          Register another test
        </SolidButton>
      </SpacedContentBox>
    </Page>
  );
}

export default ExternalKitRegistrationComplete;
