import React from "react";

import { useHistory, useLocation } from "react-router-dom";

import { LOGIN_URL, SIGNUP_DONE_URL } from "../core/urls";
import { SIGNUP_MUTATION } from "../graphql/accounts";
import useDocTitle from "../hooks/use-doc-title";
import marketingConsentField from "../tpo/MarketingConsentField";
import AccountPage from "./AccountPage";
import Box from "./Box";
import Form from "./Form";
import Input from "./Input";
import { AuthAction } from "./LoginPage";
import Text from "./Text";

function SignupPage() {
  useDocTitle("Signup");

  const history = useHistory();
  const location = useLocation();

  return (
    <AccountPage heading="Sign up">
      <Text pt={40}>
        We're looking forward to having you on the platform. Simply sign up with your email and a
        strong password below to create an account and start your free self assessment.
      </Text>
      <Box pt={40}>
        <Form
          mutation={SIGNUP_MUTATION}
          handleSubmitted={result => {
            history.push(SIGNUP_DONE_URL, { createdUserEmail: result.email });
          }}
          data={[
            {
              fields: [
                {
                  name: "firstName",
                  initialValue: location.state?.firstName || "",
                  widget: {
                    component: Input,
                    props: {
                      autoComplete: "given-name",
                      label: "First name"
                    }
                  }
                },
                {
                  name: "lastName",
                  initialValue: location.state?.lastName || "",
                  widget: {
                    component: Input,
                    props: {
                      autoComplete: "family-name",
                      label: "Last name"
                    }
                  },
                  boxProps: {
                    pt: "15px"
                  }
                },
                {
                  name: "email",
                  initialValue: location.state?.email || "",
                  widget: {
                    component: Input,
                    props: {
                      disabled: location.state?.email !== undefined,
                      autoComplete: "email",
                      label: "Email address"
                    }
                  },
                  boxProps: {
                    pt: "15px"
                  }
                },
                {
                  name: "password",
                  initialValue: "",
                  widget: {
                    component: Input,
                    props: {
                      type: "password",
                      label: "Password",
                      showEye: true
                    }
                  },
                  boxProps: {
                    pt: "15px"
                  }
                },
                marketingConsentField
              ]
            }
          ]}
          formWrapperProps={{
            mb: 30
          }}
        />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <AuthAction url={LOGIN_URL}>Already have an account?</AuthAction>
      </Box>
    </AccountPage>
  );
}

export default SignupPage;
