import { useHistory } from "react-router-dom";

import PropTypes from "prop-types";

import Box from "../components/Box";
import { BaseInternalTextLink } from "../components/Links";
import RichText from "../components/RichText";
import Text, { SpanText } from "../components/Text";
import { theme } from "../core/theme";
import { getTestProductQuestionnaireIntroUrl, getTestUrl } from "../core/urls";
import { priceFormat } from "../core/utils";
import { SolidButton, ButtonTab, CircleButton } from "./Buttons";
import { HeadingMediumLarge } from "./Headings";
import TestProductResponsiveImage, { TestProductImage } from "./TestProductResponsiveImage";
import Tooltip from "./Tooltip";

export function StackedTestProductCard({
  name,
  price,
  overview,
  testType,
  numOfBiomarkersTested,
  productFamily,
  slug,
  previousPrice,
  shot1Purple,
  badge,
  matchedSymptoms,
  stacked,
  areasOfInterest
}) {
  const history = useHistory();

  const testUrl = getTestUrl(slug, productFamily.slug);

  return (
    <Box
      borderRadius="5px"
      data-component-name="Test product card"
      display="flex"
      flexDirection={stacked ? "column" : "row"}
      overflow="hidden"
      onClick={e => {
        if (!e.nestedClick) {
          history.push(testUrl);
        }
      }}
      cursor="pointer"
    >
      <Box position="relative" width={stacked ? "100%" : "40%"}>
        {!!badge && (
          <Box position="absolute" zIndex={1}>
            {badge}
          </Box>
        )}
        <TestProductImage background="#5220DD" height={stacked ? "300px" : "100%"}>
          <TestProductResponsiveImage
            images={
              shot1Purple?.map(image => ({
                width: image.width,
                image: image.resizeUrl
              })) || []
            }
            breakpoints={{
              1000: 500
            }}
            pixelDensity={2}
          />
        </TestProductImage>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        backgroundColor="white"
        p={[4, 4, 5, 6]}
        flexGrow={1}
        width={stacked ? "100%" : "60%"}
      >
        <HeadingMediumLarge as="h2" fontFamily="gilroyBold" color="dark" mb={30}>
          <BaseInternalTextLink href={testUrl}>{name}</BaseInternalTextLink>
        </HeadingMediumLarge>
        <Box mb={30}>
          <RichText>{overview}</RichText>
        </Box>
        <Text mb="10px">
          <SpanText fontWeight={600}>Test type</SpanText> - {testType}
        </Text>
        <Text mb={matchedSymptoms.length ? "10px" : 30}>
          <SpanText fontWeight={600}>Biomarkers tested</SpanText> - {numOfBiomarkersTested}
        </Text>
        {!!matchedSymptoms.length && (
          <Text mb={30}>
            <SpanText fontWeight={600}>Top 5 matched symptoms</SpanText> -{" "}
            {matchedSymptoms.join(", ")}
          </Text>
        )}
        <Box
          display="flex"
          flexDirection={stacked ? "column" : "row"}
          alignItems={stacked ? "start" : "center"}
        >
          <Box display="flex" alignItems="center">
            {previousPrice && (
              <Text fontFamily="dinBold" fontSize={44} mr={2}>
                <span
                  style={{
                    textDecoration: "line-through",
                    textDecorationThickness: "4px",
                    textDecorationColor: "#e44c4b",
                    color: "#e44c4b"
                  }}
                >
                  £{priceFormat(previousPrice)}
                </span>
              </Text>
            )}
            <Text fontFamily="dinBold" fontSize={44}>
              £{price}
            </Text>
          </Box>
          <SolidButton
            padding="15px 30px"
            bg="green"
            borderColor="green"
            ml={stacked ? 0 : theme.spacing.medium}
            mt={stacked ? 20 : 0}
            handleClick={e => {
              e.nestedClick = true;
              history.push(testUrl);
            }}
          >
            Learn More &gt;
          </SolidButton>
        </Box>
        {!!areasOfInterest?.length && (
          <Box display="flex" flexDirection="column" flexGrow={1} pt={30}>
            <Box display="flex" gap={10} flexWrap="wrap" alignItems="flex-start" mt="auto">
              {areasOfInterest?.map(areasOfInterest => (
                <SolidButton
                  key={areasOfInterest}
                  {...ButtonTab.defaultProps}
                  color="dark"
                  bg="haze"
                  padding="10px 20px"
                  fontSize="10px"
                >
                  {areasOfInterest}
                </SolidButton>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

StackedTestProductCard.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  promoImage: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  overview: PropTypes.string.isRequired,
  testType: PropTypes.string.isRequired,
  numOfBiomarkersTested: PropTypes.number.isRequired,
  productFamily: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired
  })
};

export function GridTestProductCard({
  name,
  price,
  productFamily,
  slug,
  previousPrice,
  shot1Purple,
  badge,
  stacked,
  areasOfInterest
}) {
  const history = useHistory();

  const testUrl = getTestUrl(slug, productFamily.slug);

  return (
    <Box
      borderRadius="5px"
      data-component-name="Test product card"
      display="flex"
      flexDirection={stacked ? "column" : "row"}
      overflow="hidden"
      onClick={e => {
        if (!e.nestedClick) {
          history.push(testUrl);
        }
      }}
      cursor="pointer"
    >
      <Box position="relative" width={stacked ? "100%" : "40%"}>
        {!!badge && (
          <Box position="absolute" zIndex={1}>
            {badge}
          </Box>
        )}
        <TestProductImage background="#5220DD" height={stacked ? "300px" : "100%"}>
          <TestProductResponsiveImage
            images={
              shot1Purple?.map(image => ({
                width: image.width,
                image: image.resizeUrl
              })) || []
            }
            breakpoints={{
              1000: 500
            }}
            pixelDensity={2}
          />
        </TestProductImage>
      </Box>
      <Box
        display="flex"
        flexGrow={1}
        flexDirection="column"
        backgroundColor="white"
        px={[20]}
        pb={[20, 20, 30, 40]}
        pt={[15, 15, 15, 30]}
        width={stacked ? "100%" : "60%"}
        height="100%"
      >
        <Box
          display="flex"
          flexDirection={stacked ? "column" : "row"}
          alignItems={stacked ? "start" : "center"}
        >
          <HeadingMediumLarge
            as="h2"
            fontFamily="gilroyBold"
            color="dark"
            fontSize={18}
            pb={2}
            mb={1}
          >
            <BaseInternalTextLink href={testUrl}>{name}</BaseInternalTextLink>
          </HeadingMediumLarge>
          <Box display="flex" alignItems="center" mb={1}>
            {previousPrice && (
              <Text fontFamily="dinBold" fontSize={44} mr={2}>
                <span
                  style={{
                    textDecoration: "line-through",
                    textDecorationThickness: "4px",
                    textDecorationColor: "#e44c4b",
                    color: "#e44c4b"
                  }}
                >
                  £{priceFormat(previousPrice)}
                </span>
              </Text>
            )}
            <Text fontFamily="dinBold" fontSize={44}>
              £{price}
            </Text>
          </Box>
          <Box display="flex" gap={10} pt={40} pb={2}>
            <SolidButton
              fontSize={12}
              padding="5px 20px"
              handleClick={e => {
                e.nestedClick = true;
                history.push(getTestProductQuestionnaireIntroUrl(slug, "symptoms"));
              }}
            >
              Is this test right for you?
            </SolidButton>
            {
              <Tooltip
                content={
                  <Box bg="haze" maxWidth={130} p={2} pb={20} borderRadius={5}>
                    <Text fontSize={12}>
                      Assess how well your symptoms align with this test and gain valuable insights
                    </Text>
                  </Box>
                }
              >
                <CircleButton bg="dark" color="white" fontSize="10px" display={["none", "flex"]}>
                  ?
                </CircleButton>
              </Tooltip>
            }
          </Box>
          <SolidButton
            padding="15px 30px"
            bg="green"
            borderColor="green"
            ml={stacked ? 0 : theme.spacing.medium}
            handleClick={e => {
              e.nestedClick = true;
              history.push(testUrl);
            }}
          >
            Learn More &gt;
          </SolidButton>
        </Box>
        {!!areasOfInterest?.length && (
          <Box display="flex" flexDirection="column" flexGrow={1} pt={30}>
            <Box display="flex" gap={10} flexWrap="wrap" alignItems="flex-start" mt="auto">
              {areasOfInterest?.map(areasOfInterest => (
                <SolidButton
                  key={areasOfInterest}
                  {...ButtonTab.defaultProps}
                  color="dark"
                  bg="haze"
                  padding="8px 15px"
                  fontSize="10px"
                  lineHeight="10px"
                >
                  {areasOfInterest}
                </SolidButton>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

GridTestProductCard.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  promoImage: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  overview: PropTypes.string.isRequired,
  testType: PropTypes.string.isRequired,
  numOfBiomarkersTested: PropTypes.number.isRequired,
  productFamily: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired
  })
};
