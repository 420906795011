import { createContext } from "react";

import Box from "../components/Box";
import { ExternalTextLink } from "../components/Links";
import { ChartConfigurationContext } from "./ChartCards";
import {
  DiscreteChartCardNEW,
  SegementedProgressChartCardNEW,
  UnknownChartCardNew
} from "./ChartCardsNew";

export const CardListContext = createContext();

export function ResultCharts({ children, result }) {
  if (result.chartConfiguration?.chartType === "DISCRETE_CHART") {
    return (
      <ChartConfigurationContext.Provider value={result.chartConfiguration}>
        <DiscreteChartCardNEW result={result}>{children}</DiscreteChartCardNEW>
      </ChartConfigurationContext.Provider>
    );
  } else if (result.chartConfiguration?.chartType === "SEGMENTED_PROGRESS_CHART") {
    return (
      <ChartConfigurationContext.Provider value={result.chartConfiguration}>
        <SegementedProgressChartCardNEW result={result}>{children}</SegementedProgressChartCardNEW>
      </ChartConfigurationContext.Provider>
    );
  }

  return (
    <ChartConfigurationContext.Provider value={result.chartConfiguration}>
      <UnknownChartCardNew result={result}>{children}</UnknownChartCardNew>
    </ChartConfigurationContext.Provider>
  );
}

export function Explanation({ display }) {
  return (
    <Box display={display} fontSize={14} textAlign="center">
      Your Omnos results are based on optimal biomarker ranges which differ from standard lab ranges{" "}
      {
        <ExternalTextLink href="https://omnos.me/articles/understanding-your-omnos-results">
          learn more
        </ExternalTextLink>
      }
    </Box>
  );
}
