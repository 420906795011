import React from "react";

import { SUPPLEMENT } from "../core/constants";
import { SUPPLEMENT_QUERY } from "../graphql/shop";
import useDocTitle from "../hooks/use-doc-title";
import { RecommendationHeader } from "../tpo/RecommendationPage";
import AspectRatio from "./AspectRatio";
import { useAuthContext } from "./AuthProvider";
import BackgroundImage from "./BackgroundImage";
import Box from "./Box";
import { StripBox } from "./Boxes";
import DataLoader from "./DataLoader";
import Page from "./Page";
import RecommendedSupplement from "./RecommendedSupplement";
import SupplementCategories from "./SupplementCategories";
import SupplementProductDetails from "./SupplementProductDetails";

function SupplementPageContent({
  clientId,
  slug,
  supplement: {
    compositeId,
    name,
    productImage,
    description,
    directions,
    price,
    id,
    status,
    outOfStock,
    supplementCategories
  }
}) {
  useDocTitle(name);
  const { user } = useAuthContext();

  return (
    <Page backgroundColor="haze" header={<RecommendationHeader name={name} />}>
      <StripBox location="bottom" stripColor="supplements" px={20}>
        <Box
          backgroundColor="white"
          maxWidth={1200}
          p={20}
          mx="auto"
          display="flex"
          justifyContent="center"
        >
          <Box width="100%" maxWidth={600} mx="auto">
            <AspectRatio value={1}>
              <BackgroundImage
                backgroundImage={productImage}
                alt={name}
                width="100%"
                height="100%"
                backgroundColor="white"
              />
            </AspectRatio>
          </Box>
        </Box>
      </StripBox>
      <Box bg="supplements">
        <SupplementProductDetails
          backgroundColor="supplements"
          description={description}
          directions={directions}
          price={price}
          id={id}
          status={status}
          productType={SUPPLEMENT}
          outOfStock={outOfStock}
          compositeId={compositeId}
        />
      </Box>
      {user && <RecommendedSupplement clientId={clientId} slug={slug} />}
      <SupplementCategories categories={supplementCategories} />
    </Page>
  );
}

function SupplementPage({
  match: {
    params: { clientId, slug }
  }
}) {
  return (
    <DataLoader
      query={SUPPLEMENT_QUERY}
      variables={{ slug }}
      render={({ supplement }) => {
        return <SupplementPageContent clientId={clientId} slug={slug} supplement={supplement} />;
      }}
    />
  );
}

export default SupplementPage;
