import { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import { useApolloClient, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import gql from "graphql-tag";

import { useAppState } from "../components/AppStateProvider";
import { useAuthContext } from "../components/AuthProvider";
import Box from "../components/Box";
import Divider from "../components/Divider";
import Text from "../components/Text";
import { MAIN_MENU_Z_INDEX } from "../core/constants";
import { LOGGED_OUT_URL } from "../core/urls";
import { LOG_OUT_MUTATION } from "../graphql/accounts";
import { TEST_PRODUCT_FAMILY_FIELDS } from "../graphql/shop";
import { USER_TEST_FIELDS_TRUNCATED } from "../graphql/tpo/results/types";
import usePollingRefresh from "../hooks/tpo/usePollingRefresh";
import { ReactComponent as ChevronSVG } from "../images/tpo/chevron-right.svg";
import { shouldShowNoDataDashboardView } from "./Dashboard";
import { getLoggedInMenuItems, getLoggedOutMenuItems, getSecondaryItems } from "./MenuItems";
import { USER_SUBSECTOR_FIELDS } from "./Recommendations";

const USER_SNAPSHOT_STATUS_QUERY = gql`
  query UserSnapshotStatusQuery {
    user {
      id
      snapshotStatus
      snapshotQueued
    }
  }
`;

export const TEST_PRODUCT_FAMILIES_QUERY = gql`
  query TestProductFamilies {
    testProductFamilies {
      ...TestProductFamilyFields
    }
  }
  ${TEST_PRODUCT_FAMILY_FIELDS}
`;

export const USER_DATA_QUERY = gql`
  query UserDataQuery(
    $themeSlug: String!
    $excludeEmptyRecommendations: Boolean
    $includeThingsToDoCount: Boolean
    $includeThingsToAvoidCount: Boolean
    $includeThingsToTakeCount: Boolean
    $includePeopleToSeeCount: Boolean
  ) {
    user {
      id
      snapshotStatus
    }
    userTests {
      ...UserTestFieldsTruncated
    }
    userSnapshots {
      id
    }
    examples {
      id
    }
    userTheme(themeSlug: $themeSlug) {
      id
      score
      userSectors {
        id
        userSubsectors {
          id
        }
      }
    }
    recommendations: userSubsectors(
      excludeEmptyRecommendations: $excludeEmptyRecommendations
      includeThingsToDoCount: $includeThingsToDoCount
      includeThingsToAvoidCount: $includeThingsToAvoidCount
      includeThingsToTakeCount: $includeThingsToTakeCount
      includePeopleToSeeCount: $includePeopleToSeeCount
    ) {
      ...UserSubsectorFields
    }
  }
  ${USER_SUBSECTOR_FIELDS}
  ${USER_TEST_FIELDS_TRUNCATED}
`;

const SMALL = "small";

function NavItem({ children, onClick, size, reversed }) {
  return (
    <Box as="button" onClick={onClick} p={"10px"} display="flex" alignItems="center">
      {reversed && (
        <ChevronSVG
          style={{ marginRight: "10px", transform: "rotate(180deg)" }}
          height={10}
          width={6}
          fill="white"
        />
      )}
      <Text
        as="span"
        color="white"
        fontSize={size === SMALL ? 18 : 24}
        fontFamily="gilroyBold"
        mr={reversed ? 16 : 0}
      >
        {children}
      </Text>
      {!reversed && (
        <ChevronSVG style={{ marginLeft: "10px" }} height={10} width={6} fill="white" />
      )}
    </Box>
  );
}

function MainMenu() {
  const { menuOpen, setMenuOpen, questionnaireProcessing } = useAppState();
  const { user } = useAuthContext();
  const [primaryMenuHistory, setPrimaryMenuHistory] = useState(null);
  const [secondaryMenu, setSecondaryMenu] = useState(getSecondaryItems(null));

  const apolloClient = useApolloClient();
  const history = useHistory();

  function logOut() {
    apolloClient
      .mutate({
        mutation: LOG_OUT_MUTATION
      })
      .then(() => {
        window.location.href = "/";
      })
      .catch(error => {
        console.log("Error logging out");
        console.log(error);
        Sentry.captureException(error);
      });
  }

  const { data: testProductFamiliesData } = useQuery(TEST_PRODUCT_FAMILIES_QUERY, {
    client: apolloClient
  });

  const { data: userData, refetch: refetchUserData } = useQuery(USER_DATA_QUERY, {
    client: apolloClient,
    skip: user === null,
    variables: {
      themeSlug: "health",
      excludeEmptyRecommendations: true,
      includeThingsToDoCount: true,
      includeThingsToAvoidCount: true,
      includeThingsToTakeCount: true,
      includePeopleToSeeCount: true
    }
  });

  /*
    Unlike the dashboard we can't poll in this component
    for the userSubmission because it will cause a noticable
    lag when the user is doing the questionnaire (remember this component
    is global).

    So what happens is the user does the questionnaire and returns the
    dashboard.  We poll there and if a refetch of the dashboard is caused
    the incoming userTheme will update this component as well because it
    depends on a query with this part of the gql.  (I.e. Apollo JS will broadcast)

    As a result the user will see the "Wellness Scores" in the main menu.
    And once it is showing we don't have to worry about any polling to check
    if it should ever be removed (this isn't possible).
  */

  const pollingData = usePollingRefresh(
    USER_SNAPSHOT_STATUS_QUERY,
    data => {
      const snapshotStatus = data?.user?.snapshotStatus;
      return snapshotStatus?.toLowerCase() === "complete";
    },
    refetchUserData,
    !!user && questionnaireProcessing
  );

  let snapshotStatus = userData?.user?.snapshotStatus;
  if (pollingData) {
    snapshotStatus = pollingData?.user?.snapshotStatus;
  }

  useEffect(() => {
    // When any of the influencing data shows up, reset the primaryMenuHistory array to
    // consist of a single element containing the appropriate items
    if (user) {
      const showNoDataDashboardView = shouldShowNoDataDashboardView(
        snapshotStatus,
        userData?.userTheme
      );
      const hasRecommendations =
        !!userData?.recommendations?.length && userData?.userTheme?.score > 0;
      const userTests = userData?.userTests;
      const hasExamples = !!userData?.examples?.length;

      const initialMenuItems = getLoggedInMenuItems(
        showNoDataDashboardView,
        hasRecommendations,
        userTests,
        hasExamples
      );
      setPrimaryMenuHistory([initialMenuItems]);
      setSecondaryMenu(getSecondaryItems(user));
    } else {
      const initialMenuItems = getLoggedOutMenuItems();
      setPrimaryMenuHistory([initialMenuItems]);
      setSecondaryMenu(getSecondaryItems(null));
    }
  }, [snapshotStatus, user, userData, testProductFamiliesData]);

  function handleClick(menuItem) {
    if (menuItem.children) {
      // To move down the tree, add a new element to the items, containing
      // just the children of the selected branch
      setPrimaryMenuHistory([...primaryMenuHistory, menuItem.children]);
    } else {
      if (menuItem.url === LOGGED_OUT_URL) {
        logOut();
      } else if (menuItem.url.indexOf("http") === -1) {
        setMenuOpen(false);
        history.push(menuItem.url);
      } else {
        window.location.href = menuItem.url;
      }
    }
  }

  useEffect(() => {
    // When the user opens the menu, reset the menu tree to the root items
    if (menuOpen) {
      setPrimaryMenuHistory([primaryMenuHistory[0]]);
    }
    // eslint-disable-next-line
  }, [menuOpen]);

  return (
    <Box
      bg="blue"
      position="fixed"
      top={0}
      left={0}
      bottom={0}
      right={0}
      opacity={menuOpen ? 1 : 0}
      style={{ pointerEvents: menuOpen ? "all" : "none", transition: "opacity 250ms" }}
      overflow="auto"
      zIndex={MAIN_MENU_Z_INDEX}
    >
      <Box px={20}>
        <Box display="flex" py={100} justifyContent="flex-end" maxWidth={1000} mx="auto">
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            {primaryMenuHistory !== null &&
              primaryMenuHistory[primaryMenuHistory.length - 1].map((menuItem, index) => {
                return (
                  <NavItem
                    key={menuItem.name}
                    onClick={() => {
                      handleClick(menuItem);
                    }}
                  >
                    {menuItem.name}
                  </NavItem>
                );
              })}
            {primaryMenuHistory?.length > 1 && (
              <NavItem
                key="back"
                reversed
                onClick={() => {
                  // To move back up the tree, slice off the last element of the history
                  setPrimaryMenuHistory(primaryMenuHistory.slice(0, -1));
                }}
              >
                Back
              </NavItem>
            )}
            <Divider mt={30} mb={30} color="rgba(255,255,255,0.3)" />
            {secondaryMenu.map((menuItem, index) => {
              return (
                <NavItem
                  key={menuItem.name}
                  onClick={() => {
                    handleClick(menuItem);
                  }}
                  size={SMALL}
                >
                  {menuItem.name}
                </NavItem>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default MainMenu;
