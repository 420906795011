import React from "react";

import PropTypes from "prop-types";

import {
  AFFILIATE,
  AFFILIATE_COMMISSION_MAXIM,
  AFFILIATE_LINK_COMMISSION
} from "../core/constants";
import { theme } from "../core/theme";
import useWindowSize from "../hooks/use-window-size";
import Box from "./Box";
import { SpacedPanelBox } from "./Boxes";
import { SolidButton } from "./Buttons";
import { HeadingLarge } from "./Headings";
import Table from "./Table";
import Text from "./Text";

export async function copyTextToClipboard(text) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}

function AffiliateLinkAndDiscountCode({ discountCodes, hashID, tier }) {
  const AffilateLinkHeading = "Affiliate link";
  const dicountCodeHeading = discountCodes.length > 1 ? "Discount links" : "Discount link";
  const affiliateURL = `${window.location.origin}/link/${hashID}`;
  const discountForAffiliateLink =
    tier === AFFILIATE ? AFFILIATE_LINK_COMMISSION : AFFILIATE_COMMISSION_MAXIM;
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 960;
  const discountRows = discountCodes
    ? discountCodes.map((discount, index) => {
      const url = `${window.location.origin}/code/${discount.code.toLowerCase()}`;
      return [
        <Text mt={5} data-component-name={`discount_ratio${index}`}>
          User {discount.testPercentage}% you {discount.commissionPercentage}%
        </Text>,
        <Text
          mt={4}
          fontFamily="dinBold"
          fontSize={36}
          data-component-name={`discount_code${index}`}
        >
          {discount.code}
        </Text>,
        <Text mt={5} data-component-name={`affiliate_url${index}`}>
          {url}
        </Text>,
        <SolidButton
          bg="black"
          pt={0}
          mt={1}
          borderColor="black"
          handleClick={() => {
            // copy url to clipboard
            copyTextToClipboard(url);
          }}
        >
          COPY LINK
        </SolidButton>
      ];
    })
    : [];
  return (
    <Box>
      <SpacedPanelBox data-component-name="Affiliate link">
        <HeadingLarge pb as="h2">
          {AffilateLinkHeading}
        </HeadingLarge>
        <Text pb={theme.spacing.medium}>
          Anyone that buys through this link you will receive {discountForAffiliateLink}%
          commission.
        </Text>
        <Box
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          flexWrap="wrap"
          justifyContent="space-between"
          width="100%"
          pr={theme.spacing.small}
          style={{
            overflowWrap: "anywhere"
          }}
        >
          <Text minWidth="30%" mt={5}>
            Affiliate link
          </Text>
          <Text
            data-component-name="Affiliate link URL"
            mt={5}
            pb={isMobile ? theme.spacing.large : theme.spacing.small}
          >
            {affiliateURL}
          </Text>
          <SolidButton
            bg="black"
            pt={0}
            mt={1}
            alignSelf={isMobile ? "start" : "end"}
            borderColor="black"
            handleClick={() => {
              // copy url to clipboard
              copyTextToClipboard(affiliateURL);
            }}
          >
            COPY LINK
          </SolidButton>
        </Box>
      </SpacedPanelBox>
      <SpacedPanelBox data-component-name="Discount links">
        <HeadingLarge pb as="h2">
          {dicountCodeHeading}
        </HeadingLarge>
        <Text pb={theme.spacing.large}>
          The below links and discount codes can be shared with your followers and used once per
          person. You will receive a different commission depending on the link you share.
        </Text>
        <Table
          tableHeadings={["Discount split", "Discount code", "Affiliate Link", ""]}
          tableRows={discountRows}
        />
      </SpacedPanelBox>
    </Box>
  );
}

AffiliateLinkAndDiscountCode.propTypes = {
  discountCodes: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      testPercentage: PropTypes.number
    })
  ).isRequired,
  tier: PropTypes.string.isRequired,
  hashID: PropTypes.string.isRequired
};

export default AffiliateLinkAndDiscountCode;
