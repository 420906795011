import { pairwise } from "../../core/utils";
import { isWithinRange } from "../../tpo/Charts";

export function useDiscreteGraph({ chartConfiguration, userDatapoints }) {
  const { normalisedRange } = chartConfiguration;

  const fullRange = [
    {
      value: normalisedRange.minimum
    },
    ...normalisedRange.range.map(value => ({ value })),
    {
      value: normalisedRange.maximum
    }
  ];

  const getInterpretationColor = userDatapoint => {
    const range = fullRange.map(point => point.value);
    const index = pairwise(range).findIndex(([a, b]) =>
      isWithinRange(
        userDatapoint.chartConfiguration.normalisedRange.value,
        [a, b],
        range[range.length - 1] === b
      )
    );
    let color = "#818181";
    if (index !== -1) {
      color = [...chartConfiguration.colours][index];
    }
    return color;
  };

  return {
    fullRange,
    points: userDatapoints.map(userDatapoint => {
      const { value } = userDatapoint.chartConfiguration.normalisedRange;
      let i = fullRange.findIndex(({ value: val }) => value <= val);
      const y = 0.5 + 1 * i;
      return [userDatapoint.userTest.created, y];
    }),
    userDatapoint: userDatapoints.slice(-1)[0],
    tooltips: userDatapoints.map((userDatapoint, index) => {
      const created = new Date(userDatapoint.userTest.created).toLocaleDateString(undefined, {
        day: "numeric",
        month: "long",
        year: "numeric"
      });

      const stack = [
        `<div class='interpretation' style='color: ${getInterpretationColor(userDatapoint)};'>${
          userDatapoint.chartConfiguration.interpretation
        }</div>`,
        `<div class='date'>${created}</div>`
      ];

      if (
        userDatapoints.length - 1 === index &&
        userDatapoint.dateExpired &&
        new Date(userDatapoint.dateExpired) <= new Date()
      ) {
        stack.push("<div class='retest'>(Due for retest)</div>");
      }

      return stack;
    }),
    colors: userDatapoints.map(userDatapoint => {
      return new Date(userDatapoint.dateExpired) <= new Date()
        ? "#818181"
        : getInterpretationColor(userDatapoint);
    })
  };
}
