import { theme } from "../core/theme";
import useWindowSize from "./use-window-size";

function useBreakPointBasedPropIndex() {
  const { width } = useWindowSize();
  const { breakpoints } = theme;
  return breakpoints.findIndex(breakpoint => width < breakpoint.match(/\d+/)?.[0]);
}

export default useBreakPointBasedPropIndex;
