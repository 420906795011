import { RELEASE_IMPERSONATION_URL } from "../core/urls";
import { ReactComponent as Masks } from "../images/masks.svg";
import NavButton from "./NavButton";

export default function ReleaseButton({ user, ...props }) {
  return (
    <NavButton
      {...props}
      handleClick={() => (window.location.href = RELEASE_IMPERSONATION_URL)}
      bg="red"
      borderColor="white"
      size={30}
    >
      <Masks
        fill="white"
        width={20}
        title={`You are impersonating user ${user.email}.\nClick here to release this user.`}
      />
    </NavButton>
  );
}
