import { useCallback, useContext, useMemo } from "react";

import { Redirect, useHistory, useLocation } from "react-router-dom";

import { useMutation } from "@apollo/client";
import { isNumber, isString } from "lodash";

import Box from "../components/Box";
import {
  getQuestionnaireIntroUrl,
  getQuestionnaireOutroUrl,
  getQuestionnaireStepUrl
} from "../core/urls";
import { QUESTIONNAIRE_QUERY } from "../graphql/tpo/content/queries";
import { SUBMIT_USER_ANSWER_MUTATION } from "../graphql/tpo/results/mutations";
import { SolidButton } from "./Buttons";
import { HeadingExtraExtraSmall } from "./Headings";
import SectionProgressBar from "./QuestionSectionProgressBar";
import Answers from "./QuestionnaireAnswers";
import { Button, SubmitButton } from "./QuestionnaireButtons";
import {
  AnswersContext,
  DownloadQuestionsContext,
  SubmissionContext,
  SectionsContext
} from "./QuestionnaireContexts";
import {
  useDownloadQuestions,
  usePercentageAnswers,
  useSection,
  useSubmission
} from "./QuestionnaireHooks";
import Page from "./QuestionnairePages";
import { Meta as BaseMeta } from "./QuestionnaireQuestion";
import Questions from "./QuestionnaireQuestions";
import { padQuestionNumber } from "./QuestionnaireUtilities";

function hasEarlierInCompleteSections(sectionsObj, currentSection) {
  const found = Object.entries(sectionsObj)
    .map(([section, info]) => ({ section: +section, complete: info.complete }))
    .find(({ section, complete }) => section < currentSection && !complete);
  if (found) {
    return found.section;
  }
  return false;
}

function Sections({ children, getSectionUrl }) {
  const section = useSection();
  const submission = useContext(SubmissionContext);
  const sectionsObj = submission?.sectionStatus;

  if (sectionsObj) {
    if (!(section in sectionsObj)) {
      return <Redirect to={getQuestionnaireIntroUrl("symptoms")} />;
    }
    const earlierSection = hasEarlierInCompleteSections(sectionsObj, section);
    if (isNumber(earlierSection)) {
      return <Redirect to={getSectionUrl(earlierSection)} />;
    }
  }

  return <SectionsContext.Provider value={sectionsObj}>{children}</SectionsContext.Provider>;
}

function DownloadQuestionsForCompleteQuestionnaire({ children, query, variables }) {
  const { loading, questions, answers } = useDownloadQuestions({ query, variables });

  const api = useMemo(
    () => ({
      loading,
      questions: questions || [],
      answers: answers || []
    }),
    [loading, questions, answers]
  );

  return (
    <DownloadQuestionsContext.Provider value={api}>{children}</DownloadQuestionsContext.Provider>
  );
}

function Meta({ questionIndex }) {
  const sections = useContext(SectionsContext);
  const { questions } = useContext(DownloadQuestionsContext);
  const section = useSection();

  return (
    <BaseMeta
      leftText={
        questionIndex === 0
          ? `Section ${padQuestionNumber(section, Object.keys(sections).length)}`
          : undefined
      }
      rightText={`${padQuestionNumber(questionIndex + 1, questions.length)}/${questions.length}`}
    />
  );
}

function Navigation() {
  const sections = useContext(SectionsContext);
  const { questions } = useContext(DownloadQuestionsContext);
  const { answered, setShowErrors } = useContext(AnswersContext);
  const section = useSection();
  const history = useHistory();

  return (
    <Box display="flex" alignItems="center" mt={4} mb={150}>
      {section > 1 && (
        <Button
          chevronDir="left"
          chevronProps={{
            fill: "dark",
            style: {
              marginRight: "10px",
              marginLeft: 0
            }
          }}
          chevronPosition="left"
          mr={2}
          handleClick={() => {
            history.push(getQuestionnaireStepUrl("symptoms", +section - 1));
          }}
        >
          Back
        </Button>
      )}
      <SubmitButton
        onClick={() => {
          if (questions.map(question => question.id).every(id => id in answered)) {
            +section + 1 <= Object.keys(sections).length
              ? history.push({
                  pathname: getQuestionnaireStepUrl("symptoms", +section + 1),
                  state: {
                    section: +section + 1
                  }
                })
              : history.push({
                  pathname: getQuestionnaireOutroUrl("symptoms"),
                  state: {
                    fromSection: true
                  }
                });
          } else {
            setShowErrors(true);
          }
        }}
        text={Object.keys(sections).length === +section ? "Submit" : "Next"}
      />
    </Box>
  );
}

export function allQuestionsHaveBeenAnswered(questions, answers) {
  return questions?.length && !questions.map(question => question.id).every(id => id in answers);
}

const PAGE_BUTTON_WIDTHS = [40, 40, 50];

function PageButton({ label, url, bg, color, ...rest }) {
  return (
    <SolidButton
      minWidth={PAGE_BUTTON_WIDTHS}
      maxWidth={PAGE_BUTTON_WIDTHS}
      width={PAGE_BUTTON_WIDTHS}
      maxHeight={PAGE_BUTTON_WIDTHS}
      minHeight={PAGE_BUTTON_WIDTHS}
      height={PAGE_BUTTON_WIDTHS}
      bg={bg}
      borderColor={bg}
      color={color}
      fontSize={18}
      {...rest}
    >
      {label}
    </SolidButton>
  );
}

const SECTION_BUTTON_GAP = 10; // pixels

function SectionPagination({ getSectionUrl }) {
  const urlSection = useSection();
  const sections = useContext(SectionsContext);
  const { questions } = useContext(DownloadQuestionsContext);
  const { answered, setShowErrors } = useContext(AnswersContext);
  const history = useHistory();

  if (!sections || !Object.entries(sections).length) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center" gridColumnGap={`${SECTION_BUTTON_GAP}px`}>
      {Object.entries(sections).map(([section, info]) => (
        <PageButton
          bg={(function () {
            if (urlSection === +section) {
              return "black";
            } else if (info.complete) {
              return "green";
            }
            return "haze";
          })()}
          color={(function () {
            if (urlSection === +section) {
              return "white";
            } else if (info.complete) {
              return "white";
            }
            return "black";
          })()}
          label={+section}
          url={getSectionUrl(+section)}
          handleClick={() => {
            if (+section > urlSection) {
              // going forwards
              if (questions?.length && allQuestionsHaveBeenAnswered(questions, answered)) {
                setShowErrors(true);
                return;
              }
            }
            setShowErrors(false);
            history.push({
              pathname: getQuestionnaireStepUrl("symptoms", +section),
              state: {
                section
              }
            });
          }}
          key={section}
        />
      ))}
    </Box>
  );
}

const calculateProgressBarWidth = (sectionsTotal, buttonWidth) =>
  sectionsTotal * buttonWidth + SECTION_BUTTON_GAP * (sectionsTotal - 1);

function SectionQuestionnaireProgressBar(props) {
  const percentage = usePercentageAnswers();
  const sections = useContext(SectionsContext);

  if (!sections) return null;

  if (!isString(percentage)) {
    return (
      <Box
        maxWidth="100%"
        mt={2}
        width={PAGE_BUTTON_WIDTHS.map(
          buttonWidth => `${calculateProgressBarWidth(Object.keys(sections).length, buttonWidth)}px`
        )}
        {...props}
      >
        <HeadingExtraExtraSmall
          fontSize={10}
          mb={2}
          textTransform="uppercase"
        >{`section complete: ...loading`}</HeadingExtraExtraSmall>
        <SectionProgressBar />
      </Box>
    );
  }

  return (
    <Box
      maxWidth="100%"
      mt={2}
      width={PAGE_BUTTON_WIDTHS.map(
        buttonWidth => `${calculateProgressBarWidth(Object.keys(sections).length, buttonWidth)}px`
      )}
      {...props}
    >
      <HeadingExtraExtraSmall
        fontSize={10}
        mb={2}
        textTransform="uppercase"
      >{`section complete: ${+percentage}%`}</HeadingExtraExtraSmall>
      <SectionProgressBar />
    </Box>
  );
}

function CompleteQuestionnaireQuestions() {
  const submission = useSubmission();
  const section = useSection();

  const getSectionUrl = useCallback(section => {
    return getQuestionnaireStepUrl("symptoms", section);
  }, []);

  const redirect = useCallback(sec => getQuestionnaireStepUrl("symptoms", sec), []);

  const [submitAnswerMutation] = useMutation(SUBMIT_USER_ANSWER_MUTATION);

  const onSelectMutation = useCallback(
    ({ submission, answered, questionId, answer, setAnswered }) =>
      submitAnswerMutation({
        variables: {
          input: {
            submission: submission.id,
            question: questionId,
            answer
          }
        }
      }).then(res => {
        if (res.data?.submitUserAnswerMutation?.errors?.length !== 0) {
          setAnswered({
            ...answered
          });
        }
      }),
    [submitAnswerMutation]
  );

  return (
    <SubmissionContext.Provider value={submission}>
      <Sections getSectionUrl={getSectionUrl}>
        <DownloadQuestionsForCompleteQuestionnaire
          query={QUESTIONNAIRE_QUERY}
          getRedirectUrl={redirect}
          variables={{
            presentationSection: +section,
            userSubmissionId: submission?.id
          }}
        >
          <Answers>
            <Page
              Main={
                <Questions
                  navigation={<Navigation />}
                  QuestionMetaComponent={Meta}
                  onSelectMutation={onSelectMutation}
                />
              }
              Footer={
                <Box position="fixed" bg="blue" left={0} bottom={0} width="100vw">
                  <Box bg="white" py={4} mt={4}>
                    <Box overflowX="auto" px={4} display="flex">
                      <Box mx="auto">
                        <SectionPagination getSectionUrl={getSectionUrl} />
                      </Box>
                    </Box>
                    <Box px={4}>
                      <SectionQuestionnaireProgressBar mx="auto" />
                    </Box>
                  </Box>
                </Box>
              }
            />
          </Answers>
        </DownloadQuestionsForCompleteQuestionnaire>
      </Sections>
    </SubmissionContext.Provider>
  );
}

function CompleteQuestionnaireQuestionsWrapper() {
  const section = useSection();
  return <CompleteQuestionnaireQuestions key={section} />;
}

export default CompleteQuestionnaireQuestionsWrapper;
