import Box from "../components/Box";
import Loading from "../components/Loading";
import { theme } from "../core/theme";
import { ReactComponent as SearchIcon } from "../images/tpo/search.svg";
import Stack from "./Stack";

export function NoItems({ search, loading }) {
  if (loading) return <Loading />;

  if (search) return <EmptySearch />;

  return null;
}

function EmptySearch({ message }) {
  return (
    <Stack gap={20} alignItems="center">
      <SearchIcon fill={theme.colors.dark} />
      <Box textAlign="center" fontFamily="gilroyBold" fontSize={[18, 18, 28]} lineHeight="130%">
        {message}
      </Box>
    </Stack>
  );
}

EmptySearch.defaultProps = {
  message: "No matches for this search"
};

export default EmptySearch;
