import { forwardRef } from "react";

import PropTypes from "prop-types";
import styled from "styled-components";
import { border, color, flexbox, layout, space, system, variant } from "styled-system";
import { typography } from "styled-system";

import Box from "../components/Box";
import { ButtonWithSpinner } from "../components/Buttons";
import { theme } from "../core/theme";

export const Button = styled(ButtonWithSpinner)`
  ${color}
  ${border}
  ${layout}
  ${flexbox}
  ${space}
  ${typography}
  ${system({
    whiteSpace: {
      property: "white-space"
    }
  })}
  ${variant({
    variants: {
      noPadding: {
        px: 0,
        py: 0
      },
      sm: {
        px: 2,
        py: 2
      },
      lg: {
        px: 5,
        py: 4
      }
    }
  })}
  font-family: "Gilroy W05 bold", sans-serif;
  opacity: ${props => {
    return props.disabled ? 0.5 : 1;
  }};
  text-align: center;
  text-transform: ${props => {
    if (props.textTransform) {
      return props.textTransform;
    } else {
      return "uppercase";
    }
  }};

  &:hover {
    cursor: pointer;
  }
`;

Button.defaultProps = {
  letterSpacing: "2.8px"
};

const commonProps = {
  color: "white",
  borderTopLeftRadius: 5,
  borderTopRightRadius: 5,
  borderBottomLeftRadius: 5,
  borderBottomRightRadius: 5,
  borderStyle: "solid",
  borderWidth: 2,
  borderColor: theme.colors.green,
  fontSize: "13px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer"
};

export const SolidButton = ({ children, ...rest }) => <Button {...rest}>{children}</Button>;

SolidButton.propTypes = {
  children: PropTypes.node
};

SolidButton.defaultProps = {
  ...commonProps,
  borderColor: theme.colors.dark,
  bg: theme.colors.dark
};

export const OutlineButton = ({ children, ...rest }) => <Button {...rest}>{children}</Button>;

OutlineButton.propTypes = {
  children: PropTypes.node
};

OutlineButton.defaultProps = {
  ...commonProps,
  color: theme.colors.green
};

export function ButtonTab({ children, selected, ...defaultProps }) {
  const props = selected
    ? { backgroundColor: "dark", borderColor: "dark", color: "white" }
    : { color: "midGrey", bg: "transparent", borderColor: "transparent" };

  return (
    <SolidButton {...defaultProps} {...props}>
      {children}
    </SolidButton>
  );
}

ButtonTab.defaultProps = {
  padding: "13px 20px",
  fontSize: 12,
  lineHeight: "12px",
  borderTopLeftRadius: 40,
  borderTopRightRadius: 40,
  borderBottomLeftRadius: 40,
  borderBottomRightRadius: 40,
  whiteSpace: "nowrap"
};

export const CircleButton = forwardRef(({ children, ...props }, ref) => (
  <Box
    as="button"
    borderRadius="50%"
    display="flex"
    justifyContent="center"
    alignItems="center"
    backgroundColor="white"
    p={2}
    ref={ref}
    {...props}
  >
    {children}
  </Box>
));

CircleButton.defaultProps = {
  height: 16,
  width: 16
};

export function FilterButton({ children, selected, ...props }) {
  return selected ? (
    <SolidButton {...props}>{children}</SolidButton>
  ) : (
    <OutlineButton borderColor="dark" color="dark" {...props}>
      {children}
    </OutlineButton>
  );
}

FilterButton.defaultProps = {
  padding: "13px 20px",
  fontSize: 12,
  lineHeight: "12px",
  borderTopLeftRadius: 40,
  borderTopRightRadius: 40,
  borderBottomLeftRadius: 40,
  borderBottomRightRadius: 40,
  whiteSpace: "nowrap"
};

export function PageButton({ label, bg, color, width, ...rest }) {
  return (
    <SolidButton
      minWidth={width}
      maxWidth={width}
      width={width}
      maxHeight={width}
      minHeight={width}
      height={width}
      bg={bg}
      borderColor={bg}
      color={color}
      fontSize={18}
      {...rest}
    >
      {label}
    </SolidButton>
  );
}

PageButton.defaultProps = {
  width: [40, 40, 50]
};
