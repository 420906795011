import React from "react";

import { useHistory } from "react-router-dom";

import { PLATFORM_PROFILE_GOALS_URL } from "../core/urls";
import { PLATFORM_USER_PROFILE_MUTATION, PLATFORM_USER_PROFILE_QUERY } from "../graphql/accounts";
import useDocTitle from "../hooks/use-doc-title";
import { useAuthContext } from "./AuthProvider";
import BasicPage from "./BasicPage";
import DataLoader from "./DataLoader";
import DateInput from "./DateInput";
import Form from "./Form";
import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Text from "./Text";

function getFormData({ height, weight, dateOfBirth, gender }) {
  return [
    {
      header: (
        <Text fontFamily="gilroyBold" as="h2" pb={20}>
          Details
        </Text>
      ),
      fields: [
        {
          name: "height",
          initialValue: !!height ? height : "",
          widget: {
            component: Input,
            props: {
              label: "Height in cm",
              type: "number"
            }
          }
        },
        {
          name: "weight",
          initialValue: !!weight ? weight : "",
          widget: {
            component: Input,
            props: {
              label: "Weight in kg",
              type: "number"
            }
          },
          boxProps: {
            pt: "15px"
          }
        }
      ]
    },
    {
      header: (
        <Text fontFamily="gilroyBold" as="h2" pb={20} pt={40}>
          Date of birth
        </Text>
      ),
      fields: [
        {
          initialValue: !!dateOfBirth ? dateOfBirth : "",
          name: "dateOfBirth",
          widget: {
            component: DateInput
          }
        }
      ]
    },
    {
      header: (
        <Text fontFamily="gilroyBold" as="h2" pb={20} pt={40}>
          Gender
        </Text>
      ),
      fields: [
        {
          name: "gender",
          initialValue: gender,
          widget: {
            component: RadioGroup,
            props: {
              options: [
                { name: "Male", value: "Male" },
                { name: "Female", value: "Female" }
              ]
            }
          }
        }
      ]
    }
  ];
}

function PlatformUserProfileFormPage() {
  useDocTitle("Your profile");
  const { user } = useAuthContext();
  const history = useHistory();
  return (
    <BasicPage heading="Profile">
      <DataLoader
        query={PLATFORM_USER_PROFILE_QUERY}
        render={({ platformUserProfile }) => {
          return (
            <Form
              mutation={PLATFORM_USER_PROFILE_MUTATION}
              refetchQueries={[{ query: PLATFORM_USER_PROFILE_QUERY }]}
              handleSubmitted={() => {
                history.push(PLATFORM_PROFILE_GOALS_URL);
              }}
              extraInput={{ id: user.id }}
              data={getFormData(platformUserProfile)}
              formWrapperProps={{
                mb: 30
              }}
            />
          );
        }}
      ></DataLoader>
    </BasicPage>
  );
}

export default PlatformUserProfileFormPage;
