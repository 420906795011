export const COT_CHART_USER_ICON = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="16" height="16" rx="8" fill="#transparent"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.31254 6.40002C9.31254 7.16998 8.72187 7.79998 8.00003 7.79998C7.27814 7.79998 6.68752 7.16998 6.68752 6.40002C6.68752 5.63 7.27814 5 8.00003 5C8.72187 5 9.31254 5.63 9.31254 6.40002ZM8.00005 8.6C9.01249 8.6 11 9.38 11 11H5C5 9.38 6.98751 8.6 8.00005 8.6Z" fill="white"/>
</svg>`;

export async function getIcon() {
  return new Promise(resolve => {
    let svgBlob = new Blob([COT_CHART_USER_ICON], {
      type: "image/svg+xml;charset=utf-8"
    });
    let svgUri = URL.createObjectURL(svgBlob);
    let image = new Image();

    image.onload = () => {
      resolve(image);
    };

    image.src = svgUri;
  });
}

export async function generateMarker(icon, color) {
  let canvas = document.createElement("canvas");
  canvas.height = canvas.width = 64;
  let context = canvas.getContext("2d");
  context.fillStyle = color;
  context.beginPath();
  context.arc(32, 32, 32, 0, Math.PI * 2);
  context.fill();
  context.drawImage(icon, 0, 0, 64, 64);
  return canvas.toDataURL("image/png");
}

export function buildLineGraphConfiguration({ fullRange, points, tooltips }) {
  return {
    series: [
      {
        data: points
      }
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: true
        },
        toolbar: {
          show: false
        },
        animations: {
          enabled: true,
          easing: "easeinout",
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [2],
        curve: "straight",
        colors: ["#1a1a1a"]
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6
        }
      },
      yaxis: {
        show: false,
        min: 0,
        max: fullRange.length - 1,
        tickAmount: fullRange.length - 1
      },
      xaxis: {
        type: "datetime"
      },
      grid: {
        borderColor: "#f1f1f1",
        show: true
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const stack = tooltips[dataPointIndex];
          return "<div class='tooltip'>" + stack.reduce((acc, curr) => acc + curr, "") + "</div>";
        }
      }
    }
  };
}
