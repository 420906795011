import { useCallback } from "react";

import { useHistory } from "react-router-dom";

import Box from "../components/Box";
import Image from "../components/Image";
import { InternalTextLink } from "../components/Links";
import Text from "../components/Text";
import Icon from "../images/tpo/self_assesment.svg";
import { PanelBox } from "./Boxes";
import { SolidChevronButton } from "./ChevronButton";
import { HeadingExtraExtraSmall } from "./Headings";
import { useSubmission } from "./QuestionnaireHooks";
import Page from "./QuestionnairePages";
import Section from "./Section";

export function useIntro(questionsUrl) {
  const submission = useSubmission();
  const history = useHistory();

  const proceedToQuestions = useCallback(() => {
    history.push({
      pathname: questionsUrl,
      state: {
        getStarted: true
      }
    });
  }, [history, questionsUrl]);

  return {
    handleClick: useCallback(() => {
      if (submission) {
        proceedToQuestions();
      }
    }, [submission, proceedToQuestions]),
    submission
  };
}

function Intro({ h1, h2, h3, bodyCopy, buttonText, handleClick, linkText, linkUrl }) {
  return (
    <Page
      header={h1}
      Main={
        <>
          <PanelBox medium>
            <PanelBox bg="white" nested small verticalPadding>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Image src={Icon} />
                <HeadingExtraExtraSmall
                  fontSize={[16, 20]}
                  mt={4}
                  textAlign="center"
                  textTransform="uppercase"
                  mb={[6, 7]}
                >
                  {h2}
                </HeadingExtraExtraSmall>
              </Box>
              <Section.Header>
                <HeadingExtraExtraSmall fontSize={[16, 20]}>{h3}</HeadingExtraExtraSmall>
              </Section.Header>
              <Section.BodyCopy>
                <Text fontSize={[15, 16]}>{bodyCopy}</Text>
              </Section.BodyCopy>
              <Section.Button>
                <SolidChevronButton handleClick={handleClick}>{buttonText}</SolidChevronButton>
              </Section.Button>
              {linkText && linkUrl && (
                <Section.Button mt={30}>
                  <InternalTextLink href={linkUrl}>{linkText}</InternalTextLink>
                </Section.Button>
              )}
            </PanelBox>
          </PanelBox>
        </>
      }
    />
  );
}

export default Intro;
