import React from "react";

import { useHistory } from "react-router";

import { AFFILIATE, PRACTITIONER } from "../core/constants";
import { LOGIN_URL, PARTNER_TERMS_URL } from "../core/urls";
import { CREATE_PARTNER_USER_PROFILE_MUTATION } from "../graphql/accounts";
import partnerUpdatesConsentField from "../tpo/PartnerUpdatesConsentField";
import { useAuthContext } from "./AuthProvider";
import Box from "./Box";
import Checkbox from "./Checkbox";
import CheckboxGroup from "./CheckboxGroup";
import Form from "./Form";
import { HeadingExtraSmall } from "./Headings";
import Input from "./Input";
import { ExternalTextLink } from "./Links";
import RadioGroup from "./RadioGroup";
import Text, { SpanText } from "./Text";

const firstName = {
  name: "firstName",
  initialValue: "",
  widget: {
    component: Input,
    props: {
      autoComplete: "given-name",
      label: "First name"
    }
  }
};

const lastName = {
  name: "lastName",
  initialValue: "",
  widget: {
    component: Input,
    props: {
      autoComplete: "family-name",
      label: "Last name"
    }
  },
  boxProps: {
    pt: "15px"
  }
};

const email = {
  name: "email",
  initialValue: "",
  widget: {
    component: Input,
    props: {
      type: "email",
      label: "Email address",
      autoComplete: "email"
    }
  },
  boxProps: {
    pt: "15px"
  }
};

const password = {
  name: "password",
  initialValue: "",
  widget: {
    component: Input,
    props: {
      type: "password",
      label: "Password",
      showEye: true
    }
  },
  boxProps: {
    pt: "15px"
  }
};

const businessName = {
  initialValue: "",
  name: "businessName",
  boxProps: {
    pt: "15px"
  },
  widget: {
    component: Input,
    props: {
      label: "Your organisation / business name"
    }
  }
};
const businessLocationCity = {
  initialValue: "",
  name: "businessLocationCity",
  boxProps: {
    pt: "15px"
  },
  widget: {
    component: Input,
    props: {
      label: "Location / City",
      required: true
    }
  }
};

const phoneNumber = {
  initialValue: "",
  name: "phoneNumber",
  boxProps: {
    pt: "15px"
  },
  widget: {
    component: Input,
    props: {
      label: "Contact number",
      autoComplete: "tel",
      required: true
    }
  }
};

const jobRole = {
  initialValue: "",
  name: "jobRole",
  boxProps: {
    pt: "15px"
  },
  widget: {
    component: Input,
    props: {
      label: "Job title / Role"
    }
  }
};

const descriptionForAffiliate = {
  initialValue: [],
  name: "description",
  serializer: value => {
    return value.join(",");
  },
  boxProps: { "data-component-name": "Description Checkbox Group" },
  widget: {
    component: CheckboxGroup,
    props: {
      multiSelect: true,
      options: [
        {
          name: "Influencer",
          value: "Influencer"
        },
        {
          name: "Personal Trainer",
          value: "Personal Trainer"
        },
        {
          name: "Ambassador",
          value: "Ambassador"
        },
        {
          name: "Blogger",
          value: "Blogger"
        },
        {
          name: "Retail site",
          value: "Retail site"
        }
      ]
    }
  }
};

const descriptionForPractioner = {
  initialValue: [],
  name: "description",
  serializer: value => {
    return value.join(",");
  },
  boxProps: { "data-component-name": "Description Checkbox Group" },
  widget: {
    component: CheckboxGroup,
    props: {
      maxSelect: 3,
      options: [
        {
          name: "Functional Medicine Practitioner",
          value: "Functional Medicine Practitioner"
        },
        {
          name: "Doctor",
          value: "Doctor"
        },
        {
          name: "Naturopath",
          value: "Naturopath"
        },
        {
          name: "Dietitian",
          value: "Dietitian"
        },
        {
          name: "Nutritionist",
          value: "Nutritionist"
        },
        {
          name: "Nutritional Therapist",
          value: "Nutritional Therapist"
        },
        {
          name: "Health Coach",
          value: "Health Coach"
        }
      ]
    }
  }
};

const expertiseForPractioner = {
  initialValue: [],
  name: "specialisms",
  serializer: value => {
    return value.join(",");
  },
  widget: {
    component: CheckboxGroup,
    props: {
      options: [
        {
          name: "Blood sugar regulation",
          value: "Blood sugar regulation"
        },
        {
          name: "Digestive health",
          value: "Digestive health"
        },
        {
          name: "Immune health",
          value: "Immune health"
        },
        {
          name: "Detoxification pathways",
          value: "Detoxification pathways"
        },
        {
          name: "Cardiovascular health",
          value: "Cardiovascular health"
        },
        {
          name: "Energy regulation",
          value: "Energy regulation"
        },
        {
          name: "Hormones",
          value: "Hormones"
        },
        {
          name: "Nutritional health",
          value: "Nutritional health"
        },
        {
          name: "Performance",
          value: "Performance"
        }
      ]
    }
  }
};

const expertiseForPractionerOther = {
  initialValue: "",
  name: "specialismsOther",
  boxProps: {
    pt: "15px"
  },
  widget: {
    component: Input,
    props: {
      label: "Please give detail"
    }
  }
};

const descriptionOther = {
  initialValue: "",
  name: "descriptionOther",
  boxProps: {
    pt: "15px"
  },
  widget: {
    component: Input,
    props: {
      label: "Other (please give details)"
    }
  }
};

const qualifications = {
  initialValue: "",
  name: "qualifications",
  widget: {
    component: Input,
    props: {
      label: "Enter qualifications"
    }
  }
};

const okToInterpretResults = {
  initialValue: null,
  name: "okToInterpretResults",
  widget: {
    component: RadioGroup,
    props: {
      options: [
        {
          name: "Yes",
          value: true
        },
        {
          name: "No",
          value: false
        }
      ]
    }
  }
};

const interestedInAcademy = {
  initialValue: false,
  name: "interestedInAcademy",
  boxProps: {
    pt: 0
  },
  widget: {
    component: Checkbox,
    props: {
      label: <span>Would you be interseted in Omnos Academy?</span>
    }
  }
};

const tests = {
  initialValue: [],
  name: "tests",
  serializer: value => {
    return value.join(",");
  },
  boxProps: { "data-component-name": "Tests Checkbox Group" },
  widget: {
    component: CheckboxGroup,
    props: {
      multiSelect: true,
      options: [
        {
          name: "Genes",
          value: "Genes"
        },
        {
          name: "Microbiome",
          value: "Microbiome"
        },
        {
          name: "Elements",
          value: "Elements"
        },
        {
          name: "Enviro-Toxins",
          value: "Enviro-Toxins"
        },
        {
          name: "Hormones",
          value: "Hormones"
        },
        {
          name: "Blood Tests",
          value: "Blood Tests"
        }
      ]
    }
  }
};

const sources = {
  initialValue: [],
  name: "sources",
  widget: {
    component: RadioGroup,
    props: {
      options: [
        {
          name: "Instagram*",
          value: "Instagram"
        },
        {
          name: "Direct email",
          value: "Direct email"
        },
        {
          name: "Influencer",
          value: "Influencer"
        },
        {
          name: "Magazine",
          value: "Magazine"
        },
        {
          name: "Google Search",
          value: "Google Search"
        },
        {
          name: "Event*",
          value: "Event"
        },
        {
          name: "Staff*",
          value: "Staff"
        },
        {
          name: "Friends/Family*",
          value: "Friends / Family"
        },
        {
          name: "Other",
          value: "Other"
        }
      ]
    }
  }
};

const sourcesOther = {
  initialValue: "",
  name: "sourcesOther",
  boxProps: {
    pt: "15px"
  },
  widget: {
    component: Input,
    props: {
      label: "*Please give additional details"
    }
  }
};

const acceptTerms = {
  initialValue: false,
  name: "acceptTerms",
  boxProps: {
    pt: 5
  },
  widget: {
    component: Checkbox,
    props: {
      label: (
        <span>
          I have read and agree to the{" "}
          <ExternalTextLink href={PARTNER_TERMS_URL}>
            terms and conditions<SpanText color="red">*</SpanText>
          </ExternalTextLink>
        </span>
      ),
      containsLink: true
    }
  }
};

const instagram = {
  name: "instagram",
  initialValue: "",
  widget: {
    component: Input,
    props: {
      autoComplete: "social-instagram",
      label: "Instagram"
    }
  },
  boxProps: {
    pt: "15px"
  }
};

const tiktok = {
  name: "tiktok",
  initialValue: "",
  widget: {
    component: Input,
    props: {
      autoComplete: "social-tiktok",
      label: "Tiktok"
    }
  },
  boxProps: {
    pt: "15px"
  }
};

const youtube = {
  name: "youtube",
  initialValue: "",
  widget: {
    component: Input,
    props: {
      autoComplete: "social-youtube",
      label: "Youtube"
    }
  },
  boxProps: {
    pt: "15px"
  }
};

const websiteBlog = {
  name: "websiteBlog",
  initialValue: "",
  widget: {
    component: Input,
    props: {
      autoComplete: "social-websiteBlog",
      label: "Website / Blog"
    }
  },
  boxProps: {
    pt: "15px"
  }
};

const discountPrefix = {
  initialValue: "",
  name: "discountPrefix",
  boxProps: {
    pt: "15px"
  },
  widget: {
    component: Input,
    props: {
      label: "5 characters max"
    }
  }
};

const descriptionForAffilateFieldSet = [
  {
    header: (
      <HeadingExtraSmall pt={50} pb={30}>
        What best describes you. Select as many as you like<SpanText color="red">*</SpanText>
      </HeadingExtraSmall>
    ),
    fields: [descriptionForAffiliate, descriptionOther]
  },
  {
    header: (
      <HeadingExtraSmall pt={50} pb={30}>
        Omnos Academy?
      </HeadingExtraSmall>
    ),
    fields: [interestedInAcademy]
  }
];

const descriptionForPractionerFieldSet = [
  {
    header: (
      <>
        <HeadingExtraSmall pt={50} pb={30}>
          What best describes you?<SpanText color="red">*</SpanText>
        </HeadingExtraSmall>
        <Text mb={40}>Select up to three?</Text>
      </>
    ),
    fields: [descriptionForPractioner, descriptionOther]
  }
];

const professionalDetailsFieldSet = tier => [
  {
    header: (
      <HeadingExtraSmall pt={50} pb={20}>
        Organisation / Business{tier === PRACTITIONER && <SpanText color="red">*</SpanText>}
      </HeadingExtraSmall>
    ),
    fields: [businessName, phoneNumber, jobRole, businessLocationCity]
  }
];

const professionalBackgroundFieldSet = tier => [
  {
    header: (
      <Box pt={50} pb={30}>
        <HeadingExtraSmall>
          Qualifications{tier === PRACTITIONER && <SpanText color="red">*</SpanText>}
        </HeadingExtraSmall>
        <Text pt={40}>
          Please give details of professional qualifications, or professional bodies you are
          registered with
        </Text>
      </Box>
    ),
    fields: [qualifications]
  },
  {
    header: (
      <Box pt={50} pb={30}>
        <HeadingExtraSmall>Expertise</HeadingExtraSmall>
        <Text pt={40}>
          If applicable, please select the areas that best describe your experience.{" "}
        </Text>
      </Box>
    ),
    fields: [expertiseForPractioner]
  },
  {
    header: (
      <Box pt={50} pb={30}>
        <HeadingExtraSmall>Additional information on your work</HeadingExtraSmall>
      </Box>
    ),
    fields: [expertiseForPractionerOther]
  },
  {
    header: (
      <HeadingExtraSmall pt={50} pb={30}>
        Do you feel comfortable interpreting the results you receive for the tests you are
        interested in using with us{tier === PRACTITIONER && <SpanText color="red">*</SpanText>}
      </HeadingExtraSmall>
    ),
    fields: [okToInterpretResults]
  },
  {
    header: (
      <HeadingExtraSmall pt={50} pb={30}>
        Omnos Academy?
      </HeadingExtraSmall>
    ),
    fields: [interestedInAcademy]
  }
];

const testsSourcesTermsFieldSet = [
  {
    header: (
      <Box pt={50} pb={30}>
        <HeadingExtraSmall>
          Tests<SpanText color="red">*</SpanText>
        </HeadingExtraSmall>
        <Text pt={40}>Which of the Omnos tests are you interested in promoting?</Text>
      </Box>
    ),
    fields: [tests]
  }
];

function hygeneFieldSet(partnerUpdateConsentFieldLabel) {
  const fieldSet = [
    {
      header: (
        <Box pt={50} pb={30}>
          <HeadingExtraSmall>
            How did you hear about Omnos and the Omnos partner programme?
            <SpanText color="red">*</SpanText>
          </HeadingExtraSmall>
        </Box>
      ),
      fields: [sources, sourcesOther]
    },
    {
      header: (
        <Box pt={50} pb={30}>
          <HeadingExtraSmall>
            Social Media Links<SpanText color="red">*</SpanText>
          </HeadingExtraSmall>
        </Box>
      ),
      fields: [instagram, tiktok, youtube, websiteBlog]
    },
    {
      fields: [
        partnerUpdatesConsentField({
          label: partnerUpdateConsentFieldLabel
        }),
        acceptTerms
      ]
    }
  ];
  return fieldSet;
}

const affiliateDiscountCodeSet = [
  {
    header: (
      <Box pt={50} pb={30}>
        <HeadingExtraSmall mb={20}>Affiliate code prefix</HeadingExtraSmall>
        <Text>This will make up your personal affiliate discount code i.e. OMNOS-12345</Text>
      </Box>
    ),
    fields: [discountPrefix]
  }
];

function UserDetailsFooter() {
  const history = useHistory();
  const url = `${LOGIN_URL}?next=/partners`;

  return (
    <Box pt={20}>
      Already a user? Click{" "}
      <SpanText
        as="a"
        href={url}
        color="purple"
        onClick={e => {
          e.preventDefault();
          history.push(url);
        }}
        underline
      >
        here
      </SpanText>{" "}
      to login
    </Box>
  );
}

const userDetailsFieldSet = [
  {
    header: (
      <Box pt={50} pb={30}>
        <HeadingExtraSmall>
          Your details<SpanText color="red">*</SpanText>
        </HeadingExtraSmall>
      </Box>
    ),
    fields: [firstName, lastName, email, password],
    footer: <UserDetailsFooter />
  }
];

function PartnerApplicationForm({ tier, handleSubmitted }) {
  const { user } = useAuthContext();

  let formData;
  if (tier === AFFILIATE) {
    formData = [
      ...professionalDetailsFieldSet(tier),
      ...affiliateDiscountCodeSet,
      ...descriptionForAffilateFieldSet,
      ...hygeneFieldSet(
        <span>
          By submitting this form, you indicate your consent to receiving information about your
          partner account, news, and promotions. Uncheck this box if you would not like to receive
          this information.
        </span>
      )
    ];
  } else {
    formData = [
      ...professionalDetailsFieldSet(tier),
      ...affiliateDiscountCodeSet,
      ...descriptionForPractionerFieldSet,
      ...professionalBackgroundFieldSet(tier),
      ...testsSourcesTermsFieldSet,
      ...hygeneFieldSet(
        <span>
          By submitting this form, you indicate your consent to receiving information about your
          practitioner account. Uncheck this box if you would not like to receive this information.
        </span>
      )
    ];
  }
  if (!user) {
    formData = [...userDetailsFieldSet, ...formData];
  }

  return (
    <Form
      key={tier} // force component to unmount and re-mount if tier chages so that the state gets correctly stood up
      extraInput={{ tier }}
      data={formData}
      mutation={CREATE_PARTNER_USER_PROFILE_MUTATION}
      handleSubmitted={handleSubmitted}
      formWrapperProps={{
        mb: 30
      }}
    />
  );
}

export default PartnerApplicationForm;
