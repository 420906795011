import React from "react";

import { useHistory } from "react-router-dom";

import PropTypes from "prop-types";

import { theme } from "../core/theme";
import { ALL_TESTS_SLUG, getTestProductQuestionnaireIntroUrl, getTestUrl } from "../core/urls";
import { priceFormat } from "../core/utils";
import { SolidButton as TpoSolidButton, ButtonTab, CircleButton } from "../tpo/Buttons";
import TestProductResponsiveImage, { TestProductImage } from "../tpo/TestProductResponsiveImage";
import Tooltip from "../tpo/Tooltip";
import Box from "./Box";
import { SolidButton } from "./Buttons";
import { HeadingMediumLarge } from "./Headings";
import { BaseInternalTextLink } from "./Links";
import RichText from "./RichText";
import Text, { SpanText } from "./Text";

export function UserRecommendedTestBadge({ bg, color, rank }) {
  return (
    <Box bg={bg} borderRadius="100px" px={4} py={2} mt={4} ml={4}>
      <Text color={color} fontFamily="gilroyBold" fontSize={12} letterSpacing={2.8} uppercase>
        {rank === 0 ? "Suggested Next Test" : `#${rank + 1} Matched`}
      </Text>
    </Box>
  );
}

UserRecommendedTestBadge.defaultProps = {
  bg: "white",
  color: "black"
};

function TestProductList({
  testProducts,
  familySlug,
  matchedSymptomsMap,
  stacked,
  TestProductCard
}) {
  return testProducts
    .filter(testProduct => {
      if (familySlug === ALL_TESTS_SLUG) {
        return true;
      } else {
        return testProduct?.productFamily.slug === familySlug;
      }
    })
    .sort(
      (testProduct1, testProduct2) =>
        (matchedSymptomsMap[testProduct1.id]?.rank < 5
          ? matchedSymptomsMap[testProduct1.id]?.rank
          : 1000) -
        (matchedSymptomsMap[testProduct2.id]?.rank < 5
          ? matchedSymptomsMap[testProduct2.id]?.rank
          : 1000)
    )
    .map((testProduct, index) => {
      return (
        <TestProductCard
          key={index}
          {...testProduct}
          badge={
            matchedSymptomsMap[testProduct.id]?.rank < 5 && (
              <UserRecommendedTestBadge rank={matchedSymptomsMap[testProduct.id]?.rank} />
            )
          }
          matchedSymptoms={
            matchedSymptomsMap[testProduct.id]?.symptoms
              ?.map(symptom => symptom.name)
              ?.slice(0, 5) || []
          }
          stacked={stacked}
        />
      );
    });
}

TestProductList.propTypes = {
  testProducts: PropTypes.array.isRequired,
  familySlug: PropTypes.string
};

export default TestProductList;
