import React, { useRef, useState } from "react";

import { AFFILIATE } from "../core/constants";
import { theme } from "../core/theme";
import { PARTNER_USER_PROFILE_QUERY } from "../graphql/accounts";
import useDocTitle from "../hooks/use-doc-title";
import headerImage from "../images/partners-header.jpg";
import ConnectedUsers from "../tpo/ConnectedUsers";
import AffiliateIncome from "./AffiliateIncome";
import AffiliateLinkAndDiscountCode from "./AffiliateLinkAndDiscountCode";
import { useAuthContext } from "./AuthProvider";
import BackgroundImage from "./BackgroundImage";
import Box from "./Box";
import { SpacedPanelBox } from "./Boxes";
import CenteredHeader from "./CenteredHeader";
import CreatePartnerInvitation from "./CreatePartnerInvitation";
import DataLoader from "./DataLoader";
import { HeadingExtraExtraLarge, HeadingLarge } from "./Headings";
import Page from "./Page";
import PartnerApplicationForm from "./PartnerApplicationForm";
import SwitcherHeader from "./SwitcherHeader";
import Text from "./Text";

export function PartnersDashboardHeader(props) {
  return <SwitcherHeader backgroundColor="partners" {...props} />;
}

export function PartnersCenteredHeader(props) {
  return <CenteredHeader color="dark" backgroundColor="partners" {...props} />;
}

function PartnersImageHeader() {
  return (
    <BackgroundImage backgroundColor={theme.colors.purple} backgroundImage={headerImage}>
      <Box px={20} pt={[160, 160, 240, 320]} pb={[40, 80, 120, 160]}>
        <Box maxWidth={1200} mx="auto">
          <HeadingExtraExtraLarge color="white" as={"h1"}>
            Partners
          </HeadingExtraExtraLarge>
        </Box>
      </Box>
    </BackgroundImage>
  );
}

function PartnersPageBase({ header, children }) {
  return (
    <Page backgroundColor="haze" header={header}>
      <Box pb={theme.spacing.large}>{children}</Box>
    </Page>
  );
}

function PartnersSignup({ handleSubmitted, initialTier }) {
  const tier = initialTier ? initialTier : AFFILIATE;
  const scrollToRef = useRef(null);
  return (
    <PartnersPageBase header={<PartnersImageHeader />}>
      <div ref={scrollToRef}>
        <SpacedPanelBox>
          <Box maxWidth={1000} mx="auto">
            <HeadingLarge pb level="h2">
              {tier} partner programme
            </HeadingLarge>
            {tier === AFFILIATE ? (
              <Text>
                Complete the following information to become an Omnos affiliate and start gaining
                benefits
              </Text>
            ) : (
              <Text>
                Complete the application form below to apply to be an Omnos Practitioner and start
                gaining benefits. Once your application is recieved and reviewed you will get a
                breakdown of how our practitioner programme works. Alternatively please get in touch
                with partners@omnos.me
              </Text>
            )}
          </Box>
          <Box pt={[0, 20, 40, 40]}>
            <PartnerApplicationForm tier={tier} handleSubmitted={handleSubmitted} />
          </Box>
        </SpacedPanelBox>
      </div>
    </PartnersPageBase>
  );
}

function PartnerApplicationSubmitted() {
  return (
    <PartnersPageBase header={<PartnersImageHeader />}>
      <SpacedPanelBox innerMaxWidth={600}>
        <HeadingLarge pb pt as="h2">
          Partners application submitted, we'll be in touch soon
        </HeadingLarge>
      </SpacedPanelBox>
    </PartnersPageBase>
  );
}

function PartnerDashboard({ partnerUserProfile }) {
  return (
    <>
      <PartnersPageBase
        header={
          <PartnersDashboardHeader>{partnerUserProfile.tier} Dashboard</PartnersDashboardHeader>
        }
      >
        <Box maxWidth={800} mx="auto" pt={theme.spacing.large} px={theme.spacing.medium}>
          <HeadingLarge pb level="h2">
            Welcome to your Omnos partner dashboard
          </HeadingLarge>
          <Text>
            As a practitioner and affiliate of Omnos we will record all commission that you gather
            from sales that are attributed to you. All commission items need to be claimed within 12
            months of creation. please send an invoice to claim your commission to{" "}
            <a href="mailto://finance@omnos.me">finance@omnos.me</a>.
          </Text>
        </Box>
        <AffiliateLinkAndDiscountCode
          hashID={partnerUserProfile.user.hashId}
          discountCodes={partnerUserProfile.discountSet}
          tier={partnerUserProfile.tier}
        />
        {partnerUserProfile.tier === AFFILIATE ? (
          <>
            <AffiliateIncome
              userHashId={partnerUserProfile.user.hashId}
              tier={partnerUserProfile.tier}
            />
          </>
        ) : (
          <>
            <CreatePartnerInvitation
              tier={partnerUserProfile.tier}
              userHashId={partnerUserProfile.user.hashId}
            />
            <ConnectedUsers />
            <AffiliateIncome userHashId={partnerUserProfile.user.hashId} />
          </>
        )}
      </PartnersPageBase>
    </>
  );
}

function PartnersPage({ tier }) {
  useDocTitle("Partners");
  const { user } = useAuthContext();
  const [formSubmitted, setFormSubmitted] = useState(false);
  if (formSubmitted) {
    return <PartnerApplicationSubmitted />;
  }
  if (user) {
    return (
      <DataLoader
        query={PARTNER_USER_PROFILE_QUERY}
        render={({ partnerUserProfile }) => {
          if (partnerUserProfile === null) {
            if (!tier) {
              window.location.href = "https://www.omnos.me/partners";
              return null;
            } else {
              return (
                <PartnersSignup
                  handleSubmitted={() => {
                    setFormSubmitted(true);
                  }}
                  initialTier={tier}
                />
              );
            }
          }
          if (!partnerUserProfile.approved) {
            return <PartnerApplicationSubmitted />;
          }
          return <PartnerDashboard partnerUserProfile={partnerUserProfile} />;
        }}
      />
    );
  }
  return (
    <PartnersSignup
      handleSubmitted={() => {
        setFormSubmitted(true);
      }}
      initialTier={tier}
    />
  );
}

export default PartnersPage;
