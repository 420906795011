import Box from "../components/Box";
import { SpacedContentBox } from "./Boxes";

function Jumbotron({ children, ...rest }) {
  return (
    <SpacedContentBox
      bg="purple"
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      height={[120, 140]}
      maxWidth={[null, 647, 647, 1040]}
      {...rest}
    >
      <Box px={20}>{children}</Box>
    </SpacedContentBox>
  );
}

Jumbotron.defaultProps = {
  insideHeader: true
};

export default Jumbotron;
