import React, { useState } from "react";

import propTypes from "@styled-system/prop-types";
import PropTypes from "prop-types";

import Box from "./Box";
import Text from "./Text";

function Switcher({
  bg1,
  bg2,
  current: _current,
  dataComponentName,
  handleOption1,
  handleOption2,
  option1,
  option2,
  height,
  maxWidth,
  letterSpacing,

  borderRadius,
  borderRadiusInner,
  borderStyle,
  borderWidth,
  ...rest
}) {
  const [current, setCurrent] = useState(_current);

  return (
    <Box
      bg={bg1}
      borderRadius={borderRadius}
      borderColor={bg1}
      dataComponentName={dataComponentName}
      borderWidth={borderWidth}
      borderStyle={borderStyle}
      height={height}
      mx="auto"
      maxWidth={maxWidth}
      position="relative"
      width="100%"
      display="flex"
      {...rest}
    >
      <Box
        bg={bg2}
        borderRadius={borderRadiusInner}
        borderStyle={borderStyle}
        height="100%"
        top={0}
        left={0}
        width="50%"
        position="absolute"
        style={{
          transition: "transform 250ms",
          transform: `translate3D(${current === 1 ? 0 : "100%"}, 0, 0)`
        }}
      />
      <Box
        as="button"
        position="relative"
        width="50%"
        color={current === 1 ? bg1 : bg2}
        style={{ transition: "color 250ms" }}
        onClick={() => {
          setCurrent(1);
          handleOption1 && handleOption1();
        }}
      >
        <Text
          as="span"
          display="block"
          marginCorrection={false}
          fontFamily="gilroyBold"
          fontSize={12}
          uppercase
          letterSpacing={letterSpacing}
        >
          {option1}
        </Text>
      </Box>
      <Box
        as="button"
        position="relative"
        width="50%"
        color={current === 2 ? bg1 : bg2}
        style={{ transition: "color 250ms" }}
        onClick={() => {
          setCurrent(2);
          handleOption2 && handleOption2();
        }}
      >
        <Text
          as="span"
          display="block"
          marginCorrection={false}
          fontFamily="gilroyBold"
          fontSize={12}
          uppercase
          letterSpacing={letterSpacing}
        >
          {option2}
        </Text>
      </Box>
    </Box>
  );
}

Switcher.defaultProps = {
  bg1: "white",
  bg2: "dark",
  borderColor: "white",
  borderRadius: "7px",
  borderRadiusInner: "5px",
  borderStyle: "solid",
  borderWidth: 3,
  className: "",
  height: [50, 50, 60, 60],
  maxWidth: 340,
  letterSpacing: "0.1em"
};

Switcher.propTypes = {
  bg1: propTypes.color.bg,
  bg2: propTypes.color.bg,
  className: PropTypes.string,
  handleOption1: PropTypes.func.isRequired,
  handleOption2: PropTypes.func.isRequired,
  current: PropTypes.number.isRequired,
  option1: PropTypes.string.isRequired,
  option2: PropTypes.string.isRequired
};

export default Switcher;
