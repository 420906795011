import React, { useState } from "react";

import { useParams } from "react-router-dom";

import { AnimatePresence } from "framer-motion";
import gql from "graphql-tag";

import { THINGS_TO_AVOID, THINGS_TO_DO } from "../core/constants";
import { theme } from "../core/theme";
import { TESTS_URL, getTestResultUrl, getPartnerTestResultUrl } from "../core/urls";
import { sortByKey } from "../core/utils";
import Box from "./Box";
import DataLoader from "./DataLoader";
import Expand, { FullWidthTop } from "./Expand";
import FadeIn from "./FadeIn";
import { InternalTextLink } from "./Links";
import Stack from "./Stack";
import Switcher from "./Switcher";
import Text from "./Text";

export const EXAMPLE_FOODS_QUERY = gql`
  query ExampleFoodsQuery($userId: ID) {
    foodsToDo: examples(userId: $userId, verb: "Increase", category: "Food") {
      id
      slug
      name
      parentCategory {
        id
        name
      }
    }
    foodsToAvoid: examples(userId: $userId, verb: "Reduce", category: "Food") {
      id
      slug
      name
      parentCategory {
        id
        name
      }
    }
  }
`;

export const EXAMPLE_QUERY = gql`
  query Example($exampleId: ID, $userId: ID, $verbGroup: VerbGroups) {
    example(exampleId: $exampleId) {
      id
      name
      relatedUserResults(userId: $userId, verbGroup: $verbGroup) {
        id
        result {
          id
          name
        }
      }
    }
  }
`;

function FoodExampleRecommendationAndWhys({ clientId, name, id, currentFilter }) {
  return (
    <Expand
      push={true}
      bg="white"
      color="dark"
      renderTop={(open, setOpen) => {
        return (
          <FullWidthTop open={open} setOpen={setOpen} p={20} color="dark">
            <Text fontFamily="gilroyBold" fontSize={20}>
              {name}
            </Text>
          </FullWidthTop>
        );
      }}
      renderBottom={() => {
        return (
          <Box p={20}>
            <DataLoader
              query={EXAMPLE_QUERY}
              variables={{
                exampleId: id,
                userId: clientId,
                verbGroup: currentFilter.replace(/\s/g, "_")
              }}
              render={({ example }) => {
                return (
                  <>
                    <>
                      {example.relatedUserResults.length > 0 ? (
                        <Text fontFamily="gilroyBold" as="h4" fontSize={18} pb={"30px"}>
                          Related results
                        </Text>
                      ) : (
                        <Text fontSize={16} pb={"30px"}>
                          Linked to symptom questionnaire or lower level biomarkers
                        </Text>
                      )}
                    </>
                    <Box pl={[20, null, 40, null]}>
                      <Stack itemBoxProps={{ pt: "15px", pb: "15px" }}>
                        {example.relatedUserResults.map(result => (
                          <InternalTextLink
                            key={result.id}
                            href={
                              clientId
                                ? getPartnerTestResultUrl(clientId, result.id)
                                : getTestResultUrl(result.id)
                            }
                            display="block"
                          >
                            {result.result.name}
                          </InternalTextLink>
                        ))}
                      </Stack>
                    </Box>
                  </>
                );
              }}
            />
          </Box>
        );
      }}
    />
  );
}

function FoodGroup({ name, examples, currentFilter }) {
  return (
    <>
      <Text fontFamily="gilroyBold" as="h2" pb={40} pt={[60, 60, 80, 80]} fontSize={28}>
        {name}
      </Text>
      <Stack itemBoxProps={{ pt: "10px", pb: "10px" }}>
        {examples.map((example, index) => {
          return (
            <FoodExampleRecommendationAndWhys
              key={index}
              name={example.name}
              id={example.id}
              currentFilter={currentFilter}
            />
          );
        })}
      </Stack>
    </>
  );
}

function objectToArray(obj) {
  return Object.keys(obj).map(key => {
    return { key: key, value: obj[key] };
  });
}

function groupByParentCategory(examples) {
  let grouped = {};
  examples.forEach(example => {
    const key = example?.parentCategory?.name || "Misc";
    if (grouped[key] === undefined) {
      grouped[key] = {};
    }
    grouped[key][example.id] = example.name;
    return null;
  });

  grouped = objectToArray(grouped);
  grouped = grouped.map(group => {
    const examples = objectToArray(group.value).map(item => {
      return {
        id: item.key,
        name: item.value
      };
    });
    return {
      name: group.key,
      examples
    };
  });
  grouped = sortByKey(grouped, "name");
  return grouped;
}

export function removeFoodConflicts(foodsToDo, foodsToAvoid) {
  let toAvoidIds = foodsToAvoid.map(food => food.id);
  console.log(foodsToDo.map(food => food.id).filter(id => toAvoidIds.includes(id)));
  return foodsToDo.filter(food => toAvoidIds.indexOf(food.id) === -1);
}

function FoodListContent({ clientId, foodsToDo, foodsToAvoid, isPartnerView }) {
  const [currentFilter, setCurrentFilter] = useState(THINGS_TO_DO);
  const foodsToDoNoConflict = removeFoodConflicts(foodsToDo, foodsToAvoid);

  if (!foodsToDoNoConflict?.length && !foodsToAvoid?.length) {
    return (
      <Box
        pt={theme.spacing.large}
        pb={theme.spacing.large}
        px={20}
        data-component-name="Food list"
      >
        {isPartnerView ? (
          <Text pt={theme.spacing.large} textAlign="center">
            This user has yet to complete a test to reveal their personalised food list.
          </Text>
        ) : (
          <Text pt={theme.spacing.large} textAlign="center">
            Please complete any <InternalTextLink href={TESTS_URL}>test</InternalTextLink> to reveal
            your personalised food list
          </Text>
        )}
      </Box>
    );
  }

  const foodsToDoGrouped = groupByParentCategory(foodsToDoNoConflict);
  const foodsToAvoidGrouped = groupByParentCategory(foodsToAvoid);
  const foodsGrouped = {
    [THINGS_TO_DO]: foodsToDoGrouped,
    [THINGS_TO_AVOID]: foodsToAvoidGrouped
  };

  const examples = currentFilter === THINGS_TO_DO ? foodsToDoNoConflict : foodsToAvoid;

  return (
    <Box pt={theme.spacing.large} pb={theme.spacing.large} px={20} data-component-name="Food list">
      <Switcher
        current={1}
        option1="Increase"
        option2="Avoid"
        handleOption1={() => {
          setCurrentFilter(THINGS_TO_DO);
        }}
        handleOption2={() => {
          setCurrentFilter(THINGS_TO_AVOID);
        }}
      />
      {isPartnerView && <Box pt={4} />}
      <Box>
        <Box maxWidth={800} mx="auto">
          <AnimatePresence initial={false} exitBeforeEnter>
            <FadeIn key={currentFilter}>
              {isPartnerView ? (
                <Stack itemBoxProps={{ pt: "10px", pb: "10px" }}>
                  {examples.map(example => (
                    <FoodExampleRecommendationAndWhys
                      key={example.id}
                      name={example.name}
                      id={example.id}
                      currentFilter={currentFilter}
                      clientId={clientId}
                    />
                  ))}
                </Stack>
              ) : (
                foodsGrouped[currentFilter].map((foodGroup, index) => {
                  return (
                    <FoodGroup
                      key={index}
                      name={foodGroup.name}
                      examples={foodGroup.examples}
                      currentFilter={currentFilter}
                    />
                  );
                })
              )}
            </FadeIn>
          </AnimatePresence>
        </Box>
      </Box>
    </Box>
  );
}

function FoodList() {
  const { clientId } = useParams();

  return (
    <DataLoader
      query={EXAMPLE_FOODS_QUERY}
      variables={{
        userId: clientId
      }}
      render={({ foodsToDo, foodsToAvoid }) => {
        return (
          <FoodListContent
            foodsToDo={foodsToDo}
            foodsToAvoid={foodsToAvoid}
            isPartnerView={!!clientId}
            clientId={clientId}
          />
        );
      }}
    />
  );
}

export default FoodList;
