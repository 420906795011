import { round } from "lodash";

import { constructGradientFromTerms } from "../../tpo/Charts";

export function useGradientGraph({
  chartConfiguration: {
    normalisedRange,
    chartRange: { range },
    terminology
  },
  userDatapoints
}) {
  if (normalisedRange.range.length !== range.length) return null;

  const { minimum, maximum } = normalisedRange;

  const fullRange = [
    {
      value: minimum
    },
    ...normalisedRange.range.map((point, index) => ({
      value: round(point, 2),
      label: round(range[index], 2)
    })),
    {
      value: maximum
    }
  ];

  const background = constructGradientFromTerms(terminology.join(","), "180deg");

  const points = userDatapoints.map(userDatapoint => {
    const {
      userTest: { created: x },
      chartConfiguration: {
        normalisedRange: { value }
      }
    } = userDatapoint;

    const boundedValue = round(Math.max(minimum, Math.min(value, maximum)), 2);
    const upperLimitIndex = fullRange.findIndex(point => boundedValue <= point.value);
    const lowerLimitIndex = upperLimitIndex > 0 ? upperLimitIndex - 1 : 0;

    let y;

    if (upperLimitIndex === lowerLimitIndex) {
      y = upperLimitIndex;
    } else {
      const min = fullRange[lowerLimitIndex].value;
      const max = fullRange[upperLimitIndex].value;
      const ratio = (boundedValue - min) / (max - min);
      const distance = upperLimitIndex - lowerLimitIndex;
      y = lowerLimitIndex + distance * ratio;
    }

    return [x, y];
  });

  const getInterpretationColor = interpretation => {
    let color = "#818181";
    if (interpretation?.toLowerCase() === "optimal") {
      color = "#00BF86";
    } else if (
      interpretation?.toLowerCase() === "below optimal" ||
      interpretation?.toLowerCase() === "above optimal"
    ) {
      color = "#FCA557";
    } else if (
      interpretation?.toLowerCase() === "low" ||
      interpretation?.toLowerCase() === "high"
    ) {
      color = "#FF5D6D";
    }
    return color;
  };

  return {
    background,
    range,
    fullRange,
    points,
    tooltips: userDatapoints.map((userDatapoint, index) => {
      const roundedValue = round(userDatapoint.value, 2);
      const value = `${roundedValue} ${userDatapoint.datapoint.units || ""}`;
      const created = new Date(userDatapoint.userTest.created).toLocaleDateString(undefined, {
        day: "numeric",
        month: "long",
        year: "numeric"
      });

      const stack = [
        `<div class='value_and_units'>${value}</div>`,
        `<div class='interpretation' style='color: ${getInterpretationColor(
          userDatapoint.chartConfiguration.interpretation
        )};'>${userDatapoint.chartConfiguration.interpretation}</div>`,
        `<div class='date'>${created}</div>`
      ];

      if (
        userDatapoints.length - 1 === index &&
        userDatapoint.dateExpired &&
        new Date(userDatapoint.dateExpired) <= new Date()
      ) {
        stack.push("<div class='retest'>(Due for retest)</div>");
      }

      return stack;
    }),
    colors: userDatapoints.map(userDatapoint => {
      return new Date(userDatapoint.dateExpired) <= new Date()
        ? "#818181"
        : getInterpretationColor(userDatapoint.chartConfiguration.interpretation);
    })
  };
}
