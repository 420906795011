import Box from "./Box";

export default function Circle({ children, size, backgroundColor, ...props }) {
  return (
    <Box
      flexShrink={0}
      borderRadius="50%"
      size={size}
      backgroundColor={backgroundColor}
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      {children}
    </Box>
  );
}

Circle.defaultProps = {
  backgroundColor: "rgba(0,0,0,0.08)",
  arrowColor: "dark",
  size: 40,
  direction: "right"
};
