import styled from "styled-components";
import { color, layout, position, space, system, typography } from "styled-system";

export const SpanText = styled.span`
  ${color}
  ${layout}
  ${typography}
  ${space}
  ${system({
    textTransform: {
      property: "text-transform"
    }
  })}
  text-decoration: ${props => {
    if (props.underline) return "underline";
  }};
  text-transform: ${props => {
    if (props.uppercase) return "uppercase";
  }};
`;

function getWhiteSpace(fontSize, lineHeight, capSize, capOffset) {
  const totalSize = fontSize * lineHeight;
  const capHeight = fontSize * capSize;
  const whiteSpace = (totalSize - capHeight) / 2;
  return {
    top: whiteSpace - capOffset * fontSize,
    bottom: whiteSpace + capOffset * fontSize
  };
}

const Text = styled.p`
  ${color}
  ${space}
  ${layout}
  ${typography}
  ${position}
  ${system({
    cursor: {
      property: "cursor"
    },
    flexGrow: {
      property: "flex-grow"
    },
    textAlign: {
      property: "text-align"
    },
    textDecoration: {
      property: "text-decoration"
    },
    textTransform: {
      property: "text-transform"
    },
    whiteSpace: {
      property: "white-space"
    }
  })}


  ${props => {
    if (props.marginCorrection) {
      const capSize = props.theme.capSizes[props.fontFamily];
      const capHeight = capSize.height;
      const capOffset = capSize.offset;
      if (Array.isArray(props.fontSize)) {
        const whiteSpace = getWhiteSpace(props.fontSize[0], props.lineHeight, capHeight, capOffset);
        const defaultValue = `
        &::before {
          margin-top: ${-whiteSpace.top}px;
        }
        &::after {
          margin-bottom: ${-whiteSpace.bottom}px;
        }
      `;
        const mqValues = Object.keys(props.theme.mediaQueries).map((key, index) => {
          const whiteSpace = getWhiteSpace(
            props.fontSize[index + 1],
            props.lineHeight,
            capHeight,
            capOffset
          );
          return `${props.theme.mediaQueries[key]} {
          &::before {
            margin-top: ${-whiteSpace.top}px;
          }
          &::after {
            margin-bottom: ${-whiteSpace.bottom}px;
          }
        }`;
        });
        return defaultValue + mqValues.join("");
      } else {
        const whiteSpace = getWhiteSpace(props.fontSize, props.lineHeight, capHeight, capOffset);
        return `
        &::before {
          margin-top: ${-whiteSpace.top}px;
        }
        &::after {
          margin-bottom: ${-whiteSpace.bottom}px;
        }
      `;
      }
    }
  }}
  ${props => {
    if (props.marginCorrection) {
      return `
        &::before {
          content: "";
          display: block;
        }
        &::after {
          content: "";
          display: block;
        }
      `;
    }
  }}
  text-decoration: ${props => {
    if (props.underline) return "underline";
  }};
  text-transform: ${props => {
    if (props.uppercase) return "uppercase";
  }};
`;

Text.defaultProps = {
  fontSize: 16,
  fontFamily: "gilroyMedium",
  underline: false,
  marginCorrection: true,
  lineHeight: 1.4
};

export default Text;
