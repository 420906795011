import PropTypes from "prop-types";

import { useAppState } from "../components/AppStateProvider";
import Box from "../components/Box";

function Strip({ y, r, o }) {
  const width = 25;
  const height = 3;

  return (
    <Box
      width={width}
      height={height}
      position="absolute"
      opacity={o}
      bg="white"
      top="50%"
      left="50%"
      ml={`-${width / 2}px`}
      mt={`-${height / 2}px`}
      borderRadius="999999px"
      style={{
        transition: "transform 250ms, opacity 250ms",
        transform: `translateY(${y}px) rotate(${r}deg)`
      }}
    />
  );
}

Strip.propTypes = {
  y: PropTypes.number,
  r: PropTypes.number,
  o: PropTypes.number
};

Strip.defaultProps = {
  y: 0,
  r: 0,
  o: 100
};

export default function BurgerButton() {
  const { menuOpen, setMenuOpen } = useAppState();

  const y = menuOpen ? 0 : 8;
  const r = menuOpen ? 45 : 0;
  const o = menuOpen ? 0 : 100;

  return (
    <Box
      as="button"
      onClick={() => {
        setMenuOpen(!menuOpen);
      }}
      data-component-name="Burger button"
      height={50}
      width={50}
      position="relative"
    >
      <Strip y={y} r={r} />
      <Strip o={o} />
      <Strip y={-y} r={-r} />
    </Box>
  );
}
