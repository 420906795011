import { gql } from "@apollo/client";

import {
  CHART_CONFIGURATION_FIELDS,
  USER_RESULT_FIELDS_TRUNCATED
} from "../graphql/tpo/results/types";
import { ChartConfigurationContext } from "./ChartCards";
import {
  DiscreteChartCardNEW,
  GenesAllelesChartCardNew,
  GradientsChartsCardNew,
  SegmentedChartChardNew,
  UnknownChartCardNew
} from "./ChartCardsNew";

export function DatapointCharts({ children, datapoint }) {
  if (datapoint.chartConfiguration?.chartType === "GRADIENT_CHART") {
    return (
      <ChartConfigurationContext.Provider value={datapoint.chartConfiguration}>
        <GradientsChartsCardNew datapoint={datapoint}>{children}</GradientsChartsCardNew>
      </ChartConfigurationContext.Provider>
    );
  } else if (datapoint.chartConfiguration?.chartType === "DISCRETE_CHART") {
    return (
      <ChartConfigurationContext.Provider value={datapoint.chartConfiguration}>
        <DiscreteChartCardNEW result={datapoint}>{children}</DiscreteChartCardNEW>
      </ChartConfigurationContext.Provider>
    );
  } else if (datapoint.chartConfiguration?.chartType === "SEGMENTED_CHART") {
    return (
      <ChartConfigurationContext.Provider value={datapoint.chartConfiguration}>
        <SegmentedChartChardNew datapoint={datapoint}>{children}</SegmentedChartChardNew>
      </ChartConfigurationContext.Provider>
    );
  } else if (datapoint.datapoint.chartType === "GENES_ALLELES_CHART") {
    return (
      <ChartConfigurationContext.Provider value={datapoint.chartConfiguration}>
        <GenesAllelesChartCardNew datapoint={datapoint}>{children}</GenesAllelesChartCardNew>
      </ChartConfigurationContext.Provider>
    );
  }
  return (
    <ChartConfigurationContext.Provider value={datapoint.chartConfiguration}>
      <UnknownChartCardNew result={datapoint}>{children}</UnknownChartCardNew>
    </ChartConfigurationContext.Provider>
  );
}

export const RELATED_RESULTS_QUERY = gql`
  query RELATED_RESULTS_QUERY($userDatapointId: ID!, $userId: ID) {
    userDatapoint(userDatapointId: $userDatapointId, userId: $userId) {
      id
      uploaded
      results {
        ...UserResultFieldsTruncated
        isHidden
        resultState {
          id
          name
        }
        chartConfiguration {
          ...ChartConfigurationFields
        }
      }
    }
  }
  ${CHART_CONFIGURATION_FIELDS}
  ${USER_RESULT_FIELDS_TRUNCATED}
`;
