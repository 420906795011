import styled from "styled-components";

import Box from "../components/Box";
import { theme } from "../core/theme";
import { ReactComponent as SearchIcon } from "../images/tpo/search.svg";

const InputWrapper = styled(Box)`
  position: relative;
  border-radius: 100px;
  font-size: 16px;
  color: #818181;
  font-family: "Gilroy W05 Regular";
  line-height: 24px;

  input {
    padding: 10px;
    padding-right: 60px;
    padding-left: 30px;
    background-color: transparent;
    height: 100%;
  }

  .icon {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

function TextInput({ name, onChange, value, placeholder, rightIcon, ...props }) {
  return (
    <InputWrapper rightIcon {...props}>
      <input placeholder={placeholder} name={name} onChange={onChange} value={value} />
      {rightIcon && <Box className="icon">{rightIcon}</Box>}
    </InputWrapper>
  );
}

TextInput.defaultProps = {
  bg: "white"
};

export default TextInput;

export function Search({ value, onChange }) {
  return (
    <TextInput
      placeholder="Search"
      onChange={e => onChange(e.target.value)}
      value={value}
      rightIcon={<SearchIcon fill={theme.colors.dark} />}
      bg="haze"
    />
  );
}
