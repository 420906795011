import { useCallback, useContext, useState } from "react";

import { Redirect, useHistory, useParams } from "react-router-dom";

import { gql } from "@apollo/client";
import propTypes from "@styled-system/prop-types";
import { isUndefined } from "lodash";
import PropTypes from "prop-types";

import Box from "../components/Box";
import DataLoader from "../components/DataLoader";
import { DownloadCrossOriginFile } from "../components/Links";
import Loading from "../components/Loading";
import Page from "../components/Page";
import Slider from "../components/Slider";
import Text from "../components/Text";
import {
  ERROR_404_URL,
  getPartnerTestResultsUrl,
  getTestResultsUrl,
  getThemeUrl
} from "../core/urls";
import {
  CHART_CONFIGURATION_FIELDS,
  RANGE_DEFINITION_FIELDS,
  RESULT_TYPE_FIELDS,
  USER_DATAPOINT_FIELDS_TRUNCATED,
  USER_TEST_FIELDS_TRUNCATED
} from "../graphql/tpo/results/types";
import { useDataLoader } from "../hooks/useDataLoader";
import Center from "./Center";
import ChartModal from "./ChartModal";
import { SolidChevronButton } from "./ChevronButton";
import { CollapseableText } from "./CollapseableText";
import { NoItems } from "./EmptySearch";
import { IncludeExpiredMarkers } from "./ExpiredMarkers";
import { HeadingLarge } from "./Headings";
import InfiniteScroll, { InfiniteScrollContext } from "./InfiniteScroll";
import { LazyTabs, TabsContext } from "./LazyTabs";
import Menu, { Button } from "./Menu";
import { PanelBoxV2 } from "./NewBoxes";
import JumbotronV2 from "./NewJumbotron";
import SegmentedControl from "./SegmentedControl";
import Spacer from "./Spacer";
import Stack from "./Stack";
import {
  DatapointChartCard,
  GeneDatapointSummary,
  USER_DATAPOINT_QUERY,
  UserDatapointModelContent
} from "./UserDatapoint";
import {
  RelatedResultContext,
  ResultChartCard,
  USER_RESULT_QUERY,
  UserResultModelContent
} from "./UserResult";
import { CardListContext, Explanation } from "./UserResults";
import { Search, SortMenu, useCardList, useListControls } from "./UserSubsector";
import UserTestsLoader from "./UserTestsLoader";
import { ViewerContext } from "./Viewer";

const LAB_REPORTS_QUERY = gql`
  query LabReportsQuery($userTestId: ID!, $userId: ID) {
    userTest(userTestId: $userTestId, userId: $userId) {
      id
      dataFiles {
        id
        name
        dataFileUrl
        testDataFile {
          id
          fileType {
            id
            name
            contentType
            extension
          }
        }
      }
    }
  }
`;

export function LabReports({ userTest, clientId }) {
  return (
    <PanelBoxV2 maxWidth={1020} stacked gap={20}>
      <PanelBoxV2
        maxWidth={760}
        outer={{
          pt: [30, 30, 60],
          pb: [30, 30, 80],
          bg: "white",
          px: [20]
        }}
        stacked
        gap={[10, 10, 20]}
      >
        <Box fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
          Lab Reports
        </Box>
        {userTest && (
          <DataLoader
            query={LAB_REPORTS_QUERY}
            variables={{
              userTestId: userTest.id,
              userId: clientId
            }}
            render={({ userTest: { dataFiles } }) =>
              dataFiles.length ? (
                <Box display="flex" flexDirection="column" gap={[20, 10]}>
                  {dataFiles
                    .filter(userTestDataFile => userTestDataFile.dataFileUrl)
                    .map(userTestDataFile => (
                      <Box
                        display="flex"
                        flexDirection={["column", "row"]}
                        justifyContent={[null, "space-between"]}
                        gap={[10, null, null]}
                        key={userTestDataFile.id}
                      >
                        <Center
                          fontFamily="gilroyBold"
                          fontSize={[14, 16, 16]}
                          justifyContent={["flex-start", "flex-start", "center"]}
                        >
                          {userTestDataFile.name}
                        </Center>
                        <DownloadCrossOriginFile
                          fileUrl={userTestDataFile.dataFileUrl}
                          fileName={`${userTestDataFile.name}.${userTestDataFile.testDataFile.fileType.extension}`}
                          contentType={userTestDataFile.testDataFile.fileType.contentType}
                          trigger={
                            <Box display="flex">
                              <SolidChevronButton
                                bg="green"
                                borderColor="green"
                                chevronDir="bottom"
                              >
                                Download
                              </SolidChevronButton>
                            </Box>
                          }
                        />
                      </Box>
                    ))}
                </Box>
              ) : (
                <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
                  No reports available at this time
                </Box>
              )
            }
          />
        )}
      </PanelBoxV2>
    </PanelBoxV2>
  );
}

const ALL_TESTS_QUERY = gql`
  query AllTestquery($userId: ID, $allTests: Boolean) {
    allUserTests: userTests(userId: $userId, allTests: $allTests) {
      ...UserTestFieldsTruncated
    }
  }
  ${USER_TEST_FIELDS_TRUNCATED}
`;

const verticalSpace = [22, 26, 32, 40];

export function UserTestsCarousel({
  bg,
  buttonBg,
  handlePrev,
  handleNext,
  index,
  userTests,
  height,
  ...rest
}) {
  return (
    <Box dataComponentName="IconCarousel" position="relative" bg={bg} {...rest}>
      <Slider
        buttonInset={["10%", "10%", "-40px", "-40px"]}
        currentIndex={index}
        handleNext={handleNext}
        handlePrev={handlePrev}
        buttonBg={buttonBg}
      >
        {userTests.map((userTest, index) => {
          return (
            <Box
              key={index}
              data-component-name="Slide"
              display="flex"
              justifyContent="center"
              alignItems="center"
              bg={userTest.bg}
              py={verticalSpace}
              height={height}
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box
                  data-component-name="Period symbol"
                  // Periodic symbol
                  borderColor="dark"
                  borderWidth="3px"
                  borderRadius="33%"
                  borderStyle="solid"
                  size={115}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text fontSize={65} fontFamily="dinBold">
                    {userTest.name[0]}
                  </Text>
                </Box>
                <Text
                  fontFamily="gilroyBold"
                  as="h3"
                  fontSize={[16, 28]}
                  pt={4}
                  textAlign="center"
                  textTransform="capitalize"
                  marginCorrection={false}
                >
                  {userTest.name}
                </Text>
                <Text
                  fontFamily="gilroyBold"
                  fontSize={[12, 16]}
                  textAlign="center"
                  marginCorrection={false}
                >
                  {userTest.date}
                </Text>
              </Box>
            </Box>
          );
        })}
      </Slider>
    </Box>
  );
}

UserTestsCarousel.propTypes = {
  bg: propTypes.color.bg,
  handlePrev: PropTypes.func,
  handleNext: PropTypes.func,
  sectorIndex: PropTypes.number,
  sectors: PropTypes.arrayOf(PropTypes.object)
};

UserTestsCarousel.defaultProps = {
  bg: "haze",
  height: [220, 300]
};

const TEST_RESULTS_QUERY = gql`
  query TestResultsQuery(
    $userId: ID
    $userTestId: ID!
    $first: Int!
    $after: String
    $orderBy: String
    $displayGroup: String
  ) {
    userTest(userId: $userId, userTestId: $userTestId) {
      ...UserTestFieldsTruncated
      userResultConnections(
        after: $after
        first: $first
        orderBy: $orderBy
        displayGroup: $displayGroup
      ) {
        edges {
          cursor
          node {
            id
            result {
              ...ResultTypeFields
            }
            chartConfiguration {
              ...ChartConfigurationFields
            }
            resultState {
              id
              name
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${USER_TEST_FIELDS_TRUNCATED}
  ${RESULT_TYPE_FIELDS}
  ${CHART_CONFIGURATION_FIELDS}
`;

const TEST_BIOMARKERS_CONNECTION_QUERY = gql`
  query TEST_BIOMARKERS_CONNECTIONS_QUERY(
    $userId: ID
    $userResultId: ID
    $userTestId: ID
    $first: Int!
    $after: String
    $search: String
    $orderBy: String
    $includeModifiers: Boolean
  ) {
    userDatapointConnections(
      userId: $userId
      userResultId: $userResultId
      userTestId: $userTestId
      after: $after
      first: $first
      search: $search
      orderBy: $orderBy
      includeModifiers: $includeModifiers
    ) {
      edges {
        cursor
        node {
          id
          uploaded
          datapoint {
            id
            name
            category
            units
            description
            function
            reason
            chartType
          }
          riskAllele
          value
          dueForRetest
          dateExpired
          chartConfiguration {
            ...ChartConfigurationFields
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${CHART_CONFIGURATION_FIELDS}
`;

const PAGE_SIZE = 10;

const listControls = [
  {
    label: "Results",
    value: "results"
  },
  {
    label: "Biomarkers",
    value: "biomarkers"
  }
];

function ImpactMenu({ open, setOpen, options, setValue, isSelected }) {
  return (
    <Menu opened={open} onChange={setOpen}>
      <Menu.Target>
        <Button lineHeight="24px" paddingY="16px" paddingX="40px" open={open}>
          Impact Areas
        </Button>
      </Menu.Target>
      <Menu.Dropdown mt={2} py={2} zIndex={2} minWidth={200} maxWidth={310}>
        {options.map(option => (
          <Menu.Item
            key={option.id}
            onClick={() => setValue(option.id)}
            selected={isSelected(option)}
            fontSize={14}
            lineHeight={1.7}
            py={16}
          >
            {option.name}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
}

function ImpactTitleAndDescription() {
  const { tab } = useContext(TabsContext);

  if (!tab?.name || !tab.id === "all") return null;

  return (
    <Box>
      {tab?.name && (
        <Box as="h2" fontFamily="gilroyBold" fontSize={[28]} lineHeight="150%">
          {tab?.name}
        </Box>
      )}
      {tab?.description && (
        <>
          <Box py={[7.5, 7.5, 15]} />
          <CollapseableText>{tab?.description}</CollapseableText>
        </>
      )}
    </Box>
  );
}

function GenesResults({ userTest, resultDisplayGroups }) {
  const viewerContext = useContext(ViewerContext);

  const tabs = resultDisplayGroups.map(({ id, name, description }) => ({
    id,
    description,
    name,
    query: TEST_RESULTS_QUERY,
    variables: {
      userId: viewerContext?.userId,
      userTestId: userTest.id,
      first: PAGE_SIZE,
      after: "",
      orderBy: "normalised_order",
      displayGroup: name
    }
  }));

  tabs.unshift({
    id: "all",
    name: "All Genes",
    query: TEST_RESULTS_QUERY,
    variables: {
      userId: viewerContext?.userId,
      userTestId: userTest.id,
      first: PAGE_SIZE,
      after: "",
      orderBy: "normalised_order"
    }
  });

  const [menuOpen, setMenuOpen] = useState(false);

  const ctx = useContext(CardListContext);

  return (
    <LazyTabs tabs={tabs} fetchPolicy="network-only" nextFetchPolicy="network-only">
      <TabsContext.Consumer>
        {({ tabs, isSelected, setTab, data, fetchMore, loading }) => (
          <>
            <PanelBoxV2
              maxWidth={760}
              inner={{
                display: "flex",
                flexDirection: "column",
                gap: [20, 20, 40]
              }}
            >
              <ImpactMenu
                open={menuOpen}
                setOpen={setMenuOpen}
                options={tabs}
                isSelected={isSelected}
                setValue={setTab}
              />
              <ImpactTitleAndDescription />
            </PanelBoxV2>
            <PanelBoxV2
              maxWidth={1020}
              inner={{
                display: "flex",
                flexDirection: "column",
                gap: 10
              }}
            >
              <InfiniteScroll
                loader={<Loading />}
                hasMore={data?.userTest?.userResultConnections?.pageInfo?.hasNextPage}
                loading={loading}
                next={() => {
                  const endCursor = data?.userTest?.userResultConnections?.pageInfo?.endCursor;
                  if (endCursor) {
                    fetchMore({
                      variables: {
                        after: endCursor,
                        first: 10
                      }
                    });
                  }
                }}
                items={data?.userTest?.userResultConnections?.edges?.map(edge => edge.node)}
              >
                <InfiniteScrollContext.Consumer>
                  {({ itemsList, setBottomElement }) => (
                    <>
                      {itemsList.slice(0, -1).map(item => (
                        <ResultChartCard
                          key={item.id}
                          userResult={item}
                          onClick={() => {
                            ctx?.viewCard({
                              type: "results",
                              item: item
                            });
                          }}
                        />
                      ))}
                      {itemsList.slice(-1).map(item => (
                        <ResultChartCard
                          key={item.id}
                          userResult={item}
                          onClick={() => {
                            ctx?.viewCard({
                              type: "results",
                              item: item
                            });
                          }}
                          ref={setBottomElement}
                        />
                      ))}
                    </>
                  )}
                </InfiniteScrollContext.Consumer>
              </InfiniteScroll>
            </PanelBoxV2>
          </>
        )}
      </TabsContext.Consumer>
    </LazyTabs>
  );
}

const GENES_BIOMARKERS_SORTING_OPTIONS = [
  {
    label: "Gene Name: A - Z",
    value: "datapoint__name"
  },
  {
    label: "Gene Name: Z - A",
    value: "-datapoint__name"
  }
];

function GenesBiomarkers({ userTest }) {
  const viewerContext = useContext(ViewerContext);

  const controls = useListControls({
    sortOptions: GENES_BIOMARKERS_SORTING_OPTIONS
  });

  const { data, loading, fetchMore } = useDataLoader({
    query: TEST_BIOMARKERS_CONNECTION_QUERY,
    variables: {
      userTestId: userTest.id,
      userId: viewerContext?.userId,
      first: PAGE_SIZE,
      after: "",
      search: controls.debouncedSearch,
      orderBy: GENES_BIOMARKERS_SORTING_OPTIONS.find(option => controls.sort === option.value)
        ?.value,
      includeModifiers: true
    }
  });

  const ctx = useContext(CardListContext);

  const items = data?.userDatapointConnections?.edges?.map(edge => edge.node);

  return (
    <>
      <PanelBoxV2
        maxWidth={[1020]}
        inner={{
          display: "flex",
          flexDirection: "column",
          gap: [20, 20, 40]
        }}
      >
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection={["column", "row", "row"]}
            gap={20}
            justifyContent={["center", "flex-end", "flex-end"]}
          >
            <Search value={controls.search} setValue={controls.setSearch} />
            <Box display="flex" justifyContent="flex-end">
              <SortMenu
                value={controls.sort}
                setValue={controls.setSort}
                open={controls.sortMenuOpen}
                setOpen={controls.setSortMenuOpen}
                options={controls.sortOptions}
              />
            </Box>
          </Box>
        </Box>
        {items?.length ? (
          <InfiniteScroll
            loader={<Loading />}
            hasMore={data?.userDatapointConnections?.pageInfo?.hasNextPage}
            loading={loading}
            next={() => {
              const endCursor = data?.userDatapointConnections?.pageInfo?.endCursor;
              if (endCursor) {
                fetchMore({
                  variables: {
                    after: endCursor,
                    first: 10
                  }
                });
              }
            }}
            items={items}
          >
            <Box py={[20, 20, 40]} borderRadius={5} bg="white">
              <Box bg="haze" display="flex" flexDirection="column" gap={1} pb="1px">
                <InfiniteScrollContext.Consumer>
                  {({ itemsList, setBottomElement }) => (
                    <>
                      {itemsList.slice(0, -1).map(item => (
                        <GeneDatapointSummary
                          userDatapoint={item}
                          key={item.id}
                          py={[2, 2, 20]}
                          px={[20, 20, 40]}
                          bg="white"
                          openModal={() => {
                            ctx?.viewCard({
                              type: "datapoints",
                              item
                            });
                          }}
                        />
                      ))}
                      {itemsList.slice(-1).map(item => (
                        <GeneDatapointSummary
                          userDatapoint={item}
                          key={item.id}
                          py={[2, 2, 20]}
                          px={[20, 20, 40]}
                          bg="white"
                          openModal={() => {
                            ctx?.viewCard({
                              type: "datapoints",
                              item
                            });
                          }}
                          ref={setBottomElement}
                        />
                      ))}
                    </>
                  )}
                </InfiniteScrollContext.Consumer>
              </Box>
            </Box>
          </InfiniteScroll>
        ) : (
          <NoItems search={controls.search} loading={loading} />
        )}
      </PanelBoxV2>
    </>
  );
}

function ModelContent({ type, item, isGenesTest }) {
  const viewerContext = useContext(ViewerContext);
  const clientId = viewerContext?.userId;

  if (type === "results") {
    return (
      <DataLoader
        query={USER_RESULT_QUERY}
        variables={{
          userId: clientId,
          userResultId: item.id
        }}
        render={({ userResult, relatedGenes }) => (
          <UserResultModelContent
            userResult={userResult}
            relatedGenes={relatedGenes}
            isGenesTest={isGenesTest}
          />
        )}
      />
    );
  } else if (type === "datapoints") {
    return (
      <DataLoader
        query={USER_DATAPOINT_QUERY}
        variables={{
          userDatapointId: item.id,
          userId: clientId
        }}
        render={({ userDatapoint }) => (
          <UserDatapointModelContent userDatapoint={userDatapoint} isGenesTest={isGenesTest} />
        )}
      />
    );
  }
  return null;
}

export function GenesTestContent({ userTest, clientId, resultDisplayGroups }) {
  const [list, setList] = useState("results");
  const { cardOpen, closeCard, viewCard, setCardOpen } = useCardList();

  return (
    <Stack gap={[20, 20, 40]}>
      <LabReports userTest={userTest} clientId={clientId} />
      <SegmentedControl
        data={listControls}
        onChange={setList}
        value={list}
        height={55}
        ml="auto"
        mr="auto"
      />
      <CardListContext.Provider
        value={{
          cardOpen,
          closeCard,
          viewCard,
          setCardOpen
        }}
      >
        {list === "results" && (
          <GenesResults resultDisplayGroups={resultDisplayGroups} userTest={userTest} />
        )}
        {list === "biomarkers" && <GenesBiomarkers userTest={userTest} />}
      </CardListContext.Provider>
      <ChartModal close={() => setCardOpen(undefined)} show={!!cardOpen?.type}>
        <ModelContent
          type={cardOpen?.type}
          item={cardOpen?.item}
          isGenesTest={userTest.isGenesTest}
        />
      </ChartModal>
    </Stack>
  );
}

export const GET_DISPLAY_GROUPS = gql`
  query GetDisplayGroups($userId: ID, $userTestId: ID!) {
    userTest(userId: $userId, userTestId: $userTestId) {
      id
      resultDisplayGroups {
        id
        name
        description
      }
    }
  }
`;

export function GenesTest({ userTest }) {
  const { clientId, userTestId } = useParams();

  return (
    <>
      <PanelBoxV2
        maxWidth={1538}
        outer={{
          pt: [30, 30, 60],
          pb: [50, 50, 80],
          px: [20, 20, 0]
        }}
      >
        <DataLoader
          query={GET_DISPLAY_GROUPS}
          variables={{
            userId: clientId,
            userTestId
          }}
          render={({ userTest: { resultDisplayGroups } }) => (
            <GenesTestContent resultDisplayGroups={resultDisplayGroups} userTest={userTest} />
          )}
        />
      </PanelBoxV2>
    </>
  );
}

function FullPictureBlock() {
  const history = useHistory();

  return (
    <PanelBoxV2 maxWidth={760}>
      <Box
        background="#5220DD linear-gradient(137deg, #5220DD 0%, #9747FF 100%)"
        p={[20, 20, 40]}
        display="flex"
        flexDirection="column"
        gap={40}
        borderRadius={5}
      >
        <Box>
          <HeadingLarge color="white" fontSize={[18, 18, 28]}>
            Get the full picture
          </HeadingLarge>
          <Box py={[7.5, 7.5, 15]} />
          <Text color="white" fontSize={[14, 14, 16]}>
            Take a look at how your body's core systems are impacted by your biomarkers and your
            symptoms. Additionally, explore your personalised and prioritised recommendation
            according to your results.
          </Text>
        </Box>
        {/* TODO - we need a new button component where width is controllable to avoid doing this */}
        <Box display="flex">
          <SolidChevronButton
            bg="green"
            borderColor="green"
            onClick={() => {
              history.push({
                pathname: getThemeUrl("health")
              });
            }}
          >
            See your health scores
          </SolidChevronButton>
        </Box>
      </Box>
    </PanelBoxV2>
  );
}

export function NonGenesBiomarkers({ showFullExplanation, userTest, clientId }) {
  const ctx = useContext(CardListContext);
  const viewerContext = useContext(ViewerContext);

  const controls = useListControls({
    sortOptions: [
      {
        label: "Biomarkers: A - Z",
        value: "datapoint__category,datapoint__name"
      },
      {
        label: "Biomarkers: Z - A",
        value: "-datapoint__category,-datapoint__name"
      },
      {
        label: "Biomarkers: Low to High",
        value: "-normalised_order"
      },
      {
        label: "Biomarkers: High to Low",
        value: "normalised_order"
      }
    ]
  });

  const { data, loading, fetchMore } = useDataLoader({
    query: TEST_BIOMARKERS_CONNECTION_QUERY,
    variables: {
      userTestId: userTest.id,
      userId: viewerContext?.userId,
      first: PAGE_SIZE,
      after: "",
      orderBy: controls.sort,
      search: controls.debouncedSearch
    }
  });

  const userDatapoints = data?.userDatapointConnections?.edges.map(edge => edge.node) || [];

  return (
    <>
      {showFullExplanation && <FullPictureBlock />}
      <LabReports userTest={userTest} clientId={clientId} />
      <Explanation />
      <PanelBoxV2 maxWidth={[1020]}>
        <Box
          display="flex"
          flexDirection={["column", "row", "row"]}
          gap={20}
          justifyContent={["center", "flex-end", "flex-end"]}
        >
          <Search value={controls.search} setValue={controls.setSearch} />
          <Box display="flex" justifyContent="flex-end">
            <SortMenu
              value={controls.sort}
              setValue={controls.setSort}
              open={controls.sortMenuOpen}
              setOpen={controls.setSortMenuOpen}
              options={controls.sortOptions}
            />
          </Box>
        </Box>
      </PanelBoxV2>
      <PanelBoxV2
        maxWidth={[1020]}
        inner={{
          display: "flex",
          flexDirection: "column",
          gap: 10
        }}
      >
        {userDatapoints?.length ? (
          <InfiniteScroll
            loader={<Loading />}
            hasMore={data?.userDatapointConnections?.pageInfo?.hasNextPage}
            loading={loading}
            next={() => {
              const endCursor = data?.userDatapointConnections?.pageInfo?.endCursor;
              if (endCursor) {
                fetchMore({
                  variables: {
                    after: endCursor,
                    first: 10
                  }
                });
              }
            }}
            items={userDatapoints}
          >
            <RelatedResultContext.Provider
              value={{
                openModal: result => {
                  ctx.viewCard({
                    type: "results",
                    item: result
                  });
                }
              }}
            >
              <InfiniteScrollContext.Consumer>
                {({ itemsList, setBottomElement }) => (
                  <>
                    {itemsList.slice(0, -1).map(item => (
                      <DatapointChartCard
                        userDatapoint={item}
                        key={item.id}
                        onClick={() => {
                          ctx?.viewCard({
                            type: "datapoints",
                            item
                          });
                        }}
                        includeRelatedResults={false}
                      />
                    ))}
                    {itemsList.slice(-1).map(item => (
                      <DatapointChartCard
                        userDatapoint={item}
                        key={item.id}
                        onClick={() => {
                          ctx?.viewCard({
                            type: "datapoints",
                            item
                          });
                        }}
                        includeRelatedResults={false}
                        ref={setBottomElement}
                      />
                    ))}
                  </>
                )}
              </InfiniteScrollContext.Consumer>
            </RelatedResultContext.Provider>
          </InfiniteScroll>
        ) : (
          <NoItems search={controls.search} loading={loading} />
        )}
      </PanelBoxV2>
    </>
  );
}

NonGenesBiomarkers.defaultProps = {
  showFullExplanation: true
};

export function NonGenesTestContent({ showFullExplanation, userTest, clientId }) {
  const { cardOpen, viewCard, setCardOpen } = useCardList();

  return (
    <Stack gap={[20, 20, 40]}>
      <CardListContext.Provider
        value={{
          viewCard
        }}
      >
        <NonGenesBiomarkers
          userTest={userTest}
          showFullExplanation={showFullExplanation}
          clientId={clientId}
        />
      </CardListContext.Provider>
      <ChartModal close={() => setCardOpen(undefined)} show={!!cardOpen?.type}>
        <ModelContent type={cardOpen?.type} item={cardOpen?.item} />
      </ChartModal>
    </Stack>
  );
}

NonGenesTestContent.defaultProps = {
  showFullExplanation: true
};

export function NonGenesTest({ userTest }) {
  return (
    <PanelBoxV2
      maxWidth={1538}
      outer={{
        pt: [30, 30, 60],
        pb: [50, 50, 80],
        px: [20, 20, 0]
      }}
      inner={{
        display: "flex",
        flexDirection: "column",
        gap: [20, 20, 40]
      }}
    >
      <NonGenesTestContent userTest={userTest} />
    </PanelBoxV2>
  );
}

function UserTestContent({ allUserTests }) {
  const { clientId } = useParams();
  const isPartnerView = !!clientId;
  const { userTestId } = useParams();
  const history = useHistory();

  const userTest = allUserTests.find(userTest => userTest.id === userTestId);

  const isGenesTest = userTest?.isGenesTest;

  const handlePrevUserTest = useCallback(() => {
    const currentIndex = allUserTests.findIndex(userTest => userTest.id === userTestId);
    const nextUserTestId =
      currentIndex > 0
        ? allUserTests[currentIndex - 1].id
        : allUserTests[allUserTests.length - 1].id;
    const url = isPartnerView
      ? getPartnerTestResultsUrl(clientId, nextUserTestId)
      : getTestResultsUrl(nextUserTestId);
    return history.push(url);
  }, [history, isPartnerView, clientId, allUserTests, userTestId]);

  const handleNextUserTest = useCallback(() => {
    const currentIndex = allUserTests.findIndex(userTest => userTest.id === userTestId);
    const nextUserTestId =
      currentIndex < allUserTests.length - 1
        ? allUserTests[currentIndex + 1].id
        : allUserTests[0].id;
    const url = isPartnerView
      ? getPartnerTestResultsUrl(clientId, nextUserTestId)
      : getTestResultsUrl(nextUserTestId);
    return history.push(url);
  }, [history, isPartnerView, clientId, allUserTests, userTestId]);

  if (isUndefined(userTest)) {
    return <Redirect to={ERROR_404_URL} />;
  }

  return (
    <Page bg="haze" includeFooter>
      <JumbotronV2
        title="Test Results"
        display={["none", "none", "block"]}
        // top={<IncludeExpiredMarkers />}
      />
      <PanelBoxV2
        outer={{
          bg: "white",
          py: [30, 30, 60],
          pb: [50, 50, 80]
        }}
      >
        <PanelBoxV2
          maxWidth={[464, 464, 464, 500]}
          outer={{
            px: [20, null]
          }}
        >
          <UserTestsLoader isPartnerView={isPartnerView} clientId={clientId} />
        </PanelBoxV2>
        <Spacer py={[2, 2, 20]} />
        <UserTestsCarousel
          index={allUserTests.findIndex(userTest => userTest.id === userTestId)}
          userTests={allUserTests.map(userTest => ({
            bg: userTest.testProduct?.productFamily?.familyColour || "white",
            name: userTest?.name || "",
            id: userTest.id,
            date: userTest.sampleCollectedDateFormatted
          }))}
          handlePrev={handlePrevUserTest}
          handleNext={handleNextUserTest}
          bg="white"
          buttonBg="haze"
        />
      </PanelBoxV2>
      {isGenesTest ? <GenesTest userTest={userTest} /> : <NonGenesTest userTest={userTest} />}
    </Page>
  );
}

function UserTest() {
  const { clientId, userTestId } = useParams();
  const isPartnerView = !!clientId;

  return (
    <DataLoader
      key={userTestId}
      query={ALL_TESTS_QUERY}
      variables={
        isPartnerView
          ? {
              userId: clientId,
              allTests: true
            }
          : null
      }
      render={({ allUserTests }) => (
        <ViewerContext.Provider
          value={{
            userId: clientId
          }}
        >
          <UserTestContent allUserTests={allUserTests} />
        </ViewerContext.Provider>
      )}
    />
  );
}

export default UserTest;
