import { useRef } from "react";

import styled from "styled-components";

import Box from "../components/Box";
import useWindowSize from "../hooks/use-window-size";

const SwitchContainer = styled(Box)`
  display: flex;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  position: relative;
  padding: 1px;

  div {
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    border-radius: ${props => props.borderRadius}px;
  }
`;

export default function Switch({ falselyBg, truthyBg, data, onChange, height, value, ml, mr }) {
  const refs = useRef({});

  useWindowSize();
  // otherwise the widths calculated for the "transform" below are wrong
  // if the user resizes the browser

  return (
    <Box display="flex" ml={ml} mr={mr}>
      <SwitchContainer bg={value ? truthyBg : falselyBg} borderRadius={height}>
        <Box
          height="100%"
          top={0}
          left={0}
          width={`${100 / data.length}%`}
          position="absolute"
          style={{
            transition: "transform 200ms ease 0s, width 100ms ease 0s",
            transform: `translate(${(function () {
              const index = data.findIndex(item => item.value === value);
              const itemsBefore = Object.values(refs.current).slice(0, index);
              const width = itemsBefore.reduce((accumulatedWidth, item) => {
                const elem = item.current;
                const { width } = elem.getBoundingClientRect();
                return accumulatedWidth + width;
              }, 0);
              return `${width}px`;
            })()})`
          }}
        />
        {data.map((item, index) => (
          <Box
            className="item"
            key={item.value}
            onClick={() => {
              if (item.value !== value) {
                onChange(item.value);
              }
            }}
            bg={item.value === value ? item.bg : null}
            color={item.color}
            borderRadius={item.value === value ? "100%" : null}
            height={height}
            width={height}
            style={{
              transition:
                item.value === value ? "border-left-color 200ms ease 0s" : "color 200ms ease 0s"
            }}
            ref={elem => {
              if (!(index in refs.current)) {
                refs.current[index] = {
                  current: elem
                };
              } else {
                refs.current[index].current = elem;
              }
            }}
            fontFamily="gilroyBold"
            fontSize={12}
          >
            {item.value === value ? item.label : null}
          </Box>
        ))}
      </SwitchContainer>
    </Box>
  );
}

Switch.defaultProps = {
  falselyBg: "green",
  truthyBg: "rgba(255, 255, 255, 0.30)",
  padding: 2,
  data: [
    {
      label: "No",
      value: false,
      bg: "dark",
      color: "white"
    },
    {
      label: "Yes",
      value: true,
      bg: "white",
      color: "dark"
    }
  ]
};
