import { Redirect, useHistory, useLocation } from "react-router-dom";

import Box from "../components/Box";
import Text from "../components/Text";
import { DASHBOARD_HOME_URL, getQuestionnaireIntroUrl, getTestUrl } from "../core/urls";
import useDocTitle from "../hooks/use-doc-title";
import { PanelBox } from "./Boxes";
import { SolidChevronButton } from "./ChevronButton";
import { HeadingExtraSmall } from "./Headings";
import { useTestProduct } from "./QuestionnaireHooks";
import { LoadingResults, Results, useOutro } from "./QuestionnaireOutro";
import Page from "./QuestionnairePages";
import { usedAppBackButton, usedQuestionnaireNavigation } from "./QuestionnaireUtilities";
import Section from "./Section";
import TestProductResponsiveImage, { TestProductImage } from "./TestProductResponsiveImage";
import { BestMatchedTest } from "./TestRecommendations";

function TestProductQuestionnaireResults({ userTestRecommendation }) {
  useDocTitle("Questionnaire Results");
  const history = useHistory();

  return (
    <Page
      header={userTestRecommendation.product.name}
      Main={
        <PanelBox medium>
          <PanelBox bg="white" nested small verticalPadding outer={{ mb: 20 }}>
            <Box display="flex" flexDirection={["column", "column", "row"]}>
              <Box
                position="relative"
                width={["100%", "100%", "50%"]}
                display="flex"
                justifyContent="flex-end"
                pr={[0, 0, 20]}
                height={["300px", "300px", "auto"]}
              >
                <TestProductImage background="#F2F2F2" minHeight="300px" height="100%" width="100%">
                  <TestProductResponsiveImage
                    images={userTestRecommendation.product.shot1Grey.map(image => ({
                      width: image.width,
                      image: image.resizeUrl
                    }))}
                    pixelDensity={2}
                  />
                </TestProductImage>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                width={["100%", "100%", "50%"]}
                pl={[0, 0, 20]}
                mt={[50, 50, 0]}
              >
                <BestMatchedTest.TestMatchDegree userTestRecommendation={userTestRecommendation} />
                <BestMatchedTest.SymptomList userTestRecommendation={userTestRecommendation} />
                <SolidChevronButton
                  paddingSize="mediumNarrow"
                  bg="green"
                  borderColor="green"
                  color="white"
                  mr="auto"
                  handleClick={() =>
                    history.push({
                      pathname: getTestUrl(
                        userTestRecommendation.product.slug,
                        userTestRecommendation.product.productFamily.slug
                      )
                    })
                  }
                  mt={40}
                >
                  Buy now
                </SolidChevronButton>
              </Box>
            </Box>
            <Box mt={40}>
              <Section.Header>
                <HeadingExtraSmall fontSize={18}>Next steps</HeadingExtraSmall>
              </Section.Header>
              <Text>
                Complete our free symptoms questionnaire and we’ll recommend the most appropriate
                test for you across our full suite of available tests. We’ll also provide an overall
                wellness score and lifestyle recommendations.
              </Text>
              <SolidChevronButton
                paddingSize="mediumNarrow"
                bg="green"
                borderColor="green"
                color="white"
                mr="auto"
                mt={4}
                handleClick={() => history.push(getQuestionnaireIntroUrl("symptoms"))}
              >
                self assessment
              </SolidChevronButton>
            </Box>
          </PanelBox>
        </PanelBox>
      }
    />
  );
}

function TestProductQuestionnaireOutroComponent({ product }) {
  const { bestMatchedTest } = useOutro(product, true);

  if (!bestMatchedTest?.show) {
    return <LoadingResults />;
  }

  return (
    <Results
      threshold={0}
      userTestRecommendation={bestMatchedTest.test}
      Component={TestProductQuestionnaireResults}
    />
  );
}

function TestProductQuestionnaireOutro() {
  const history = useHistory();
  const location = useLocation();
  const testProductData = useTestProduct();

  if (!usedQuestionnaireNavigation(location) || usedAppBackButton(history)) {
    return <Redirect to={DASHBOARD_HOME_URL} />;
  }

  if (!testProductData) {
    return <LoadingResults />;
  }

  return <TestProductQuestionnaireOutroComponent product={testProductData.testProduct.id} />;
}

export default TestProductQuestionnaireOutro;
