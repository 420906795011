import { isUndefined, round } from "lodash";

import Box from "../components/Box";
import Text from "../components/Text";
import { pairwise } from "../core/utils";
import { ReactComponent as Avatar } from "../images/tpo/avatar-tooltip-upwards.svg";
import { HeadingExtraExtraSmall } from "./Headings";
import PercentageBar from "./PercentageBar";

/**
 * Ideally the gql should implement a union of possible chart types.
 * Each type in the union would then feed the props needed for the react component.
 * Alas, apolloJS doesn't seem to be working when I try and set it up for unions and interfaces.
 * So we'll need to bump apolloJS first before switching to this approach on the BE.
 *
 * For now we need to do things like check terminology to know how to interpret the colours
 * given a value.  All this should be moved to the BE once apolloJS has been bumped.
 */

export function UnknownChart() {
  return (
    <Text
      fontFamily="gilroyBold"
      fontSize={[14, 16]}
      textTransform="uppercase"
      textAlign="center"
      mt={2}
      mb={4}
    >
      results unavailable
    </Text>
  );
}

function Point({ label }) {
  return (
    <Box position="relative" fontSize="10px">
      <Box>{label}</Box>
      <Box display="flex" justifyContent="center">
        <Box height="5px" width="1px" backgroundColor="black" />
      </Box>
    </Box>
  );
}

function Points({ points }) {
  return (
    <Box position="relative" height={20}>
      {points
        .filter(point => point.position >= 0 && point.position <= 100)
        .map((point, index) => (
          <Box
            key={point.position}
            position="absolute"
            left={`${point.position}%`}
            transform="translate(-50%)"
          >
            <Point label={point.label} />
          </Box>
        ))}
    </Box>
  );
}

function ValueIcon({ valuePerCent }) {
  return (
    <Box position="relative" height={30}>
      <Box position="absolute" transform="translateX(-50%)" left={`${valuePerCent}%`}>
        <Avatar />
      </Box>
    </Box>
  );
}

function BaseGradientChart({ chart, points, value }) {
  // Gradiant Chart: Low, Below, Optimal, Above, High

  return (
    <>
      <Points points={points} />
      {chart}
      <ValueIcon valuePerCent={value} />
    </>
  );
}

/**
 * Sometimes the points overlap.  How should we handle this?
 * One option would be to may be use a different gradient chart if there is a
 * too small gap between points.
 */

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
    : null;
}

export const TERMINOLOGY_TO_GRADIENT = {
  "low,below optimal,optimal,above optimal,high": {
    angle: "90deg",
    gradient: [
      [0, hexToRgb("#FF5D6D")],
      [18.87, hexToRgb("#FCCE57")],
      [50.2, hexToRgb("#00BF86")],
      [81.25, hexToRgb("#FFD558")],
      [100, hexToRgb("#FF5D6D")]
    ]
  },
  "optimal,above optimal,high": {
    angle: "90deg",
    gradient: [
      [0, hexToRgb("#00BF86")],
      [51.04, hexToRgb("#FFD558")],
      [100, hexToRgb("#FF5D6D")]
    ]
  },
  "low,below optimal,optimal": {
    angle: "90deg",
    gradient: [
      [0, hexToRgb("#FF5D6D")],
      [50, hexToRgb("#FCCE57")],
      [75, hexToRgb("#00BF86")]
    ]
  },
  "below optimal,optimal,above optimal": {
    angle: "90deg",
    gradient: [
      [0, hexToRgb("#FCA557")],
      [25, hexToRgb("#FCCE57")],
      [50, hexToRgb("#00BF86")],
      [75, hexToRgb("#FCCE57")],
      [100, hexToRgb("#FCA557")]
    ]
  },
  "below optimal,optimal,above optimal,high": {
    angle: "90deg",
    gradient: [
      [25, hexToRgb("#FCCE57")],
      [40, hexToRgb("#00BF86")],
      [55, hexToRgb("#FCCE57")],
      [100, hexToRgb("#FF5D6D")]
    ]
  },
  "low,below optimal,optimal,above optimal": {
    angle: "90deg",
    gradient: [
      [0, hexToRgb("#FF5D6D")],
      [35.85, hexToRgb("#FCCE57")],
      [63.92, hexToRgb("#00BF86")],
      [87.5, hexToRgb("#FFD558")]
    ]
  },
  "below optimal,optimal": {
    angle: "90deg",
    gradient: [
      [0, hexToRgb("#FCA557")],
      [50, hexToRgb("#FCCE57")],
      [100, hexToRgb("#00BF86")]
    ]
  },
  "optimal,above optimal": {
    angle: "90deg",
    gradient: [
      [0, hexToRgb("#00BF86")],
      [51.04, hexToRgb("#FFD558")],
      [98.96, hexToRgb("#FCA557")]
    ]
  }
};

export function rgbArrayToString(rgbArr) {
  return `rgb(${rgbArr.join(",")})`;
}

export function constructGradientFromTerms(terms, deg) {
  if (terms in TERMINOLOGY_TO_GRADIENT) {
    const bits = TERMINOLOGY_TO_GRADIENT[terms];
    const gradient = bits.gradient.map(
      ([percent, colour]) => `${rgbArrayToString(colour)} ${percent}%`
    );
    return `linear-gradient(${deg || bits.angle}, ${gradient.join(",")})`;
  }
  return null;
}

function pickHex(color1, color2, weight) {
  var p = weight;
  var w = p * 2 - 1;
  var w1 = (w / 1 + 1) / 2;
  var w2 = 1 - w1;
  var rgb = [
    Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2)
  ];
  return rgb;
}

// Inspired by: https://stackoverflow.com/a/30144587
export function getColourAtPointOnGradient(gradient, valuePerCent) {
  const index = gradient.findIndex(([percent]) => valuePerCent <= percent);
  if (index === -1) return "dark";
  if (index === 0) return gradient[0][1];
  const firstColor = gradient[index - 1][1];
  const secondColor = gradient[index][1];
  const min = gradient[index - 1][0];
  const max = gradient[index][0];
  const ratio = 1 - (valuePerCent - min) / (max - min);
  return pickHex(firstColor, secondColor, ratio);
}

export function useGradientChart({ normalisedRange, range, terminology, title }) {
  if (normalisedRange.range.length !== range.length) {
    return null;
  }

  const minimum = normalisedRange.minimum;
  const maximum = normalisedRange.maximum;
  const value = normalisedRange.value;

  const fullRange = [
    {
      value: minimum,
      position: 0
    },
    ...normalisedRange.range.map((point, index) => ({
      value: round(point, 2),
      label: round(range[index], 2),
      position:
        (100 / normalisedRange.range.length) * index + 100 / normalisedRange.range.length / 2
    })),
    {
      value: maximum,
      position: 100
    }
  ];

  const boundedValue = round(Math.max(minimum, Math.min(value, maximum)), 2);
  const upperLimitIndex = fullRange.findIndex(point => boundedValue <= point.value);
  const lowerLimitIndex = upperLimitIndex > 0 ? upperLimitIndex - 1 : 0;

  let normalisedValue;

  if (upperLimitIndex === lowerLimitIndex) {
    normalisedValue = fullRange[upperLimitIndex].position;
  } else {
    const min = fullRange[lowerLimitIndex].value;
    const max = fullRange[upperLimitIndex].value;
    const ratio = (boundedValue - min) / (max - min);
    const distance = fullRange[upperLimitIndex].position - fullRange[lowerLimitIndex].position;
    normalisedValue = fullRange[lowerLimitIndex].position + distance * ratio;
  }

  const background = constructGradientFromTerms(terminology.join(","));
  const points = fullRange.filter(point => !isUndefined(point.label));

  return {
    background,
    points,
    value: normalisedValue
  };
}

export function GradientChart({ background, points, value, hasExpired }) {
  return (
    <BaseGradientChart
      chart={
        <Box
          background={background}
          borderRadius="100px"
          height={"10px"}
          filter={hasExpired ? "saturate(0%)" : null}
        />
      }
      points={points}
      value={value}
    />
  );
}

function Interval({ borderRadius, bg, width, height }) {
  return <Box bg={bg} borderRadius={borderRadius} height={height} width={width} />;
}

function Intervals({ bg, intervals = [], borderRadius, height, filter }) {
  return (
    <Box display="flex" bg={bg} borderRadius={borderRadius} height={height} filter={filter}>
      {intervals.map((interval, index) => (
        <interval.Component borderRadius={borderRadius} key={index} {...interval.props} />
      ))}
    </Box>
  );
}

Intervals.defaultProps = {
  borderRadius: 100,
  height: "10px"
};

function BaseDiscreteChart({ IntervalComponent, bg, values, height, hasExpired }) {
  return (
    <Intervals
      filter={hasExpired ? "saturate(0%)" : null}
      bg={bg}
      height={height}
      intervals={values.map(value => ({
        Component: IntervalComponent,
        props: {
          bg: value.bg,
          width: `${100 / values.length}%`,
          meta: { value }
        }
      }))}
    />
  );
}

BaseDiscreteChart.defaultProps = {
  bg: "haze",
  IntervalComponent: Interval
};

export function isWithinRange(value, range, isMaximum) {
  if (value >= range[0] && (isMaximum ? value <= range[1] : value < range[1])) return true;
  return false;
}

function BaseDiscreteChartWithTermName({ terms, values, hasExpired, wordProps }) {
  return (
    <>
      <BaseDiscreteChart values={values} hasExpired={hasExpired} />
      <Box display="flex" mt={2} fontSize="8px">
        {terms.map((term, index) => (
          <Box
            textTransform="uppercase"
            textAlign="center"
            key={index}
            width={`${100 / terms.length}%`}
            display="flex"
            alignItems="center"
            borderRight="1px solid black"
            {...wordProps}
          >
            <Box backgroundColor="black" height="1px" flexGrow={1} flexShrink={1} minWidth="10%" />
            <Box fontFamily="gilroyBold" mx="5%">
              {term}
            </Box>
            <Box backgroundColor="black" height="1px" flexGrow={1} flexShrink={1} minWidth="10%" />
          </Box>
        ))}
      </Box>
    </>
  );
}

export function useDiscreteChart({ colours, terminology, normalisedRange }) {
  const range = [normalisedRange.minimum, ...normalisedRange.range, normalisedRange.maximum];
  const value = normalisedRange.value;

  const values = pairwise(range).map(([pointA, pointB], index) => ({
    bg: isWithinRange(value, [pointA, pointB], range[range.length - 1] === pointB)
      ? colours[index]
      : "transparent"
  }));

  return {
    terms: terminology,
    values,
    interpretationColor: values.find(value => value.bg !== "transparent")?.bg
  };
}

export function DiscreteChart({ terms, values, hasExpired, wordProps }) {
  return (
    <BaseDiscreteChartWithTermName
      terms={terms}
      values={values}
      hasExpired={hasExpired}
      wordProps={wordProps}
    />
  );
}
export function BaseSegmentedProgressChart({
  colours,
  fill = false,
  backgroundColor,
  defaultColor
}) {
  const intervals = colours
    .map(colour => ({
      Component: Interval,
      props: {
        bg: colour,
        width: "10%"
      }
    }))
    .concat(
      fill
        ? Array.from(Array(10 - colours.length).keys()).map(() => ({
            Component: Interval,
            props: { bg: defaultColor, width: "10%" }
          }))
        : []
    );

  return <Intervals bg={backgroundColor} intervals={intervals} />;
}

BaseSegmentedProgressChart.defaultProps = {
  borderRadius: 100,
  height: "10px"
};

export function useSegmentedProgressChart({
  colours: allColours,
  value,
  fill,
  defaultColor = "haze",
  backgroundColor = "white"
}) {
  // TODO change "colours" to "color" to be consistent

  const colours = allColours?.length
    ? allColours.filter((colour, index) => index * 10 <= value * 100)
    : [];

  return {
    colours,
    fill,
    defaultColor,
    backgroundColor,
    interpretationColor: colours.slice(-1)?.[0]
  };
}

export function SegmentedProgressChart({
  colours,
  fill,
  defaultColor = "haze",
  backgroundColor = "white"
}) {
  // if (!colours?.length) return null;

  // default color is the default color of a segment
  // background color is the background color of the container

  return (
    <BaseSegmentedProgressChart
      colours={colours}
      fill={fill}
      defaultColor={defaultColor}
      backgroundColor={backgroundColor}
    />
  );
}

function MidPoint(props) {
  return (
    <Box position="relative" width={props.width}>
      <Box left="50%" position="absolute" transform="translate(-50%)">
        <Point label={props.meta.value.term} />
      </Box>
    </Box>
  );
}

function ValueIconUndernearth(props) {
  return (
    <Box width={props.width}>
      {props.meta.value.showValueIcon ? <ValueIcon valuePerCent={50} /> : null}
    </Box>
  );
}

function BaseSegmentedChart({ discreteValues, value, terms, hasExpired, showValueIcon = true }) {
  if (discreteValues === null || terms === null) return null;

  return (
    <>
      <BaseDiscreteChart
        bg="transparent"
        borderRadius={0}
        height="20px"
        values={terms}
        IntervalComponent={MidPoint}
      />
      <BaseDiscreteChart values={discreteValues} hasExpired={hasExpired} />
      {showValueIcon && (
        <BaseDiscreteChart
          bg="transparent"
          borderRadius={0}
          height="20px"
          values={value}
          IntervalComponent={ValueIconUndernearth}
        />
      )}
    </>
  );
}

export function useSegmentedChart({ colours, normalisedRange, terminology, interpretation }) {
  const range = [normalisedRange?.minimum, ...normalisedRange?.range, normalisedRange?.maximum];
  const segments = pairwise(range).map((points, index) => index);

  return {
    terms:
      segments?.length && segments.length === terminology?.length
        ? segments.map(index => ({
            bg: "transparent",
            term: terminology?.[index]
          }))
        : null,
    discreteValues:
      segments?.length && segments.length === colours?.length
        ? segments.map(index => ({ bg: colours?.[index] || "transparent" }))
        : null,
    value: segments?.map(index => ({ showValueIcon: terminology?.[index] === interpretation }))
  };
}

export function SegmentedChart({ terms, discreteValues, value, hasExpired, showValueIcon }) {
  return (
    <BaseSegmentedChart
      terms={terms}
      discreteValues={discreteValues}
      value={value}
      hasExpired={hasExpired}
      showValueIcon={showValueIcon}
    />
  );
}

export function GenesAllelesRowChart({ color, risk, snp, type }) {
  return (
    <Box display="flex" justifyContent="space-between" mt={30} mb={15}>
      <Box>
        <Box>
          <HeadingExtraExtraSmall>SNP</HeadingExtraExtraSmall>
        </Box>
        <Box>{snp}</Box>
      </Box>
      <Box>
        <Box>
          <HeadingExtraExtraSmall>Type</HeadingExtraExtraSmall>
        </Box>
        <Box>{type}</Box>
      </Box>
      <Box>
        <Box>
          <HeadingExtraExtraSmall>Risk</HeadingExtraExtraSmall>
        </Box>
        <Box color={color}>{risk}</Box>
      </Box>
    </Box>
  );
}
