import gql from "graphql-tag";

export const SUBSECTOR_FIELDS_TRUNCATED = gql`
  fragment SubsectorFieldsTruncated on SubsectorType {
    id
    name
    score
  }
`;

export const SECTOR_FIELDS_TRUNCATED = gql`
  fragment SectorFieldsTruncated on SectorType {
    id
    name
    score
    subsectorSet {
      ...SubsectorFieldsTruncated
    }
  }
  ${SUBSECTOR_FIELDS_TRUNCATED}
`;

export const THEME_FIELDS_TRUNCATED = gql`
  fragment ThemeFieldsTruncated on ThemeType {
    id
    name
    score
    sectorSet {
      ...SectorFieldsTruncated
    }
  }
  ${SECTOR_FIELDS_TRUNCATED}
`;

export const SUBSECTOR_FIELDS = gql`
  fragment SubsectorFields on SubsectorType {
    id
    name
    score
    description
  }
`;

export const USER_SUBSECTOR_FIELDS = gql`
  fragment UserSubsectorFields on UserSubsectorType {
    id
    name
    slug
    priority
    score
  }
`;

export const SECTOR_FIELDS = gql`
  fragment SectorFields on SectorType {
    id
    name
    score
    description
    subsectorSet {
      ...SubsectorFields
    }
    slug
  }
  ${SUBSECTOR_FIELDS}
`;

export const USER_SECTOR_FIELDS = gql`
  fragment UserSectorFields on UserSectorType {
    id
    name
    slug
    theme
    sortOrder
    description
    score
    userSubsectors {
      ...UserSubsectorFields
    }
  }
  ${USER_SUBSECTOR_FIELDS}
`;

export const THEME_FIELDS = gql`
  fragment ThemeFields on ThemeType {
    id
    name
    score
    description
    sectorSet {
      ...SectorFields
    }
    userSectors {
      ...UserSectorFields
    }
  }
  ${SECTOR_FIELDS}
  ${USER_SECTOR_FIELDS}
`;

export const CORE_USER_THEME_FIELDS = gql`
  fragment CoreUserThemeFields on UserThemeType {
    id
    name
    score
    invertedScore
    description
    slug
    scoreInterpretations {
      name
      score
    }
  }
`;

export const CORE_USER_SECTOR_FIELDS = gql`
  fragment CoreUserSectorFields on UserSectorType {
    id
    name
    score
    invertedScore
    description
    slug
  }
`;

export const CORE_USER_SUBSECTOR_FIELDS = gql`
  fragment CoreUserSubsectorFields on UserSubsectorType {
    id
    name
    score
    invertedScore
    slug
    priority
    description
  }
`;

export const CORE_USER_THEME_WITH_SECTORS_FIELDS = gql`
  fragment CoreUserThemeWithSectorsFields on UserThemeType {
    ...CoreUserThemeFields
    chartConfiguration {
      colours
      normalisedRange {
        value
      }
    }
    userSectors {
      ...CoreUserSectorFields
      chartConfiguration {
        colours
        normalisedRange {
          value
        }
      }
      userSubsectors {
        ...CoreUserSubsectorFields
      }
    }
  }
  ${CORE_USER_THEME_FIELDS}
  ${CORE_USER_SECTOR_FIELDS}
  ${CORE_USER_SUBSECTOR_FIELDS}
`;

// export const USER_THEME_FIELDS = gql`
//   fragment UserThemeFields on UserThemeType {

//   }
// `;
