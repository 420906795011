import Box from "../components/Box";

function Center({ children, ...rest }) {
  return <Box {...rest}>{children}</Box>;
}

Center.defaultProps = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};

export default Center;
